@use "sass:math";

@function get-line-height($font-size, $line-height) {
  @return math.div($line-height, $font-size);
}

@function rem($value, $base: $font-size) {
  @return ($value / $base) + rem;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: $global-font-family;
  -webkit-font-smoothing: antialiased;
  height: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::selection {
  background: $brand-color;
  color: #fff;
  text-shadow: none;
}

body {
  background-color: #fff;
  color: $font-color;
  font-family: $global-font-family;
  font-size: $font-size;
  line-height: $global-line-height;
  padding-top: 102px;

  @include screen-md-min {
    &.main-nav-page {
      padding-top: 0;
    }
  }

  &.overflow-hidden {
    //visibility: hidden;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
  }
}

.page-main {
  background-color: #fff;
  position: relative;
  z-index: 3;

  @include screen-sm {
    margin-bottom: 0 !important;
  }
}

img {
  display: block;
  height: auto;
  max-width: 100%;
}

ul,
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h5u {
  font-family: $heading-font;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 1em;
}

h1,
.h1 {
  font-size: 58px;
  line-height: get-line-height(48px, 58px);

  @include screen-max {
    font-size: 54px;
  }

  @include screen-lg {
    font-size: 48px;
  }

  @include screen-sm {
    font-size: 44px;
  }

  @include screen-xs {
    font-size: 36px;
  }
}

h2,
.h2 {
  font-size: 44px;
  letter-spacing: 0.03em;
  line-height: get-line-height(36px, 44px);
  margin-bottom: rem(20px);

  @include screen-max {
    font-size: 40px;
  }

  @include screen-lg {
    font-size: 36px;
  }

  @include screen-sm {
    font-size: 32px;
  }

  @include screen-xs {
    font-size: 28px;
  }
}

h3,
.h3 {
  font-size: 30px;
  letter-spacing: 0.02em;
  line-height: get-line-height(24px, 30px);

  @include screen-max {
    font-size: 28px;
  }

  @include screen-lg {
    font-size: 24px;
  }

  @include screen-sm {
    font-size: 22px;
  }
}

h4,
.h4 {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: get-line-height(20px, 24px);
  text-transform: uppercase;

  @include screen-max {
    font-size: 22px;
  }

  @include screen-lg {
    font-size: 20px;
  }

  @include screen-sm {
    font-size: 18px;
  }
}

h5,
.h5 {
  font-size: 24px;
  letter-spacing: 0.02em;
  line-height: get-line-height(18px, 22px);

  @include screen-max {
    font-size: 22px;
  }

  @include screen-lg {
    font-size: 18px;
  }

  @include screen-sm {
    font-size: 16px;
  }

  @include screen-xs {
    font-size: 18px;
  }
}

h6,
.h6 {
  font-size: 18px;
  line-height: get-line-height(14px, 16px);

  @include screen-max {
    font-size: 18px;
  }

  @include screen-lg {
    font-size: 14px;
  }

  @include screen-xs {
    font-size: 16px;
  }
}

.h5u {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: get-line-height(20px, 24px);

  @include screen-max {
    font-size: 22px;
  }

  @include screen-lg {
    font-size: 18px;
  }

  @include screen-sm {
    font-size: 16px;
  }
}

.p1,
.p2,
.p3,
.p4,
.p5,
.p3u {
  font-family: $font-family-secondary;
}

.p1 {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.04em;
  line-height: 162%;

  @include screen-max {
    font-size: 17px;
  }

  @include screen-lg {
    font-size: 16px;
  }

  @include screen-sm {
    font-size: 15px;
  }

  @include screen-xs {
    font-size: 16px;
  }
}

.p2 {
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.04em;
  line-height: 167%;

  @include screen-max {
    font-size: 14px;
  }

  @include screen-lg {
    font-size: 12px;
  }

  @include screen-sm {
    font-size: 12px;
  }

  @include screen-xs {
    font-size: 14px;
  }
}

.p3 {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 150%;

  @include screen-max {
    font-size: 14px;
  }

  @include screen-lg {
    font-size: 12px;
  }

  @include screen-sm {
    font-size: 12px;
  }

  @include screen-xs {
    font-size: 14px;
  }
}

.p4 {
  font-size: 13px;
  font-weight: 400;
  line-height: 145%;

  @include screen-max {
    font-size: 12px;
  }

  @include screen-lg {
    font-size: 11px;
  }

  @include screen-sm {
    font-size: 11px;
  }

  @include screen-xs {
    font-size: 12px;
  }
}

.p5 {
  font-size: 12px;
  font-weight: 400;
  line-height: get-line-height(12px, 18px);

  @include screen-max {
    font-size: 11px;
  }

  @include screen-lg {
    font-size: 10px;
  }

  @include screen-sm {
    font-size: 10px;
  }

  @include screen-xs {
    font-size: 11px;
  }
}

.p3u {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 167%;

  @include screen-max {
    font-size: 14px;
  }

  @include screen-lg {
    font-size: 12px;
  }

  @include screen-sm {
    font-size: 12px;
  }

  @include screen-xs {
    font-size: 11px;
  }
}

.quote {
  font-family: $heading-font;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 140%;

  @include screen-max {
    font-size: 26px;
  }

  @include screen-lg {
    font-size: 20px;
  }

  @include screen-sm {
    font-size: 18px;
  }

  @include screen-xs {
    font-size: 18px;
  }

  &--bold {
    font-weight: 500;
  }
}

input,
textarea,
button {
  //prevent outline
  border: none;

  &:focus {
    outline: none;
  }
}

button {
  background-color: transparent;
  color: inherit;
  font: inherit;
}

h1 {
  margin-bottom: 14px;
}

a {
  outline: none;
  color: $graphite;
  font-weight: 500;

  &:hover {
    color: $apple;
  }
}
// + 76
.container {
  margin: 0 auto;
  max-width: 1070px;
  padding-left: 50px;
  padding-right: 50px;

  @include screen-sm {
    padding-left: 30px;
    padding-right: 30px;
  }

  @include screen-xs {
    padding-left: 20px;
    padding-right: 20px;
  }

  &--post {
    max-width: 990px;
  }

  &--small {
    max-width: 900px;
  }

  &--medium {
    max-width: 990px;
  }

  &--wider {
    max-width: 1180px;
  }

  &--large {
    max-width: 1220px;
  }

  &--max {
    max-width: 1280px;
  }

  &--header {
    max-width: 1420px;
  }

  &--p0 {
    padding: 0;
  }
}

.p0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.top-p0 {
  padding-top: 0 !important;
}

.bottom-p0 {
  padding-bottom: 0 !important;
}

.p20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.top-p20 {
  padding-top: 20px !important;
}

.bottom-p20 {
  padding-bottom: 20px !important;
}

.p40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;

  @include screen-sm {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}

.top-p40 {
  padding-top: 40px !important;

  @include screen-sm {
    padding-top: 20px !important;
  }
}

.bottom-p40 {
  padding-bottom: 40px !important;

  @include screen-sm {
    padding-bottom: 20px !important;
  }
}

.p80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.top-p80 {
  padding-top: 80px !important;
}

.bottom-p80 {
  padding-bottom: 80px !important;
}

.top-p100 {
  padding-top: 100px !important;
}

.p100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.bottom-p100 {
  padding-bottom: 100px !important;
}


.bottom-p140 {
  padding-bottom: 140px !important;
}

.p120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}


.m0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.top-m0 {
  margin-top: 0 !important;
}

.bottom-m0 {
  margin-bottom: 0 !important;
}

.m20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.top-m20 {
  margin-top: 20px !important;
}

.bottom-m20 {
  margin-bottom: 20px !important;
}

.m40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.top-m40 {
  margin-top: 40px !important;
}

.bottom-m40 {
  margin-bottom: 40px !important;
}

.m80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.top-m80 {
  margin-top: 80px !important;
}

.bottom-m80 {
  margin-bottom: 80px !important;
}




.list {
  line-height: 36px;
}

.disable-hover,
.disable-hover * {
  pointer-events: none !important;
}

.list-dotted {
  color: $stone;
  margin-bottom: 40px;
  padding-top: 3px;

  @include screen-xs {
    margin-bottom: 30px;
  }

  li {
    font-weight: normal;
    line-height: get-line-height(12px, 18px);
    margin-bottom: 4px;
    padding-left: 16px;
    position: relative;

    p {
      color: $font-color-secondary;
      margin-bottom: 0;
    }

    &::before {
      color: $stone;
      content: "\e92d";
      font-family: icomoon !important;
      font-size: 8px;
      left: 0;
      position: absolute;
      top: 6px;

      @include screen-max {
        top: 5px;
      }

      @include screen-lg {
        top: 4px;
      }

      @include screen-xs {
        top: 7px;
      }
    }
  }
}

.site-content {
  color: $font-color-secondary;
  padding-bottom: 80px;
  padding-top: 0;

  @include screen-md {
    padding-bottom: 40px;
  }

  @include screen-md {
    padding-top: 0;

    h1 {
      //font-size: 24px;
    }

    h2 {
      //font-size: 22px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $font-color;
    font-family: $heading-font;
  }

  h1 {
    //margin-bottom: .8em;
  }

  h2 {
    margin-bottom: 0.4em;
  }

  p {
    margin-bottom: 0.7em;
  }

  p + h2 {
    margin-top: 1.5em;
  }

  h3 {
    margin-bottom: 20px;
    margin-top: 20px;
    padding-top: 15px;

    @include screen-lg {
      //font-size: 22px;
    }

    @include screen-md {
      //font-size: 20px;
      //margin-bottom: 15px;
    }

    @include screen-xs {
      //font-size: 18px;
      //margin-bottom: 10px;
    }
  }

  a {
    color: $font-color;
    //font-family: $heading-font;
    font-weight: 500;
    text-decoration: underline;

    &:hover {
      color: $apple;
    }
  }

  ul {
    @extend .list-dotted;
  }

  ol {
    color: $stone;
    list-style-position: inside;
    margin-bottom: 40px;
    padding-left: 0;
    padding-top: 3px;

    li {
      font-weight: normal;
      line-height: get-line-height(12px, 18px);
      margin-bottom: 4px;
      padding-left: 0;
      position: relative;
    }
  }

  .nav-404 {
    list-style: none;
  }

  &--template {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.holder-404 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 32px 0 0;

  @include screen-sm {
    flex-direction: column;
  }

  h1 {
    color: $font-color;
    font-family: $heading-font;
    font-size: 48px;
    font-weight: 600;
    letter-spacing: 1.5px;
    line-height: 60px;
    margin-bottom: 30px;

    @include screen-lg {
      font-size: 40px;
    }

    @include screen-md {
      font-size: 30px;
      margin-bottom: 15px;
    }

    @include screen-xs {
      font-size: 24px;
      margin-bottom: 10px;
    }
  }

  p {
    color: $font-color-secondary;
    line-height: 1.8;
    margin-bottom: 40px;

    @include screen-xs {
      font-size: 16px;
      line-height: get-line-height(16px, 25px);
      margin-bottom: 30px;
    }
  }

  .actions {
    align-items: center;
    display: flex;
    padding-top: 5px;

    a {
      color: $font-color;
      display: inline-flex;
      font-family: $heading-font;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1px;

      &:hover {
        color: $apple;
      }
    }

    span {
      display: inline-flex;
      margin-left: 20px;
      margin-right: 20px;
      margin-top: -8px;

      @include screen-xs {
        font-size: 16px;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: -6px;
      }
    }
  }

  img {
    width: 100%;
  }
}

.not-found {
  &__content {
    flex-shrink: 0;
    padding-right: 40px;

    @include screen-sm {
      margin-bottom: 50px;
      padding-right: 0;
      width: 100%;
    }
  }

  &__image {
    max-width: 513px;
    padding-right: 105px;

    @include screen-lg {
      padding-right: 0;
    }
    @include screen-sm {
      max-width: 400px;
    }
  }
}

body.error404 {
  .site-content {
    background: #eef1f6;
  }
}

.video-layout {
  .fancybox-slide {
    background-color: #000;
    padding: 0;

    &.--video {
      padding: 0;
    }
  }

  .fancybox-bg {
    background-color: #000;
    display: none;
    opacity: 1;
  }

  .fancybox-toolbar {
    opacity: 1;
    padding: 22px;
    visibility: visible;
  }

  .fancybox-button {
    background-color: #fff;
    border-radius: 50%;
    color: $font-color-secondary;
    cursor: pointer;
    font-size: 32px;
    height: 36px;
    padding: 0;
    width: 36px;
    z-index: 99999;

    &:hover {
      color: #000;
    }

    span {
      display: block;
    }
  }
}

.slick-navigation {
  //position: absolute;
  //top: 0;
  //left: 0;
  //width: 100%;
  //height: 100%;
}

.fancybox-navigation,
.featured-navigation {
  .fancybox-button,
  .featured-button {
    background: 0 0;
    border-radius: 50%;
    color: $graphite;
    height: 100px;
    padding: 0;
    top: calc(50% - 50px);
    width: 42px;

    &--arrow_left {
      & > div {
        right: 0;

        &::before {
          right: 21px;
          transform: rotate(180deg);
        }
      }
    }

    &--arrow_right {
      & > div {
        left: 0;

        &::before {
          left: 21px;
        }
      }
    }

    & > div {
      background-color: $white;
      border-radius: 50%;
      font-size: 13px;
      height: 100px;
      position: absolute;
      top: 0;
      transition: background-color 0.25s, color 0.25s, transform 0.25s;
      width: 100px;

      //@extend .icon-modal-arrow;

      &::before {
        content: "\e924";
        font-family: "icomoon" !important;
        font-size: 7px;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: -4px;
        position: absolute;
        top: 50%;
      }
    }

    svg {
      display: none;
    }

    &:not([disabled]):hover {
      color: $apple;

      & > div {
        background-color: #fff;
      }

      &.fancybox-button--arrow_left {
        & > div {
          transform: translateX(4px);
        }
      }

      &.fancybox-button--arrow_right {
        & > div {
          transform: translateX(-4px);
        }
      }
    }

    &:disabled:hover {
      color: #909eb1;
    }
  }

  &--dark {
    .fancybox-button,
    .featured-button {
      & > div {
        background: $graphite;
        color: $apple;
      }
    }
  }
}

.icon-close {
  &.icon-close-fancybox-mobile {
    background: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
    color: $font-color-secondary;
    cursor: pointer;
    display: none;
    font-size: 32px;
    position: fixed;
    right: 20px;
    top: 27px;
    transition: color 0.2s;
    z-index: 999999;
    @include screen-xs {
      //display: block;
    }

    &:hover {
      color: #000;
    }
  }
}

.fancybox-active .header {
  transform: translateY(-100px);
}

.project-layout {
  .fancybox-bg {
    background-color: $graphite;
    mix-blend-mode: multiply;
    opacity: 0.8;
  }
}

.disable-scroll {
  @include screen-md {
    overflow: hidden;
  }
}

@keyframes leftToRight {
  0% {
    left: auto;
    right: 0;
    width: 100%;
  }

  49% {
    left: auto;
    right: 0;
    width: 0;
  }

  50% {
    left: 0;
    right: auto;
    width: 0;
  }

  51% {
    left: 0;
    right: auto;
    width: 0;
  }

  100% {
    left: 0;
    right: auto;
    width: 100%;
  }
}

.underline,
.underline-inverse {
  position: relative;
}

.underline::before,
.underline-inverse::before {
  background-color: currentColor;
  bottom: -0.3rem;
  content: "";
  height: 2px;
  pointer-events: none;
  position: absolute;
  width: 100%;
}

.underline-inverse::before {
  width: 0;
}

.underline:hover::before,
.underline-inverse:hover::before {
  animation: leftToRight 0.4s ease forwards;
}

/* Custom animation */
.fancybox-fx-slide-in-out.fancybox-slide--previous {
  //transform: translateX(-100%);
  animation: close-modal 0.5s ease-in-out;
}

.fancybox-fx-slide-in-out.fancybox-slide--next {
  //animation: close-modal .6s ease-in-out;
}

.fancybox-fx-slide-in-out.fancybox-slide--current {
  animation: open-modal 0.4s ease-in-out;
}

@keyframes close-modal {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes open-modal {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.input-control {
  position: relative;

  input {
    //user-select: none;
    appearance: none;
    background: transparent;
    border-bottom: 1px solid $grey_4;
    border-radius: 0;
    font-family: $heading-font;
    font-weight: 500;
    height: 26px;
    padding-bottom: 1px;
    padding-left: 0;
    transition: all 0.2s;
    width: 100%;
  }

  label {
    font-family: $heading-font;
    font-size: 14px;
    font-weight: 500;
    left: 0;
    line-height: 1;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s;
  }

  &:hover {
    input {
      border-color: $link-color;
    }
  }

  .label--is-active {
    color: $grey_3;
    font-size: 9px;
    font-weight: normal;
    text-transform: uppercase;
    top: 0;
    transform: translateY(-100%);
  }

  &.is-focused {
    input {
      border-color: $link-color;
    }

    label {
      @extend .label--is-active;
    }
  }

  &.is-filled {
    label {
      @extend .label--is-active;
    }
  }

  &.is-error {
    input {
      border-color: $red;
    }

    label {
      @extend .label--is-active;
      color: $red;
    }
  }
}

.loader-spinner {
  animation: cssload-spin 575ms infinite linear;
  border: 2px solid #000;
  border-left-color: transparent;
  border-radius: 50%;
  display: none;
  height: 16px;
  margin: 0 auto;
  width: 16px;
}

@keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
  }
}

#wpadminbar {
  display: none;
}

.heading {
  &--secondary {
    color: $font-color;
    font-family: $heading-font;
    font-size: 48px;
    font-weight: 600;
    letter-spacing: 1.5px;
    margin-bottom: 100px;
    padding-left: 100px;

    @include screen-md {
      font-size: 38px;
      margin-bottom: 50px;
      padding-left: 0;
    }

    @include screen-sm {
      font-size: 28px;
      margin-bottom: 0;
    }
  }

  &--decorate {
    font-family: $heading-font;
    font-size: 48px;
    font-weight: 600;
    letter-spacing: 1.5px;
    margin-bottom: 48px;
    position: relative;

    @include screen-md {
      font-size: 38px;
    }

    @include screen-sm {
      font-size: 32px;
      margin-bottom: 30px;
    }

    &::after {
      bottom: -10px;
      content: "";
      height: 3px;
      left: 0;
      position: absolute;
      width: 40px;
    }
  }

  &--light {
    color: #fff;

    &::after {
      background-color: $brand-color;
    }
  }
}

hr {
  background-color: $grey_4;
  border: 0;
  height: 1px;
  margin: 54px 0;
}

.l2 {
  font-family: $font-family-primary;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: $forest;
}


img.img-contain {
  object-fit: contain !important;
}

.grey-bg {
  background-color: $grey_7;
}

.dark-grey-bg {
  background-color: $grey_5;
}

.graphite-bg {
  background-color: $graphite;
}


.center-heading {
  text-align: center;
  padding: 0 50px;
  margin: 40px auto 80px;

  @include screen-sm {
    padding: 20px;
  }

  h4 {
    margin-bottom: 16px;
    text-transform: uppercase;
    color: $forest;
    font-size: 18px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.36px;
  }

  h2 {
    font-size: 36px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.72px;
    color: $black;
    margin-bottom: 0px;
  }

  p {
    text-align: center;
    font-family: $font-family-secondary;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 26px; /* 162.5% */
    letter-spacing: 0.64px;
    padding: 0 50px;
    max-width: 970px;
    margin: 12px auto 0;
  }

  &.dark {
    h4 {
      color: $mint;
    }

    h2, p {
      color: white;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}
