.working-remotely {
  background-color: $grey_5;
  padding-bottom: 90px;
  padding-top: 60px;

  @include screen-sm {
    padding-bottom: 60px;
    padding-top: 40px;
  }

  @include screen-xs {
    padding-top: 20px;
  }


  &__title {
    margin-bottom: 22px;
  }

  &__description {
    margin-bottom: 90px;
    max-width: 950px;

    @include screen-lg {
      max-width: 757px;
    }

    @include screen-sm {
      margin-bottom: 40px;
    }
  }

  &__holder {
    display: flex;
    justify-content: space-between;

    @include screen-sm {
      flex-direction: column;
    }
  }

  &__image {
    flex-basis: 50%;
    flex-grow: 0;

    @include screen-sm {
      flex-basis: auto;
      margin-bottom: 30px;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__list {
    flex-basis: 50%;
    padding-left: 60px;

    @include screen-sm {
      flex-basis: 100%;
      padding-left: 0;
    }
  }

  &__item {
    margin-bottom: 48px;

    &:last-child {
      margin-bottom: 0;
    }


    &-title {
      margin-bottom: 10px;
    }

  }

}
