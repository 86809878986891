#menu-header-nav li:nth-child(2) .sub-menu {
    // display: none;
    @include screen-md {
        // display: block;
    }
}

.services-mega-menu {
    background-color: $black;
    width: 921px;
    position: absolute;
    top: 55px;
    opacity: 0;
    right: -5000px;
    z-index: 100;
    padding: 50px 70px 74px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: opacity 0.35s, top 0.35s;

    @include screen-md {
        display: none;
    }

    &.in {
        right: 0;
        top: 78px;
        opacity: 1 !important;
    }

    h2, h2 a {
        color: white;
        font-family: $font-family-primary;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 24px */
        letter-spacing: 0.4px;
        text-decoration-line: underline;
        text-decoration-thickness: 1px;
        margin-bottom: 30px;
        text-underline-offset: 2px;

        &.active, &:hover {
            color: $mint;
        }
    }

    .menu-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 30px;

        .capability {
            border-radius: 8px;
            background: $graphite;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
            width: 240px;
            height: 180px;
            padding: 30px 30px 20px;
            margin-bottom: 43px;

            img {
                width: 100px;
                height: 100px;
                margin: 0;
            }

            h4 {
                color: $mint;
                text-align: center;
                font-family: $font-family-primary;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%; /* 19.2px */
                letter-spacing: 0.32px;
                margin: 0;
                padding: 0;
                text-transform: none;
            }

            &.active, &:hover {
                text-decoration: none;
                background: linear-gradient(180deg, $graphite 0%, #414a50 100%);
            }
        }

        .service {
            width: 240px;

            h3 {
                margin-bottom: 24px;
            }

            h3 a {
                color: white;
                font-family: $font-family-primary;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%; /* 24px */
                letter-spacing: 0.4px;
                text-decoration-line: underline;
                text-decoration-thickness: 1px;
                text-underline-offset: 2px;

                &.active, &:hover {
                    color: $mint;
                }
            }

            ul {
                margin: 0;
                padding: 0;

                li {
                    margin-bottom: 5px;
                    margin-left: 11px;
                    padding-inline-start: 4px;
                    list-style-image: url(/wp-content/themes/fullclarity/assets/src/img/bullet.svg);
                }

                li a {
                    color: white;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 160%; /* 22.4px */
                    letter-spacing: 0.56px;
                    text-decoration-line: underline;
                    text-decoration-thickness: 1px;
                    padding: 0;
                    font-family: $font-family-secondary;
                    text-underline-offset: 2px;

                    &:hover {
                        color: $mint;
                    }
                }
            }
        }
    }
}
