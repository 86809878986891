.post-content {

  hr {
    background-color: #e1e1e1;
    margin: 80px 0;
  }

  &__inner {
    padding-bottom: 60px;

    @include screen-md {
      padding: 10px 0;
    }

    .body-text {
      color: $black;

      &.p2 {
        padding-top: 40px;
      }

      h2 {
        @extend .h2;

        font-size: 36px;

        @include screen-lg {
          margin-bottom: 30px;
          margin-top: 60px;
          font-size: 28px;
        }

        @include screen-md {
          margin-bottom: 15px;
          padding-top: 20px;
          font-size: 28px;
        }

        @include screen-xs {
          margin-bottom: 10px;
          margin-top: 10px;
          padding-top: 10px;
          font-size: 28px;
        }

        &.no-top-indent {
          margin-top: 0;
          padding-top: 0;
        }
      }

      h3 {
        @extend .h3;

        @include screen-lg {
        }
        margin-bottom: 15px;
        margin-top: 50px;

        @include screen-md {
          margin-bottom: 15px;
        }

        @include screen-xs {
          margin-bottom: 10px;
        }
      }

      h4 {
        @extend .h4;

        @include screen-lg {
          margin-bottom: 20px;
          margin-top: 30px;
        }

        @include screen-md {
          margin-bottom: 15px;
        }

        @include screen-xs {
          margin-bottom: 10px;
        }
      }

      h5, h6 {
        @extend .h5;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 43.2px */
        letter-spacing: 0.72px;
        margin-top: 100px;
        margin-bottom: 18px;

        &:first-child {
          margin-top: 40px;
        }

        @include screen-lg {
          font-size: 24px;
          margin-bottom: 20px;
          margin-top: 80px;
        }
      }

      h6 {
        font-size: 24px;

        @include screen-lg {
          font-size: 18px;
        }
      }

      a {
        color: $font-color;
        font-weight: 400 !important;
        text-decoration: underline;

        span {
          font-weight: inherit !important;
        }

        &:hover {
          color: $apple;
        }
      }

      ul {
        color: $black;
        margin-bottom: 40px;
        padding-top: 20px;
        margin-left: 50px;

        @include screen-xs {
          margin-bottom: 30px;
        }

        li {
          font-weight: 300;
          line-height: 160%;
          margin-bottom: 16px;
          padding-left: 16px;
          position: relative;
          font-size: 16px;

          p {
            color: $font-color-secondary;
            margin-bottom: 0;
            font-size: 16px;
          }

          &::before {
            color: $apple;
            content: '\e92d';
            font-family: icomoon !important;
            font-size: 8px;
            left: 0;
            position: absolute;
            top: 0px;

            @include screen-xs {
              //top: 7px;
            }
          }
        }

        &.links {
          li {
            a {
              color: $font-color-secondary;
              font-family: $heading-font;
              font-weight: 500;
              //letter-spacing: 1px;

              &:hover {
                color: $brand-color;
              }
            }

            &::before {
              color: $font-color-secondary;
              content: '\e90f';
              font-size: 9px;
            }
          }
        }
      }

      p {
        color: $black;
        margin-bottom: 1.3em;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 160%; /* 25.6px */
        letter-spacing: 0.64px;

        @include screen-sm {
          font-size: 14px;
        }

        span {
          font-weight: 300 !important;
        }
      }

      blockquote {
        @extend .quote;
        @extend .quote--bold;
        position: relative;
        background: $grey_7;
        padding: 60px 130px;
        margin: 60px 0;

        @include screen-sm {
          margin: 60px 0px 60px 0px;
          padding: 60px 60px;
        }

        &::before {
          bottom: 100%;
          color: $apple;
          content: '\e922';
          font-family: 'icomoon' !important;
          font-size: 26px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-weight: normal;
          line-height: 1;
          pointer-events: none;
          position: absolute;
          left: 110px;
          top: 44px;
          transform: translateY(6px) translateX(-25px);
          z-index: 1;

          @include screen-sm {
            left: 45px;
          }
        }

        p {
          color: $font-color;
          margin-bottom: 0;
          color: $graphite;
          font-family: $font-family-secondary;
          font-size: 18px;
          font-style: normal;
          font-weight: 300;
          line-height: 140%; /* 51.2px */
          letter-spacing: 0.64px;

          @include screen-sm {
            font-size: 16px;
          }
        }

        cite {
          text-align: right;
          display: block;
          font-weight: 400;
          margin-top: 10px;
          font-size: 18px;

          @include screen-sm {
            font-size: 14px;
          }

          &::before {
            content: '—';
          }
        }
      }

    }

    .body-images {
      margin-bottom: 30px;
      padding-top: 60px;

      @include screen-xs {
        padding-top: 10px;
      }

      &__item {
        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      &--grid {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(2, 1fr);

        @include screen-xs {
          grid-gap: 10px;
        }
      }
    }
  }

  &--background {
    background: $grey_5;
  }

  &--team-single {
    padding-bottom: 100px;
    padding-top: 100px;

    @include  screen-md {
      padding: 80px 0;
    }
    @include screen-sm {
      padding: 60px 0;
    }
    @include screen-xs {
      padding: 50px 0;
    }

    .post-content {
      &__inner {
        padding-bottom: 0;
        padding-left: 100px;
        padding-right: 100px;

        @include screen-md {
          padding-left: 40px;
          padding-right: 40px;
        }

        @include screen-sm {
          padding-left: 0;
          padding-right: 0;
        }

        .body-text {
          h3 {
            margin-top: 0;
            padding-top: 0;
          }

          ul {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &--service {
    padding-top: 80px;
    //padding-bottom: 80px;

    @include screen-md {
      padding-top: 60px;
    }

    @include screen-xs {
      padding-top: 40px;
    }
  }
}

.single-post-wrapper {
  background: $grey_7;
  padding-bottom: 0;
  padding-top: 70px;

  @include screen-sm {
    padding-top: 30px;
  }

  .quick-links__holder {
    background: transparent;
  }

  .quick-links__item {
    margin-top: 0;
  }
}

.image-text-left-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 60px;

  &.image-right {
    flex-direction: row-reverse;
  }

  &__image {
    max-width: 50%;
    flex: 1;
  }

  &__text {
    max-width: 50%;
    flex: 1;

    h4 {
      margin-top: 20px;
      text-transform: none;
      font-size: 28px;
      font-weight: 500;
    }

    p {
      color: $black;
      font-family: $font-family-secondary;
      margin-bottom: 20px;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 160%;
      letter-spacing: .64px;

      a {
        color: $black;
        font-weight: 400!important;
      }
    }
  }

  @include screen-sm {
    gap: 40px;
    flex-direction: column;

    h4 {

    }

    &__image {
      max-width: 100%;
      width: 100%;
    }

    &__text {
      max-width: 100%;
      width: 100%;
    }
  }
}
