.card-item {
  flex-basis: 0;

  &__photo {
    border-radius: 50%;
    height: 175px;
    margin-bottom: 38px;
    overflow: hidden;
    width: 175px;

    @include  screen-lg {
      height: 140px;
      width: 140px;
    }

    @include screen-sm {
      margin-bottom: 27px;
    }

    @include screen-xs {
      height: 100px;
      width: 100px;
    }

  }

  &--team {
    flex-basis: auto;

    @include screen-lg {
      flex-basis: 0;
    }
  }

  &__photo-link {
    border-radius: 50%;
    display: inline-block;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;

    &:hover {
      img {
        //filter:  opacity(.9);
      }
    }

    img {
      height: 100%;
      object-fit: cover;
      transition: filter 0.16s;
      width: 100%;
    }

    &::after {
      border: 5px solid #fff;
      border-radius: 50%;
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: opacity 0.2s;
    }

    &:hover {
      &::after {
        opacity: 0.3;
      }
    }
  }

  &__hover-info {
    align-items: flex-end;
    background-color: rgba(0, 160, 214, 0.2);
    border-radius: 50%;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.16s;
  }

  &__subheader {
    color: $black;
    margin-bottom: 5px;
  }

  &__header {
    color: $forest;
    margin-bottom: 20px;

    @include screen-sm {
      margin-bottom: 15px;
    }

    .card-item--team & {
      margin-bottom: 10px;
    }

    span {
      color: $brand-color;
      display: none;
      margin-left: 20px;

      &::before {
        font-size: 14px;
        font-weight: bold;
        vertical-align: middle;
      }
    }

    &--bold {
      font-family: $heading-font;
      font-size: 24px;
      font-weight: 600;
    }

    &-link {
      color: $font-color;
      font-weight: 600;
      position: relative;
      text-decoration: underline;
      //pointer-events: none;

      &::before {
        color: $font-color-secondary;
        content: '\e90f';

        display: none;
        font-family: 'icomoon' !important;
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-weight: normal;
        pointer-events: none;
        position: absolute;
        right: -12px;
        top: 50%;
        transform: translateY(-50%) translateX(100%);
      }

      &:hover {
        &::before {
          color: $brand-color;
        }
      }
    }
  }

  &__link {
    opacity: 0;
  }

  &:hover {
    .card-item {
      &__link {
        opacity: 1;
      }
    }
  }

  &__image {
    align-items: center;
    background: #fff;
    display: flex;
    height: 140px;
    justify-content: flex-start;
    margin-bottom: 70px;
    max-width: 200px;
    position: relative;

    @include screen-sm {
      margin-bottom: 50px;
    }

    @include screen-xs {
      margin-bottom: 30px;
    }

    svg {
      display: block;
      flex-grow: 0;
      flex-shrink: 0;
      height: 100%;
      max-height: 100%;
      max-width: 100%;
      width: auto;
    }
  }

  &__info {
    margin-bottom: 32px;
  }

  &__info,
  &__info a {
    color: $font-color-secondary;
    line-height: 1.5;
    text-decoration: none;

    .card-item--team & {
      line-height: get-line-height(18px, 32px);
    }
  }

  &__info a {
    &:hover {
      text-decoration: underline;
    }
  }
}

.step-animation {
  &__item {
    position: relative;
  }

  &__card {
    left: 0;
    opacity: 0;
    position: relative;
    top: 0;
    transition: all 0.3s;
    visibility: hidden;

    svg {
      display: inline-block;
    }

    .blue-circle-svg {
      stroke: #bdccdb;

      &.is-highlighted {
        stroke: #00addb;
      }
    }

    .blue-circle {
      background: #fff;
      border: 2px solid $mint;
      border-radius: 50%;
      height: 12px;
      left: -5px;
      position: absolute;
      top: -5px;
      width: 12px;

      &.is-active {
        border-color: $mint;
      }
    }

    .absolute-layer {
      left: 0;
      position: absolute;
      top: 0;
    }

    &:first-child {
      opacity: 1;
      position: relative;
      visibility: visible;
    }

    &.is-visible {
      opacity: 1;
      visibility: visible;
    }
  }
}

.price-card {
  display: flex;
  flex-basis: 33.33333%;
  margin-bottom: 38px;
  padding-left: 15px;
  padding-right: 15px;

  @include screen-md {
    flex-basis: 50%;
    max-width: 340px;
  }

  @include screen-xs {
    padding-left: 0;
    padding-right: 0;
  }

  &--popular {
    .price-card {
      &__inner {
        border-color: $brand-color;
      }
    }
  }

  &__sticker {
    background: $brand-color;
    color: #fff;
    font-family: $heading-font;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.89px;
    padding-top: 2px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    transform: rotate(42deg) translate(29px, -11px);
    transform-origin: center;
    width: 120px;
  }

  &__holder {
    border-radius: 9px;
    box-shadow: 0 2px 12px 0 rgba(64, 54, 78, 0.1);
    display: flex;
    flex-grow: 1;
    overflow: hidden;
  }

  &__inner {
    background: #fff;
    border-top: 6px solid $brand-color;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    padding: 42px 30px 50px;
    position: relative;
    text-align: center;

    @include screen-xs {
      padding: 31px 15px 30px;
    }
  }

  &__price {
    font-family: $heading-font;
    font-size: 48px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 12px;

    @include screen-xs {
      margin-bottom: 5px;
    }

    sup {
      font-size: 28px;
      margin-left: 3px;
      margin-right: 3px;
      top: -0.45em;
    }
  }

  &__title {
    font-family: $heading-font;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.35px;
    line-height: get-line-height(18px, 24px);
    margin-bottom: 30px;

    @include screen-xs {
      margin-bottom: 20px;
    }
  }

  &__description {
    color: $font-color-secondary;
    font-size: 16px;
    margin-bottom: 56px;
    text-align: left;

    @include screen-xs {
      margin-bottom: 30px;
    }

    ul {
      li {
        margin-bottom: 10px;
        padding-left: 25px;
        position: relative;

        @include screen-xs {
          margin-bottom: 8px;
        }

        &::before {
          content: '\e90d';
          font-family: icomoon !important;
          font-size: 6px;
          left: 7px;
          position: absolute;
          top: 7px;
        }
      }
    }
  }

  &__action {
    padding-left: 10px;
    padding-right: 10px;

    .btn {
      width: 100%;
    }
  }

}
