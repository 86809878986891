.showreel {
  margin-bottom: 56px;
  padding-top: 62px;


  @include screen-sm {
    padding-top: 20px;
  }


  &__inner {
    overflow: hidden;
    padding-bottom: 20px;
    padding-top: 20px;

    position: relative;

    @include screen-sm {
      padding-top: 60px;
    }
  }

  &__background {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(43.21deg,#53778a,#8ea4af);
    }

    &-circle-big {
      background-image: url('../img/showreel-circle-big.svg');
      background-position: right center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 90%;
      position: absolute;
      right: 9%;
      top: 2.5%;
      width: 58%;
      z-index: 1;

      @include screen-sm {
        right: -20%;
        top: 6.5%;
        width: 80%;
      }
    }

    &-circle-small {
      background-image: url('../img/showreel-circle-small.svg');
      background-position: left top;
      background-repeat: no-repeat;
      background-size: 100% auto;
      height: 90%;
      left: 5%;
      opacity: 0.3;
      position: absolute;
      top: 0;
      width: 28%;
      z-index: 1;
    }
  }

  &__content {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    padding-left: 76px;
    position: relative;
    z-index: 2;

    @include screen-sm {
      align-items: flex-start;
      flex-direction: column;
      padding-left: 20px;
    }
  }

  &__group {
    flex-basis: 300px;
    flex-shrink: 0;
    padding-bottom: 112px;

    @include screen-sm {
      flex-basis: auto;
    }

    @include screen-xs {
      padding-bottom: 40px;

    }
  }

  &__image {
    flex-grow: 1;
    padding-left: 47px;

    @include screen-xs {
      padding-left: 0;
    }
  }


  &__title {
    color: #fff;
    font-size: 64px;
    line-height: get-line-height(64px, 72px);

    @include screen-sm {
      font-size: 58px;
    }
  }

  &__caption {
    color: $grey_4;
    font-family: $font-family-primary;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 30px;
    margin-bottom: 72px;

    @include screen-sm {
      margin-bottom: 30px;
    }
  }

  &__action {
    .video-link {
      color: $white;

      &__title {
        border-color: $white;
      }

      &:hover {
        color: $apple;

        .video-link__title {
          border-color: $apple;
        }
      }
    }
  }

}
