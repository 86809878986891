.blocks {
  display: flex;
  flex-direction: column;
  gap: 80px;

  &--no-gap {
    gap: 0 80px;
  }

  &__column {
    flex-basis: 50%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;

    &--shrink {
      flex: 0 0 0;
    }

    &--grow {
      flex: 1 0 0;
    }

    &--align-center {
      align-items: center;
    }

    &--third {
      flex-basis: 60%;
    }
  }

  &__row {
    display: flex;
    flex-wrap: nowrap;
    gap: 64px;

    &--spaced {
      gap: 64px 94px;
    }

    &--inverse {
      flex-direction: row-reverse;
    }

    @include screen-md {
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

h3 + .blocks {
  margin-bottom: 40px;
}

.block {
  flex-grow: 1;
  position: relative;

  &__content {
    height: 100%;
    width: 100%;

    h3 {
      margin-bottom: 12px;
    }

    p,
    p span {
      font-family: $font-family-secondary;
      font-style: normal;
      font-weight: 300 !important;
      font-size: 16px;
      line-height: 160%;
      letter-spacing: 0.04em;
    }

    p + p {
      margin-top: 1rem;
    }
  }
}
