.solutions-section {
  padding: 64px 80px 96px;

  @include screen-sm {
    padding: 32px;
  }

  h2 {
    margin-bottom: 40px;
  }

  .service-card {
    display: flex;
    max-width: 824px;
    margin: 0 auto;

    &__inner {
      @include screen-md {
        height: 320px;
      }

      @include screen-md {
        height: auto;
        max-height: initial;

        display: flex;
        flex-direction: column-reverse;

        img {
          max-height: 320px;
        }
      }
    }

    img {
      width: 100%;
      min-width: 340px;
    }
  }

  .service-card + .service-card {
    margin-top: 80px;
    @include screen-sm {
      margin-top: 64px;
    }
  }
}
