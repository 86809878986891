.feature {

  @include screen-md {
  }
  overflow: hidden;
  position: relative;

  @include screen-sm {
    //padding-top: 20px;
  }

  &__inner {
    display: flex;
    justify-content: flex-end;

    @include screen-sm {
      flex-direction: column;
    }
  }

  &__background {
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;

    @include screen-sm {
      overflow: hidden;
      position: relative;
    }

    &-overlay {
      height: 100%;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;

      @include screen-sm {
        display: none;
      }

      &::after {
        background: $grey_7;
        content: '';
        height: 100%;
        left: 100%;
        position: absolute;
        top: 0;
        width: 100vw;
      }

      svg {
        display: block;
        height: 100%;
        object-fit: contain;
        object-position: left center;

        path {
          fill: $grey_5;
        }
      }
    }

    &-holder {
      height: 100%;
      max-width: 100%;
      //overflow: hidden;
      position: relative;
    }

    &-image {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__content {
    max-width: 62%;
    padding-bottom: 64px;
    padding-top: 64px;
    position: relative;

    @include screen-sm {
      max-width: 100%;
      padding-bottom: 0;
      padding-top: 0;
    }

    &-inner {
      background: #fff;
      box-shadow: 0 0 18px 3px rgba(0, 0, 0, 0.1);
      padding: 40px;

      @include screen-sm {
        box-shadow: unset;
        padding: 30px 0;
      }

      @include screen-xs {
        //padding: 15px;
      }
    }
  }

  &__heading {
    font-family: $heading-font;
    margin-bottom: 34px;
  }

  &__description {
    color: $graphite;

    p {
      margin-bottom: 1em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__social {
    a {
      color: $font-color-secondary;

      &:hover {
        color: $font-color;
      }
    }
  }

  &.feature--container {
    .container {
      height: 100%;
      position: relative;
      //overflow: hidden;
    }
  }

  &.feature--reverse {
    .feature {
      &__background {
        &-overlay {
          left: 0;
          right: unset;
          width: 64%;

          @include screen-sm {
            width: 37%;
          }

          svg {
            position: absolute;
            right: 0;
          }
        }

        &-image {
          margin-left: auto;
          width: auto;
        }
      }

      &__inner {
        justify-content: flex-start;
      }

      &__content {
        max-width: 60%;

        @include screen-sm {
          max-width: 100%;
        }
      }
    }
  }

  &.feature--secondary {
    overflow: visible;

    .feature {
      &__inner {
        box-shadow: 4px 4px 24px 3px rgba(0, 0, 0, 0.1);
        overflow: hidden;

        @include screen-md {
          border-radius: 0;
          box-shadow: unset;
          flex-direction: column;
        }
      }

      &__background {
        flex-basis: 49%;
        height: auto;
        position: relative;
        width: 49%;

        @include screen-md {
          flex-basis: 100%;
          height: 0;
          margin: 0;
          padding-bottom: 50%;
          width: 100%;
        }

        &-holder {
          max-width: unset;
          transform: unset;

          @include screen-md {
            left: 0;
            position: absolute;
            top: 0;
          }

          &::after {
            background: #fff;
            box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.5);
            content: '';
            height: 100%;
            opacity: 1;
            position: absolute;
            right: 0;
            top: 0;
            transform: skew(13deg, 0deg) translateX(100%);
            transform-origin: 0 100%;
            width: 50%;

            @include screen-md {
              border-radius: 0;
              bottom: 0;
              box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.5);
              height: 30%;
              left: 0;
              right: unset;
              top: unset;
              transform: skew(0deg, -8deg) translateY(0);
              transform-origin: 100% 100%;
              width: 100%;
            }
          }
        }

        &-image {
          transform: unset;
        }
      }

      &__content {
        flex-basis: 51%;
        min-height: 585px;
        padding-bottom: 0;
        padding-top: 0;
        width: 51%;

        @include screen-md {
          flex-basis: 100%;
          margin-bottom: 30px;
          min-height: unset;
          order: -1;
          width: 100%;
        }

        &-inner {
          box-shadow: unset;
          padding: 52px 50px 52px 62px;

          @include screen-md {
            padding: 0;
          }
        }
      }

      &__heading {
        font-weight: 600;
        margin-bottom: 41px;
        position: relative;

        @include screen-sm {
          margin-bottom: 30px;
        }

        &::after {
          background-color: $brand-color;
          bottom: -10px;
          content: '';
          height: 3px;
          left: 0;
          position: absolute;
          width: 40px;
        }
      }

      &__description {
        margin-bottom: 30px;

        @include screen-sm {
          margin-bottom: 10px;
        }

        p {
          margin-bottom: 30px;
        }

        a {
          color: $font-color;
          font-family: $heading-font;
          font-weight: 600;
          letter-spacing: 0.042em;
          text-decoration: underline;

          &:hover {
            color: $brand-color;
          }
        }
      }

      &__link {
        a {
          color: $font-color;
          font-family: $heading-font;
          font-size: 18px;
          font-weight: 600;
          letter-spacing: 1px;
          text-decoration: underline;

          &:hover {
            color: $brand-color;
          }
        }
      }

    }

  }

  &.feature--inverse {
    .feature {
      &__background {
        order: 1;

        &-holder {
          &::after {
            left: 0;
            right: unset;
            transform: skew(-13deg, 0deg) translateX(-100%);

            @include screen-md {
              transform: skew(0deg, -8deg) translateY(0);
            }
          }
        }
      }

      &__content {

        @include screen-md {
          order: -1;
        }

        &-inner {
          box-shadow: unset;
          padding: 52px 65px 52px 50px;

          @include screen-md {
            padding: 0;
          }
        }
      }
    }
  }

  &.feature--full {
    background-color: $font-color-secondary;
    margin-bottom: 0;

    @include screen-md {
      //background-color: transparent;
    }

    .feature {
      &__inner {
        border-radius: 0;
        box-shadow: unset;

        @include screen-md {
          overflow: visible;
        }
      }

      &__background {
        flex-basis: 50%;
        height: 100%;
        position: absolute;
        width: 50%;


        @include screen-md {
          margin-left: calc((100vw - 100%) / -2);
          overflow: visible;
          padding-bottom: 0;
          position: static;
          width: 100vw;
        }

        &-holder {

          @include screen-md {
            position: relative;
          }

          &::after {
            background-color: $font-color-secondary;
            left: unset;
            right: 0;
            transform: skew(-13deg, 0deg) translateX(100%);
            transform-origin: 100% 0;

            @include screen-md {
              background-color: #fff;
              border-radius: 0;
              bottom: 0;
              box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.5);
              height: 15%;
              left: 0;
              right: unset;
              top: unset;
              transform: skew(0deg, -8deg) translateY(0);
              transform-origin: 100% 100%;
              width: 100%;
            }

          }
        }
      }

      &__content {
        flex-basis: 50%;
        min-height: unset;
        padding-bottom: 140px;
        padding-top: 150px;
        width: 50%;


        @include screen-md {
          background-color: $font-color-secondary;
          flex-basis: 100%;
          padding-bottom: 30px;
          padding-top: 30px;
          width: 100%;
        }

        &-inner {
          background-color: transparent;
          padding: 52px 10px 52px 50px;

          @include screen-md {
            padding: 0;
          }
        }
      }

      &__heading {
        color: #fff;

        &::after {
          background-color: #fff;
        }
      }

      &__description {
        color: #fff;
        font-family: $heading-font;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.48;
        margin-bottom: 58px;

        @include screen-md {
          margin-bottom: 30px;
        }
      }

      &__position {
        color: #fff;
        font-size: 16px;
      }
    }
  }

  &--image-gallery {
    height: 100vh;
    margin-bottom: 100px;
    max-height: 630px;
    min-height: 400px;

    @include screen-lg {
      max-height: 500px;
    }

    @include screen-md {
      height: auto;
      margin-bottom: 80px;
      max-height: unset;
      min-height: unset;
    }

    @include screen-sm {
      margin-bottom: 50px;
    }

    @include screen-xs {
      margin-bottom: 20px;
    }

    .container {
      height: 100%;

      @include screen-md {
        padding: 0;
      }
    }

    .feature {
      &__inner {
        height: 100%;
        overflow: hidden;
        position: relative;

        @include screen-md {
          justify-content: flex-start;
        }
      }

      &__background {
        &--left {
          width: 70%;

          @include screen-md {
            width: 100%;
          }

          .feature {
            &__background {
              &-holder {
                border-right: 30px solid rgba(255, 255, 255, 1);
                padding-left: 0;

                @include screen-md {
                  border-right: unset;
                  height: 0;
                  padding-bottom: 50%;
                }
              }
            }
          }
        }

        &--right {
          .feature {
            &__background {
              &-holder {

                @include screen-md {
                  height: auto;
                  min-height: unset;
                }

                &-inner {
                  height: 50%;
                  right: 0;

                  @include screen-md {
                    height: 100%;
                    width: 50%;
                  }

                  &:first-child {
                    padding-bottom: 15px;

                    @include screen-md {
                      height: 0;
                      left: 0;
                      padding-bottom: 40%;
                      position: relative;
                      width: 50%;
                    }
                  }

                  &:last-child {
                    bottom: 0;
                    left: unset;
                    padding-top: 15px;
                    right: 0;
                    width: 100%;

                    @include screen-md {
                      height: 0;
                      padding-bottom: 40%;
                      padding-top: 0;
                      position: relative;
                      width: 50%;
                    }
                  }
                }
              }
            }
          }
        }

        &-holder {
          @include screen-md {

          }
          pointer-events: none;

          &::before {
            display: none;
          }
        }
      }
    }

  }

  &__title-block {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
  }

  &__count {
    width: 28px;
    height: 28px;
    background-color: transparent;
    border: 2px solid $forest;
    color: $forest;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    padding-top: 3px;
  }
}
