

.engagement {
  padding: 0;

  &-home{
    padding-bottom: 160px;
    padding-top: 160px;

    @include screen-md {
      padding-bottom: 67px;
      padding-top: 67px;
    }

    @include screen-sm-custom {
      padding-bottom: 46px;
      padding-top: 46px;
    }
  }


  &-gallery {
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(14, 1fr);
    grid-template-rows: repeat(10, 68px);
    margin: -12px;

    @include screen-lg {
      grid-template-rows: repeat(10, 54px);
    }

    @include screen-md {
      grid-template-columns: repeat(14, 1fr);margin-bottom: 10px;
      grid-template-rows: repeat(23, 50px);
    }

    @include screen-sm-custom {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(20, auto);
    }

    &-item {
      padding: 12px;

      &:nth-child(1) {
        grid-column: 1/6;
        grid-row: 1/7;
      }

      &:nth-child(2) {
        grid-column: 10/15;
        grid-row: 4/8;
      }

      &:nth-child(3) {
        grid-column: 6/11;
        grid-row: 1/4;
      }

      &:nth-child(4) {
        grid-column: 11/15;
        grid-row: 1/4;
      }

      &:nth-child(5) {
        grid-column: 1/6;
        grid-row: 7/11;
      }

      &:nth-child(6) {
        grid-column: 6/10;
        grid-row: 4/11;
      }

      &:nth-child(7) {
        grid-column: 10/15;
        grid-row: 8/11;
      }

      @include screen-md {
        &:nth-child(1) {
          grid-column: 1/9;
          grid-row: 1/9;
        }

        &:nth-child(2) {
          grid-column: 1/9;
          grid-row: 14/19;
        }

        &:nth-child(3) {
          grid-column: 9/15;
          grid-row: 1/5;
        }

        &:nth-child(4) {
          grid-column: 9/15;
          grid-row: 14/19;
        }

        &:nth-child(5) {
          grid-column: 1/9;
          grid-row: 9/14;
        }

        &:nth-child(6) {
          grid-column: 9/15;
          grid-row: 5/14;
        }

        &:nth-child(7) {
          grid-column: 1/15;
          grid-row: 19/24;
        }
      }

      @include screen-sm-custom {
        &:nth-child(1) {
          grid-column: 1/2;
          grid-row: 1/3;
        }

        &:nth-child(2) {
          grid-column: 1/9;
          grid-row: 8/12;
        }

        &:nth-child(3) {
          grid-column: 1/1;
          grid-row: 4/8;
        }

        &:nth-child(4),
        &:nth-child(6),
        &:nth-child(7) {
          display: none;
        }

        &:nth-child(5) {
          grid-column: 1/9;
          grid-row: 13/19;
        }
      }

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }

      &--quote {
        color: $white;
        text-align: center;

        p {
          margin-bottom: 10px;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px; /* 140% */
          letter-spacing: 0.8px;


          &:last-child {
            margin-bottom: 0;
          }
        }

        em {
          color: $mint;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          letter-spacing: 0.05em;
          line-height: get-line-height(14px, 21px);

          @include screen-max {
            font-size: 17px;
          }

          @include screen-lg {
            font-size: 14px;
          }
        }

        & > div {
          align-items: center;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          padding: 50px;

          @include screen-sm-custom {
            padding: 50px 35px;
          }
        }

        .quote-dark {
          background: $graphite;

          @include screen-md {
            padding: 35px;
          }
        }

        .quote-green {
          background: $forest;

          @include screen-md {
            padding: 35px;
          }


          ::selection {
            background: darken($brand-color, 5%);
          }
        }
      }
    }
  }
}
