.studio {
  &__background {
    img {
      height: auto;
      width: 100%;
    }
  }

  &__content {
    background: $grey_7;
    padding-bottom: 70px;

    @include screen-sm-custom {
      background: $white;
      padding-bottom: 30px;
    }
  }

  &__inner {
    background: #fff;
    box-shadow: 0 0 18px 3px rgba(0, 0, 0, 0.1);
    margin-top: -80px;
    padding: 50px;
    position: relative;

    h3 {
      font-size: 28px !important;
    }

    p {
      font-size: 14px !important;
      line-height: 24px !important;
    }

    @include screen-sm {
      margin-top: 0;
      padding: 30px;
    }

    @include screen-sm-custom {
      box-shadow: unset;
      padding: 30px 0;
    }


  }

  &__heading {
    margin-bottom: 15px;
  }

  &__text {
    color: $graphite;

    a {
      font-weight: 400;
    }

    &.flex {
      gap: 72px;

      & > div:first-child {
        flex: 1;
      }

      & > div:last-child {
        width: 290px;

        @include screen-md {
          width: 200px;
        }

        p:last-child {
          margin-top: 17px;
        }
      }

      @include screen-sm {
        flex-direction: column;
        gap: 30px;

        & > div {
          width: 100%;
        }
      }
    }
  }

}
