.featured-gallery {
  margin-top: 51px;
  overflow: hidden;
  position: relative;

  @include screen-sm {
    margin-left: -20px;
    margin-right: -20px;
  }

  &__holder {
    position: relative;
  }

  &__item {
    &-holder {
      height: 0;
      padding-bottom: 55%;
      position: relative;

      @include screen-sm {
        padding-bottom: 60%;
      }

      @include screen-xs {
        padding-bottom: 80%;
      }
    }
  }

  &__background {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    &-overlay {
      background: $black;
      height: 100%;
      left: 0;
      mix-blend-mode: multiply;
      opacity: 0.6;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &__foreground {
    align-items: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    padding-left: 30px;
    padding-right: 30px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  &__logo {
    margin-bottom: 30px;
    max-width: 300px;
    text-align: center;
    width: 100%;

    @include screen-md {
      max-width: 220px;
    }

    @include screen-sm {
      margin-bottom: 15px;
      max-width: 150px;
    }

    @include screen-xs {
      margin-bottom: 10px;
      max-width: 100px;
    }

    img {
      display: inline-block;
    }
  }

  &__action {
    display: none;
    padding-top: 50px;
  }

  &__title {
    font-family: $heading-font;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;

    @include screen-sm {
      font-size: 20px;
    }

    @include screen-xs {
      font-size: 18px;
    }
  }

  &__subtitle {
    @include screen-xs {
      font-size: 16px;
    }
  }

  &__progress-bar {
    bottom: 0;
    height: 5px;
    left: 0;
    position: absolute;
    width: 100%;

    div {
      background: $mint;
      display: block;
      height: 100%;
      width: 0;
    }
  }

  &--flexible {
    margin: 70px 0;

    @include screen-sm {
      margin: 50px 0;
    }

    @include screen-xs {
      margin: 40px 0;
    }

    .featured-gallery {
      &__item {
        &-holder {
          padding-bottom: 54%;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &:hover {
    .featured-navigation {
      opacity: 1;
    }

    .featured-gallery__action {
      display: block;
    }
  }
}

.featured-navigation {
  opacity: 0;

  @include screen-sm-custom {
    display: none;
  }

  .featured-button {
    position: absolute;

    &--arrow_left {
      cursor: pointer;
      left: 0;

      & > div {
        right: 0;

        &::before {
          //transform: rotate(180deg);
          //right: 24px;
        }
      }
    }

    &--arrow_right {
      cursor: pointer;
      right: 0;

      & > div {
        left: 0;

        &::before {
          //left: 24px;
        }
      }
    }
  }
}

.related-projects {
  background-color: $grey_7;
  overflow: hidden;
  padding-bottom: 136px;
  padding-top: 100px;
  width: 100%;

  @include screen-sm {
    padding-bottom: 60px;
    padding-top: 80px;
  }

  @include screen-xs {
    padding-bottom: 40px;
    padding-top: 60px;
  }

  .content-text {
    font-size: 16px;
    max-width: calc(100% - 44px);
  }
}

.featured-projects-gallery {
  padding-top: 40px;
  position: relative;
  overflow: hidden;

  margin-left: -22px;
  margin-right: -22px;

  @include screen-sm {
    padding-top: 30px;
  }

  .navigation-arrows {
    margin-top: -29px;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-100%);
  }

  &__heading {
    position: relative;
  }

  &__holder {
    position: relative;
  }

  .projects__item {
    margin: 0;
    width: 432px;
    flex-basis: 432px;
  }

  &__slider {
    z-index: 2;
  }

  .slick-slide {
    // margin-right: 20px;
  }

  .slick-list {
    overflow: visible;
  }

  .slick-track {
    display: flex;
    gap: 22px;
  }
}
