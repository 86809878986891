.field {
  position: relative;
  padding-bottom: 21px;

  &__input {
    appearance: none;
    border-bottom: 1px solid transparent;
    border-bottom: 1px solid $grey_4;
    border-radius: 0;
    color: $graphite;
    font-family: $heading-font;
    font-size: 18px;
    font-weight: 500;
    height: 28px;
    margin-top: 4px;
    min-width: 220px;
    padding-bottom: 1px;
    padding-left: 0;
    transition: all 0.2s;
    user-select: none;
    width: 220px;

    @include screen-sm {
      font-size: 16px;
      width: 100%;
    }

    &::selection {
      background: $brand-color;
      color: #fff !important;
      text-shadow: none;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      box-shadow: 0 0 0 1000px white inset;
      -webkit-text-fill-color: #333;
      //border: none !important;
    }

    &--readonly {
      border: none !important;
      cursor: default;
      pointer-events: none;

      &::placeholder {
        opacity: 0.7 !important;
      }

      &:focus {
        &::placeholder {
          opacity: 1 !important;
        }
      }
    }

    &::placeholder {
      color: $font-color-secondary;
      opacity: 0;
      transition: all 0.2s;
    }

    &:focus {
      &::placeholder {
        opacity: 0;
      }
    }

    &-buffer {
      left: -1000px;
      position: absolute;
      top: -1000px;
      visibility: hidden;
      white-space: nowrap;
    }
  }

  &__label {
    font-family: $heading-font;
    font-size: 18px;
    font-weight: 500;
    pointer-events: none;
    position: absolute;
    z-index: 1;
    top: 4px;
    transition: all 0.2s;

    &--title {
      color: $font-color-secondary;
    }

    &--error {
      color: $graphite;
      display: none;
    }

    &--persist {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1.44px;
      opacity: 1;
      text-transform: uppercase;
      top: -12px;
    }
  }

  &--is-filled &,
  &--is-focused & {
    &__label {
      color: $grey_3;
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
      top: -9px;
    }
  }

  &--is-focused & {
    &__input {
      border-bottom-color: $brand-color;
    }
  }
}

.wpcf7-not-valid-tip {
  position: absolute;
  color: $global-danger-background;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}

.wpcf7-list-item-label {
  font-family: $font-family-secondary;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: $graphite;
  line-height: 140%;
}
