.locations {
  padding: 137px 0;

  @include screen-lg {
    padding: 100px 0;
  }

  @include screen-md {
    padding: 80px 0;
  }

  @include screen-sm {
    padding: 50px 0;
  }

  .container {
    max-width: 1100px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 85px;
    width: 100%;

    @include screen-sm-custom {
      flex-direction: column;
      margin-bottom: 62px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__column {
    &--image {
      max-width: 480px;
      padding-top: 10px;
      position: relative;
      width: 100%;

      @include screen-lg {
        max-width: 400px;
      }

      @include screen-sm-custom {
        height: 260px;
        margin-left: calc((100vw - 100%) / -2);
        max-width: unset;
        width: 100vw;
      }

    }

    &--info {
      padding-right: 40px;

      @include screen-sm-custom {
        margin-bottom: 30px;
        padding-right: 0;
      }
    }
  }

  &__heading {
    font-family: $heading-font;
    font-size: 48px;
    letter-spacing: 0.023em;
    line-height: get-line-height(48px, 62px);
    margin-bottom: 20px;

    @include screen-lg {
      font-size: 40px;
    }

    @include screen-md {
      font-size: 30px;
    }

    @include screen-sm {
      font-size: 24px;
    }

    @include screen-xs {
      font-size: 24px;
      margin-bottom: 28px;
    }
  }

  &__description {
    color: $font-color-secondary;
    font-size: 20px;
    letter-spacing: 0.063em;
    line-height: get-line-height(20px, 36px);
    margin-bottom: 30px;

    @include screen-md {
      font-size: 18px;
      line-height: get-line-height(18px, 36px);
    }
  }

  &__action {
    a {
      color: $font-color;
      font-family: $heading-font;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.063em;

      &[href^='tel'] {
        text-decoration: none;
      }

      &:hover {
        color: $brand-color;
      }
    }
  }

  &__image {
    height: 100%;
    padding-right: 22px;
    position: absolute;
    width: 100%;

    @include screen-sm-custom {
      padding-right: 0;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
}
