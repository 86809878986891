.working-together {
  background: $graphite;
  overflow: hidden;
  padding-bottom: 158px;
  padding-top: 100px;

  @include screen-md {
    margin-bottom: 50px;
    padding-bottom: 50px;
    padding-top: 80px;
  }

  @include screen-sm {
    margin-bottom: 40px;
    padding-bottom: 20px;
  }

  &__inner {
    position: relative;

    .working-together__card-inner {
      background: $white;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -11px;
    margin-right: -11px;
  }



  &__card {
    flex-basis: 33.3333%;
    flex-shrink: 0;
    padding: 11px;

    @include screen-sm {
      flex-basis: 100%;
    }

    &-group {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    &-inner {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      padding: 30px 20px;
      text-align: center;

      @include screen-sm-custom {
        margin: auto;
        max-width: 340px;
      }
    }

    &-image {
      margin-bottom: 20px;
      max-width: 160px;
      position: relative;
      width: 100%;
      margin-top: -20px;
    }

    &-heading {
      margin-bottom: 15px;
      font-size: 18px !important;
    }

    &-info {
      margin-bottom: 30px;

      .p2 {
        font-size: 14px !important;
      }
    }
  }

  &__heading {
    color: $white;
    margin-bottom: 36px;
    font-size: 48px;


    @include screen-md {
      margin-bottom: 15px;
    }

    @include screen-xs {
      margin-bottom: 15px;
    }
  }

  &__description {
    color: $white;
    margin-bottom: 52px;

    @include screen-xs {
      margin-bottom: 30px;
    }

  }

  &--single-service {

  }
}
