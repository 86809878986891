.paragraph {
  font-family: $font-family-secondary;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.04em;
  line-height: get-line-height(12px, 20px);

  //@include screen-max {
  //  font-size: 14px;
  //}
  //
  //@include screen-lg {
  //  font-size: 12px;
  //}
}

.share {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  min-width: 182px;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.16s;
  user-select: none;

  @include screen-md {
    min-width: 140px;
  }

  &__button {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 15px;
    width: 100%;

    &-icon {
      display: inline-flex;
      font-size: 22px;
      margin-right: 14px;
    }

    &-caption {
      color: #000;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      padding-top: 2px;
    }

    &-toggle {
      &-collapsed {
        //display: inline-flex;
        display: none;
      }

      &-open {
        display: none;
      }

      &-icon {
        color: $grey_4;
        display: inline-flex;
        font-size: 20px;
        margin-left: auto;

        &:hover {
          color: $black;
        }
      }
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__list {
    background: $white;
    opacity: 0;
    padding: 7px;
    visibility: hidden;
    width: 100%;
  }

  &__item {
    &-link {
      align-items: center;
      color: $stone;
      display: flex;
      font-size: 14px;
      padding: 10px;
      text-decoration: none;
      transition: all 0.16s;

      &:hover {
        background: $grey_5;
        color: $black;

        .share__item-icon {
          color: $black;
        }
      }
    }

    &-icon {
      color: $grey_4;
      display: inline-flex;
      font-size: 18px;
      justify-content: center;
      margin-right: 10px;
      text-align: center;
      transition: all 0.16s;
      width: 18px;

      .icon-share-email {
        font-size: 13px;
      }

      .icon-share-twitter {
        font-size: 15px;
      }
    }

    &-title {
    }
  }

  &__item {
  }

  &.is-active {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    .share {
      &__button {
        &-toggle {
          &-collapsed {
            display: none;
          }

          &-open {
            display: inline-flex;
          }
        }
      }

      &__list {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.share-buttons {
  backdrop-filter: blur(3px);
  backface-visibility: hidden;
  background-color: rgba(#2a3552, 0.7);
  display: block;
  height: 100vh;
  left: 0;
  opacity: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: all 0.2s;
  visibility: hidden;
  width: 100vw;
  z-index: 20;

  @include screen-xs {
    backdrop-filter: unset;
    background: $white;
  }

  @include screen-sm {
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
  }

  &.is-active {
    opacity: 1;
    visibility: visible;
  }

  &__container {
    align-items: center;
    display: flex;
    flex-grow: 1;
    height: 100%;
    justify-content: center;
    padding: 50px;

    @include screen-sm {
      padding: 30px;
    }
    @include screen-xs {
      padding: 0;
    }
  }

  &__holder {
    align-self: center;
    background: $white;
    border-radius: 4px;
    box-shadow: 0 12px 44px 0 rgba(#293247, 0.58);
    flex-basis: 423px;
    height: auto;
    max-width: 423px;
    width: 100%;

    @include screen-xs {
      align-self: flex-start;
      border-radius: 0;
      box-shadow: none;
      flex-basis: 100%;
      max-width: unset;
    }
  }

  &__inner {
    box-shadow: 0 2px 4px 0 rgba(#000, 0.2);

    @include screen-xs {
      box-shadow: none;
    }
  }

  &__header {
    align-items: center;
    background: #fff;
    border-radius: 4px 4px 0 0;
    box-shadow: 0 2px 8px 0 rgba($font-color-secondary, 0.2);
    display: flex;
    justify-content: space-between;
    padding: 26px 30px;

    @include screen-xs {
      border-radius: 0;
    }
  }

  &__title {
    color: $headings-color;
    font-size: 36px;
    font-weight: 600;
  }

  .icon-close {
    color: $font-color-secondary;
    cursor: pointer;
    font-size: 32px;
    transition: color 0.2s;

    &:hover {
      color: $font-color;
    }
  }

  &__content {
    padding: 0 30px;
  }

  &__item {
    border-bottom: 1px solid rgba($font-color, 0.3);

    &:last-child {
      border-bottom: none;
    }
  }

  &__link {
    align-items: center;
    display: flex;
    padding: 26px 0;
    text-decoration: none;

    &-icon {
      align-items: center;
      color: $font-color-secondary;
      display: inline-flex;
      font-size: 25px;
      justify-content: center;
      transition: color 0.16s;
      width: 25px;
    }

    &-caption {
      color: $font-color;
      font-size: 20px;
      margin-left: 20px;
    }

    .icon-share-email {
      font-size: 18px;
    }

    .icon-share-twitter {
      font-size: 22px;
    }

    .icon-share-linkedin {
      font-size: 24px;
    }

    &:hover {
      .share-buttons {
        &__link {
          &-icon {
            color: $font-color;
          }
        }
      }
    }
  }
}

.content-text {
  color: $graphite;

  &--limited {
    max-width: 600px;
  }

  &--arrows-indent {
    padding-right: 100px;
  }

  p {
    margin-bottom: 1.4em;

    @include screen-sm {
      margin-bottom: 1em;
    }

    &:last-child {
      margin-bottom: 0;

      img {
        margin-bottom: 0;
      }
    }
  }
}

.content-heading {
  @extend .h2;
  margin-bottom: 21px;

  @include screen-md {
    margin-bottom: 15px;
  }

  @include screen-xs {
    margin-bottom: 10px;
  }

  &--limited {
    max-width: 380px;

    @include screen-lg {
      max-width: 340px;
    }

    @include screen-sm {
      max-width: unset;
    }
  }

  &--small {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.container-condensed {
  padding-left: 40px;
  padding-right: 40px;

  @include screen-lg {
    padding-left: 20px;
    padding-right: 20px;
  }
  @include screen-sm {
    padding-left: 0;
    padding-right: 0;
  }
}

.default-template {
  background-color: $grey_7;
  padding-bottom: 140px;
  padding-top: 80px;

  &__container {
    max-width: 884px;

    @include screen-lg {
      max-width: 684px;
    }

    & > *:first-child {
      margin-top: 0;
      padding-top: 0;
    }
  }
}

.navigation-arrows {
  bottom: 0;
  display: flex;
  padding-bottom: 10px;
  position: absolute;
  right: 0;

  @include screen-sm-custom {
    display: none;
  }

  &__button {
    align-items: center;
    color: $stone;
    cursor: pointer;
    display: flex;
    font-size: 9px;
    height: 14px;
    justify-content: center;
    text-decoration: none;
    width: 14px;

    @include screen-lg {
      font-size: 7px;
    }

    @include screen-sm {
      font-size: 9px;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &:hover {
      color: $brand-color;

      &::before {
        color: $apple;
      }
    }

    &-next {
      margin-left: 12px;
    }
  }
}

.full-width-image {
  margin-bottom: 100px;

  @include screen-md {
    margin-bottom: 80px;
  }

  @include screen-sm {
    margin-bottom: 50px;
  }

  &__holder {
    img {
      height: auto;
      width: 100%;
    }
  }

  &--flexible {
    margin-bottom: 70px;
    padding-top: 35px;

    @include screen-md {
      margin-bottom: 50px;
      padding-top: 20px;
    }

    @include screen-sm {
      margin-bottom: 30px;
      padding-top: 15px;
    }
  }

  &--bg {
    position: relative;

    &::before {
      background-color: $grey_6;
      content: "";
      height: 70%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .full-width-image {
      &__holder {
        position: relative;
        z-index: 1;
      }
    }
  }

  &--no-indent {
    padding-top: 0;
  }
}

.image-max {
  img {
    height: auto;
    width: 100%;
  }

  &--goal {
    padding-top: 142px;
    position: relative;

    @include screen-sm {
      padding-top: 80px;
    }

    @include screen-xs {
      padding-top: 40px;
    }

    &::before {
      background-color: $grey_7;
      bottom: 0;
      content: "";
      height: 60%;
      left: 0;
      position: absolute;
      width: 100%;
    }

    .container {
      position: relative;
    }
  }
}

.image_text {
  margin-bottom: 100px;

  @include screen-md {
    margin-bottom: 80px;
  }

  @include screen-sm {
    margin-bottom: 50px;
  }

  @include screen-xs {
    margin-bottom: 30px;
  }

  &__holder {
    align-items: center;
    display: flex;
    justify-content: space-between;

    @include screen-sm {
      flex-direction: column-reverse;
    }
  }

  &__image {
    flex-basis: 52%;
    flex-shrink: 0;
    width: 52%;

    @include screen-md {
      flex-basis: 40%;
      width: 40%;
    }

    @include screen-sm {
      flex-basis: 100%;
      width: 100%;
    }

    img {
      height: auto;
      width: 100%;
    }
  }

  &__content {
    padding-left: 60px;

    @include screen-sm {
      margin-bottom: 40px;
      padding-left: 0;
    }

    @include screen-xs {
      margin-bottom: 30px;
    }

    .content-text {
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.title {
  margin-bottom: 30px;
  padding-bottom: 17px;
  position: relative;

  @include screen-md {
    margin-bottom: 25px;
  }

  @include screen-xs {
    margin-bottom: 20px;
  }

  &__main-title {
    color: $font-color;
    font-family: $heading-font;
    font-size: 48px;
    font-weight: 600;
    letter-spacing: 1.5px;
    line-height: get-line-height(48px, 60px);

    @include screen-lg {
      font-size: 40px;
    }

    @include screen-md {
      font-size: 30px;
    }

    @include screen-xs {
      font-size: 24px;
    }
  }

  &__icon {
    height: 55px;
    margin-bottom: 30px;
    width: 55px;

    img,
    svg {
      display: block;
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }

  &__top-title {
    color: $brand-color;
    display: block;
    font-family: $heading-font;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.75px;
    margin-bottom: 5px;

    @include screen-lg {
      font-size: 22px;
    }

    @include screen-md {
      font-size: 20px;
    }

    @include screen-xs {
      font-size: 18px;
    }
  }

  &__bottom-title {
    display: block;
    font-family: $heading-font;
    font-weight: 600;
    letter-spacing: 1.67px;
    margin-top: 10px;
  }

  &__caption {
    @extend .content-text;
    display: block;
    margin-top: 5px;
  }

  &--decorate {
    position: relative;

    &::after {
      background: $brand-color;
      content: "";
      height: 3px;
      left: 0;
      position: absolute;
      top: calc(100% + 8px);
      width: 36px;
    }
  }
}

.list--decorate {
  ul {
    color: $font-color-secondary;
    font-size: 16px;
    margin-bottom: 15px;

    li {
      margin-bottom: 10px;
      padding-left: 20px;
      position: relative;

      &::before {
        content: "\e90d";
        font-family: icomoon !important;
        font-size: 6px;
        left: 0;
        position: absolute;
        top: 8px;
      }
    }
  }
}

.team-avatars {
  display: flex;

  &__item {
    margin-right: -10px;
    position: relative;
    z-index: 1;

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        z-index: #{10 - $i};
      }
    }

    &:hover {
      z-index: 11;
    }
  }

  &__photo {
    border: 2px solid #fff;
    border-radius: 50%;
    height: 37px;
    overflow: hidden;
    width: 37px;

    img {
      display: block;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
}

.hide-desktop {
  display: none;

  @include screen-sm {
    display: block;
  }
}

.hide-mobile {
  @include screen-sm {
    display: none;
  }
}

.step-image {
  align-items: center;
  display: flex;

  @include screen-md {
    flex-direction: column;
  }

  .steps {
    flex-basis: 338px;
    flex-shrink: 0;

    @include screen-md {
      margin-bottom: 80px;
    }

    @include screen-sm {
      margin-bottom: 50px;
    }
  }

  &__image {
    padding-left: 100px;

    @include screen-lg {
      padding-left: 50px;
    }

    @include screen-md {
      padding-left: 0;
    }

    img {
      height: auto;
      width: 100%;

      @include screen-md {
        max-width: 100%;
        width: auto;
      }
    }
  }
}

.carousel-nav {
  align-items: center;
  color: $black;
  display: flex;
  font-size: 16px;
  margin-left: -10px;
  margin-right: -10px;

  @include screen-lg {
    font-size: 14px;
  }

  @include screen-sm {
    font-size: 16px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: $stone;
    font-size: 9px;
    height: auto;
    margin-top: -2px;
    padding: 10px;
    position: static;
    width: auto;

    @include screen-lg {
      font-size: 7px;
    }

    @include screen-sm {
      font-size: 9px;
    }

    &::after {
      display: none;
    }

    &:hover {
      color: $black;
    }

    &.swiper-button-disabled {
      color: $grey_4;
      opacity: 1;
    }
  }

  .swiper-button-prev {
    margin-right: 15px;
  }

  .swiper-button-next {
    margin-left: 15px;
  }

  &--green {
    .swiper-button-prev,
    .swiper-button-next {
      color: $apple;

      &:hover {
        color: $forest;
      }
    }
  }
}

.nav-counter {
  display: flex;
  font-weight: 500;

  & > span {
    min-width: 9px;
    text-align: center;
  }

  &-delimiter {
    margin-left: 3px;
    margin-right: 3px;
  }
}

.link {
  color: $graphite;

  &-caption {
    font-family: $paragraph-font-family;
    font-size: 14px;
    letter-spacing: 0.56px;
    line-height: get-line-height(12px, 20px);
    text-decoration: underline;
    font-weight: 400;

    @include screen-max {
      font-size: 14px;
    }

    @include screen-lg {
      font-size: 14px;
    }

    @include screen-sm {
      font-size: 14px;
    }

    @include screen-xs {
      font-size: 14px;
    }
  }

  &--icon {
    align-items: center;
    display: inline-flex;
    text-decoration: none;

    [class*=" icon-"],
    [class^="icon-"] {
      font-size: 7px;
    }
  }

  &--arrow {
    [class*=" icon-"],
    [class^="icon-"] {
      margin-left: 10px;
    }
  }
}

.quick-links {
  //padding-top: 28px;

  &__holder {
    padding-bottom: 160px;

    @include screen-sm {
      padding-bottom: 60px;
    }

    @include screen-xs {
      padding-bottom: 40px;
    }
  }

  &__inner {
    display: flex;

    @include screen-sm {
      flex-direction: column;
      margin-top: -28px;
    }
  }

  &__item {
    flex-basis: 50%;
    margin-top: -28px;
    position: relative;

    @include screen-sm {
      flex-basis: 100%;
      margin-bottom: 20px;
      margin-top: 0;
    }
  }

  &__link {
    display: block;
    position: relative;

    &::after {
      background: $mint;
      bottom: 0;
      content: "";
      height: 6px;
      left: 0;
      opacity: 0;
      position: absolute;
      transition: opacity 0.2s;
      width: 100%;
    }

    &:hover {
      &::after {
        opacity: 1;
      }

      .quick-links__title {
        text-decoration: underline;
      }
    }
  }

  &__background {
    display: block;
    height: 0;
    padding-bottom: 65%;
    position: relative;
    width: 100%;

    img {
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &::after {
      background: $black;
      background-blend-mode: multiply;
      content: "";
      height: 100%;
      left: 0;
      opacity: 0.6;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &__foreground {
    color: $white;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    left: 0;
    padding: 20px 25px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &__tag {
    font-weight: 500;
    margin-bottom: 11px;
    text-transform: uppercase;
  }

  &__title {
  }
}

.user-hero {
  margin-bottom: 97px;

  @include screen-sm {
    margin-bottom: 30px;
  }

  &--post-single {
    margin-bottom: 180px;

    @include screen-sm {
      margin-bottom: 110px;
    }

    @include screen-xs {
      margin-bottom: 80px;
    }
  }

  &__inner {
    background: $grey_7;
    box-shadow: 4px 4px 24px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    min-height: 412px;
    position: relative;

    @include screen-sm {
      background: transparent;
      box-shadow: unset;
      flex-direction: column;
      min-height: unset;
    }
  }

  &__background {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 45%;

    @include screen-sm {
      margin-bottom: 30px;
      position: static;
      width: 100%;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;

      @include screen-sm {
        height: auto;
        max-height: none;
      }
    }

    svg {
      display: block;
      height: 100%;
      left: 0;
      margin-left: -1px;
      position: absolute;
      top: 0;
      width: auto;

      @include screen-sm {
        display: none;
      }
    }
  }

  &__content {
    height: auto;
    position: relative;
    width: 55%;

    @include screen-sm {
      width: auto;
    }

    &-inner {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      padding: 40px 40px 60px;

      @include screen-sm {
        padding: 0;
      }
    }
  }

  &__action {
    padding-top: 20px;
  }

  &__heading {
    margin-bottom: 30px;
  }

  &__description {
    color: $graphite;

    @include screen-sm {
      max-width: unset;
    }
    //max-width: 391px;

    a {
      color: $black;
      font-weight: 400;

      &:hover {
        color: $apple;
      }
    }

    p {
      margin-bottom: 1.3em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.article-hero {
  padding-bottom: 73px;
  padding-top: 54px;

  @include screen-sm {
    padding-bottom: 30px;
    padding-top: 30px;
  }

  &__inner {
    background: $grey_7;
    display: flex;
    position: relative;

    @include screen-md {
      background: transparent;
      box-shadow: unset;
      min-height: unset;
      flex-direction: column-reverse;
    }
  }

  &__background {
    height: 100%;
    min-width: 45%;

    @include screen-md {
      position: static;
      width: 100%;
      height: auto;
      max-height: 480px;
      //margin-bottom: 30px;
    }

    img {
      height: 100%;
      min-height: 440px;
      max-height: 440px;
      object-fit: cover;
      object-position: center;
      width: auto;

      @include screen-md {
        height: auto;
        width: 100%;
        max-height: 480px;
        min-height: unset;
      }
    }
  }

  &__content {
    height: auto;
    overflow: hidden;
    position: relative;
    background-color: $graphite;
    min-width: 45%;

    @include screen-md {
      background-color: $graphite;
      min-width: unset;
      width: auto;
    }

    &-inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 32px 40px;
      position: relative;
      height: 100%;

      .h1 {
        font-size: 48px;
      }

      @include screen-sm {
        padding: 24px 26px;
      }
    }

    & > svg {
      height: 100%;
      position: absolute;
      right: 0;

      @include screen-sm {
        display: none;
      }
    }
  }

  &__tags {
    margin-bottom: 62px;

    @include screen-md {
      margin-bottom: 30px;
    }
  }

  &__heading {
    color: $white;
    margin-bottom: 60px;

    @include screen-md {
      margin-bottom: 30px;
      font-size: 36px !important;
    }

    a {
      color: $white;
      text-decoration-line: none;
      text-decoration-thickness: 4px;

      &:hover {
        text-decoration-line: underline;
      }
    }
  }

  &__author {
    margin-top: auto;
  }

  &__featured {
    position: absolute;
    top: 0;
    right: 0;
    background: white;
    color: #212322;
    padding: 8px 16px;
    margin: 10px;
    text-transform: uppercase;
    font-size: 18px;
    user-select: none;
    cursor: pointer;
  }

  &--single {
    padding-top: 56px;
    position: relative;
    z-index: 3;

    @include screen-md {
      padding-bottom: 20px;
    }

    @include screen-sm {
      padding-top: 30px;
    }

    .article-hero {
      &__inner {
        background: transparent;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;

        @include screen-md {
          min-height: unset;
          flex-wrap: wrap;
          flex-direction: column;
        }
      }

      &__background {
        min-width: unset;

        @include screen-md {
          width: 100%;
        }

        img {
          object-position: center;

          @include screen-lg {
            max-width: 400px;
          }

          @include screen-md {
            max-height: 480px;
            min-height: unset;
            max-width: unset;
            width: 100%;

          }
        }
      }

      &__content {
        background-color: $white;
        min-width: unset;
        overflow: visible;
        width: 50%;

        @include screen-md {
          background: transparent;
          width: 100%;
        }

        &-inner {
          padding: 20px 20px 20px 40px;
          width: auto;

          @include screen-md {
            padding: 24px 0px;
          }
        }

        .author__details {
          color: $font-color;

          a {
            color: $font-color;
          }
        }
      }

      &__tags {
        margin-bottom: 36px;

        @include screen-md {
          margin-bottom: 20px;
        }
      }

      &__heading {
        color: $black;
        margin-bottom: 40px;
        padding-right: 40px;
        font-size: 48px;
        line-height: 120%;

        @include screen-md {
          padding-right: 0;
          margin-bottom: 0px;
          font-size: 36px;
        }
      }

      &__group {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-right: 130px;
        position: relative;
        margin-top: 32px;

        @include screen-md {
          margin-top: 20px;
        }
      }
    }
  }
}

.section-backgrounds {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &__circle-large {
    background-image: url("../img/circle-decoration-3.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 100%;
    max-width: 1004px;
    position: absolute;
    right: 0;
    top: 50px;
    width: 80%;
  }

  &__circle-big {
    background-image: url("../img/circle-decoration-1.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 100%;
    left: 0;
    max-width: 840px;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 58%;
  }

  &__circle-small {
    background-image: url("../img/circle-decoration-2.svg");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
    bottom: 0;
    height: 100%;
    max-width: 317px;
    position: absolute;
    right: 0;
    width: 31%;
  }

  &__wt1 {
    background-image: url("../img/wt-1.svg");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
    bottom: 0;
    height: 100%;
    max-width: 716px;
    opacity: 0.2;
    position: absolute;
    right: 0;
    width: 70%;
  }

  &__wt2 {
    background-image: url("../img/wt-2.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 100%;
    left: 0;
    max-width: 381px;
    opacity: 0.1;
    position: absolute;
    top: 48px;
    width: 57%;
  }
}

.content-gallery {
  &__inner {
    display: flex;
    margin: -10px;

    @include screen-xs {
      margin: -5px;
    }
  }

  &__background {
    padding: 10px;

    @include screen-xs {
      padding: 5px;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    &-holder {
      height: 100%;

      &-inner {
        height: 100%;
      }
    }

    &--left {
      flex-basis: 62%;
      flex-grow: 0;
      flex-shrink: 0;

      @include screen-sm {
        flex-basis: 50%;
      }
    }

    &--right {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .content-gallery {
        &__background-holder {
          display: flex;
          flex-direction: column;
          margin: -10px;

          @include screen-xs {
            margin: -5px;
          }

          &-inner {
            padding: 10px;

            @include screen-xs {
              padding: 5px;
            }

            &:first-child {
              flex-basis: 42%;
            }

            &:last-child {
              flex-grow: 1;
            }
          }
        }
      }
    }
  }
}

.tippy-box {
  background-color: $black;
  border-radius: 26px;
}

.tippy-content {
  font-family: $font-family-secondary;
  font-size: 16px;
  line-height: get-line-height(16px, 22px);
  max-width: 280px;
  padding: 10px 26px;
  text-align: center;

  @include screen-max {
    font-size: 14px;
    padding: 8px 22px;
  }

  @include screen-lg {
    font-size: 12px;
    padding: 6px 20px;
  }
}

#gtx-trans {
  display: none !important;
}

.dropdown {
  &__button {
    align-items: center;
    background-color: $grey_7;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 8px 10px;
    touch-action: manipulation;
    width: 130px;

    @include screen-lg {
      width: 110px;
    }

    &-icon {
      color: $graphite;
      display: flex;
      font-size: 6px;
      transition: all 0.16s;

      @include screen-lg {
        font-size: 5px;
      }
    }

    &-caption {
      color: $grey_1;
      display: block;
      font-size: 14px;
      overflow: hidden;
      padding-right: 10px;
      text-overflow: ellipsis;
      user-select: none;
      white-space: nowrap;

      @include screen-lg {
        //font-size: 12px;
      }
    }

    &:hover {
      background-color: $grey_5;
    }

    &.is-active {
      background-color: $graphite;

      .dropdown__button-icon {
        color: $mint;
        transform: rotate(180deg);
      }

      .dropdown__button-caption {
        color: $white;
      }
    }
  }

  &__viewport {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    z-index: 1000;
  }

  &__content {
    pointer-events: auto;
    background: #fff;
    box-shadow: 0 0 18px 3px rgba(0, 0, 0, 0.1);
    margin-top: 15px;
    max-width: fit-content;
    opacity: 0;
    padding: 20px 25px;
    position: absolute;
    right: 0;
    transform: translateY(10px);
    transition: all 0.16s;
    visibility: hidden;
    width: 100%;
    z-index: 1;

    @include screen-lg {
      max-width: 630px;
    }

    &.is-active {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }

    &-group {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
    }

    &-clear {
      cursor: pointer;

      &:hover {
        color: $brand-color;
      }

      &.is-hidden {
        opacity: 0;
        visibility: hidden;
      }
    }

    &-title {
      color: $black;
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      margin: -8px;
    }

    &-item {
      flex-basis: 16.6666%;
      flex-shrink: 0;
      padding: 8px;

      @include screen-sm {
        flex-basis: 20%;
      }

      @include screen-sm-custom {
        flex-basis: 25%;
      }

      @include screen-xs {
        flex-basis: 33.3333%;
      }

      input {
        position: absolute;
        visibility: hidden;

        &:checked + label {
          background-color: $grey_6;

          path,
          circle,
          line,
          rect {
            stroke: $forest;
          }

          ellipse {
            fill: $forest;
          }
        }
      }

      &-button {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        margin-bottom: 10px;
        padding: 15px 0 5px;
        text-align: center;
        transition: background-color 0.16s;
        width: 100%;

        &:hover {
          background-color: $grey_6;
        }
      }

      &-icon {
        height: 24px;
        margin-bottom: 12px;
        width: 24px;
        //background-color: #ccc;

        img {
          height: 100%;
          object-fit: contain;
          width: 100%;
        }
      }

      &-title {
        align-items: center;
        color: $graphite;
        display: flex;
        font-size: 16px;
        line-height: get-line-height(12px, 14px);
        min-height: 28px;
        padding: 0 5px;

        @include screen-max {
          font-size: 14px;
        }

        @include screen-lg {
          font-size: 12px;
          padding: 0;
        }
      }
    }
  }

  &__item {
    padding: 10px;
    margin: 0 -13px;
  }
}

.quote-card {
  color: $white;
  display: flex;
  //height: 300px;
  margin-bottom: 44px;
  padding-left: 22px;
  padding-right: 22px;
  width: 50%;

  @include screen-sm {
    margin-bottom: 22px;
    padding-left: 11px;
    padding-right: 11px;
  }

  @include screen-sm-custom {
    width: 100%;
  }

  &__inner {
    height: 100%;
    width: 100%;
  }

  &__title {
    color: $mint;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: get-line-height(22px, 22px);
    margin-bottom: 20px;
  }

  &--success-story {
    .quote-card {
      &__inner {
        background-color: $graphite;
        background-image: url("../img/success-story-curve.svg");
        background-position: left top;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 30px 30px 75px 40px;

        @include screen-sm {
          padding: 30px 25px 50px;
        }
      }

      &__logo {
        margin-bottom: 30px;
        opacity: 0.8;
        text-align: right;

        img {
          display: inline-block;
          max-width: 112px;
        }
      }

      &__content {
        color: $white;
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 0.02em;
        line-height: get-line-height(30px, 38px);
        margin-bottom: 30px;
        max-width: 286px;

        @include screen-sm {
          font-size: 26px;
        }
      }
    }
  }

  &--quote {
    .quote-card {
      &__inner {
        background-color: $forest;
        padding: 30px 36px 48px 40px;

        @include screen-sm {
          padding: 30px 25px 50px;
        }

        ::selection {
          background: darken($forest, 5%);
        }
      }

      &__logo {
        margin-bottom: 33px;
        text-align: right;

        img {
          display: inline-block;
          max-width: 106px;
        }
      }

      &__icon {
        color: $mint;
        display: flex;
        font-size: 42px;
        margin-bottom: 20px;
      }

      &__content {
        color: $white;
        margin-bottom: 6px;
      }

      &__caption {
        color: $grey_7;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.05em;
        line-height: get-line-height(11px, 16px);

        @include screen-max {
          font-size: 17px;
        }

        @include screen-lg {
          font-size: 16px;
        }

        @include screen-sm {
          font-size: 15px;
        }

        @include screen-xs {
          font-size: 14px;
        }
      }
    }
  }
}

.video-link {
  align-items: center;
  color: $apple;
  display: inline-flex;
  line-height: 1;
  text-decoration: none;

  &:hover {
    color: $forest;

    .video-link__title {
      border-color: $forest;

      &:before {
        background-color: $forest;
        animation: leftToRight 0.6s ease forwards;
      }
    }
  }

  &__icon {
    font-size: 40px;
    transition: all 0.16s;
  }

  &__title {
    display: flex;
    font-family: $heading-font;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 18px;
    margin-left: 20px;
    margin-top: -3px;
    padding-bottom: 0;
    text-decoration-line: none;
    text-transform: uppercase;
    transition: all 0.16s;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      height: 2px;
      width: 100%;
      bottom: -2px;
      background-color: $apple;
      pointer-events: none;
      transition: background-color 0.16s;
    }
  }
}


.avatar {
  border-radius: 50%;
  overflow: hidden;
  width: 38px;
  height: 38px;
  padding: 0;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
