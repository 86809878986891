@keyframes topbar-x {
  0% {
    top: 0;
    transform: rotate(0);
  }

  45% {
    top: 25%;
    transform: rotate(145deg);
  }

  75% {
    transform: rotate(130deg);
  }

  100% {
    transform: rotate(135deg);
  }
}
@keyframes topbar-back {
  0% {
    top: 25%;
    transform: rotate(135deg);
  }

  45% {
    transform: rotate(-10deg);
  }

  75% {
    transform: rotate(5deg);
  }

  100% {
    top: 0;
    transform: rotate(0);
  }
}
@keyframes bottombar-x {
  0% {
    bottom: 0;
    transform: rotate(0);
  }

  45% {
    bottom: 25%;
    transform: rotate(-145deg);
  }

  75% {
    transform: rotate(-130deg);
  }

  100% {
    transform: rotate(-135deg);
  }
}
@keyframes bottombar-back {
  0% {
    bottom: 25%;
    transform: rotate(-135deg);
  }

  45% {
    transform: rotate(10deg);
  }

  75% {
    transform: rotate(-5deg);
  }

  100% {
    bottom: 0;
    transform: rotate(0);
  }
}
@keyframes show-header {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}
@keyframes hide-header {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

.header {
  background-color: #fff;
  height: 102px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  will-change: transform, height;
  z-index: 11;

  svg, path {
    fill: $black;
  }

  &--transparent {
    ul.header-nav > li {
      &.current-page-item,
      &.current-page-ancestor {
        > a {
          color: $black !important;

          &::after {
            content: "";
            margin: auto;
            position: absolute;
            bottom: 7px;
            left: 10px;
            right: 10px;
            width: calc(100% - 20px);
            height: 1px;
            background: $mint;

            @include screen-sm {
              display: none;
            }
          }
        }
      }

      .logo-image {
        svg, path {
          fill: $mint;
        }
      }

      .logo-name {
        svg, path {
          fill: $white;
        }
      }
    }

    &:not(.is-scroll) {
      background-color: transparent;
      color: $white;

      ul.header-nav > li {
        &.current-page-item,
        &.current-page-ancestor {
          > a {
            color: $white !important;

            @include screen-sm {
              color: $stone !important;

              &:hover {
                color: $black !important;
              }
            }
          }
        }
      }


      .logo-image {
        svg, path {
          fill: $mint;
        }
      }

      .logo-name {
        svg, path {
          fill: $white;
        }
      }

      &.header {
        .hamburger .menu-bar {
          background: $white;
          border-color: $white;
        }

        .hamburger.toggled .menu-bar {
          background: transparent;
          border-color: $white;

          &.bar1,
          &.bar3 {
            background: white;
          }
        }

        &:not(.header-nav-active) {
          .header-nav a {
            color: $grey_4;
          }
          .sub-menu {
            background: $black;

            li a:hover, li.current_page_item a  {
              background: $graphite;
            }
          }
        }
      }
    }


  }

  &:hover {
    .section-info__icon {
      //color: $brand-color;
    }
  }

  .disable-nav-hide {
  }

  &.is-scroll {
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.05);
    position: fixed;

    @include screen-md-min {
      &:not(.disable-nav-hide) {
        .show-contact-form {
          background: $apple;
          color: $white;
          padding: 12px 20px;
          margin-top: 4px;

          &:hover {
            background: $forest;
          }
        }
      }
    }
  }

  &.is-scroll:not(.header-nav-active):not(.disable-nav-hide),
  &.is-scroll:not(.header-always-show):not(.disable-nav-hide) {
    transform: translateY(-100%);

    &.nav-up {
      transform: translateY(-100%);
      transition: box-shadow 0.16s, height 0.16s, transform 0.16s;
    }

    &.nav-down {
      transform: translateY(0);
      transition: box-shadow 0.16s, height 0.16s, transform 0.16s;

      .hamburger {
        opacity: 1;
        visibility: visible;
      }

      .btn {
        opacity: 0;
        visibility: hidden;
      }

      .header-nav {
        &.is-active {
          @include screen-md-min {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  &.header-nav-active,
  &.header-always-show {
    opacity: 1 !important;
    position: fixed;
    transform: translateY(0) !important;
  }

  .container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;

    @include screen-xs {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  &-right {
    display: flex;
    height: 100%;
    position: relative;
    align-items: center;

    @include screen-md {
      align-items: center;
    }
  }

  .hamburger {
    display: none;
    height: 23px;
    margin: 0;
    padding-top: 0;
    transition: 0s;
    width: 29px;
    z-index: 999;
    cursor: pointer;

    @include screen-sm {
      display: block;
    }

    .menu-bar {
      background: $font-color;
      display: block;
      height: 1px;
      margin: 0 auto;
      position: relative;
      transition: 0s;
      width: 29px;

      &.bar1 {
        animation: topbar-back 0s;
        animation-fill-mode: both;
        animation-play-state: initial !important;
        top: 0;
      }

      &.bar2 {
        opacity: 1;
      }

      &.bar3 {
        animation: bottombar-back 0s;
        animation-fill-mode: both;
        animation-play-state: initial !important;
        bottom: 0;
      }
    }

    &.toggled {
      .bar1 {
        animation: topbar-x 0s;
        animation-fill-mode: both;
        background: $graphite;
        top: 12px;
        width: 10px;
      }

      .bar2 {
        background: transparent;
        border: 1px solid $graphite;
        border-radius: 50%;
        height: 24px;
        top: -11px;
        width: 24px;
      }

      .bar3 {
        animation: bottombar-x 0s;
        animation-fill-mode: both;
        background: $graphite;
        top: -33px;
        width: 10px;
      }
    }

    .menu-bar + .menu-bar {
      margin-top: 10px;
    }
  }

  &-nav {
    align-items: center;
    display: flex;
    margin-right: -10px;
    opacity: 1;
    transform: translateY(0);
    transition: all 0.16s;

    @include screen-sm {
      align-items: flex-start;
      background-color: #fff;
      flex-direction: column;
      height: auto;
      justify-content: flex-start;
      left: 0;
      opacity: 0;
      overflow-y: auto;
      padding-bottom: 50px;
      padding-left: 27px;
      padding-right: 27px;
      padding-top: 8px;
      pointer-events: none;
      position: fixed;
      top: 102px;
      transition: all 0.3s;
      visibility: hidden;
      width: 100%;
      z-index: 100;
    }

    & > li {
      align-items: center;
      display: flex;
      height: 100%;
      position: relative;
      max-width: 240px;
    }

    & > li.current_page_item,
    & > li.current_page_ancestor {
      & > a {
        color: $black;

        @include screen-sm {
          background: $grey_5;
        }

        &::after {
          content: "";
          margin: auto;
          position: absolute;
          bottom: 7px;
          left: 10px;
          right: 10px;
          width: calc(100% - 20px);
          height: 1px;
          background: $mint;

          @include screen-sm {
            display: none;
          }
        }
      }
    }

    & > li.current_page_ancestor {
      & > a {
        @include screen-sm {
          background: $white;
          color: $black !important;
          width: 100%;
        }
      }
    }

    & > li.current_page_item {
      & > a {
        @include screen-sm {
          background: $grey_5;
          color: $navigation-color;
        }
      }
    }

    li {
      position: relative;

      @include screen-sm {
        align-items: flex-start;
        flex-direction: column;
        height: auto;
        padding-right: 0;
        width: 100%;
      }

      &:last-child {
        padding-right: 0;

        &::after {
          display: none;
        }
      }

      &:hover {
        .sub-menu {
          opacity: 1;
          transform: translateY(0);
          visibility: visible;
        }
      }
    }

    &__item {
      a {
        @include screen-sm {
          color: #fff;
        }
      }

      &.active {
        a {
          color: $brand-color;
          @include screen-sm {
            color: #fff;
          }

          &:hover {
          }
        }
      }
    }

    &.is-active {
      @include screen-sm {
        height: calc(100vh - 102px);
        opacity: 1;
        pointer-events: auto;
        visibility: visible;

        a {
          opacity: 1;
          width: 100%;
        }
      }
    }

    a {
      color: $navigation-color;
      font-family: $heading-font;
      font-size: 18px;
      font-weight: 400;
      margin: 0 6px;
      padding: 15px 10px 10px;
      position: relative;
      text-decoration: none;
      transition: color 0.16s;
      line-height: normal;
      letter-spacing: 0.9px;


      @include screen-lg {
        font-size: 14px;
      }

      @include screen-sm {
        display: block;
        font-size: 16px;
        letter-spacing: 0;
        margin: 0;
        min-width: 168px;
        opacity: 0;
        padding: 10px;
        text-align: left;
        transition: 0.2s;
      }

      &:hover {
        color: $black;
      }
    }
  }

  .btn:not(.cta) {
    margin-left: 19px;
    opacity: 0; // uncomment in the future
    //display: none;
    position: absolute;
    right: 0;
    top: 2px;
    transform: translateY(-25%);
    transition: all 0.1s;
    visibility: hidden; // uncomment in the future
    @include screen-sm {
      position: static;
      top: unset;
      transform: none;
      //opacity: 1;
      //visibility: visible;
    }
    //@include screen-sm {
    //  font-size: 16px;
    //}
    @include screen-xs {
      font-size: 15px;
      height: 39px;
      margin-left: 0;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.logo {
  align-items: center;
  display: flex;
  text-decoration: none;

  &-image {
    display: block;
    margin-right: 20px;
    width: 35px;

    @include screen-xs {
      margin-right: 0px;
    }
  }

  &-name {
    display: block;
    width: 144px;

    @include screen-xs {
      display: none;
    }
  }

  img {
    height: 100%;
    max-height: 100%;
  }
}

.section-info {
  align-items: center;
  display: flex;

  &__icon-holder {
    display: flex;
    margin-right: 18px;
    position: relative;
    @include screen-sm {
      margin-right: 14px;
    }
    @include screen-xs {
      margin-right: 10px;
    }
  }

  &__icon-progress {
    border: 2px solid $mint;
    border-radius: 50%;
    box-shadow: 0 0 0 3px #fff inset;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;

    svg {
      display: block;
      height: 100%;
      width: 100%;
    }

    &.contact-flag {
      path {
        fill: $brand-color;
      }
    }
  }

  &__icon {
    align-items: center;
    color: $heading-font;
    display: flex;
    font-size: 40px;
    height: 40px;
    justify-content: center;
    transition: color 0.16s;
    width: 40px;

    .icon-capabilities {
      font-size: 30px;
    }

    .icon-skillset-1 {
      font-size: 17px;
    }

    .icon-projects {
      font-size: 21px;
    }

    .icon-testimonials {
      font-size: 16px;
    }

    a {
      color: $heading-font;
      display: inline-flex;
      text-decoration: none;
    }
  }

  &__index {
    color: $headings-color;
    font-family: $font-family-primary;
    font-size: 24px;
    font-weight: 500;
    line-height: 1;

    @include screen-sm {
      font-size: 20px;
    }
    @include screen-xs {
      font-size: 18px;
    }
    @include screen-xxs {
      font-size: 16px;
    }
  }

  &__title {
    color: $graphite;
    display: flex;
    font-family: $heading-font;
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: normal;
    transform: translateY(1px);

    @include screen-xs {
      font-size: 16px;
    }
  }

  .section-title {
    &__index {
      font-weight: normal;
      margin-right: 9px;
      text-transform: uppercase;
      user-select: none;

      @include screen-sm {
        //min-width: 35px;
        //margin-right: 0;
      }
      @include screen-xs {
        //min-width: 22px;
        //margin-right: 0;
      }
    }

    &__delimiter {
      background: $mint;
      height: 20px;
      margin-right: 9px;
      margin-top: -1px;
      user-select: none;
      width: 1px;

      @include screen-xs {
        //margin-right: 4px;
      }

      &.is-hidden {
        display: none;
      }
    }

    &__name {
      font-weight: normal;
      text-transform: uppercase;
      user-select: none;

      &.is-hidden {
        display: none;
      }
    }
  }
}

.logo-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.logo__text {
  margin-right: 20px;
  width: 174px;

  @include screen-xs {
    width: 170px;
  }
}

.sub-menu {
  background: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  min-width: 200px;
  opacity: 0;
  padding: 8px 7px;
  position: absolute;
  top: 100%;
  visibility: hidden;

  @include screen-sm {
    box-shadow: unset;
    margin-bottom: 0px;
    min-width: unset;
    opacity: 1;
    padding: 0 0 0 30px;
    position: static;
    visibility: visible;
    width: 100%;
  }

  li {
    padding: 0;
    position: relative;

    a {
      display: block;
      font-size: 15px;
      letter-spacing: initial;
      margin: 0;
      padding: 10px;
      text-transform: inherit;
      white-space: nowrap;

      @include screen-max {
        font-size: 15px;
      }

      @include screen-lg {
        font-size: 14px;
      }

      @include screen-sm {
        font-size: 16px;
      }

      &:hover {
        background: $grey_5;
      }
    }

    &.current_page_item {
      a {
        background: $grey_5;
      }
    }
  }
}

body.overflow-hidden {
  .header .cta {
    display: none;
  }

  // .alert-banner + .header {
  // }

  @include screen-sm {
    &.page-template-legacy-tech-landing-template {
      .contact-form {
        top: calc(102px + 25px);
      }
    }
  }
}
