.row {
  display: flex;
  flex-wrap: wrap;
}

.col {
  position: relative;
  width: 100%;

  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
