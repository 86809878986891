.project__card {
  display: flex;
  flex-direction: column;
  background-color: $white;
  max-width: 290px;
  margin-right: 20px;

  &-image {
    max-height: 222px;
    overflow: hidden;
  }

  &-content {
    padding: 20px 14px 24px;
    min-height: 150px;

    display: flex;
    flex-direction: column;

    h5 {
      margin-bottom: 4px;
    }

    p {
      flex-grow: 1;
    }
  }

  .technologies__icons-list {
    display: flex;
    gap: 13px;
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.projects__slider {
  width: 100%;
  height: 100%;
  margin-top: 23px;
  margin: 37px 0 64px;
  height: auto;
}
