.clutch {
  --swiper-navigation-size: 30px;
  --swiper-navigation-color: #{$grey_4};
  background-color: $grey_6;
  padding: 90px 80px;

  @include screen-sm {
    padding: 45px 40px;
  }

  &__inner {
    position: relative;
    gap: 48px;
    display: flex;
    flex-direction: column;
    max-width: 1024px;
    margin: 0 auto;
  }

  &__intro {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .clutch-widget {
      width: 195px;
    }

    @include screen-sm {
      flex-direction: column;
      gap: 20px;
      align-items: center;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    z-index: 2;
  }

  &__content {
    position: relative;
    padding: 0 80px;
    margin-left: -80px;
    margin-right: -80px;

    @include screen-sm {
      padding: 0 40px;
      margin-left: -40px;
      margin-right: -40px;
    }
  }

  &__carousel {
    overflow: hidden;

    .swiper-button-prev {
      left: 20px;
      @include screen-sm {
        left: 10px;
      }
    }

    .swiper-button-next {
      right: 20px;
      @include screen-sm {
        right: 10px;
      }
    }
  }

  &-review {
    display: flex;
    flex-direction: column;
    height: auto;
    background: $white;
    padding: 20px;

    &__rating {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-bottom: 13px;

      &-number {
        font-family: $font-family-secondary;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;

        text-align: center;
        letter-spacing: -0.015em;
      }
    }

    &__author {
      font-size: 14px;
      color: $grey_2;
    }

    &__description {
      font-family: $font-family-tertiary;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: $grey_1;
      margin-bottom: 13px;
      flex-grow: 1;
    }

    &__verified {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 28px;

      font-family: $font-family-tertiary;
      color: $grey_3;
      font-weight: 600;
      font-size: 12px;
      line-height: 136.5%;
    }
  }
}

.star__rating {
  $checked: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><g data-name="Layer 2"><path class="cls-1" d="m5.94 9.38-3.11 1.88a.5.5 0 0 1-.75-.54l.83-3.53L.17 4.81a.5.5 0 0 1 .29-.87l3.6-.31L5.48.3a.5.5 0 0 1 .92 0l1.41 3.33 3.61.31a.49.49 0 0 1 .28.87L9 7.19l.82 3.53a.5.5 0 0 1-.74.54Z" data-name="Layer 1"/></g></svg>');
  $unchecked: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><g data-name="Layer 2"><path class="cls-1" d="M5.94 1.78 6.89 4l.24.55.6.06 2.43.2-1.85 1.63-.45.39.14.59.55 2.37-2.09-1.27-.52-.31-.52.31-2.09 1.27.55-2.37.12-.59-.46-.39-1.82-1.61 2.43-.2.6-.06L5 4l1-2.24M5.94 0a.48.48 0 0 0-.46.3L4.06 3.63l-3.6.31a.5.5 0 0 0-.29.87l2.74 2.38-.83 3.53a.5.5 0 0 0 .49.62.52.52 0 0 0 .26-.08l3.11-1.88 3.11 1.88a.49.49 0 0 0 .74-.54L9 7.19l2.7-2.38a.49.49 0 0 0-.28-.87l-3.61-.31L6.4.3a.5.5 0 0 0-.46-.3Z" data-name="Layer 1"/></g></svg>');
  $starColour: #e62415;

  display: flex;

  .star {
    width: 16px;
    height: 16px;

    &:before,
    &:after {
      content: "";
      display: block;
      height: 100%;
      min-height: 16px;
      width: 100%;
    }

    // unchecked
    &.unchecked {
      &:before {
        background-color: $starColour;
        mask-image: $unchecked;
      }
    }

    &.checked {
      &:before {
        background-color: $starColour;
        mask-image: $checked;
      }
    }
  }
}
