.clients {
  background: $white;
  overflow: hidden;
  padding-bottom: 78px;
  padding-top: 130px;

  @include screen-lg {
    padding-top: 120px;
  }

  @include screen-md {
    padding-bottom: 20px;
    padding-top: 50px;
  }

  &__heading {
    margin-bottom: 15px;
  }

  &__description {
    color: $font-color-secondary;
    font-size: 18px;
    line-height: get-line-height(18px, 32px);
    margin-bottom: 24px;

    @include screen-xs {
      font-size: 16px;
      line-height: get-line-height(16px, 25px);
    }

  }

  &__link {
    a {
      color: $font-color;
      font-family: $heading-font;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 1px;

      &:hover {
        color: $brand-color;
      }
    }
  }


  &__columns {
    display: flex;
    justify-content: space-between;
    margin-left: -40px;
    margin-right: -40px;

    @include screen-md {
      flex-wrap: wrap;
    }

  }

  &__column {
    padding-left: 40px;
    padding-right: 40px;


    &--first {
      flex-basis: 33.3333%;
      flex-shrink: 0;

      @include screen-md {
        flex-basis: 100%;
        margin-bottom: 50px;
        padding-right: 34px;
      }

      //@include screen-md {
      //  margin-bottom: 34px;
      //}

    }

    &--expanded {
      flex-grow: 1;
    }
  }

  .logos {
    &__item {
      align-items: center;
      display: flex;
      flex-basis: 33.3333%;
      justify-content: center;
      margin-bottom: 60px;
      padding: 10px 15px;

      @include screen-max {
        margin-bottom: 50px;
      }

      @include screen-lg {
        margin-bottom: 48px;
      }

      @include screen-sm {
        margin-bottom: 50px;
      }

      img {
        height: 100%;
        object-fit: contain;
        width: 100%;
      }

      &-inner {
        height: 100%;
        max-height: 70px;
        max-width: 168px;

        @include screen-max {
          max-height: 60px;
          max-width: 150px;
        }

        @include screen-lg {
          max-height: 55px;
          max-width: 140px;
        }

        @include screen-md {
          max-height: 55px;
          max-width: 140px;
        }

        @include screen-sm {
          max-height: 55px;
          max-width: 140px;
        }

        &--savy {
          max-height: 38px;

          @include screen-lg {
            max-height: 30px;
          }
        }

        &--red-whale {
          max-height: 23px;

          @include screen-lg {
            max-height: 18px;
          }
        }

        &--har {
          max-height: 57px;

          @include screen-lg {
            max-height: 45px;
          }
        }

        &--hos {
          max-height: 39px;

          @include screen-lg {
            max-height: 35px;
          }
        }
      }
    }
  }

}
