.gallery {
  display: flex;
  justify-content: center;
  position: relative;
  --swiper-navigation-size: 100px;

  &--elevated {
    box-shadow: 4px 4px 24px 3px rgba(0, 0, 0, 0.1);

    .swiper-pagination {
      bottom: -40px;
    }
  }

  .beer-slider {
    .beer-reveal {
      // position: relative;
      &:before {
        content: "";
        height: 65%;
        width: 2px;
        background-color: $apple;
        position: absolute;
        right: 0;
        top: 50%;
        bottom: 50%;
        transform: translateY(-50%);
      }
    }

    .beer-handle {
      border: 2px solid $apple;
      background-color: $grey_5;
      width: 40px;
      height: 40px;
      transform: translate3d(calc(-50% - 1px), -50%, 0);
      transition: none;

      &:before {
        left: 5px;
      }

      &:after {
        right: -5px;
      }
    }

    &:hover {
      .beer-handle {
        background-color: $apple;

        &:before,
        &:after {
          border-color: white;
        }
      }
    }
  }

  .swiper {
    &--contained {
      max-width: 864px;
    }

    overflow: hidden;
    position: relative;
    user-select: none;

    &-pagination {
      z-index: 5;
      bottom: 0;
      background: $graphite;
      height: 34px;
    }

    &-button-next,
    &-button-prev {
      opacity: 0;
      z-index: 2;
      transition: background-color 0.25s, color 0.25s, transform 0.25s, opacity 200ms;

      width: 100px;
      height: 100px;
      background: $graphite;
      border-radius: 50%;
      color: $mint;

      &:after {
        content: "\e924";
        font-family: icomoon;
        font-size: 7px;
        background-size: contain;
        -webkit-font-smoothing: antialiased;
      }
    }

    &-button-next {
      margin-right: -70px;

      &:hover {
        transform: translateX(-4px);
      }

      &::after {
        margin-right: 55px;
      }
    }

    &-button-prev {
      margin-left: -70px;

      &:hover {
        transform: translateX(4px);
      }

      &::after {
        transform: rotate(180deg);
        margin-left: 55px;
      }
    }

    &:hover {
      .swiper-button-next,
      .swiper-button-prev {
        opacity: 1;

        &.swiper-button-disabled {
          opacity: 0.35;
        }
      }
    }

    @media (pointer: coarse) {
      /* Styles for touch-enabled devices */
      /* Add your CSS rules here */
      .swiper-button-next,
      .swiper-button-prev {
        opacity: 1;

        &.swiper-button-disabled {
          opacity: 0.35;
        }
      }
    }

    &-pagination-bullet {
      width: 30px;
      border-radius: 0;
      height: 3px;
      margin: 0 6px;
      opacity: 1;
      background: $white;
    }

    &-pagination-bullet-active {
      background-color: $mint;
    }

    &-slide {
      user-select: none;
    }
  }
}

.gallery-section {
  background: #f2f2f2;

  &__content {
    position: relative;
    display: flex;
  }

  &__left {
    flex-basis: 40%;
    padding: 90px 0 48px 48px;

    h1 {
      color: $forest;
    }
  }

  &__right {
    flex-grow: 1;
    padding: 48px 48px 0;
  }
}
