.section {
  padding: 80px 0;
  position: relative;
  @include screen-md {
    padding: 80px 0;
  }
  @include screen-sm {
    padding: 60px 0;
  }
  @include screen-xs {
    padding: 50px 0;
  }

  &--accent {
    background-color: $grey_7;
  }

  &--template {
    padding-bottom: 0;
    padding-top: 0;
  }

  &--hide-overflow,
  &--sprints {
    overflow: hidden;
  }

  &.about--inner,
  &--services-inner {
    padding-bottom: 0;
    padding-top: 63px;

    @include screen-sm {
      padding-top: 20px;
    }
  }

  &--principles-inner {
    background-color: $grey_7;
    padding-top: 100px;

    @include screen-sm {
      padding-top: 60px;
    }

    @include screen-xs {
      padding-top: 40px;
    }
  }

  &.services--sprints {
    padding-bottom: 0;
    padding-top: 36px;
  }

  &--team-landing,
  &--team-single {
    background-color: #fff;
    padding-bottom: 0;
    padding-top: 0;
  }

  &--decorate {
    position: relative;
  }

  &--p0 {
    padding: 0 !important;
  }

  // Half padding
  &--ph {
    padding: 40px 0;
  }

  // Half bottom padding
  &--pbh {
    padding: 80px 0 40px;
  }

  // Half top padding
  &--pth {
    padding: 40px 0 80px;
  }

  &--pt0 {
    padding-top: 0 !important;
  }

  &--pb0 {
    padding-bottom: 0 !important;
  }

  &__header {
    font-family: $heading-font;
    font-size: 64px;
    font-weight: 500;
    line-height: get-line-height(54px, 65px);
    margin-bottom: 28px;

    @include screen-max {
      font-size: 64px;
    }

    @include screen-lg {
      font-size: 54px;
    }

    @include screen-sm {
      font-size: 48px;
    }

    @include screen-xs {
      font-size: 44px;
    }
  }

  &--team-single {
    padding-bottom: 0;
  }

  &--team-projects {
    padding-bottom: 0;
    padding-top: 70px;

    @include screen-md {
      padding-top: 50px;
    }

    @include screen-sm {
      padding-top: 50px;
    }

    .container {
      position: relative;
    }

    &::before {
      background: $grey_6;
      content: "";
      height: 80%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;

      @include screen-sm {
        height: 100%;
      }
    }
  }

  &--team-columns {
    overflow: hidden;
    padding-bottom: 104px;
    padding-top: 0;

    @include screen-sm {
      padding-bottom: 50px;
      padding-top: 30px;
    }

    @include screen-xs {
      padding-top: 10px;
    }
  }

  &--project-single {
    background-color: $grey_6;
    padding-bottom: 0;

    //@include screen-md {
    //  padding-top: 40px;
    //}
    //@include screen-sm {
    //  padding-top: 20px;
    //}
  }

  &--featured-projects-gallery {
    overflow: hidden;
    padding-bottom: 0;
    padding-top: 0;

    .featured-projects-gallery__slider {
      &::after {
        display: none;
      }
    }
  }

  &--bg {
    background-color: $grey_6;
  }

  &--dark {
    background-color: $graphite;
    color: $white;
  }

  &--black {
    background-color: $black;
    color: $white;
  }

  &--single-service {
    margin-bottom: 100px;
    overflow: hidden;
    padding-bottom: 0;
    padding-top: 42px;

    @include screen-md {
      margin-bottom: 80px;
      padding-top: 20px;
    }
    @include screen-sm {
      margin-bottom: 50px;
    }
  }

  &__content {
    color: #fff;
    font-family: $font-family-secondary;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0.05em;
    line-height: get-line-height(18px, 26px);
    margin-bottom: 66px;

    @include screen-max {
      font-size: 24px;
    }

    @include screen-lg {
      font-size: 18px;
    }

    @include screen-sm {
      font-size: 16px;
    }

    @include screen-xs {
      font-size: 14px;
    }

    &--contact {
      margin-bottom: 60px;
    }
  }

  &--flexible {
    padding-bottom: 90px;
    padding-top: 0;

    @include screen-sm {
      padding-bottom: 50px;
    }

    .content-text {
      max-width: 820px;

      h3 {
        color: $font-color;
        font-family: $heading-font;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.5px;
        line-height: get-line-height(24px, 36px);
        margin-bottom: 20px;
        margin-top: 70px;

        @include screen-md {
          font-size: 22px;
          margin-bottom: 15px;
          margin-top: 40px;
        }
      }

      &--smaller {
        h3 {
          margin-top: 0;
        }

        p {
          margin-bottom: 1em;

          &:last-child {
            margin-bottom: 0;

            img {
              margin-bottom: 60px;

              @include screen-sm {
                margin-bottom: 30px;
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .columns {
      &__item {
        @include screen-md {
          margin-bottom: 30px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        img {
          display: block;
          height: auto;
          margin-bottom: 60px;
          width: 100%;
        }
      }
    }

    .section {
      &__inner {
        background-color: #fff;
        box-shadow: 0 2px 12px 0 rgba(64, 54, 78, 0.1);
        padding: 100px 0 80px;

        @include screen-md {
          padding-bottom: 55px;
          padding-top: 70px;
        }

        @include screen-sm {
          padding-bottom: 40px;
          padding-top: 50px;
        }

        &--transparent {
          background-color: transparent;
          box-shadow: unset;
        }

        &--rounded {
          border-radius: 9px;
        }

        &--nopb {
          padding-bottom: 0;
        }
      }

      &__container {
        padding-left: 100px;
        padding-right: 100px;

        @include screen-md {
          padding-left: 60px;
          padding-right: 60px;
        }

        @include screen-sm {
          padding-left: 40px;
          padding-right: 40px;
        }

        @include screen-xs {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }

    & > .container {
      @include screen-xs {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &-secondary {
      margin-bottom: 70px;
      margin-top: 70px;
      padding-bottom: 0;
      padding-top: 0;

      @include screen-sm {
        margin-bottom: 50px;
        margin-top: 50px;
      }

      &-bigger {
        margin-bottom: 100px;

        @include screen-md {
          margin-bottom: 70px;
        }

        @include screen-sm {
          margin-bottom: 50px;
        }
      }

      &-no-indent {
        margin-bottom: 0;
      }

      .container {
        @include screen-xs {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    &-bottom-overflow {
      margin-bottom: -100px;
      padding-bottom: 170px;

      @include screen-md {
        padding-bottom: 150px;
      }

      .section {
        &__inner {
          padding-bottom: 0;
        }
      }

      & + .section {
        margin-top: 0;

        .featured-gallery--flexible {
          margin-top: 0;
        }
      }
    }
  }

  &--intro {
    padding-bottom: 0;
  }
}

.contact {
  padding: 100px 0 !important;

  @include screen-md {
    padding: 50px 0 70px !important;
  }

  .row {
    justify-content: space-between;

    .col {
      justify-content: center;
      display: flex;
      flex-direction: column;
    }

    img {
      margin: 0 0 0 auto;
    }

    @include screen-md {
      flex-direction: column;
      gap: 60px;
    }
  }

  &.work .section {
    &__header {
      font-size: 36px;
      line-height: 120%;
      letter-spacing: 0.72px;
    }

    &__content {
      font-size: 16px;
      line-height: 160%;
      letter-spacing: 0.64px;
      margin-bottom: 80px;
      max-width: 350px;

      @include screen-md {
        margin-bottom: 40px;
        max-width: 600px;
      }
    }
  }

  .btn--contact {
    width: 192px;
  }

  img.greyscale {
    filter: grayscale(1);
  }

  &__background {
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;

    svg {
      height: 100%;
      object-fit: cover;
      opacity: 0.2;
      width: 100%;
    }
  }
}

.section--inverse {
  background-color: $font-color-secondary;
  color: #fff;
  padding: 110px 0 70px;
  @include screen-md {
    padding-bottom: 80px;
    padding-top: 80px;
  }
  @include screen-xs {
    padding: 60px 0;
  }

  [href*="tel"] {
    white-space: nowrap;
  }

  a:not(.btn) {
    color: #fff;
    font-weight: 400;

    &:hover {
      text-decoration: none;
    }
  }
}

.section--content-center {
  text-align: center;
}

.section--img {
  padding: 0;
  position: relative;

  img {
    height: 517px;
    object-fit: cover;
    width: 100%;
    @include screen-sm {
      height: 350px;
    }
    @include screen-xs {
      height: 200px;
    }
  }

  //&::after {
  //  content: '';
  //  display: block;
  //  position: absolute;
  //  top: 0;
  //  bottom: 0;
  //  left: 0;
  //  right: 0;
  //  background-color: rgba(0,160,214,0.2);
  //}
}

.section--padding {
  padding-bottom: 0;
}

.section--team {
  padding-bottom: 68px;
  padding-top: 75px;
}

.capabilities {
  padding-bottom: 260px;
  padding-top: 180px;
  @include screen-lg {
    padding-bottom: 140px;
    padding-top: 140px;
  }
  @include screen-md {
    padding-bottom: 100px;
    padding-top: 100px;
  }
  @include screen-sm {
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .layout-flex {
  }
}

.pattern-dotted {
  background-image: url("../img/dotted-pattern.svg?v=2");
  background-position: center top 10px;
  background-size: auto;
  // @include screen-md {
  //   background-size: 200% auto;
  // }
  // @include screen-xs {
  //   background-size: 300% auto;
  // }
}

.section-bg {
  position: relative;
}

.section-bg--bottom::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 160px;
  background-color: #f9f9f9;
}

.section-bg--bottom-small::after {
  height: 80px;
}

.section-bg--top::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 160px;
  background-color: #f9f9f9;
}

.section-bg--top-small::before {
  height: 80px;
}

.section-bg--z1 {
  z-index: 1;
}

.bg--grey {
  background-color: $grey_7;
}
