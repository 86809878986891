.modal {
  box-shadow: 4px 4px 24px 3px rgba(0, 0, 0, 0.1);
  max-width: 904px;
  top: -10%;

  @include screen-sm {
    top: 0;
  }

  &.fancybox-content {
    padding: 0 !important;
  }

  &__inner {
    display: flex;
    flex-direction: row;

    @include screen-sm {
      flex-direction: column;
    }
  }

  h3 {
    font-size: 24px;
  }

  &__content {
    padding: 60px 30px;
  }

  &__image {
    min-width: 400px;
    max-width: 50%;
    min-height: 100%;

    @include screen-sm {
      min-width: auto;
      max-width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  button.modal__close {
    width: 24px;
    height: 24px;
    font-size: 24px;
    padding: 0;
    display: flex;
    margin: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin: 20px 0;

    label {
      color: #333a3c;
      z-index: 1;
    }

    [type="submit"] {
      margin-top: 20px;
    }
  }
}
