.prices {
  background: $grey_6;
  padding-bottom: 100px;
  padding-top: 100px;

  @include screen-sm {
    padding-bottom: 50px;
    padding-top: 50px;
  }


  &__title {
    font-family: $heading-font;
    font-size: 64px;
    font-weight: 600;
    letter-spacing: 1.5px;
    line-height: get-line-height(64px, 85px);
    margin-bottom: 10px;
    text-align: center;

    @include screen-md {
      font-size: 50px;
    }
    @include screen-sm {
      font-size: 32px;
    }

  }

  &__description {
    color: $font-color-secondary;
    font-size: 24px;
    margin-bottom: 35px;
    text-align: center;

    @include screen-md {
      font-size: 20px;
      line-height: 1.45;
    }

    @include screen-xs {
      font-size: 18px;
      line-height: 1.45;
      margin-bottom: 15px;
    }
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 1110px;
    padding: 20px 50px 0;

    @include screen-md {
      padding: 20px 0 0;
    }

    @include screen-sm-custom {
      align-items: center;
      flex-direction: column;
    }
  }

  &__note {
    color: $font-color-secondary;
    flex-basis: 100%;
    padding-left: 15px;

    @include screen-md {
      padding-left: 0;
      text-align: center;
    }
  }


}
