/*@font-face {*/
/*    font-family: 'FS Albert Pro';*/
/*    src: url('FSAlbertPro-Light.woff2') format('woff2'),*/
/*        url('FSAlbertPro-Light.woff') format('woff');*/
/*    font-weight: 300;*/
/*    font-style: normal;*/
/*    font-display: swap;*/
/*}*/

/*@font-face {*/
/*    font-family: 'FS Albert Pro';*/
/*    src: url('FSAlbertPro.woff2') format('woff2'),*/
/*        url('FSAlbertPro.woff') format('woff');*/
/*    font-weight: normal;*/
/*    font-style: normal;*/
/*    font-display: swap;*/
/*}*/


/*@font-face {*/
/*    font-family: 'Tungsten';*/
/*    src: url('Tungsten-Light.woff2') format('woff2'),*/
/*    url('Tungsten-Light.woff') format('woff');*/
/*    font-weight: 200;*/
/*    font-style: normal;*/
/*    font-display: swap;*/
/*}*/

/*@font-face {*/
/*    font-family: 'Tungsten Book';*/
/*    src: url('Tungsten-Book.woff2') format('woff2'),*/
/*    url('Tungsten-Book.woff') format('woff');*/
/*    font-weight: 300;*/
/*    font-style: normal;*/
/*    font-display: swap;*/
/*}*/

/*@font-face {*/
/*    font-family: 'Tungsten';*/
/*    src: url('Tungsten-Medium.woff2') format('woff2'),*/
/*    url('Tungsten-Medium.woff') format('woff');*/
/*    font-weight: 400;*/
/*    font-style: normal;*/
/*    font-display: swap;*/
/*}*/

/*@font-face {*/
/*    font-family: 'Tungsten';*/
/*    src: url('Tungsten-Semibold.woff2') format('woff2'),*/
/*    url('Tungsten-Semibold.woff') format('woff');*/
/*    font-weight: 500;*/
/*    font-style: normal;*/
/*    font-display: swap;*/
/*}*/

/*@font-face {*/
/*    font-family: 'Tungsten';*/
/*    src: url('Tungsten-Bold.woff2') format('woff2'),*/
/*        url('Tungsten-Bold.woff') format('woff');*/
/*    font-weight: 600;*/
/*    font-style: normal;*/
/*    font-display: swap;*/
/*}*/

/*@font-face {*/
/*    font-family: 'Tungsten';*/
/*    src: url('Tungsten-Black.woff2') format('woff2'),*/
/*    url('Tungsten-Black.woff') format('woff');*/
/*    font-weight: 700;*/
/*    font-style: normal;*/
/*    font-display: swap;*/
/*}*/


@font-face {
    font-family: 'Ciutadella';
    src: url('CiutadellaW04-Lt.woff2') format('woff2'),
        url('CiutadellaW04-Lt.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ciutadella';
    src: url('CiutadellaW04-Reg.woff2') format('woff2'),
    url('CiutadellaW04-Reg.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ciutadella';
    src: url('CiutadellaW04-Medium.woff2') format('woff2'),
        url('CiutadellaW04-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ciutadella';
    src: url('CiutadellaW04-SmBold.woff2') format('woff2'),
    url('CiutadellaW04-SmBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ciutadella';
    src: url('CiutadellaW04-Bold.woff2') format('woff2'),
    url('CiutadellaW04-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}


