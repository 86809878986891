.description {
  background: #fff;
  margin-bottom: 100px;
  position: relative;
  z-index: 2;

  @include screen-sm {
    margin-bottom: 50px;
  }

  @include screen-xs {
    margin-bottom: 15px;
  }

  &__subtitle {
    color: #fff;
    font-size: 25px;
    letter-spacing: -0.015em;
    line-height: 1.5;
    max-width: 940px;
    padding-bottom: 106px;
    position: relative;
    z-index: 2;

    @include screen-sm {
      font-size: 20px;
      padding-bottom: 50px;
    }

    @include screen-xs {
      font-size: 18px;
      padding-bottom: 30px;
    }
  }

  &__title {
    background-color: transparent;
    padding-top: 0;
    position: relative;
    z-index: 2;

    &-holder {
      position: relative;

      &::before {
        background-color: $font-color-secondary;
        content: '';
        height: 586px;
        left: 0;
        margin-left: -9999px;
        padding-left: 9999px;
        position: absolute;
        right: 0;
        top: -100px;

        @include screen-md {
          height: 400px;
          top: -50px;
        }
        @include screen-sm {
          height: 100%;
          margin-right: -9999px;
          top: 0;
        }
      }
    }

    &-text {
      color: #fff;
      font-family: $heading-font;
      font-size: 64px;
      font-weight: 600;
      letter-spacing: 1.5px;
      line-height: get-line-height(64px, 75px);
      margin-bottom: 14px;
      max-width: 763px;
      padding-right: 15px;
      position: relative;
      z-index: 1;

      @include screen-md {
        font-size: 50px;
        margin-bottom: 20px;
      }
      @include screen-sm {
        font-size: 32px;
        margin-bottom: 0;
        padding-bottom: 10px;
        padding-right: 0;
        padding-top: 30px;
      }
    }



  }

  &__content {
    background: #fff;
    padding: 82px 0 30px;
    position: relative;
    z-index: 2;

    @include screen-md {
      padding: 52px 0 30px;
    }

    @include screen-sm {
      padding: 30px 0 20px;
    }

    h2 {
      @extend .h2;

      font-size: 36px;

      @include screen-lg {
        margin-bottom: 30px;
        margin-top: 60px;
        font-size: 28px;
      }

      @include screen-md {
        margin-bottom: 15px;
        padding-top: 20px;
        font-size: 28px;
      }

      @include screen-xs {
        margin-bottom: 10px;
        margin-top: 10px;
        padding-top: 10px;
        font-size: 28px;
      }

      &.no-top-indent {
        margin-top: 0;
        padding-top: 0;
      }
    }

    h5, h6 {
      @extend .h5;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 43.2px */
      letter-spacing: 0.72px;
      margin-top: 100px;
      margin-bottom: 18px;

      &:first-child {
        margin-top: 40px;
      }

      @include screen-lg {
        font-size: 24px;
        margin-bottom: 20px;
        margin-top: 80px;
      }
    }

    h6 {
      font-size: 24px;

      @include screen-lg {
        font-size: 18px;
      }
    }

    p {
      margin-bottom: 1.3em;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 160%; /* 25.6px */
      letter-spacing: 0.64px;

      @include screen-sm {
        font-size: 14px;
      }

      span {
        font-weight: 300 !important;
      }
    }

    a {
      color: $font-color;
      font-weight: 500 !important;
      text-decoration: underline;

      span {
        font-weight: inherit !important;
      }

      &:hover {
        color: $brand-color;
      }
    }

  }

  &__image {
    position: relative;
    z-index: 2;

    img {
      display: block;
      height: auto;
      max-width: 100%;
    }
  }

  &--post {
    background-color: transparent;
    margin-top: -100px;

    @include screen-sm {
      background-color: $font-color-secondary;
      margin-top: 0;
    }

    .description {
      &__inner {
        background-color: $font-color-secondary;
        padding: 50px 60px 45px;

        @include screen-sm {
          padding: 30px 0;
        }
      }

      &__title {
        &-holder {
          &::before {
            display: none;
          }
        }

        &-text {
          margin-bottom: 33px;
        }
      }

      .tag {

      }
    }
  }

  &--text {
    margin-bottom: 40px;

    @include screen-md {
      margin-bottom: 30px;
    }

    @include screen-xs {
      margin-bottom: 15px;
    }

    .description {
      &__content {
        padding-bottom: 0;
        padding-top: 0;

        @include screen-md {
          padding: 30px 0 0;
        }
      }
    }
  }

  &__header-group {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    @include screen-sm {
      margin-bottom: 0;
    }
  }

  &--sprints {
    margin-bottom: 0;

    .description {
      color: $graphite;

      &__content {
        padding-bottom: 47px;
        padding-top: 80px;

        @include screen-sm {
          padding-bottom: 20px;
          padding-top: 40px;
        }
      }
    }
  }

  blockquote {
    @extend .quote;
    @extend .quote--bold;
    margin: 70px 100px 50px 163px;
    padding: 0 63px 0 0;
    position: relative;

    @include screen-sm {
      margin: 70px 30px 50px 93px;
    }

    @include screen-xs {
      padding: 0 20px 0 0;
    }

    &::before {
      bottom: 100%;
      color: $apple;
      content: '\e922';
      font-family: 'icomoon' !important;
      font-size: 31px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: normal;
      line-height: 1;
      pointer-events: none;
      position: absolute;
      right: 100%;
      transform: translateY(6px) translateX(-25px);
      z-index: -1;
    }

    p {
      color: $font-color;
      margin-bottom: 0;
      color: $graphite;
      font-family: $font-family-secondary;
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 160%; /* 51.2px */
      letter-spacing: 0.64px;

      @include screen-sm {
        font-size: 18px;
      }
    }

    cite {
      text-align: right;
      display: block;
      font-weight: 400;
      margin-top: 10px;
      font-size: 18px;

      @include screen-sm {
        font-size: 14px;
      }

      &::before {
        content: '—';
      }
    }
  }
}
