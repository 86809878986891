@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?fcwhnk');
  src:  url('fonts/icomoon.eot?fcwhnk#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?fcwhnk') format('truetype'),
    url('fonts/icomoon.woff?fcwhnk') format('woff'),
    url('fonts/icomoon.svg?fcwhnk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-page-icon-about:before {
  content: "\e91b";
}
.icon-page-icon-contact:before {
  content: "\e930";
}
.icon-page-icon-home:before {
  content: "\e931";
}
.icon-page-icon-projects:before {
  content: "\e932";
}
.icon-page-icon-services:before {
  content: "\e933";
}
.icon-share:before {
  content: "\e92f";
}
.icon-circle-arrow-down:before {
  content: "\e92e";
}
.icon-node:before {
  content: "\e92d";
}
.icon-dribbble:before {
  content: "\e92c";
}
.icon-quote-top-line:before {
  content: "\e920";
}
.icon-quote-bottom-line:before {
  content: "\e921";
}
.icon-quote-top-filled:before {
  content: "\e922";
}
.icon-quote-bottom-filled:before {
  content: "\e923";
}
.icon-arrow-right:before {
  content: "\e924";
}
.icon-arrow-up:before {
  content: "\e925";
}
.icon-arrow-down:before {
  content: "\e926";
}
.icon-arrow-left:before {
  content: "\e927";
}
.icon-small-arrow-right:before {
  content: "\e928";
}
.icon-small-arrow-top:before {
  content: "\e929";
}
.icon-small-arrow-left:before {
  content: "\e92a";
}
.icon-small-arrow-bottom:before {
  content: "\e92b";
}
.icon-Play:before {
  content: "\e91f";
}
.icon-faq-icon:before {
  content: "\e91e";
}
.icon-dribbble-icon:before {
  content: "\e91d";
}
.icon-share-copy:before {
  content: "\e913";
}
.icon-share-email:before {
  content: "\e914";
}
.icon-share-instagram:before {
  content: "\e915";
}
.icon-share-facebook:before {
  content: "\e916";
}
.icon-share-twitter:before {
  content: "\e917";
}
.icon-team-social-linkedin:before {
  content: "\e918";
}
.icon-team-social-facebook:before {
  content: "\e919";
}
.icon-quote:before {
  content: "\e91a";
}
.icon-clock:before {
  content: "\e91c";
}
.icon-form-step-checkmark:before {
  content: "\e912";
}
.icon-form-step-image:before {
  content: "\e911";
}
.icon-readmore-arrow:before {
  content: "\e910";
}
.icon-list-icon:before {
  content: "\e90d";
}
.icon-quote-icon:before {
  content: "\e90e";
}
.icon-modal-arrow:before {
  content: "\e90f";
}
.icon-close:before {
  content: "\e90c";
}
.icon-skillset-1:before {
  content: "\e907";
}
.icon-linkedin:before {
  content: "\e903";
}
.icon-capabilities:before {
  content: "\e904";
}
.icon-contact:before {
  content: "\e905";
}
.icon-projects:before {
  content: "\e906";
}
.icon-skillset-2:before {
  content: "\e908";
}
.icon-skillset-3:before {
  content: "\e909";
}
.icon-team:before {
  content: "\e90a";
}
.icon-testimonials:before {
  content: "\e90b";
}
.icon-computer:before {
  content: "\e900";
}
.icon-calendar:before {
  content: "\e901";
}
.icon-arrow:before {
  content: "\e902";
}
