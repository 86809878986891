.hero {
  background-color: $white;
  padding-bottom: 90px;
  padding-top: 100px;

  @include screen-sm {
    padding-bottom: 40px;
    padding-top: 50px;
  }

  @include screen-xs {
    padding-top: 40px;
  }

  &-content {
    //padding-left: 20px;

    @include screen-xs {
      padding-left: 0;
    }

    &--navigation {
      padding-right: 50px;
      position: relative;
    }
  }

  &__title {
    font-weight: 500;
    margin-bottom: 14px;
    font-size: 48px;
    line-height: 120%;
    font-weight: 500;

    @include screen-sm {
      font-size: 40px;
    }
  }

  .container {
    height: 100%;
  }

  &__caption {
    color: $graphite;
    margin-bottom: 24px;
    max-width: 670px;

    @include screen-max {
      margin-bottom: 22px;
      max-width: 600px;
    }

    @include screen-lg {
      margin-bottom: 20px;
      max-width: 532px;
    }

    &-link {
      color: $font-color-secondary;
      text-decoration: underline;
      transition: all 0.16s;

      &:hover {
        color: $brand-color;
      }
    }

    &--team-landing {
      letter-spacing: 0;
      margin-bottom: 13px;
      max-width: 940px;
      //margin-bottom: 100px;
    }
  }

  &-content-left {
    flex-basis: 50%;
    @include screen-md {
      flex-basis: 100%;
      padding-bottom: 70px;
    }
    @include screen-sm {
      padding-bottom: 50px;
    }
  }

  &-content-right {
    flex-basis: 50%;
    padding-left: 70px;
    position: relative;
    text-align: right;
    width: 50%;

    @include screen-md {
      flex-basis: 100%;
      padding-left: 0;
      width: 100%;
    }
  }

  &--inner {
    background-color: $font-color;
    height: 500px;
    padding: 0;
    position: relative;

    @include screen-md {
      height: 300px;
    }

    @include screen-sm {
      height: 200px;
    }
  }

  &--bigger {
    height: 600px;

    @include screen-lg {
      height: 500px;
    }

    @include screen-md {
      height: 400px;
    }

    @include screen-sm {
      height: 250px;
    }
  }

  &--post {
    height: 500px;

    @include screen-lg {
      height: 400px;
    }

    @include screen-md {
      height: 300px;
    }

    @include screen-sm {
      height: 200px;
    }

    @include screen-xs {
      height: 150px;
    }
  }

  &--xl {
    height: 660px;

    @include screen-lg {
      height: auto;
    }
  }

  &--secondary {
    background: transparent;
    padding-bottom: 40px;
    padding-top: 22px;

    .hero {
      &__caption {
        letter-spacing: 0;
        margin-bottom: 0;
        max-width: 960px;

        p {
          margin-bottom: 1em;

          &:last-child {
            margin-bottom: 0;
          }
        }

        @include screen-xs {
          font-size: 15px;
        }
      }

      &-content {
        position: relative;
      }
    }
  }

  &--post-single {
    background-color: transparent;
    margin-bottom: 55px;
    padding-bottom: 0;
    padding-top: 0;

    @include screen-sm {
      margin-bottom: 30px;
    }
  }

  &--tertiary {
    background-color: transparent;
    padding-top: 0;
    position: relative;
    z-index: 2;

    .hero {
      &-content {
        position: relative;

        &::before {
          background-color: $font-color-secondary;
          content: "";
          height: 586px;
          left: 0;
          margin-left: -9999px;
          padding-left: 9999px;
          position: absolute;
          right: 0;
          top: -100px;
        }
      }

      &__title {
        color: #fff;
        line-height: get-line-height(64px, 75px);
        max-width: 763px;
        position: relative;
        z-index: 1;
      }
    }
  }

  &__background {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: -150px;
    top: 0;
    width: 100%;
    z-index: 1;

    @include screen-md {
      right: -300px;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__background {
    z-index: 2;
  }

  &--side-image {
    padding: 0;
    height: 600px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      background-color: $grey_7;
      height: 110px;
      width: 100%;
      left: 0;
      bottom: 0;
    }

    .hero {
      &__container {
        max-width: 1024px;
        margin: 0 auto;
        height: 100%;
      }

      &__inner {
        display: flex;
        align-items: center;
        height: 100%;
        position: relative;
        padding: 0 80px;

        @include screen-sm {
          padding: 0 40px;
          align-items: flex-start;
        }
      }

      &__background {
        position: absolute;

        &-image {
          width: 100%;
          height: 100%;
          img {
            height: 100%;
            object-fit: cover;
            width: 100%;
          }
        }
      }

      &__foreground {
        max-width: 368px;
        position: relative;
        z-index: 3;

        @include screen-lg {
          padding-bottom: 100px;
          padding-top: 180px;
        }

        @include screen-md {
          padding-bottom: 80px;
          padding-top: 80px;
        }

        @include screen-sm {
          max-width: unset;
          padding-top: 63px;
        }
      }

      &__title {
        color: #fff;
        max-width: 750px;
        font-weight: 500;
        font-size: 48px;
        line-height: 58px;
        @include screen-sm {
          font-size: 24px;
          line-height: 1;
        }
      }

      &__caption {
        color: #fff;
        margin-bottom: 0;
        max-width: 520px;
        @include screen-sm {
          font-size: 14px;
        }

        // @include screen-sm {
        //   display: none;
        // }
      }
    }

    &-darken {
      background-color: #7b899c;
      background-image: linear-gradient(-21deg, #7b899c 0%, #40506a 100%);

      .hero {
        &__background {
          &-image {
            width: 44%;
          }
        }

        &__foreground {
          max-width: 56%;

          @include screen-sm {
            max-width: unset;
          }

          .container {
            padding-right: 20px;
          }
        }
      }
    }
  }

  &--project-logo {
    height: 100vh;
    max-height: 660px;

    @include screen-md {
      height: calc(100vh - 90px);
      max-height: 500px;
    }

    @include screen-sm {
      height: auto;
      max-height: unset;
      padding-bottom: 80px;
      padding-top: 80px;
    }

    .hero {
      &__background {
        &-image {
          height: 100%;
        }

        &::after {
          background-color: #4e5d76;
          content: "";
          height: 100%;
          left: 0;
          opacity: 0.6;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }

      &__foreground {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        position: relative;
        z-index: 3;

        &-holder {
          align-items: center;
          color: #fff;
          display: flex;
          flex-direction: column;
          text-align: center;
        }
      }

      &__title {
        font-size: 48px;
        letter-spacing: 1px;
        line-height: 1.2;
        margin-bottom: 15px;

        @include screen-md {
          font-size: 40px;
        }

        @include screen-sm {
          font-size: 28px;
        }

        @include screen-xs {
          font-size: 24px;
        }
      }

      &__caption {
        color: #fff;
        font-size: 24px;
        letter-spacing: 0;
        margin-bottom: 0;

        @include screen-md {
          font-size: 20px;
        }

        @include screen-sm {
          font-size: 18px;
        }
      }

      &__project-logo {
        margin-bottom: 35px;
        max-width: 170px;
      }
    }
  }

  &--centered {

    .hero__container {
      max-width: 1180px;
    }

    text-align: center;

    .hero__caption {
      margin: 0 auto 24px;

      &.p1 {
        font-size: 16px;
      }
    }

    .hero__title {
      font-size: 36px;
      line-height: 120%;

      @include screen-sm {
        font-size: 28px;
      }
    }

    &.hero--side-image {
      .hero__inner {
        flex-direction: column;
        justify-content: center;
      }

      .hero__foreground {
        max-width: 550px;
      }

      .hero__logo {
        margin-left: auto;
        margin-right: auto;
      }

      .hero__background {
        &::after {
          content: "";
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
  }

  &--home,
  &--capability,
  &--location {
    height: 768px;
    background-color: $graphite;
    overflow: hidden;
    position: relative;

    &.white-bg {
      background-color: white;
    }

    @include screen-md {
      height: 664px;
    }

    @include screen-sm {
      height: 564px;
      padding-top: 102px;
    }

    .hero {
      &__background {
        max-width: 1024px;
        margin: 0 auto;
        svg {
          position: absolute;
          right: 0;
          path {
            fill: $black;
          }
        }

        &::after {
          content: "";
          position: absolute;
          height: 100%;
          width: 50vw;
          right: -50vw;

          background-color: $black;
        }
      }

      &__foreground {
        position: relative;
        display: flex;
        justify-content: space-between;
        max-width: 1184px;
        padding: 0 80px;
        margin: 0 auto;
        height: 100%;
        color: $white;
        z-index: 10;

        @include screen-xs {
          padding: 0 40px;
        }

        @include screen-sm {
          .hero-content-right {
            display: none;
          }
        }
      }

      &__breadcrumb {
        font-family: 'Ciutadella';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        color: $mint;
        margin-bottom: 0px;
      }

      &__title {
        font-size: 56px;
        max-width: 368px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 57.6px */
        margin: 10px 0 !important;

        @include screen-lg {
          font-size: 48px;
        }

        @include screen-sm {
          font-size: 40px;
          max-width: 420px;
        }
      }

      &__caption {
        color: $white;
        max-width: 350px;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 160%; /* 28.8px */
        letter-spacing: 0.72px;

        @include screen-sm {
          max-width: 420px;
        }
      }

      &-content-right,
      &-content-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        gap: 24px;
        padding: 0 !important;
      }

      &__gallery {
        height: 100%;
        position: relative;
        z-index: 10;

        @include screen-md {
          transform: scale(0.8);
          right: -40px;
        }

        svg,
        &-circle {
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: -170px;
          z-index: 10;
          width: 340px;
          height: 340px;
          max-width: 340px;
        }

        &-circle {
          overflow: hidden;
          border-radius: 50%;
          right: 80px;

          @include screen-lg {
            right: 54px !important;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            filter: blur(0px);
            transition: all 250ms ease-out, blur 200ms linear;
          }
        }

        &-outline {
          right: 160px !important;

          @include screen-lg {
            right: 108px !important;
          }
        }

        &-logo {
          width: 244px;
          height: 42px;
          position: absolute;
          bottom: 40px;
          right: -10px;
          display: flex;
          align-items: center;

          @include screen-md {
            bottom: 10px;
            right: 10px;
          }

          img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .hide {
          opacity: 0;
          filter: blur(15px);
        }
      }
    }
  }

}

.hero__logo {
  max-width: 240px;
  margin-bottom: 20px;

  img {
    max-height: 80px;
    width: auto;
    margin: 0 auto;
  }
}


.hero--capability,
.hero--location {
  height: auto;
  padding-bottom: 0;

  .hero__foreground {
    height: 542px;

    @include screen-sm {
      height: 442px;
    }

    .hero-content-left {
      gap: 24px;
      margin-top: -30px;

      .hero__title {
        font-size: 48px;
        margin: 0 !important;
      }
    }

    .hero-content-right {
      max-width: 480px;
      margin-top: -30px;

      .hero__gallery {
        height: auto;
      }
    }
  }

  .hero__logos {
    background: $black;
    width: 100%;

    &.white-bg {
      background-color: white;
    }

    .container {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 64px 50px;

      @include screen-lg {
        justify-content: center;
        gap: 50px;

        img:nth-child(3) {
          display: none;
        }
      }

      @include screen-md {
        gap: 40px;

        img:nth-child(5) {
          display: none;
        }
      }

      @include screen-sm-custom {
        img:nth-child(5) {
          display: none;
        }
      }

      @include screen-sm {
        padding: 34px 30px;
      }

      @include screen-xs {
        img:nth-child(4) {
          display: none;
        }
      }
    }
  }
}

.hero--location {
  @include screen-sm {
    padding-top: 10px;
  }
}


.location-hero {
  margin-top: -62px;
  max-height: 682px;
  min-height: 682px;
  height: 100%;
  width: 100%;

  .background-image {
    position: relative;

    img {
      width: 100%;
      object-fit: cover;
      max-height: 682px;
      min-height: 682px;
    }

    &::after {
      content: "";
      position: absolute;
      background-color: rgba(0, 0, 0, 0.7);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  .foreground {
    position: absolute;
    top: 0;
    left: 0 !important;
    width: 100%;
    height: 100%;
    left: 100%;
    z-index: 1;
    padding: 80px;

    @include screen-xs {
      padding: 60px;
    }

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
      transform: scale(1.15);
    }

    h1, p {
      max-width: 515px;
      color: white;
    }

    h1 {
      font-size: 48px;
      padding-top: 56px;
      line-height: 58px;
      margin-bottom: 22px;

      @include screen-sm-custom {
        font-size: 36px;
        line-height: 1.2;
        padding-top: 36px;
      }
    }

    p {
      font-size: 16px;
      line-height: 26px;
      font-family: $font-family-secondary;
      font-weight: 300;
    }

    .awards-with-laurels {
      display: flex;
      flex-direction: row;
      gap: 45px;
      align-items: center;
      justify-content: center;
      margin-top: 90px;
      height: 101px;

      @include screen-sm {
        margin-top: 60px;
        gap: 15px;
      }

      @include screen-xs {
        gap: 0px;
      }

      .laurel {
        opacity: 0.3;
        transform: scaleX(1.4) scaleY(1.4);
      }

      > :last-child {
        transform: scaleX(-1.4) scaleY(1.4);
      }
    }

    .awards {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 45px;
      width: 100%;
      max-width: 630px;


      .award {
        width: 112px;

        .title, .result {
          text-transform: uppercase;
        }

        .title {
          color: white;
          font-size: 16px;
          font-weight: 300;
          margin: 0;
          padding: 0;
          line-height: 1.2;
        }

        .result {
          color: $blonde;
          font-size: 14px;
          font-weight: 700;
          font-family: $font-family-primary;
          margin-top: 8px;

          &.winner {
            color: $mint;

          }
        }

        @include screen-sm {
          &:last-child {
            display: none;
          }
        }

        @include screen-xs {
          &:first-child {
            display: none;
          }
        }
      }
    }
  }
}
