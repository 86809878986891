.single-card-block {
  width: 100%;
  height: 496px;


  &__background {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__inner {
    display: flex;
    position: relative;
    padding: 48px;
    height: 100%;
  }

  &--inverse {
    .single-card-block__inner {
      flex-direction: row-reverse;
    }
  }
}

.small-card-block {
  width: calc(50% - 10px);
  padding-bottom: 16px;

  @include screen-sm {
    width: 100%;
  }

  .single-card-block__inner {
    padding: 0px;
  }
}

.dual-card-block {
  &__inner {
    display: flex;
    gap: 20px;

    .card {
      flex-basis: 50%;
    }
  }
}

.show-sm {
  display: block;

  @include screen-sm {
    display: none;
  }
}

.hide-sm {
  display: none;

  @include screen-sm {
    display: block;
  }
}
