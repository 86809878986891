.video-section,
.video__section {
  padding: 80px 45.5px;


  @include screen-sm {
    padding: 50px 0;
  }

  h1 {
    font-size: 48px;
  }

  &-intro {
    font-size: 18px;
    max-width: 773px;
    margin: 12px auto 32px;
  }

  &__inner {
    background: $grey_5;
    overflow: hidden;
  }

  &__holder {
    display: flex;
    padding: 112px 0 110px;
    position: relative;
    width: 100%;

    @include screen-sm {
      flex-direction: column;
      padding: 50px 0;
    }

    //&::after {
    //  content: "";
    //  width: 100vw;
    //  position: absolute;
    //  left: 100%;
    //  top:0;
    //  background: #fff;
    //  height: 100%;
    //
    //  @include screen-sm {
    //    display: none;
    //  }
    //
    //}
  }

  &__left {
    display: flex;
    flex-basis: 30%;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: space-between;

    @include screen-sm {
      flex-basis: 100%;
      padding-bottom: 20px;
    }

    &-top {
      padding-bottom: 20px;

      .heading {
        margin-bottom: 30px;
      }

      .section-description {
        color: $black;
      }
    }

    &-bottom {
      padding-bottom: 30px;
    }

    .slider-next {
      @include screen-sm {
        display: none;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
    padding-left: 100px;
    padding-top: 8px;
    position: relative;

    @include screen-md {
      padding-left: 50px;
    }

    @include screen-sm {
      padding-left: 0;
      padding-right: 0;
    }

    .slider-next {
      display: none;
      max-width: 215px;
      padding-top: 25px;

      @include screen-sm {
        //display: block;
        display: none;
      }
    }
  }

  &--testimonials {
    padding-bottom: 0;
    position: relative;

    @include screen-sm {
      padding-top: 0;
    }

    .video-section {
      &__inner {
        position: relative;
      }

      &__holder {
        padding: 112px 0;

        @include screen-md {
          padding: 80px 0;
        }

        @include screen-sm {
          padding: 40px 0 70px;
        }

        &::before {
          background-color: $white;
          bottom: 0;
          content: "";
          height: 162px;
          left: 100%;
          position: absolute;
          width: 100%;
          z-index: 1;

          @include screen-sm {
            display: none;
          }
        }
      }

      &__right {
        padding-left: 0;
        position: static;
      }
    }
  }

  &--project {
    padding-bottom: 0;
    padding-top: 0;

    .video-section__holder {
      &::after {
        display: none;
      }
    }
  }

  &__dots {
    bottom: 18px;
    left: 0;
    position: absolute;
    width: 100%;

    .slick-dots {
      display: inline-flex;
      justify-content: center;
      position: static;

      & > li {
        height: auto;
        margin: 0;
        padding: 5px;
        width: auto;

        button {
          background: $grey_4;
          height: 3px;
          padding: 0;
          transition: color 0.2s;
          width: 30px;

          &::before {
            display: none;
          }
        }

        &:hover,
        &.slick-active {
          button {
            background: $forest;
          }
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

.video-gallery {
  min-width: 100%;
  width: 0;

  &-holder {
    min-height: 0;
    min-width: 0;
    position: relative;
  }

  &__item {
    border: none !important;
    position: relative;

    &-inner {
      //transform: translate3d(0, 0, 0);
      height: 100%;
      position: relative;
      width: 100%;
    }
  }

  &__placeholder {
    height: 0;
    padding-bottom: 75%;
    position: relative;
    z-index: 2;

    img {
      height: 100%;
      object-fit: cover;
      position: absolute;
      width: 100%;
    }
  }

  &__overlay {
    align-items: center;
    background-color: rgba($graphite, 0.7);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &__video {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: 0.3s;
    visibility: hidden;
    width: 100%;
    z-index: 3;

    video {
      background-size: cover;
      display: block;
      height: 100%;
      object-fit: cover;
      width: 100%;

      .video--fullscreen & {
        object-fit: contain;
      }
    }

    &.is-active {
      opacity: 1;
      visibility: visible;
    }
  }

  &__progress-bar {
    background-color: $brand-color;
    bottom: 0;
    height: 2px;
    left: 0;
    position: absolute;
    width: 0;
    z-index: 3;
    //transition: width .5s linear;
  }
}

.video-pagination,
.testimonials-pagination {
  align-items: center;
  bottom: 0;
  color: $font-color-secondary;
  display: inline-flex;
  font-size: 13px;
  opacity: 0;
  position: absolute;
  text-align: center;
  transition: all 0.5s;
  visibility: hidden;

  @include screen-sm {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    padding-top: 20px;
    position: static;
  }

  &__current {
    color: #fff;
  }

  &__delimiter,
  &__current,
  &__total {
    user-select: none;
  }

  &__delimiter {
    margin-left: 5px;
    margin-right: 5px;
  }

  &__next,
  &__prev {
    cursor: pointer;
    display: block;
    font-size: 11px;
    margin: 0;
    padding: 2px;

    &:hover {
      color: #fff;
    }
  }

  &__prev {
    margin-right: 18px;
    transform: rotate(90deg);
  }

  &__next {
    margin-left: 18px;
    transform: rotate(-90deg);
  }

  &.is-visible {
    opacity: 1;
    visibility: visible;
    //transform: rotate(90deg) translateX(50%) translateY(-100%);

    @include screen-sm {
      transform: none;
    }
  }
}

.testimonials-pagination {
  @include screen-sm {
    justify-content: center;
    padding-top: 0;
  }
}

.gallery-watch-video {
  align-items: center;
  color: $white;
  cursor: pointer;
  display: flex;
  padding: 15px;
  transition: color 0.2s;

  &__icon {
    display: inline-flex;
    font-size: 40px;
  }

  &__caption {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 24px;
    margin-left: 20px;
    text-decoration-line: underline;
    text-transform: uppercase;

    @include screen-sm {
      font-size: 20px;
      margin-left: 20px;
    }

    @include screen-xs {
      font-size: 18px;
      letter-spacing: 1.5px;
    }
  }

  &:hover {
    color: $mint;
  }
}

.slider-next {
  &__heading {
    font-family: $heading-font;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 14px;
    text-transform: uppercase;

    @include screen-lg {
      font-size: 16px;
    }
  }

  &__link {
    appearance: none;
    cursor: pointer;
    display: none;
    height: 0;
    padding: 0;
    padding-bottom: 43%;
    position: relative;
    text-align: left;
    transition: all 0.16s;
    width: calc(100% - 87px);

    &.active {
      display: block;

      &:hover {
        opacity: 0.9;

        span {
          color: $brand-color;
        }
      }
    }
  }

  &__image {
    margin-bottom: 4px;

    &-holder {
      img {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }
}