.cookies-window {
  background-color: #fff;
  bottom: 50px;
  box-shadow: 0 0 18px 3px rgba(0, 0, 0, 0.1);
  max-width: 236px;
  opacity: 0;
  padding: 30px;
  position: fixed;
  right: 50px;
  transform: translateY(10px);
  transition: 0.5s;
  visibility: hidden;
  z-index: 10;

  @include screen-xs {
    bottom: 30px;
    left: 30px;
    max-width: unset;
    padding: 30px;
    right: 30px;
  }

  &.is-active {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }

  &__image {
    margin-bottom: 25px;
    text-align: center;

    @include screen-xs {
      display: none;
    }

    img {
      display: inline-block;
      max-width: 151px;
      width: 100%;
    }
  }

  &__button {
    text-align: center;
  }

  &__text {
    color: $graphite;
    margin-bottom: 25px;

    a {
      color: $font-color;
      font-weight: bold;
      transition: all 0.16s;

      &:hover {
        color: $brand-color;
      }
    }
  }
}
