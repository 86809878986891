.skillset {
  background-color: $grey_7;
  padding: 102px 0 181px;

  @include screen-md {
    padding-bottom: 80px;
    padding-top: 60px;
  }

  @include screen-sm {
    padding-bottom: 60px;
    padding-top: 40px;
  }

  .h2 {
    color: $forest;
    margin-bottom: 120px;

    @include screen-sm {
      margin-bottom: 70px;
    }
  }

  &-holder {
    display: flex;

    @include screen-sm {
      flex-direction: column;
    }
  }

  &-image {
    flex-basis: 50%;
    flex-shrink: 0;
    max-width: 500px;
    padding-left: 20px;
    padding-right: 46px;
    padding-top: 7px;

    @include screen-sm {
      margin-bottom: 40px;
      max-width: 300px;
      padding-left: 0;
      padding-right: 0;
    }

    svg {
      overflow: visible;
    }

    &-card {
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        transform: translateY(-1%);
      }

      &.is-active {
        transform: translateY(-11%);
      }
    }
  }

  &-carousel {
    flex-basis: 50%;
    flex-shrink: 0;
    padding-left: 64px;
    width: 50%;

    @include screen-md {
      padding-left: 30px;
    }

    @include screen-sm {
      flex-basis: 100%;
      max-width: 500px;
      padding-left: 0;
      width: 100%;
    }

    &-item {
      opacity: 0 !important;

      &.swiper-slide-active {
        opacity: 1 !important;
      }
    }

    &-title {
      color: $steel;
      margin-bottom: 20px;
    }

    &-description {
      color: $graphite;

      p {
        margin-bottom: 1em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &-swiper {
    margin-bottom: 20px;
  }

}
