.services {
  padding-bottom: 180px;
  padding-top: 147px;

  @include screen-md {
    padding-bottom: 90px;
    padding-top: 107px;
  }

  @include screen-sm-custom {
    padding-bottom: 40px;
    padding-top: 57px;
  }

  @include screen-xs {
    padding-bottom: 20px;
  }

  &-holder {
  }

  &_title {
    font-size: 36px;
    margin-bottom: 32px;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -20px;
    margin-right: -20px;
  }

  &-item {
    align-items: center;
    display: flex;
    flex-basis: 0;
    flex-direction: column;
    flex-grow: 1;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;

    @include screen-sm {
      flex-basis: 50%;
      margin-bottom: 60px;
    }

    &-image {
      height: 0;
      margin-bottom: 50px;
      padding: 0 15px 50%;
      position: relative;
      width: 100%;

      @include screen-sm {
        margin-bottom: 40px;
        padding: 0 15px 100px;
      }

      @include screen-xs {
        margin-bottom: 20px;
        padding: 0 10px 70px;
      }

      img {
        height: 100%;
        left: 0;
        object-fit: contain;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    &-title {
      margin-bottom: 25px;

      @include screen-sm {
        max-width: 160px;
      }

      @include screen-xs {
        max-width: 130px;
      }
    }

    &-action {
      //display: none;
    }

    &-list {
      ul {
        li {
          line-height: 1;
          margin-bottom: 10px;
          padding-left: 16px;
          position: relative;

          &::before {
            color: $apple;
            content: "\e92d";
            font-family: icomoon !important;
            font-size: 8px;
            left: 1px;
            position: absolute;
            top: 9px;

            @include screen-max {
              top: 8px;
            }

            @include screen-lg {
              top: 7px;
            }

            @include screen-xs {
              top: 7px;
            }
          }

          a {
            color: $stone;
            text-decoration-line: underline;

            &:hover {
              color: $apple;
            }
          }
        }
      }
    }
  }

  &--secondary {
    background: $grey_7;
    padding-top: 213px;

    @include screen-sm-custom {
      padding-top: 80px;
    }

    @include screen-xs {
      padding-bottom: 40px;
      padding-top: 40px;
    }

    .services {
      &-item {
        align-items: flex-start;
        text-align: left;

        @include screen-xs {
          flex-basis: 100%;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &-title {
          font-size: 22px;
          line-height: get-line-height(18px, 22px);
          margin-bottom: 34px;
          text-align: left;

          @include screen-max {
            font-size: 20px;
          }

          @include screen-lg {
            font-size: 18px;
          }

          @include screen-sm {
            max-width: unset;
          }

          a {
            color: $black;

            &:hover {
              color: $brand-color;
            }
          }
        }

        &-image {
          margin-bottom: 75px;

          @include screen-sm {
            margin-bottom: 40px;
          }

          @include screen-xs {
            margin-bottom: 20px;
            padding: 0 10px 100px;
          }

          img {
            @include screen-sm {
              object-position: left;
            }
          }
        }
      }
    }
  }

  &--inner {
    padding-bottom: 20px;
    padding-top: 60px;
  }

  &--block {
    display: flex;
    padding: 0;
    gap: 32px;
    max-width: 1002px;
    margin: 0 auto;

    @include screen-sm {
      flex-wrap: wrap;
    }

    .services {
      &__single {
        display: flex;
        gap: 22px;
        align-items: flex-start;
        min-height: 280px;
        background: $graphite;
        padding: 40px 32px 50px;
        width: 302px;

        @include screen-md {
          width: 600px;
        }

        @include screen-sm {
          width: 100%;
          margin: auto;
        }

        img {
          width: 140px !important;
          height: auto !important;

          @include screen-md {
            width: 100px !important;

          }

          @include screen-sm {
            width: 126px !important;

          }

          @include screen-xs {
            width: 75px !important;

          }
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        height: 100%;

        h2 {
          font-size: 24px;
          letter-spacing: 0.72px;
          margin-top: 0px;

          @include screen-md {
            min-height: 90px;
          }

          @include screen-sm {
            min-height: unset;
          }
        }

        .btn {
          margin-top: 37px;
          margin-bottom: 0px;
          max-width: fit-content;
          color: $black;
          font-size: 14px;
          padding: 11px 20px;
        }
      }
    }
  }
}

.service-card {
  margin-bottom: 100px;

  @include screen-sm {
    margin-bottom: 60px;
  }

  @include screen-xs {
    margin-bottom: 40px;
  }

  &__inner {
    box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.15);
    display: flex;
    min-height: 412px;

    @include screen-sm {
      flex-direction: column;
    }
  }

  &__background {
    flex-basis: 45%;
    flex-shrink: 0;

    &-holder {
      height: 100%;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  &__content {
    flex-grow: 1;
    padding: 40px;

    @include screen-xs {
      padding: 25px;
    }
  }

  &__heading {
    margin-bottom: 30px;
  }

  &__description {
    margin-bottom: 20px;

    p {
      margin-bottom: 1.3em;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: $black;
      font-weight: 400;

      &:hover {
        color: $apple;
      }
    }
  }

  &--inverse {
    .service-card {
      &__inner {
        flex-direction: row-reverse;

        @include screen-sm {
          flex-direction: column;
        }
      }
    }
  }

  &--small & {
    &__inner {
      min-height: auto;
      max-height: 320px;
    }

    &__background {
      flex-basis: 33%;
    }
  }
}

.process {
  &-block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.service {
  &-block {
    width: 100%;
    max-width: 864px;
    margin: auto;
    display: flex;
    gap: 20px;
    color: white;

    h2 {
      font-size: 36px;
      font-weight: 500;
      letter-spacing: 0.72px;
      line-height: 120%;
      display: flex;
      align-items: center;

      .number {
        min-width: 48px;
        width: 48px;
        height: 48px;
        background-image: url(/wp-content/themes/fullclarity/assets/src/img/white-circle.svg);
        background-size: 48px;
        background-repeat: no-repeat;
        background-position: center;
        display: inline-block;
        margin-right: 10px;
        position: relative;

        span {
          text-align: center;
          width: 100%;
          position: absolute;
          top: 2px;
          display: block;
        }
      }
    }

    h4 {
      text-transform: none;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0.48px;
      line-height: 120%;
    }

    p {
      font-size: 16px;
      font-family: $font-family-secondary;
      font-weight: 300;
      line-height: 160%; /* 25.6px */
      letter-spacing: 0.64px;
      margin: 19px 0 36px;
    }

    img {
      aspect-ratio: 420/258;
      object-fit: cover;
    }

    ul {
      margin-left: 12px;
      margin-bottom: 30px;
    }

    li {
      font-size: 14px;
      font-weight: 400;
      line-height: 160%;
      letter-spacing: 0.56px;
      font-family: $font-family-secondary;
      margin-bottom: 8px;
      padding-inline-start: 4px;
      list-style-image: url(/wp-content/themes/fullclarity/assets/src/img/bullet.svg);

      a {
        color: white;

        &:hover {
          color: $mint;
        }
      }
    }

    .btn {
      color: white;
      border-color: white;
      padding: 12px 20px;
      font-size: 14px;
      font-weight: 400;
      border-width: 1px;
      transition: all 250ms;

      &:hover {
        color: $mint;
        border-color: $mint;
      }
    }

    .col:nth-child(2) {
      padding: 64px 80px 0;
      max-width: 422px;

      @include screen-lg {
        padding: 64px 40px 0 80px;
      }

      @include screen-md {
        padding: 64px 0px 0 40px;
        max-width: 260px;
      }

      @include screen-sm {
        padding: 30px 0px 0;
        max-width: 260px;
      }
    }

    @include screen-sm {
      flex-direction: column;
      margin: -30px 0;
      padding: 0 20px;
    }
  }
}


.competencies-block {
  .flex-grid {
    display: flex;
    justify-content: center;
    gap: 40px 16px;
    flex-wrap: wrap;
    max-width: 848px;
    margin: 0 auto;

    img {
      margin-bottom: 32px;
      height: 42px;
      width: auto;
      max-width: 42px;
    }

    > div {
      width: 200px;
      height: 176px;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 19.2px */
      letter-spacing: 0.32px;
      text-transform: uppercase;
    }


    @include screen-sm {
      gap: 20px 16px;

      > div {
        height: 150px;
      }

      img {
        margin-bottom: 20px;
      }
    }
  }

  &.grey {
    .flex-grid {
      > div {
        background: $grey_7;
      }
    }
  }
}


.workflow-block {
  display: flex;
  margin: 0 auto;
  max-width: 964px;
  justify-content: space-between;
  align-items: center;
  gap: 90px;
  padding: 32px 50px;

  @include screen-lg {
    gap: 40px;
  }

  @include screen-sm {
    flex-wrap: wrap;
    justify-content: center;
    gap: 80px;
    padding: 0px 50px;
  }

  .col {
    max-width: 348px;

    @include screen-sm {
      max-width: 500px;
    }

    .wheel-item {
      background: $grey_7;
      min-height: 170px;
    }

    h4 {
      color: $forest;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 21.6px */
      letter-spacing: 0.36px;
      margin-bottom: 12px;
    }

    h3 {
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 43.2px */
      letter-spacing: 0.72px;
      margin-bottom: 20px;
    }

    p {
      color: $graphite;
      font-family: $font-family-secondary;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 160%; /* 25.6px */
      letter-spacing: 0.64px;
      margin-bottom: 40px;
    }
  }

}

.col-right {
  min-width: 416px;
  height: 416px;
  position: relative;

  @include screen-sm {
    height: 360px;
  }
}


.sprint-wheel {
  position: relative;
  padding: 44px;
  transform: scale(0.77);
  top: -40px;
  left: -80px;

  @include screen-lg {
    transform: scale(0.66);
    top: 0;
    left: -120px;
  }

  @include screen-md {
    transform: scale(0.5);
    top: 0;
    left: -120px;
  }

  @include screen-sm {
    transform: scale(0.66);
    top: -40px;
    left: -90px;
  }

  @include screen-xs {
    transform: scale(0.5);
    top: -40px;
    left: -70px;
  }

  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;

    .numbers, .units {
      text-align: center;
      font-style: normal;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 42.108px;
      letter-spacing: 0.842px;
      line-height: 100%;
    }

    .numbers {
      font-size: 132px;
      line-height: 100%;
      margin: 18px 0 -18px;
    }
  }

  svg {
    g {
      transform: translate(0, 0);
      transition: all 250ms;
    }

    g.radial-text.selected {
      &[data-wheel-value="1"] {
        transform: translate(1px, -10px);
      }

      &[data-wheel-value="2"] {
        transform: translate(8px, -5px);
      }

      &[data-wheel-value="3"] {
        transform: translate(10px, 4px);
      }

      &[data-wheel-value="4"] {
        transform: translate(-4px, 9px);
      }

      &[data-wheel-value="5"] {
        transform: translate(-7px, -10px);
      }

      &[data-wheel-value="6"] {
        transform: translate(-2px, -12px);
      }
    }
  }

  .overlay {
    z-index: 100;
    position: absolute;

    path {
      pointer-events: none;
      fill: black;

      &.trigger {
        pointer-events: all;
        cursor: pointer;
      }

      &.selected {
        fill: white;
      }
    }
  }


  $outerWidth: 540px;

  .outer, .middle {
    position: absolute;
    width: $outerWidth;
    height: $outerWidth;
    z-index: 0;

    .mask, .mask2, .mask3 {
      position: absolute;
      width: calc($outerWidth - 80px);
      height: calc($outerWidth - 80px);
      left: 40px;
      top: 40px;
      border-radius: 50%;
      background-color: white;
      z-index: 1;
      pointer-events: none;
    }

    .scaler {

      &.selected .wrapper {
        transform: rotate(0deg) scale(1.085);

        .sector {
          background: $forest;
        }
      }

      .wrapper {
        width: $outerWidth;
        height: $outerWidth;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        transition: all 250ms;
        transform: rotate(0deg) scale(1);

        .sector {
          position: absolute;
          transform-origin: 100% 100%;
          background: transparent;
          width: 5000px;
          height: 5000px;
          left: 50%;
          top: 50%;
          margin-top: -5000px;
          margin-left: -5000px;
          background: #F2F2F2;
          z-index: 0;
          transition: all 250ms;
          cursor: pointer;
        }

        .sector-1 {
          transform: rotate(90deg) skew(54deg);
        }

        .sector-2 {
          transform: rotate(126deg) skew(18deg);
        }

        .sector-3 {
          transform: rotate(198deg) skew(54deg);
        }

        .sector-4a {
          transform: rotate(234deg) skew(0deg);
        }

        .sector-4b {
          transform: rotate(323deg) skew(35deg);
        }

        .sector-5 {
          transform: rotate(378deg) skew(54deg);
        }

        .sector-6 {
          transform: rotate(414deg) skew(54deg);
        }
      }
    }
  }

  .middle {
    z-index: 10;
    pointer-events: none;

    $middleWidth: 448px;
    left: 90px;
    top: 90px;

    width: $middleWidth;
    height: $middleWidth;

    .mask {
      width: calc($middleWidth - 80px);
      height: calc($middleWidth - 80px);
      left: 40px;
      top: 40px;
      opacity: 0.6;
    }

    .mask2 {
      width: calc($middleWidth - 150px);
      height: calc($middleWidth - 150px);
      left: 75px;
      top: 75px;
      z-index: 15;
      border: 4px solid #f2f2f2;
    }

    .mask3 {
      width: calc($middleWidth - 190px);
      height: calc($middleWidth - 190px);
      left: 95px;
      top: 95px;
      z-index: 35;
    }

    .scaler {

      &.selected .wrapper {
        transform: rotate(0deg) scale(1);

        .sector {
          background: $apple;
        }
      }

      .wrapper {
        width: $middleWidth;
        height: $middleWidth;
        transform: rotate(0deg) scale(1);

        .sector {
          background: #C1C3C3;
        }
      }
    }

    .ticks {
      position: absolute;
      top: $middleWidth / 2;
      left: $middleWidth / 2;
      z-index: 10;
      pointer-events: none;

      &.phase-ticks {
        z-index: 30;
      }

      .tick {
        width: calc($middleWidth + 4px);
        position: absolute;

        .line {
          height: 0.5px;
          position: relative;
          background: white;
          top: -0.25px;
        }


        &.thick {
          .line  {
            height: 2px;
          }
        }

        &.phase-tick {
          background: transparent;
          width: 304px;

          .line {
            height: 2px;
            position: relative;
            background: $grey_3;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}
