.popup {
  align-items: center;
  backface-visibility: hidden;
  bottom: 0;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow-y: auto;
  padding: 15px;
  position: fixed;
  right: 0;
  top: 0;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: 0.3s;
  visibility: hidden;
  width: 100vw;
  z-index: 1000;

  @include screen-xs {
    padding: 0;
    //display: block;
  }

  &::before {
    background-color: $graphite;
    content: '';
    height: 100%;
    left: 0;
    mix-blend-mode: multiply;
    opacity: 0.8;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &--contact {
    padding: 15px;
  }


  &__inner {
    background-color: #fff;
    box-shadow: 0 0 18px 3px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    max-width: 860px;
    opacity: 0;
    overflow: hidden;
    position: relative;
    transform: translateY(-40px);
    transition: 0.3s;
    width: 100%;

    @include screen-xs {
      border-radius: 0;
      display: flex;
      flex-direction: column;
      min-height: 100vh;
    }

    &--contact {
      max-width: 447px;
      min-height: unset;
      padding-bottom: 46px;
      padding-top: 60px;

      .popup__header {
        margin-bottom: 15px;
        position: static;
      }

      .popup__content {
        padding-bottom: 0;
        padding-top: 0;
      }
    }

  }

  &__header {
    align-items: center;
    //height: 110px;
    display: flex;
    justify-content: center;
    //box-shadow: 0 2px 8px 0 rgba(64,54,78,0.2);
    position: relative;
    text-align: center;

    @include screen-sm {
      //height: 100px;
    }
    @include screen-xs {
      //height: 96px;
      //padding-left: 50px;
      //padding-right: 50px;
    }
  }

  &__title {
    color: $black;
    margin-bottom: 15px;
    padding: 0 20px;
    text-align: center;
  }

  &--project-password {
    .popup {
      &__inner {
        max-width: 488px;
      }

      &__header {
        height: 90px;
      }

      &__content {
        padding: 60px 20px 0;
      }

      &__footer {
        color: $grey_3;
        font-family: $font-family-secondary;
        font-size: 13px;
        padding-bottom: 20px;
        padding-top: 0;
        text-align: center;

        a {
          color: $grey_1;
          margin-left: 1px;

          &:hover {
            color: $apple;
          }
        }
      }
    }

    .password-form {
      &__holder {
        margin: auto;
        max-width: 220px;
        padding-bottom: 40px;
        padding-top: 30px;
      }
    }
  }


  &__content {
    padding: 60px 20px;
    //background-color: #f9f9f9;
    text-align: center;

  }

  &__response-message {
    color: $black;
    display: none;
    padding: 10px 0;
    text-align: center;
  }

  &__content-text {
    @extend .paragraph;
    color: $graphite;
    margin: auto;
    max-width: 620px;

    a {
      color: $font-color;
    }
  }

  &__content-actions {
    padding-top: 45px;

    @include screen-xs {
      padding-top: 30px;
    }
  }

  &__close {
    color: $grey_4;
    cursor: pointer;
    display: flex;
    font-size: 24px;
    position: absolute;
    right: 15px;
    top: 15px;
    transition: color 0.2s;
    z-index: 3;

    &:hover {
      color: $black;
    }
  }

  &.is-active {
    opacity: 1;
    transition: 0.3s;
    visibility: visible;

    .popup__inner {
      opacity: 1;
      transform: translateY(0);
      transition: 0.3s;
    }
  }

  @supports (-webkit-overflow-scrolling: touch) {
    & {
      -webkit-overflow-scrolling: touch;
    }
  }

}
