.page-template {
  &-home-template,
  &-front-page,
  &-capability-template {
    padding-top: 0;
  }
}

.our-story {
  h3 {
    font-size: 36px;
    letter-spacing: 0.72px;
    line-height: 120%;
    margin-bottom: 16px;
  }

  img {
    filter: grayscale(1);
  }

  a {
    color: $black;
    font-weight: 400;

    &:hover {
      color: $forest;
    }
  }
}
