.text {
  line-height: 1.61;
  max-width: 690px;
  @include screen-md {
    max-width: 100% !important;
  }

  p:not(:last-child) {
    margin-bottom: $global-medium-margin;
    @include  screen-xs {
      margin-bottom: $global-margin;
    }
  }

  a {
    color: $font-color;
    //margin: 0 10px;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    letter-spacing: normal;
    line-height: normal;
  }

  &--white {
    color: #fff;
  }
  
  &--narrow {
    max-width: 500px;
  }
  
  &--full-width {
    max-width: unset;
  }

  &--center {
    text-align: center;
  }

}
