$screen-xxs: 374px;
$screen-xs: 480px;
$screen-sm-custom: 576px;
$screen-sm: 768px;
$screen-md: 960px;
$screen-lg: 1024px;
$screen-max: 1280px;

@mixin screen-max {
  @media (max-width: #{$screen-max}) {
    @content;
  }
}
@mixin screen-lg {
  @media (max-width: #{$screen-lg}) {
    @content;
  }
}
@mixin screen-md {
  @media (max-width: #{$screen-md}) {
    @content;
  }
}
@mixin screen-sm {
  @media (max-width: #{$screen-sm}) {
    @content;
  }
}
@mixin screen-sm-custom {
  @media (max-width: #{$screen-sm-custom}) {
    @content;
  }
}
@mixin screen-xs {
  @media (max-width: #{$screen-xs}) {
    @content;
  }
}
@mixin screen-xxs {
  @media (max-width: #{$screen-xxs}) {
    @content;
  }
}

@mixin tablet-only {
  @media (max-width: #{$screen-md}) and (min-width: #{$screen-xs - 1}) {
    @content;
  }
}

@mixin screen-lg-min {
  @media (min-width: #{$screen-lg + 1}) {
    @content;
  }
}
@mixin screen-md-min {
  @media (min-width: #{$screen-md + 1}) {
    @content;
  }
}
@mixin screen-sm-min {
  @media (min-width: #{$screen-sm + 1}) {
    @content;
  }
}

@mixin image-2x($image) {
  @media (minpixel-ratio: 1.3),
    (minpixel-ratio: 2.6/2),
    (min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
  }
}

@mixin image-2x-size($image, $width, $height) {
  @media (minpixel-ratio: 1.3),
    (minpixel-ratio: 2.6/2),
    (min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $width $height;
  }
}
