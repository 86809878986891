@keyframes highlight {
  0% {
    color: $font-color-secondary;
    stroke: $font-color-secondary;
  }

  50% {
    color: $brand-color;
    stroke: $brand-color;
  }

  100% {
    color: $font-color-secondary;
    stroke: $font-color-secondary;
  }
}

$spinnerSize: 40;
@keyframes spinner {
  0% {
    stroke-dashoffset: (19);
    transform: rotate(24deg);
  }

  50% {
    stroke-dashoffset: (3 * $spinnerSize);
    transform: rotate(500deg);
  }

  100% {
    stroke-dashoffset: (19);
    transform: rotate(744deg);
  }
}

svg.spinner {
  height: $spinnerSize + px;
  viewBox: 0 0 $spinnerSize $spinnerSize;
  width: $spinnerSize + px;
  x: 0;
  y: 0;

  &.spinner--dark {
    position: absolute;

    circle {
      stroke: $font-color;
      stroke-dashoffset: 126;
      transition: all 0.6s;
    }
  }

  circle {
    fill: transparent;
    stroke: $font-color-secondary;
    stroke-dasharray: (3.14 * $spinnerSize);
    //animation: spinner 1s linear 1;
    stroke-dashoffset: 19;
    stroke-linecap: round;
    stroke-width: 2.5;
    transform: rotate(24deg);
    transform-origin: (0.5px * $spinnerSize) (0.5px * $spinnerSize) 0;
    transition: all 0.16s;
  }
}


.contact-form {
  backface-visibility: hidden;
  background-color: #fff;
  height: 100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: all 0.3s;
  visibility: hidden;
  width: 100vw;
  z-index: 20;

  @include screen-sm {
    background-color: $grey_7;
    height: calc(100% - 102px);
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    top: 102px;
  }

  &.is-active {
    opacity: 1;
    visibility: visible;
  }

  &__inner {
    background: #fff;
    display: flex;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    @include screen-sm {
      display: block;
      flex-direction: column;
      height: auto;
    }
  }

  &__aside {
    color: #fff;
    max-width: 320px;
    min-width: 320px;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    position: relative;
    transform: translateX(-20px);
    transition: all 0.3s;
    width: 100%;

    @include screen-sm {
      color: $font-color;
    }

    @include screen-md {
      min-width: 280px;
    }

    @include screen-sm {
      max-width: unset;
      overflow: visible;
      -webkit-overflow-scrolling: auto;
      transform: none;
      width: 100%;
      z-index: 1;
    }

    &-header {
      align-items: center;
      color: $font-color;
      display: none;
      justify-content: space-between;
      padding: 38px 30px 14px;

      @include screen-sm {
        background-color: $grey_7;
        display: flex;
      }

      //@include screen-xs {
      //  padding-left: 20px;
      //  padding-right: 20px;
      //}

    }

    &-content {
      height: 100%;
      position: relative;

      @include screen-sm {
        padding-bottom: 36px;
        padding-left: 30px;
        padding-right: 30px;
      }

      //@include screen-xs {
      //  padding-left: 20px;
      //  padding-right: 20px;
      //}
    }

    &-footer {
      align-items: center;
      box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.5);
      display: none;
      height: 90px;
      justify-content: center;

      @include screen-sm {
        display: flex;
      }

      @include screen-xs {
        justify-content: space-between;
        padding-left: 5px;
        padding-right: 5px;
      }

      .btn {
        margin-left: 10px;
        margin-right: 10px;
        @include screen-xs {
          flex-grow: 1;
          justify-content: center;
          text-align: center;
        }
      }
    }


    &-mobile {
      display: none;
      @include screen-sm {
        display: block;
      }

      .contact-form__aside-group {
        margin-bottom: 0;

        p {
          margin-bottom: 28px;
        }
      }
    }

    &-background {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;



      &-image {
        display: none;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        @include screen-sm {
          display: block;
        }

        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      &-overlay {
        background: $graphite;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        @include screen-sm {
          //opacity: 0.7;
          background-color: $grey_7;
        }
      }
    }

    &-foreground {
      height: 100%;
      overflow: auto;
      position: relative;

      @include screen-md {
        //padding: 40px 30px;
      }
      @include screen-sm {
        //min-height: 425px;
      }
      @include screen-xs {
        //padding-left: 15px;
        //padding-right: 15px;
      }
    }

    &-top {
      padding: 40px;
    }

    &-bottom {
      border-top: 1px solid rgba(87, 92, 94, 0.5);
      padding: 40px 32px 45px;
      position: relative;
    }


    &-desktop {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;

      @include screen-sm {
        display: none;
      }
    }


    &-title {
      margin-bottom: 10px;

      @include screen-sm {
        font-size: 24px;
        margin-bottom: 0;
      }
    }

    &-group {
      margin-bottom: 56px;

      a {
        color: #fff;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.029em;
        line-height: get-line-height(12px, 20px);
        text-decoration: underline;

        @include screen-lg {
          font-size: 12px;
        }

        @include screen-sm {
          color: $graphite;
        }

        &:hover {
          //text-decoration: none;
          color: $apple;
        }
      }

      &--contact-us {
        padding-top: 50px;
      }

      &--find-us {
        font-size: 14px;
        font-weight: 300;
        line-height: get-line-height(12px, 20px);
        margin-bottom: 42px;
        padding-top: 0;

        @include screen-lg {
          font-size: 12px;
        }
      }

      &--map {
        margin-bottom: 15px;

        a {
          padding-left: 16px;
          position: relative;

          &::before {
            color: $apple;
            content: '\e92d';
            font-family: icomoon !important;
            font-size: 8px;
            left: 0;
            position: absolute;
            top: 3px;

            @include screen-lg {
              top: 2px;
            }
          }
        }
      }
    }

    &-testimonial {
      font-family: $font-family-primary;

      &-content {
        color: $grey_4;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: initial;
        line-height: get-line-height(12px, 14px);
        margin-bottom: 10px;

        @include screen-max {
          font-size: 14px;
          margin-bottom: 9px;
        }

        @include screen-lg {
          font-size: 12px;
          margin-bottom: 8px;
        }
      }

      &-caption {
        color: $grey_6;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.05em;
        line-height: get-line-height(11px, 14px);
        padding-right: 70px;

        @include screen-max {
          font-size: 13px;
          padding-right: 65px;
        }

        @include screen-lg {
          font-size: 11px;
          padding-right: 60px;
        }
      }

      &-logo {
        bottom: 30px;
        height: 100%;
        max-height: 32px;
        max-width: 70px;
        position: absolute;
        right: 30px;
        width: 100%;

        @include screen-max {
          max-height: 30px;
          max-width: 60px;
        }

        @include screen-lg {
          max-height: 26px;
          max-width: 56px;
        }

        img {
          height: 100%;
          object-fit: contain;
          width: 100%;
        }
      }

    }

    &-content {
      font-family: $font-family-secondary;
      letter-spacing: 0.04em;

      ul {
        padding-left: 0;

        @include screen-sm {
          padding-left: 10px;
        }

        li {
          line-height: 1;
          margin-bottom: 10px;
          padding-left: 16px;
          position: relative;

          &::before {
            color: $apple;
            content: '\e92d';
            font-family: icomoon !important;
            font-size: 8px;
            left: 0;
            position: absolute;
            top: 9px;

            @include screen-lg {
              top: 8px;
            }
          }
        }
      }
    }
  }



  &__close {
    margin-right: 15px;
    //width: 25px;
    //height: 25px;
    //display: inline-flex;
    //align-items: center;
    //justify-content: center;
    //cursor: pointer;
    //position: absolute;
    //left: 20px;
    //top: 24px;
    //color: $grey_3;

    //@include screen-sm {
    //  color: $graphite;
    //}

    //&:hover {
    //  color: $apple;
    //
    //  @include screen-sm {
    //    color: $black;
    //  }
    //
    //  span {
    //    //transform: translateX(-10px);
    //    @include screen-sm {
    //      transform: none;
    //      opacity: 1;
    //    }
    //  }
    //}

    //@include screen-sm {
    //  position: static;
    //}
    //span {
    //  font-size: 7px;
    //  transition: .2s;
    //
    //  @include screen-sm {
    //    font-size: 28px;
    //    transform: none;
    //    //opacity: 0.5;
    //  }
    //
    //  @include screen-xs {
    //    font-size: 24px;
    //  }
    //}
  }

  &__content {
    background-color: #fff;
    flex-grow: 3;
    overflow: hidden;
    padding-bottom: 90px;
    position: relative;
    width: 65%;

    @include screen-md {
      padding-bottom: 0;
      width: 100%;
    }

    @include screen-sm {
      background-color: $grey_7;
    }

    &::before {
      background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      content: '';
      height: 70px;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
      @include screen-md {
        display: none;
      }
    }

    &-title {
      margin-bottom: 13px;

      @include screen-md {
        //font-size: 24px;
      }
    }

    &-subtitle {
      margin-bottom: 40px;

      @include screen-md {
        margin-bottom: 57px;
      }
    }

    &-inner {
      display: flex;
      height: 100%;
      justify-content: center;
      min-height: 100%;
      -webkit-overflow-scrolling: touch;
      overflow-y: auto;
      padding-top: 90px;
      @include screen-md {
        -webkit-overflow-scrolling: auto;
        padding-bottom: 88px;
        padding-top: 0;
      }
    }

    &-container {
      height: 100%;
      max-width: 800px;
      padding-bottom: 40px;
      padding-left: 55px;
      padding-right: 50px;
      width: 100%;

      @include screen-md {
        max-width: unset;
        padding: 30px 30px 10px 40px;
      }
    }

    &-footer {
      align-items: center;
      background: #fff;
      border-top: 1px solid #f1f1f1;
      bottom: 0;
      display: flex;
      height: 75px;
      justify-content: flex-end;
      left: 0;
      padding-left: 25px;
      padding-right: 25px;
      position: absolute;
      width: 100%;
      z-index: 10;

      @include screen-sm {
        background-color: $grey_7;
        justify-content: center;
        padding-left: 30px;
        padding-right: 30px;
        position: relative;
      }

      @include screen-xs {
        padding-left: 20px;
        padding-right: 20px;
      }

      .btn-transparent {
        @include screen-xs {
          display: none;
        }
      }
    }
  }



}

.contact-form {
  &.is-active {
    .contact-form__aside {
      transform: translateX(0);
    }
  }
}

.modal-form {
  padding-bottom: 40px;

  @include screen-md {
    padding-bottom: 0;
  }

  &__field {
    flex-grow: 1;
    position: relative;

  }

  &__step {
    align-items: center;
    color: $graphite;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    height: 32px;
    justify-content: center;
    margin-right: 16px;
    position: relative;
    user-select: none;
    width: 32px;

    @include screen-sm {
      margin-right: 15px;
    }

    //&:after {
    //  position: absolute;
    //  width: 2px;
    //  height: 22px;
    //  content: "";
    //  bottom: 100%;
    //  margin-bottom: 9px;
    //  background-image: url("../img/form-step-dotes.svg");
    //}

    &-index {
      display: inline-flex;
      font-family: $font-family-secondary;
      font-size: 18px;
      font-weight: 600;
      position: absolute;
      transition: all 0.2s;
    }

    &-circle {
      display: inline-flex;
      font-size: 40px;
      position: absolute;
      transition: all 0.2s;

      svg {
        circle {
          transition: all 0.2s;
        }
      }
    }

    &-checkmark {
      display: inline-flex;
      font-size: 12px;
      opacity: 0;
      position: absolute;
      transform: scale(0);
      transition: all 0.2s;
      visibility: hidden;
    }
  }

  &__input {
    appearance: none;
    border-bottom: 1px solid transparent;
    border-bottom: 1px solid $grey_4;
    border-radius: 0;
    color: $graphite;
    font-family: $heading-font;
    font-size: 18px;
    font-weight: 500;
    height: 28px;
    margin-top: 4px;
    min-width: 220px;
    padding-bottom: 1px;
    padding-left: 0;
    transition: all 0.2s;
    user-select: none;
    width: 220px;

    @include screen-sm {
      background-color: $grey_7;
      font-size: 16px;
    }
    //max-width: 280px;


    &::selection {
      background: $brand-color;
      color: #fff !important;
      text-shadow: none;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      box-shadow: 0 0 0 1000px white inset;
      -webkit-text-fill-color: #333;
      //border: none !important;
    }

    &--readonly {
      border: none !important;
      cursor: default;
      pointer-events: none;

      &::placeholder {
        opacity: 0.7 !important;
      }

      &:focus {
        &::placeholder {
          opacity: 1 !important;
        }
      }
    }

    &::placeholder {
      color: $font-color-secondary;
      opacity: 0;
      transition: all 0.2s;
    }

    &:focus {
      &::placeholder {
        opacity: 0;
      }
    }

    &-buffer {
      left: -1000px;
      position: absolute;
      top: -1000px;
      visibility: hidden;
      white-space: nowrap;
    }
  }

  &__label {
    font-family: $heading-font;
    font-size: 18px;
    font-weight: 500;
    pointer-events: none;
    position: absolute;
    top: 4px;
    transition: all 0.2s;

    //@include screen-sm {
    //  top: -10px;
    //  font-size: 10px;
    //}

    &--title {
      color: $font-color-secondary;
    }

    &--error {
      color: $graphite;
      display: none;
      //color: $brand-color;
    }

    &--persist {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1.44px;
      opacity: 1;
      text-transform: uppercase;
      top: -12px;
    }
  }


  &__group {
    margin-bottom: 40px;
    //display: none;

    .modal-form {
      &__textarea {
        //border-bottom: 1px solid transparentize($font-color-secondary, .7);
        //color: $font-color-secondary;
        resize: none;

        &:hover {
          //border-bottom: 1px solid $font-color-secondary;
        }

        &:focus {
          //border-bottom-color: $link-color;
          //color: $font-color;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      .modal-form {
        &__step {
          &::after {
            display: none;
          }
        }
      }
    }

    &-top {
      display: flex;
      max-width: 100%;
    }

    &-bottom {
      display: none;
      padding-left: 48px;
      padding-top: 18px;
      //opacity: 0;
      //height: 0;
      //overflow: hidden;
      @include screen-sm {
        padding-top: 30px;
      }
    }

    &.group-collapsed {
      .modal-form {
        &__group {
          &-bottom {
            //display: block;
          }
        }
      }
    }

    &:hover {
      .modal-form {
        &__step {
          color: $font-color;

          &-circle {
            .spinner {
              &.spinner--dark {
                circle {
                  stroke: $font-color;
                  stroke-dashoffset: 19;
                }
              }
            }
          }


        }

        &__input {
          //border-bottom: 1px solid transparentize(#15223C, .7);
          //color: transparentize(#15223C, .3);
          &::placeholder {
            //color: $font-color;
          }
        }

        &__textarea {
          //border-bottom: 1px solid transparentize($font-color-secondary, .7);
          &:hover {
            //border-bottom: 1px solid $font-color-secondary;
          }

          &:focus {
            //border-bottom-color: $link-color;
            //color: $font-color;
          }
        }

        &__label {
          //color: transparentize(#15223C, .3);
          &--error {
            //color: $brand-color;
          }
        }
      }

      &.group-complete {
        .modal-form {
          &__input {
            color: $font-color;

            &[type='text'] {
              &::placeholder {
                color: $font-color;
                opacity: 1 !important;
              }
            }
          }

          &__textarea {
            border-bottom: 1px solid transparentize($font-color-secondary, 0.7);
            color: $font-color-secondary;

            &:hover {
              border-bottom: 1px solid $font-color-secondary;
            }

            &:focus {
              border-bottom-color: $link-color;
              color: $font-color;
            }
          }
        }
      }
    }

    &.group-allow {
      .modal-form {
        &__input {
          user-select: inherit;
        }
      }
    }

    &.group-incomplete {
      &:not(.group-focus) {
        .modal-form {
          &__label {
            &--title {
              font-size: 11px;
              font-weight: 400;
              opacity: 0;
              text-transform: uppercase;
              top: -9px;
            }
          }

          &__input {
            //border-bottom-color: transparent;
            //color: $font-color-secondary;
            &:hover {
              //border-bottom: 1px solid $font-color-secondary;
            }
          }
        }
      }
    }

    &.group-complete {
      &:not(.group-focus) {
        .modal-form {
          &__label {
            &--title {
              font-size: 11px;
              font-weight: 400;
              opacity: 1;
              text-transform: uppercase;
              top: -9px;
            }

            &--persist {
              opacity: 1;
            }
          }
        }
      }

      .modal-form {
        &__label {
          //&--title {
          //  font-weight: 600;
          //  text-transform: uppercase;
          //  font-size: 12px;
          //  //letter-spacing: 1.44px;
          //  top: -9px;
          //}
        }

        &__step {
          &-checkmark {
            color: $forest;
            opacity: 1;
            transform: scale(1);
            visibility: visible;
          }

          &-index {
            opacity: 0;
            transform: scale(0);
            visibility: hidden;
          }

          &-circle {
            svg {
              circle {
                stroke: $forest;
              }
            }
          }
        }

        &__input {
          //border-bottom-color: transparent;
          //color: $font-color-secondary;
          &:hover {
            //border-bottom: 1px solid $font-color-secondary;
          }

          &:focus {
            //border-bottom-color: $link-color;
            //color: $font-color;
          }
          //&::placeholder {
          //  color: $font-color;
          //  opacity: 1;
          //}
        }

        &__textarea {
          width: 100%;
          //border-bottom: 1px solid transparentize($font-color-secondary, .7);
          &:hover {
            //border-bottom: 1px solid $font-color-secondary;
          }

          &:focus {
            //border-bottom-color: $link-color;
            //color: $font-color;
          }
        }
      }
    }

    &.group-focus {
      .modal-form {
        &__step {
          color: $forest;

          svg {
            circle {
              stroke: $forest;
            }
          }
        }

        &__label {
          &--title {
            color: $grey_3;
            font-size: 12px;
            font-weight: 400;
            text-transform: uppercase;
            top: -9px;
          }

          &--readonly {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: normal;
            top: unset;
          }
        }

        &__input {
          border-bottom-color: $forest;
          //color: $font-color;
        }

        &__textarea {
          width: 100%;
        }

        &__group {
          &-bottom {
            //display: block;
          }
        }
      }
    }

    &.group-error {
      .modal-form {
        &__label {
          font-size: 11px;
          font-weight: 400;
          text-transform: uppercase;
          top: -4px;

          &--error {
            display: block;
          }

          &--title {
            display: none;
          }
        }

        &__input {
          //border-bottom-color: $red;
        }
      }

    }

    &.group-highlight {
      .modal-form {
        &__step {
          & > * {
            animation: highlight 800ms ease-out;
            animation-iteration-count: 2;
          }
        }
      }
    }

  }

  &__choices {
    $offset: 5px;
    $offsetSm: 3px;

    &-list {
      display: flex;
      flex-wrap: wrap;
      margin-left: -$offset;
      margin-right: -$offset;
      width: 640px;

      @include screen-sm {
        margin-left: -$offsetSm;
        margin-right: -$offsetSm;
      }
    }

    &-item {
      flex-basis: 33.3333%;
      margin-bottom: 6px;
      padding-left: $offset;
      padding-right: $offset;

      @include screen-md {
        flex-basis: 50%;
      }

      @include screen-sm {
        padding-left: $offsetSm;
        padding-right: $offsetSm;
      }

      input {
        display: none;

        &:checked + label {
          background-color: $stone;
          border-color: $stone;
          color: $white;
        }
      }

      label {
        align-items: center;
        border: 1px solid $grey_4;
        border-radius: 8px;
        color: $grey_2;
        cursor: pointer;
        display: flex;
        font-family: $font-family-primary;
        font-size: 16px;
        font-weight: 500;
        height: 36px;
        justify-content: center;
        line-height: get-line-height(14px, 16px);
        padding-left: 6px;
        padding-right: 6px;
        padding-top: 2px;
        text-align: center;
        transition: all 0.1s;
        user-select: none;
        width: 200px;

        &:hover {
          background-color: $stone;
          border-color: $stone;
          color: $white;
        }
      }
    }
  }
}


div.wpcf7 {
  //@include screen-xs {
  //  width: 100%;
  //}
  &-response-output {
    display: none !important;
  }

  .ajax-loader {
    display: none;
  }
}
