.principles {
  background: $grey_6;
  overflow: hidden;
  padding-bottom: 33px;
  padding-top: 136px;

  @include screen-lg {
    padding-top: 120px;
  }

  @include screen-md {
    padding-bottom: 20px;
    padding-top: 50px;
  }

  &__heading {
    margin-bottom: 30px;
  }

  &__description {
    color: $graphite;
    margin-bottom: 0;
  }

  &__link {
    padding-top: 30px;
  }

  &__columns {
    display: flex;
    justify-content: space-between;
    margin-left: -30px;
    margin-right: -30px;
    overflow: hidden;


    @include screen-lg {
      flex-wrap: wrap;
      max-width: 960px;
    }
  }

  &__column {
    flex-basis: 33.3333%;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 25px;

    @include screen-md {
      flex-basis: 50%;
    }

    @include screen-sm-custom {
      flex-basis: 100%;
    }

    &--first {
      padding-right: 30px;
      padding-top: 0;

      @include screen-md {
        flex-basis: 100%;
        margin-bottom: 30px;
      }
    }
  }

  &__item {
    padding-bottom: 70px;

    @include screen-md {
      padding-bottom: 50px;
    }

    @include screen-sm-custom {
      padding-bottom: 25px;
    }
  }

  &__title {
    margin-bottom: 10px;

    @include screen-md {
      font-size: 20px;
    }

    @include screen-sm {
      font-size: 18px;
    }

  }
}


.principles-card {
  margin-bottom: 100px;

  @include screen-sm {
    margin-bottom: 60px;
  }

  @include screen-xs {
    margin-bottom: 40px;
  }

  &__inner {
    box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.15);
    display: flex;
    min-height: 412px;

    @include screen-sm {
      flex-direction: column;
    }
  }

  &__background {
    flex-basis: 45%;
    flex-shrink: 0;

    &-holder {
      height: 100%;
      position: relative;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  &__content {
    align-items: flex-start;
    background-color: $white;
    display: flex;
    flex-grow: 1;
    padding: 40px;

    @include screen-xs {
      padding: 25px;
    }
  }

  &__heading {
    margin-bottom: 30px;
  }

  &__description {
    //margin-bottom: 20px;

    p {
      margin-bottom: 1.3em;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: $black;
      font-weight: 400;

      &:hover {
        color: $brand-color;
      }
    }
  }

  &__link {
    padding-top: 20px;
  }

  &--inverse {
    .principles-card {
      &__inner {
        flex-direction: row-reverse;

        @include screen-sm {
          flex-direction: column;
        }
      }
    }
  }


}
