.btn {
  align-items: center;
  background-color: $apple;
  border: 2px solid $apple;
  border-radius: 0;
  color: $white;
  cursor: pointer;
  display: inline-flex;
  font-family: $heading-font;
  font-size: 18px;
  font-weight: normal;
  height: 46px;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.16s;
  user-select: none;
  white-space: nowrap;

  @include screen-lg {
    height: 47px;
    padding-left: 46px;
    padding-right: 46px;
  }

  @include screen-sm {
    height: 40px;
    padding-left: 39px;
    padding-right: 39px;
  }

  &:hover,
  &.hover-state {
    background-color: $forest;
    border-color: $forest;
    color: #fff;
  }

  &.hover-state {
    &:hover {
      opacity: 0.9;
    }
  }

  .loader-spinner {
    border-color: $white;
    border-left-color: transparent;
    position: absolute;
  }

  &:disabled {
    background: $grey_4;
    border-color: $grey_4;
    pointer-events: none;
  }

  &.loading-state {
    background-color: $apple;

    .btn__caption {
      opacity: 0;
    }

    .loader-spinner {
      display: block;
      opacity: 1;
    }
  }

  &--small {
    border-width: 1px;
    font-size: 14px;
    height: 39px;
    padding-left: 20px;
    padding-right: 20px;

    @include screen-lg {
      font-size: 12px;
      height: 35px;
    }

    @include screen-sm {
      height: 33px;
      padding-left: 18px;
      padding-right: 18px;
    }
  }


  &--secondary {
    background: transparent;
    border-color: $graphite;
    color: $graphite;

    &:hover {
      background: transparent;
      border-color: $black;
      color: $black;
    }

    &:disabled {
      border-color: $grey_3;
      color: $grey_3;
    }

    &-inverse {
      background: transparent;
      border-color: $white;
      color: $white;

      &:hover {
        background: transparent;
        border-color: $grey_5;
        color: $grey_5;
        opacity: 0.9;
      }
    }
  }

  &--contact {
    border-color: $button_green;
    color: $button_green;
    font-size: 24px;
    height: 60px;
    text-transform: none;

    &:hover {
      background: $button_green;
      border-color: $button_green;
      color: $white;
    }
  }

  &--transparent {
    border-color: $white;
    color: $white;
    font-size: 24px;
    height: 60px;
    text-transform: none;


    &:hover {
      background: $white;
      border-color: $white;
      color: $black;
    }
  }


  & + .btn {
    margin-left: 1.5rem;

    @include screen-sm {
      margin-top: 1.5rem;
      margin-left: 0;
      text-align: center;
    }
  }
}
