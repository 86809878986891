.process {
  background-color: $forest;
  padding: 140px 0 102px;

  @include screen-md {
    padding-bottom: 60px;
    padding-top: 80px;
  }

  @include screen-sm {
    padding-bottom: 40px;
    padding-top: 60px;
  }

  .h2 {
    color: $grey_5;
    margin-bottom: 30px;
  }

  &-holder {
    display: flex;
    justify-content: space-between;

    @include screen-sm-custom {
      align-items: center;
      flex-direction: column-reverse;
    }
  }

  &-content {
    max-width: 300px;

    @include screen-lg {
      max-width: 242px;
    }

    @include screen-sm-custom {
      text-align: center;
    }

    ::selection {
      background: darken($brand-color, 5%);
    }
  }

  &-description {
    color: $grey_5;
    margin-bottom: 40px;
  }

  &-action {
    .btn {
      &:hover {
        border-color: $mint;
        color: $mint;
      }
    }
  }

  &-image {
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 0;
    max-width: 600px;
    padding-left: 40px;
    padding-top: 28px;
    text-align: center;

    @include screen-lg {
      max-width: 500px;
    }

    @include screen-sm-custom {
      margin-bottom: 20px;
      padding: 0;
      width: 100%;
    }

    img {
      display: inline-block;

      @include screen-sm-custom {
        max-width: 280px;
      }
    }
  }
}
