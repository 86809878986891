.alert-banner {
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 80px;
  gap: 10px;
  top: 0;
  z-index: 11;
  margin-top: -102px;
  height: 40px;
  width: 100%;
  background: $mint;
  color: $black;
  font-size: 16px;
  margin-bottom: 80px;

  a {
    color: inherit;
  }

  @include screen-sm {
    padding: 8px;
  }

  & + header {
    top: 33px;
  }

  &.fractional {
    background: $apple;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%; /* 19.2px */
    letter-spacing: 0.32px;
  }
}

body.overflow-hidden {
  .alert-banner {
    position: fixed;
    top: 102px;
  }
}

.location-header {
  height: 40px;

  .alert-banner {
    margin-top: 0;
    margin-bottom: 0;
    height: 40px;
  }
}
