.team {
  background: $grey_7;
  padding-bottom: 152px;
  padding-top: 128px;

  @include screen-md {
    padding-bottom: 67px;
    padding-top: 67px;
  }

  @include screen-sm-custom {
    padding-bottom: 46px;
    padding-top: 46px;
  }

  &--about {
    padding-bottom: 144px;
    padding-top: 100px;

    h2 {
      margin-bottom: 20px;
    }
  }

  &__description {
    color: $graphite;
    margin-bottom: 30px;
    font-weight: 300;
  }

  &__action {
    margin-bottom: 77px;
  }

  &-holder {
    display: flex;
    padding: 0 10px 0 20px;

    @include screen-sm {
      flex-direction: column;
      padding: 0;
    }
  }

  &-content {
    flex-basis: 330px;
    flex-grow: 0;
    padding-top: 6px;

    @include screen-lg {
      flex-basis: 260px;
    }

    @include screen-sm {
      flex-basis: auto;
      margin-bottom: 50px;
    }
  }

  &-title {
    margin-bottom: 30px;
  }

  &-list {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    padding-left: 47px;

    @include screen-sm {
      justify-content: center;
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 0;
    }
  }

  &-item {
    align-self: flex-start;
    margin-left: 50px;
    max-width: 250px;
    overflow: hidden;
    width: 100%;

    @include screen-md {
      margin-left: 30px;
    }

    @include screen-sm {
      margin-left: 0;
      padding-left: 15px;
      padding-right: 15px;
    }

    &-link {
      display: block;
      height: 0;
      padding-bottom: 100%;
      position: relative;
      width: 100%;

      & > div {
        border-radius: 50%;
        height: 100%;
        left: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 100%;

        &::after {
          border: 5px solid #fff;
          border-radius: 50%;
          bottom: 0;
          content: "";
          display: block;
          left: 0;
          opacity: 0;
          position: absolute;
          right: 0;
          top: 0;
          transition: opacity 0.2s;
        }
      }

      img {
        height: 100%;
        object-fit: cover;
        transition: transform 0.1s;
        width: 100%;
      }

      &:hover {
        div {
          &::after {
            opacity: 0.3;
          }
        }
      }
    }
  }

  &-description {
    color: $graphite;
    font-weight: normal;
    margin-bottom: 20px;
  }
}

.team-group {
  padding-top: 50px;
  padding-bottom: 64px;
  position: relative;

  @include screen-md {
    padding-top: 60px;
  }

  @include screen-sm {
    padding-top: 30px;
  }

  &--leadership {
    &::before {
      background: $grey_7;
      bottom: 0;
      content: "";
      height: 114px;
      position: absolute;
      width: 100%;
      z-index: 0;
    }

    .team-cards__item {
      position: relative;
    }

    .container {
      position: relative;
    }

    .link {
      &::before {
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  &__background {
    position: relative;
  }

  &__links {
    display: flex;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    a {
      flex: 0 0 50%;
    }
  }

  &__title {
    font-family: $heading-font;
    font-size: 48px;
    font-weight: 600;
    letter-spacing: 0.023em;
    line-height: get-line-height(48px, 85px);
    margin-bottom: 20px;

    @include screen-md {
      font-size: 28px;
    }

    @include screen-sm {
      font-size: 26px;
    }

    &--indent {
      margin-bottom: 50px;

      @include screen-sm {
        margin-bottom: 30px;
      }
    }
  }

  &--white {
    background-color: $grey_7;
    padding-top: 152px;

    @include screen-md {
      padding-top: 72px;
    }
  }

  &--leadership {
    @include screen-md {
      padding-bottom: 0;
    }
  }

  &--remote {
    //padding-top: 30px;
    padding-top: 0;

    @include screen-sm {
      padding-top: 0;
    }

    .team-cards {
      padding-bottom: 30px;
    }
  }
}

.team-cards {
  overflow: hidden;
  padding-bottom: 90px;
  position: relative;

  @include screen-md {
    padding-bottom: 60px;
  }

  @include screen-sm {
    padding-bottom: 30px;
  }

  &--leadership {
    bottom: 0;
    left: 0;
    padding-bottom: 0;
    position: absolute;
    width: 100%;

    @include screen-md {
      background: $white;
      position: relative;
    }

    .container {
      @include screen-md {
        padding: 0;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    &--secondary {
      margin-left: -50px;
      margin-right: -50px;

      @include screen-md {
        margin-left: -30px;
        margin-right: -30px;
      }
    }

    &--leadership {
      background: $white;
    }
  }

  &__item {
    flex: 1 1 0;

    &.columns-2 {
      background: $white;
      padding: 42px 50px;

      @include screen-sm-custom {
        flex-basis: 100%;
        margin-bottom: 0;
        padding: 30px;
        width: 100%;
      }
    }

    &.columns-3 {
      flex-basis: 33.3333%;
      flex-grow: 0;
      margin-bottom: 104px;
      padding: 0 50px;
      width: 33.3333%;

      @include screen-md {
        padding: 0 30px;
      }

      @include screen-sm {
        flex-basis: 50%;
        margin-bottom: 50px;
        width: 50%;
      }

      @include screen-xs {
        flex-basis: 100%;
        width: 100%;
      }
    }

    &--careers {
      padding: 0 25px !important;

      .team-cards {
        &__holder {
          align-items: flex-end;
          background: $forest;
          color: $white;
          display: flex;
          height: calc(100% - 55px);
          padding: 0 25px;
          width: 100%;

          @include screen-sm {
            height: auto;
            padding: 25px;
          }
        }

        &__content {
          padding-bottom: 42px;

          @include screen-sm {
            padding-bottom: 0;
          }

          .careers-title {
            font-family: $heading-font;
            font-size: 36px;
            font-weight: 600;
            line-height: get-line-height(34px, 41px);
            margin-bottom: 14px;

            @include screen-lg {
              font-size: 34px;
            }
          }

          .careers-description {
            font-family: $font-family-secondary;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0.04em;
            line-height: get-line-height(11px, 20px);
            margin-bottom: 50px;

            @include screen-lg {
              font-size: 11px;
            }
          }

          a {
            color: $white;
            font-weight: normal;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }

    &--secondary {
      .team-cards {
        &__position {
          margin-bottom: 25px;
        }

        &__excerpt {
          color: $graphite;
          font-size: 14px;
          font-weight: 300;
          margin-bottom: 20px;

          @include screen-lg {
            font-size: 11px;
          }
        }

        &__action {
          opacity: 0;
          transition: opacity 0.2s;

          @media (hover: none) and (pointer: coarse) {
            opacity: 1;
          }
        }

        &__photo {
          position: relative;

          &::after {
            border: 5px solid #fff;
            border-radius: 50%;
            bottom: 0;
            content: "";
            display: block;
            left: 0;
            opacity: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: opacity 0.2s;
          }
        }
      }

      &:hover {
        .team-cards__action {
          opacity: 1;
        }

        .team-cards__photo {
          &::after {
            opacity: 0.3;
          }
        }
      }
    }
  }

  &__photo {
    border-radius: 50%;
    display: flex;
    height: 175px;
    margin-bottom: 40px;
    overflow: hidden;
    position: relative;
    width: 175px;

    @include screen-lg {
      height: 140px;
      width: 140px;
    }

    @include screen-sm {
      margin-bottom: 20px;
    }

    &--remote {
      margin-bottom: 40px;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__details {
    &--limited {
      //max-width: 80%;

      &-small {
        max-width: unset;
        padding-right: 30px;
      }
    }
  }

  &__name {
    color: $font-color;
    margin-bottom: 5px;
    margin-top: 0;
  }

  &__position {
    color: $forest;
    margin-bottom: 27px;
    position: relative;

    @include screen-md {
      font-size: 20px;
      margin-bottom: 15px;
    }

    @include screen-sm {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }

  &__excerpt {
    color: $graphite;
    margin-bottom: 50px;

    @include screen-sm-custom {
      margin-bottom: 20px;
    }
  }

  &__link {
    align-items: center;
    color: $font-color;
    display: inline-flex;
    text-decoration: none;
    transition: 0.16s;

    .icon-modal-arrow {
      color: $font-color-secondary;
      font-size: 14px;
      margin-right: 10px;
      padding: 10px;
      transition: 0.16s;
    }

    &-caption {
      color: $font-color;
      //opacity: 0;
      //visibility: hidden;
      //transform: translateX(-4px);
      font-family: $heading-font;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1px;
      position: relative;
      text-decoration: underline;
      transition: 0.16s;
      //top: 1px;
    }

    &:hover {
      .team-cards {
        &__link {
          &-caption {
            //opacity: 1;
            //visibility: visible;
            //transform: translateX(0);
            color: $brand-color;
          }
        }
      }

      .icon-modal-arrow {
        color: $brand-color;
      }
    }
  }

  &--no-indent {
    padding-bottom: 0;
  }
}
