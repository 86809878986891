@keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.cssload-speeding-wheel {
  animation: cssload-spin 575ms infinite linear;
  border: 2px solid #fff;
  border-left-color: #000;
  border-radius: 50%;
  height: 40px;
  margin: 0 auto;
  position: relative;
  width: 40px;
  z-index: 21;
}

.process-loader .cssload-speeding-wheel {
  border: 3px solid $font-color-secondary;
  border-left-color: #fff;
  display: block;
}

.process-loader {
  @include screen-sm {
    &::after {
      background: #fff;
      content: '';
      height: 100vh;
      left: 0;
      position: fixed;
      top: 0;
      width: 100vw;
      z-index: 20;
    }
  }
}
