@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: './fonts/' !default;
$slick-font-family: 'icomoon' !default;
$slick-loader-path: './' !default;
$slick-arrow-color: #292f37 !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: '\e900' !default;
$slick-next-character: '\e900' !default;
$slick-dot-character: '\2022' !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
  @if function-exists(image-url) {
    @return image-url($url);
  }
  @else {
    @return url($slick-loader-path + $url);
  }
}

@function slick-font-url($url) {
  @if function-exists(font-url) {
    @return font-url($url);
  }
  @else {
    @return url($slick-font-path + $url);
  }
}

/* Slider */

.slick-list {
  .slick-loading & {
    //background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
  }
}

/* Icons */
@if $slick-font-family == 'slick' {
  @font-face {
    font-family: 'slick';
    font-style: normal;
    font-weight: normal;
    src: slick-font-url('slick.eot');
    src: slick-font-url('slick.eot?#iefix') format('embedded-opentype'), slick-font-url('slick.woff') format('woff'), slick-font-url('slick.ttf') format('truetype'), slick-font-url('slick.svg#slick') format('svg');
  }
}

/* Arrows */

.slick-prev,
.slick-next {
  align-items: center;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
  color: transparent;
  cursor: pointer;
  display: inline-flex;
  font-size: 0;
  height: 28px;
  justify-content: center;
  line-height: 0px;
  outline: none;
  padding: 0 0 0 2px;
  position: relative;
  width: 28px;

  &:hover {
    background: $brand-color;
    border-color: $brand-color;
    outline: none;

    &::before {
      //opacity: $slick-opacity-on-hover;
      //color: $brand-color;
      color: $slick-arrow-color;
    }
  }

  &.slick-disabled {
    background-color: transparent;
    border-color: #fff;
    opacity: 0.7;

    &::before {
      color: #fff;
    }
  }

  &::before {

    color: #fff;
    font-family: $slick-font-family;
    font-size: 10px;
    //opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1;
  }
}

.slick-prev {
  transform: rotate(180deg);

  [dir='rtl'] & {
    left: auto;
    right: -25px;
  }

  &::before {
    content: $slick-prev-character;

    [dir='rtl'] & {
      content: $slick-next-character;
    }
  }
}

.slick-next {
  margin-left: 9px;

  [dir='rtl'] & {
    left: -25px;
    right: auto;
  }

  &::before {
    content: $slick-next-character;

    [dir='rtl'] & {
      content: $slick-prev-character;
    }
  }
}

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  bottom: -25px;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;

  li {
    cursor: pointer;
    display: inline-block;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    position: relative;
    width: 20px;

    button {
      background: transparent;
      border: 0;
      color: transparent;
      cursor: pointer;
      display: block;
      font-size: 0;
      height: 20px;
      line-height: 0px;
      outline: none;
      padding: 5px;
      width: 20px;

      &:hover,
      &:focus {
        outline: none;

        &::before {
          opacity: $slick-opacity-on-hover;
        }
      }

      &::before {
        color: $slick-dot-color;
        content: $slick-dot-character;
        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        height: 20px;
        left: 0;
        line-height: 20px;
        opacity: $slick-opacity-not-active;
        position: absolute;
        text-align: center;
        top: 0;
        width: 20px;
      }
    }

    &.slick-active button::before {
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }
  }
}
