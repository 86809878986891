.squad {
  display: flex;
  flex-direction: column;
  padding: 64px;
  gap: 10px;
  max-width: 1024px;
  background: $black;
  color: $white;
  margin: 0 auto;
  margin-bottom: 80px;

  @include screen-sm {
    padding: 32px;
    margin-bottom: 40px;
  }

  &__intro {
    display: flex;
    gap: 20px;
    flex-direction: column;
    text-align: center;
    padding-bottom: 48px;

    @include screen-sm {
      padding-bottom: 24px;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 78px;

    @include screen-sm {
      justify-content: center;
      gap: 52px;
    }
  }

  &__members {
    display: flex;
    gap: 48px;
    flex-wrap: wrap;
    @include screen-sm {
      justify-content: center;
      text-align: center;
      gap: 64px;
    }
  }
}

.squad-member {
  max-width: 210px;

  &__avatar {
    margin-bottom: 38px;

    @include screen-sm {
      margin-bottom: 18px;
    }

    img {
      width: 140px;
      height: 140px;
      border-radius: 50%;
      margin: 0 auto;
    }
  }

  &__name {
    margin-bottom: 5px;
  }

  &__role {
    color: $mint;
    text-transform: initial;
    margin-bottom: 25px;

    @include screen-sm {
      margin-bottom: 12px;
      font-size: 24px;
    }
    
    @include screen-xs {
      font-size: 24px;
    }    
  }
}

.squad__responsibilities {
  display: flex;
  flex-direction: column;
  width: 343px;
}

.squad-responsibility {
  &__header,
  &__row {
    display: flex;
    width: 100%;
    height: 33px;
    & + & {
      border-top: 1px solid $graphite;
    }

    & > div {
      flex-grow: 1;
    }

    & > div + div {
      display: flex;
      justify-content: center;
      flex-grow: initial;
    }
  }

  &__head,
  &__cell {
    display: flex;
    align-items: center;
    min-width: 45px;
  }

  &__header + &__row {
    border-top: 0px;
    margin-top: 20px;
  }
}
