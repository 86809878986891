.user {
  display: flex;
  flex-direction: column;
  min-height: 450px;
  max-width: 180px;

  &__avatar {
    margin-bottom: 38px;
    position: relative;

    @include screen-sm {
      margin-bottom: 18px;
    }

    img {
      width: 140px;
      height: 140px;
      border-radius: 50%;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 140px;
      height: 140px;
      border-radius: 50%;
      border: 5px solid rgba(249, 249 , 249, 0.5);
      z-index: 1;
      content: '';
    }
  }

  &__name {
    margin-bottom: 5px;
    font-size: 16px;
  }

  &__role {
    color: $forest;
    text-transform: initial;
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: 500;
    line-height: 120%; /* 21.6px */
    letter-spacing: 0.36px;

    @include screen-sm {
      margin-bottom: 12px;
    }
  }

  &__description {
    flex-grow: 1;
    font-family: $font-family-secondary;
    font-size: 14px;
    font-weight: 300;
    line-height: 160%;
  }
}

.user-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 30px;
  margin: 0 auto;

  .user {
    padding-top: 30px;

    @include screen-lg {
      max-width: calc(50% - 15px);
      min-height: unset;
    }

    @include screen-xs {
      max-width: 100%;
      min-height: unset;
    }

    .user__buttons {
      padding-top: 20px;
    }
  }
}
