.messaging-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 120px 50px 80px;
  gap: 20px;

  @include screen-sm {
    padding: 48px 20px;
    margin-top: 40px;
  }

  &.top-p96 {
    padding-top: 96px;

    @include screen-sm {
      padding: 60px 20px;
      margin-top: 0px;
    }
  }

  .message {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 160%;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: $black;

    max-width: 800px;
    margin: 0 auto;

    @include screen-lg {
      font-size: 24px;
      max-width: 614px;
    }
  }

  &.mobile-dark {
    @include screen-sm {
      background: $graphite;

      .message{
        color: white;
      }
    }
  }
}
