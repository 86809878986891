.breadcrumbs {
  padding-top: 80px;

  @include screen-lg {
    padding-top: 85px;
  }

  @include screen-md {
    padding-top: 80px;
  }

  @include screen-sm {
    padding-top: 60px;
  }

  @include screen-xs {
    padding-top: 50px;
  }

  &__inner {
    @include screen-xs {
      padding-left: 0;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    align-items: center;
    color: $forest;
    display: flex;
    font-family: $heading-font;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.8px;
    line-height: get-line-height(16px, 19px);
    text-decoration: none;
    text-transform: uppercase;

    @include screen-lg {
      font-size: 16px;
    }

    @include screen-sm {
      font-size: 15px;
    }

    &::before {
      content: '/';
      margin-left: 4px;
      margin-right: 4px;
    }

    a {
      color: $forest;
    }

    &:first-child {
      &::before {
        display: none;
      }
    }

    &:nth-child(3) {
      display: none;
    }

    &:last-child:not(:first-child) {
      &::after {
        display: none;
      }
    }

    a {
      color: $forest;
      text-decoration: none;
      transition: all 0.16s;

      &:hover {
        color: $apple;
      }
    }
  }

  &__remove {
    display: flex;
    height: 11px;
    margin-left: 7px;
    margin-top: -2px;
    width: 11px;

    svg {
      display: block;
      height: 100%;
      object-fit: contain;
      width: 100%;

      * {
        fill: $font-color-secondary;
        transition: all 0.16s;
      }
    }

    &:hover {
      svg {
        * {
          fill: $brand-color;
        }
      }
    }
  }

  &__inner {
    &--project-single {
      align-items: center;
      display: flex;
      justify-content: space-between;
      position: relative;

      .navigation-arrows {
        bottom: unset;
        top: 2px;
      }
    }

    &--with-navigation {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }

  &--white {
    margin-bottom: 32px;
    padding-top: 0;

    @include screen-sm {
      margin-bottom: 0;
      padding-top: 40px;
    }

    .breadcrumbs {
      &__item {
        color: #fff;

        a {
          color: #fff;

          &:hover {
            color: #fff;
            opacity: 0.9;
          }
        }
      }
    }
  }

  &--team-landing {
    margin-bottom: 60px;
    padding-top: 40px;

    @include screen-sm {
      margin-bottom: 50px;
      padding-top: 20px;
    }

    @include screen-xs {
      margin-bottom: 30px;
      padding-top: 10px;
    }
  }

  &--bg {
    background-color: $grey_6;
  }

}
