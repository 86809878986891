.author {
  align-items: center;
  display: flex;

  &__photo {
    align-items: center;
    border-radius: 50%;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    height: 48px;
    justify-content: center;
    margin-right: 18px;
    overflow: hidden;
    position: relative;
    width: 48px;

    img {
      border-radius: 50%;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__details {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 1.4;

    @include screen-sm {
      font-size: 16px;
    }

    a {
      color: #fff;
      display: inline-block;
      line-height: 1.1;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__time {
    align-items: center;
    display: flex;
    font-family: $font-family-secondary;
    font-size: 12px;
    font-weight: 300;
  }

  &--mobile-centered {
    @include screen-sm {
      flex-direction: column;
      text-align: center;
    }

    .author {
      &__photo {
        @include screen-sm {
          margin-bottom: 10px;
        }
      }
    }
  }

}
