.technologies {
  background-color: $white;
  margin-bottom: 100px;
  padding-bottom: 100px;
  padding-top: 93px;

  @include screen-md {
    margin-bottom: 80px;
    padding-bottom: 80px;
    padding-top: 80px;
  }

  @include screen-sm {
    margin-bottom: 50px;
    padding-bottom: 50px;
    padding-top: 50px;
  }

  @include screen-xs {
    margin-bottom: 30px;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    padding-left: 63px;

    @include screen-lg {
      padding-left: 0;
    }

    @include screen-sm-custom {
      flex-direction: column-reverse;
    }

  }

  &__list {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    justify-content: space-between;
    //width: 348px;

    @include screen-md {
      width: 280px;
    }

    @include screen-sm {
      width: 220px;
    }

    @include screen-sm-custom {
      width: 100%;
    }
  }

  &__item {
    height: 60px;
    margin-bottom: 50px;
    text-align: center;
    width: 33.333%;

    @include screen-md {
      height: 50px;
      margin-bottom: 40px;
    }

    @include screen-sm {
      height: 40px;
      margin-bottom: 30px;
    }

    @include screen-sm-custom {
      height: 50px;
      margin-bottom: 40px;
    }

    img {
      display: inline-block;
      height: 100%;
      width: auto;
    }
  }

  &__content {
    max-width: 530px;
    padding-left: 50px;

    @include screen-sm {
      padding-left: 30px;
    }

    @include screen-sm-custom {
      margin-bottom: 30px;
      padding-left: 0;
    }

    p {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &--project {
    margin-bottom: 0;

    .technologies {
      &__content {
        p {
          margin-bottom: 1em;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
