@keyframes fade {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  to {
    opacity: 1;
  }
}

@keyframes placeload {
  0% {
    background-position: -600px 0;
  }

  100% {
    background-position: 600px 0;
  }
}

.loading-animation {
  $grey-nuance-lighter: $grey_6;
  $grey-nuance-darker: darken($grey_6, 4%);

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeload;
  animation-timing-function: linear;
  background-image: linear-gradient(
    to right,
    $grey-nuance-lighter 0%,
    $grey-nuance-darker 18%,
    $grey-nuance-lighter 33%
  ) !important;
  background-size: 1200px 104px !important;
}

.projects {
  background-color: $white;
  padding-bottom: 78px;
  padding-top: 0;

  @include screen-sm {
    padding-bottom: 40px;
  }

  &.projects--blurred {
    .projects__item {
      //filter: blur(4px);
      @include screen-xs {
        filter: unset;
      }
    }
  }

  &__header {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    position: relative;
  }

  &__count {
    padding-top: 11px;
    position: relative;
    top: 10px;

    @include screen-sm {
      top: 0;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -22px;
    margin-right: -22px;

    @include screen-sm {
      margin-left: -11px;
      margin-right: -11px;
    }

    @include screen-sm-custom {
      margin-left: 0;
      margin-right: 0;
    }

    &--preloader {
      display: none;

      &.is-visible {
        display: flex;
      }
    }

    &--masonry {
      overflow: hidden;

      .projects__item:nth-child(2) {
        margin-top: 44px;

        @include screen-sm {
          margin-top: 0;
        }
      }
    }

    &.is-hidden {
      display: none;
    }
  }

  &__item {
    backface-visibility: hidden;
    display: block;
    flex-basis: calc(50% - 44px);
    margin-bottom: 44px;
    margin-left: 22px;
    margin-right: 22px;
    position: relative;
    transform: translate3d(0, 0, 0);
    width: calc(50% - 44px);

    &.hidden {
      display: none;
    }

    @include screen-sm {
      flex-basis: calc(50% - 22px);
      margin-bottom: 22px;
      margin-left: 11px;
      margin-right: 11px;
      width: calc(50% - 22px);
    }

    @include screen-sm-custom {
      flex-basis: 100%;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }

    &-holder {
      align-items: flex-start;
      color: #fff;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      padding: 30px 25px;
      position: absolute;
      transition: all 0.2s;
      width: 100%;
    }

    &-title {
      color: $white;
      font-family: $heading-font;
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0.02em;
      line-height: get-line-height(18px, 22px);
      margin-bottom: 5px;

      @include screen-max {
        font-size: 20px;
      }

      @include screen-lg {
        font-size: 18px;
      }

      span {
        color: rgba(64, 54, 78, 0.5);
        font-size: 14px;
      }

      a {
        font-size: 14px;
      }
    }

    &-caption {
      color: $grey_5;
      margin-bottom: 16px;
    }

    .background {
      &-gallery {
        height: 100%;

        .slick-list,
        .slick-track,
        .slick-slide,
        .slick-slide > div {
          height: 100%;
        }

        .slick-slide {
          position: relative;
        }

        &__item {
          height: 100%;

          img {
            height: 100%;
            object-fit: cover;
            width: 100%;
          }
        }
      }

      &-overlay {
        align-items: center;
        background: $graphite;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        mix-blend-mode: multiply;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: 0.16s;
        width: 100%;
      }
    }

    //&-corner {
    //  width: 38px;
    //  height: 38px;
    //  background-size: 30px auto;
    //  position: absolute;
    //  border-radius: 0 4px 0 0;
    //  bottom: -2px;
    //  left: -2px;
    //  background: #f9f9f9 url(../img/corner.svg) no-repeat top right;
    //  opacity: 0;
    //}

    &-member {
      &-list {
        display: flex;
        opacity: 0;
        padding-right: 10px;
        transition: 0.16s;

        &--technologies {
          opacity: 1;

          .projects__item-member-item {
            margin-right: -5px;
          }

          .projects__item-member-photo {
            height: 37px;
            width: 37px;
          }
        }
      }

      &-item {
        margin-right: -10px;
        position: relative;
        z-index: 1;

        @for $i from 1 through 10 {
          &:nth-child(#{$i}) {
            z-index: #{10 - $i};
          }
        }

        &:hover {
          z-index: 11;
        }
      }

      &-photo {
        border: 2px solid #fff;
        border-radius: 50%;
        height: 50px;
        overflow: hidden;
        width: 50px;

        img {
          display: block;
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
    }

    &-link {
      background-color: $white;
      color: $black;
      opacity: 0;
      transition: 0.16s;

      &:hover {
        background-color: $white;
        color: $black;
      }
    }

    &-info {
      opacity: 0;
      transition: 0.16s;
    }

    &:hover,
    &.hover {
      .projects {
        &__item {
          &-holder {
            border-color: #ecf4f7;
          }

          &-corner {
            opacity: 1;
          }

          &-info {
            opacity: 1;
          }

          &-member-list {
            opacity: 1;
          }

          &-link {
            opacity: 1;
          }
        }
      }

      .background {
        &-overlay {
          opacity: 0.8;
        }
      }
    }

    &--full-width {
      flex-basis: 100%;

      .background {
        &-overlay {
          opacity: 0.8;
        }
      }

      &:hover {
        .projects__item-holder {
          border-color: transparent;
          overflow: hidden;
        }

        .background {
          &-overlay {
            opacity: 0.7;
          }
        }
      }
    }

    &--preview {
      .projects {
        &__holder {
          @include screen-md {
            height: 320px;
          }
          @include screen-sm {
            height: 300px;
          }
          @include screen-xs {
            height: 250px;
          }
        }

        &__item-corner {
          background-color: $grey_6;
        }
      }
    }

    &--preview-full-width {
      .projects {
        &__item {
          &-background {
            img {
              height: 100%;
              max-height: unset;
              max-width: unset;
              object-fit: cover;
              width: 100%;
            }
          }
        }
      }
    }
  }

  &__item {
    //&:nth-child(4n-1), &:nth-child(4n-2) {
    //  .projects__holder {
    //    background: linear-gradient(337.22deg, #AFB7C5 0%, #708097 100%);
    //  }
    //}
    //
    //&:nth-child(4n-3), &:nth-child(4n-4) {
    //  .projects__holder {
    //    background: linear-gradient(338.44deg, #7B899C 0%, #40506A 100%);
    //  }
    //}

    &--preloader {
      pointer-events: none;

      .projects {
        &__item {
          pointer-events: none;
        }

        &__holder {
          @extend .loading-animation;
        }
      }

      &:hover {
        .projects {
          &__holder {
            box-shadow: unset !important;
          }
        }
      }
    }
  }

  &__holder {
    //border-radius: 4px;
    //height: 670px;
    display: flex;
    height: 0;
    padding-bottom: 126.8%;
    position: relative;

    //@include screen-sm {
    //  height: 400px;
    //}
  }

  &__item {
    @include screen-md {
      &.hover {
        .projects {
          &__holder {
            background-color: #fff;
            border-radius: 6px 6px 6px 55px;
            box-shadow: 0 2px 8px 0 rgba(64, 54, 78, 0.2);
          }

          &__item {
            &-corner {
              opacity: 1;
            }
          }
        }
      }
    }
    @media (min-width: $screen-md + 1) {
      &:hover,
      &:active,
      &:focus {
        .projects__holder {
          //box-shadow: 0 2px 8px 0 rgba(64, 54, 78, 0.2);
          background-color: #fff;
          //border-radius: 6px 6px 6px 55px;
        }
      }
    }
  }

  &--inner {
    background: #fff;

    .projects__item-corner {
      background-color: #fff;
    }
  }
}

.projects {
  &__holder {
    &.gradient {
      background: linear-gradient(180deg, #333a3c 0%, #7b7f80 100%);

      &--graphite-dark {
        background: linear-gradient(180deg, #333a3c 0%, #7b7f80 100%);
      }

      &--stone-dark {
        background: linear-gradient(180deg, #5b6770 0%, #939a9f 100%);
      }

      &--steel-dark {
        background: linear-gradient(180deg, #53778a 0%, #8ea4af 100%);
      }

      &--graphite-light {
        background: linear-gradient(0, #7b7f80 0%, #9da0a1 100%);
      }

      &--stone-light {
        background: linear-gradient(0, #939a9f 0%, #adb2b6 100%);
      }

      &--steel-light {
        background: linear-gradient(0, #8ea4af 0%, #aab9c0 100%);
      }
    }
  }

  &__item {
    &-background {
      height: 100%;
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 100%;

      img {
        bottom: 0;
        display: block;
        height: 100%;
        object-fit: contain;
        object-position: bottom right;
        position: absolute;
        right: 0;
        width: 100%;
      }

      &--contain {
        img {
          height: calc(100% - 100px);
          width: calc(100% - 60px);
        }
      }

      &--cover {
        img {
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
}

.projects-item {
  &__info {
    display: flex;
    flex-wrap: wrap;
    @include screen-lg {
      margin-bottom: 10px;
    }
    @include screen-xs {
      align-items: flex-start;
    }
  }

  &__info-item {
    align-items: center;
    background-color: transparent;
    border: 1px solid $grey_5;
    border-radius: 2px;
    color: $grey_5;
    display: inline-flex;
    font-family: $paragraph-font-family;
    margin-bottom: 8px;
    margin-right: 8px;
    padding: 7px 6px;
  }

  &__info-icon {
    align-self: center;
    display: inline-flex;
    margin-right: 8px;

    img {
      height: 13px;
      max-height: 13px;
    }

    .icon {
      &-slug {
        &-mac-os-app {
          margin-top: -2px;
        }
      }
    }
  }

  &__info-title {
    font-size: 13px;
    line-height: 1;
  }
}

.projects-item-hidden {
  display: none;
  filter: drop-shadow(4px 4px 30px rgba(0, 0, 0, 0.4));
  height: calc(100% - 100px);
  max-height: 800px;
  max-width: 1275px;
  padding: 0;
  position: relative;
  width: calc(100% - 120px);
  z-index: 1;

  @include screen-max {
    max-height: 590px;
    max-width: 1060px;
  }

  @include screen-lg {
    max-height: 517px;
    max-width: 904px;
  }

  @include screen-md {
    max-height: unset;
  }

  @include screen-xs {
    border-radius: 0;
    height: auto;
    width: 100%;
  }

  .fancybox-close-small {
    display: none;
  }
}

@include screen-xs {
  .fancybox-navigation {
    display: none;
  }

  .fancybox-slide--html {
    padding: 0;
  }
}

.projects-modal {
  display: flex;
  flex-direction: column;
  height: 100%;

  @include screen-md {
    display: block;
    overflow: auto;
  }

  .slick-projects-gallery {
    flex-grow: 1;
    height: auto;
  }

  &__inner {
    width: 100%;
    height: 100%;
  }

  &__layout {
    display: flex;
    height: 100%;
    min-width: 0;
    overflow: hidden;
    width: 100%;
    @include screen-md {
      flex-direction: column;
    }
  }

  &__left-part {
    background-color: $graphite;
    display: flex;
    flex-basis: 60%;
    flex-direction: column;
    min-width: 0;
    width: 60%;

    @include screen-md {
      flex-basis: auto;
      margin-bottom: 30px;
      width: 100%;
    }
  }

  &__right-part {
    flex-basis: 40%;
    min-width: 0;
    overflow: hidden;
    width: 40%;

    @include screen-md {
      flex-basis: auto;
      width: 100%;
    }
  }

  .icon-close {
    color: $grey_4;
    cursor: pointer;
    display: flex;
    font-size: 24px;
    position: absolute;
    right: 15px;
    top: 15px;
    transition: color 0.2s;
    z-index: 3;

    @include screen-xs {
      //display: none;
      position: absolute;
    }

    &:hover {
      color: $black;
    }
  }

  &__header {
    display: none;
    min-width: 0;
    position: relative;

    @include screen-md {
      display: block;
      padding: 30px 37px 20px 25px;
    }

    &.is-hidden {
      visibility: hidden;
    }
  }

  &__content {
    display: flex;
    flex-grow: 1;
    height: 100%;
    min-width: 0;

    @include screen-md {
      height: auto;

      .icon-close,
      .projects-item__info {
        display: none;
      }
    }

    .projects-modal__right-part {
      position: relative;
    }

    .projects-modal__right-part-inner {
      height: 100%;
      overflow: auto;
      padding-bottom: 60px;

      @include screen-md {
        position: relative;
      }
    }

    &.is-hidden {
      visibility: hidden;
    }
  }

  .projects {
    &__item {
      &-title {
        @include screen-lg {
        }
        color: $black;
        margin-bottom: 10px;
      }

      &-caption {
        @include screen-lg {
        }
        color: $graphite;
        margin-bottom: 0;
      }
    }
  }

  .projects-item {
    &__info-item {
      background-color: #71798d;
    }
  }

  &__item {
    //border-bottom: 1px solid #E5E6E9;
    padding: 0 25px 20px;
    @include screen-xs {
      padding-left: 15px;
      padding-right: 40px;
    }

    &.no-border {
      border-bottom: none;
    }

    &-title {
      margin-bottom: 10px;
    }

    &.projects-modal__item--header {
      border-bottom: none;
      padding: 30px 50px 33px 25px;
      position: relative;
      @include screen-md {
        display: none;
      }
    }

    &.projects-modal__item--description {
      padding: 0 68px 0 40px;
      @include screen-xs {
        padding: 0 15px;
      }
    }

    &.projects-modal__item--technologies {
      padding: 0 35px 32px 25px;

      @include screen-xs {
        //padding: 24px 15px 16px 15px;
      }
    }

    &.projects-modal__item--testimonial {
      border-bottom: none;
      margin-bottom: 30px;
      padding-bottom: 0;
    }
  }

  &__description-holder {
    padding-bottom: 15px;
    padding-top: 10px;
  }

  &__highlight {
    background: $forest;
    color: $white;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: get-line-height(18px, 22px);
    padding: 24px 30px;
  }

  &__description {
    $font-size: 16px;
    $line-height: get-line-height(16px, 22px);
    $limit-lines: 2;
    $limit-lines-secondary: 8;

    color: $font-color-secondary;
    font-size: $font-size;
    line-height: $line-height;
    overflow: hidden;

    &.limited {
      max-height: ($limit-lines * $font-size * $line-height) + 1;

      &.limited--secondary {
        max-height: ($limit-lines-secondary * $font-size * $line-height) + 1;
      }

      &.is-active {
        max-height: none;
      }
    }

    p {
      margin-bottom: 10px;
    }
  }

  &__access-denied {
    align-items: center;
    background-color: #fff;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 0 15px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 14;

    div {
      text-align: center;

      p {
        color: $font-color;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 15px;
      }
    }
  }

  &__loader {
    align-items: center;
    background-color: #fff;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 15;

    @include screen-sm {
      height: 100vh;
      left: 0;
      position: fixed;
      top: 0;
      width: 100vw;
      z-index: 20;
    }
  }

  &__hire {
    background: #fff;
    border-top: 1px solid $grey_5;
    bottom: 0;
    left: 0;
    padding: 11px 40px 12px;
    position: absolute;
    right: 0;
    text-align: center;
    //transform: translateY(100%);
    transition: 0.3s;
    width: 100%;
    //box-shadow: 0 2px 8px 0 rgba(64,54,78,0.2);
    z-index: 13;
    @include screen-xs {
      transform: none;
    }

    &.is-visible {
      //transform: translateY(0);
    }
    //.btn {
    //  display: flex;
    //  justify-content: center;
    //}
  }

  &__read-more-link {
    color: $font-color;
    font-family: $heading-font;
    //padding-right: 18px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.125em;
    overflow: hidden;
    position: relative;
    right: -3px;
    text-decoration: none;

    &::before {
      bottom: 1px;
      height: 2px;
    }

    &::after {
      //font-family: icomoon!important;
      //content: "\e910";
      //font-size: 8px;
      //color: $brand-color;
      //margin-left: 4px;
      //top: 50%;
      //margin-top: -4px;
      //position: absolute;
    }

    &.is-active {
      display: none;
      // &:after {
      //   transform: rotate(180deg);
      //   margin-top: -6px;
      // }
    }
  }

  &__technologies {
    display: flex;
    justify-content: space-between;
  }

  .technologies {
    &__left {
      padding-right: 30px;
      //min-width: 60%;
    }

    &__right {
      width: 130px;

      @include screen-max {
        width: 120px;
      }

      @include screen-lg {
        width: 92px;
      }

      @include screen-lg {
        padding-left: 0;
      }

      .project-length {
        font-family: $font-family-secondary;
        font-size: 16px;
        letter-spacing: 0.04em;
        line-height: get-line-height(12px, 18px);

        @include screen-max {
          font-size: 14px;
        }

        @include screen-lg {
          font-size: 12px;
        }
      }
    }

    &__title {
      color: $forest;
      font-family: $heading-font;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: get-line-height(14px, 16px);
      margin-bottom: 10px;
      text-transform: none;

      @include screen-max {
        font-size: 18px;
      }

      @include screen-lg {
        font-size: 16px;
      }

      @include screen-lg {
        font-size: 14px;
      }
    }

    &__list {
      //ul {
      //  color: $icon-color-font;
      //  font-size: 16px;
      //  margin-bottom: 15px;
      //  li {
      //    padding-left: 20px;
      //    position: relative;
      //    margin-bottom: 10px;
      //    &:before {
      //      content: "\e90d";
      //      font-family: icomoon!important;
      //      font-size: 6px;
      //      position: absolute;
      //      left: 0;
      //      top: 8px;
      //    }
      //  }
      //}
    }

    &__icons {
    }

    &__icons-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-bottom: 10px;
      margin-left: -5px;
      margin-right: -5px;

      li {
        flex-basis: 33.3333%;
        flex-shrink: 0;
        margin-bottom: 15px;
        padding: 0 5px;
      }
    }
  }

  &__testimonial {
    color: $graphite;
    display: flex;
    font-size: 16px;

    @include screen-max {
      font-size: 14px;
    }

    @include screen-lg {
      font-size: 12px;
    }
  }

  &__testimonial-info {
    //padding-left: 15px;
  }

  &__testimonial-content {
    font-size: 18px;
    font-weight: 500;
    line-height: get-line-height(12px, 16px);
    margin-bottom: 20px;
    padding-left: 29px;
    position: relative;

    @include screen-max {
      font-size: 18px;
    }

    @include screen-lg {
      font-size: 16px;
    }

    &::before {
      color: $forest;
      content: "\e922";
      font-family: icomoon !important;
      font-size: 15px;
      left: 0;
      position: absolute;
      top: -7px;
    }
  }

  &__testimonial-name {
    font-family: $heading-font;
    line-height: get-line-height(12px, 16px);
    padding-left: 29px;
  }

  .slick-active {
    .nav-item,
    .slick-projects-nav__item {
      opacity: 1;
    }
  }

  .slick-projects-nav {
    padding: 12px 0 6px;

    .slick-list {
      padding: 0 !important;
    }

    .slick-track {
      flex-wrap: nowrap;
      font-size: 0;
    }

    .slick-slide {
      //height: 36px;
      outline: none;

      &.slick-current {
        opacity: 1;

        .slick-projects-nav__item,
        .slick-projects-nav__item:hover,
        .nav-item {
          opacity: 1;
        }
      }
    }

    &__item {
      cursor: pointer;
      display: block !important;
      opacity: 0.5;
      outline: none;
      padding: 0 5px;

      &:hover {
        opacity: 0.7;
      }

      &.slick-current {
        opacity: 1;

        .nav-item {
          opacity: 1;
        }
      }

      img {
        height: 36px;
        margin-bottom: 6px;
        object-fit: cover;
        width: auto;
      }
    }
  }

  .slick-projects-gallery {
    @media (min-width: 961px) {
      height: calc(100% - 83px);

      .slick-list,
      .slick-track {
        height: 100%;
      }

      .slick-slide {
        height: 100%;

        > div {
          height: 100%;
        }
      }
    }

    &__item {
      align-items: center;
      display: flex !important;
      height: 100%;
      justify-content: center;

      & > img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  .nav-item {
    background-color: #fff;
    height: 2px;
    opacity: 0;
    width: 100%;

    .progress-line {
      background-color: $mint;
      height: 100%;
      width: 0;
    }
  }

  &--single-project {
    height: 740px;

    @include screen-lg {
      height: 600px;
    }

    @include screen-md {
      height: auto;
    }

    .projects-modal {
      &__layout {
        overflow: visible;
      }

      &__right-part {
        border-radius: 0 0 5px;
        box-shadow: 0 2px 8px 0 rgba(64, 54, 78, 0.2);

        @include screen-md {
          border-radius: 0;
          box-shadow: unset;
        }
      }
    }

    &-protected {
      height: 240px;
    }
  }
}

.slide-info {
  align-items: center;
  color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: hidden;
  //padding-bottom: 74px;
  padding-left: 25px;
  padding-right: 25px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;

  @include screen-sm {
    position: static;
  }

  @include screen-md {
    padding-bottom: 0;
  }

  @include screen-sm {
    //padding-top: 100px;
    padding-left: 0;
    padding-right: 0;

    .fancybox-button {
      display: none;
    }
  }

  &--modal {
    background-color: transparentize($font-color, 0.5);
    position: absolute;

    @include screen-sm {
      padding: 0 15px !important;
      position: absolute;
    }

    @include screen-xs {
      padding: 0 10px !important;
      position: absolute !important;
    }
  }

  &--item {
    .slide-info {
      &__inner {
        transform: translateY(65px);
        transition: 0.26s;

        @include screen-sm {
          padding: 0 25px;
          transform: translateY(0);
        }

        .projects__item:hover & {
          transform: translateY(0);
        }
      }

      &__title {
        font-family: $heading-font;
        font-size: 24px;
        letter-spacing: 0.5px;
        margin-bottom: 5px;

        @include screen-md {
          font-size: 20px;
        }
      }

      &__subtitle {
        font-size: 18px;
        margin-bottom: 40px;

        @include screen-sm {
          margin-bottom: 20px;
        }
      }
    }

    .fancybox-button {
      color: #fff;
      opacity: 1;
      top: calc(50% - 69px);
      visibility: visible;
      width: 50px;

      &--arrow_right {
        > div {
          &::before {
            left: 22px;
          }
        }
      }

      &--arrow_left {
        > div {
          &::before {
            right: 22px;
          }
        }
      }
    }
  }

  &__inner {
  }

  &:not(.slide-info--item) {
    @include screen-xs {
      padding: 0;
      position: relative;
    }
  }

  &__logo {
    display: inline-block !important;
    height: auto;
    margin-bottom: 20px;
    max-width: 300px;
    width: auto;
    @include screen-md {
      margin-bottom: 10px;
      max-width: 200px;
    }
    @include screen-sm {
      height: auto;
      max-height: 60px;
      max-width: 190px !important;
      width: auto;
    }
  }

  &__title {
    @include screen-sm {
    }
    font-size: 23px;
    font-weight: bold;
    letter-spacing: 0.021em;
    line-height: 1.2;
    margin-bottom: 10px;
  }

  &__subtitle {
    font-size: 20px;
    line-height: 1.2;
    @include screen-md {
      font-size: 16px;
    }
  }
}

.slide-video {
  min-width: 100%;
  width: 0;

  &-holder {
    min-height: 0;
    min-width: 0;
    position: relative;
  }

  &__item {
    border: none !important;
    height: 100%;
    position: relative;

    &-inner {
      //transform: translate3d(0, 0, 0);
      height: 100%;
      position: relative;
      width: 100%;
    }
  }

  &__placeholder {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.2s;
    width: 100%;
    z-index: 2;

    img {
      height: 100%;
      object-fit: cover;
      position: absolute;
      width: 100%;
    }

    &.is-hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__overlay {
    align-items: center;
    background-color: rgba($graphite, 0.7);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &__video {
    display: flex;
    height: 100%;
    width: 100%;
    z-index: 3;

    video {
      display: block;
      width: 100%;
    }
  }

  &__watch-video {
    align-items: center;
    color: $white;
    cursor: pointer;
    display: flex;
    padding: 15px;
    transition: color 0.2s;

    &__icon {
      display: inline-flex;
      font-size: 40px;
    }

    &__caption {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: -0.02em;
      line-height: 24px;
      margin-left: 20px;
      text-decoration-line: underline;
      text-transform: uppercase;
      white-space: nowrap;

      @include screen-sm {
        font-size: 20px;
        margin-left: 20px;
      }

      @include screen-xs {
        font-size: 18px;
        letter-spacing: 1.5px;
      }
    }

    &:hover {
      color: $mint;
    }
  }
}

.protip-container {
  z-index: 99999;
}

.protip-skin-default--scheme-pro.protip-container {
  background-color: $black;
  border-radius: 30px;
  font-family: $font-family-secondary;
  font-size: 11px;
  line-height: get-line-height(11px, 18px);
  padding: 4px 21px;
}

.protip-arrow {
  display: none;
}

.slick-no-slide .slick-track {
  text-align: center;
  transform: inherit !important;
  width: 100% !important;
}

.slick-no-slide .slick-slide {
  display: inline-flex;
  flex-direction: column;
  float: none;
}

.load-more-holder {
  text-align: center;
  //padding-bottom: 100px;
  transition: all 0.16s;

  @include screen-md {
    padding-bottom: 60px;
  }

  @include screen-sm {
    padding-bottom: 50px;
  }

  @include screen-xs {
    padding-bottom: 40px;
  }

  &.is-hidden {
    opacity: 0;
    visibility: hidden;
  }

  &:empty {
    display: none;
  }
}

.single-project-container {
  padding-top: 75px;

  @include screen-md {
    padding-top: 60px;
  }

  @include screen-sm {
    padding-top: 50px;
  }

  @include screen-xs {
    padding-top: 40px;
  }
}

.single-project {
  h2 {
    font-size: 36px;
  }

  .p2 {
    font-size: 16px;
  }
}
