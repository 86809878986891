.steps {
  max-width: 575px;
  padding-bottom: 30px;
  padding-top: 0;
  position: relative;

  @include screen-xs {
    padding-bottom: 0;
    padding-top: 0;
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__inner {
    align-items: center;
    display: flex;
    flex-basis: 50%;

    @include screen-xs {
      align-items: flex-start;
      flex-basis: 100%;
      flex-direction: row-reverse;
      padding-right: 25px;
    }
  }

  &__item {
    display: flex;
    min-height: 132px;
    position: relative;
    border-left: 2px solid $black;
    padding-left: 30px;
    padding-bottom: 52px;
    left: -30px;

    @include screen-xs {
      min-height: unset;
    }

    &:last-child {
      padding-bottom: 0px;
      border-left: none;

      @include screen-xs {
        margin-bottom: 0;
      }
    }

    &:nth-child(even) {
      justify-content: flex-end;

      @include screen-xs {
        justify-content: flex-start;
      }

      .steps {
        &__inner {
          flex-direction: row-reverse;
        }

        &__description,
        &__title {
          padding-left: 100px;

          @include screen-sm {
            padding-left: 70px;
          }

          @include screen-xs {
            padding-left: 60px;
          }
        }

        &__content {
          //padding-left: 47px;
          padding-right: 0;

          @include screen-sm {
            //padding-left: 25px;
          }

          @include screen-xs {
            padding-right: 0;
          }
        }

        &__icon {
          transform: translateX(50%);

          @include screen-xs {
            left: 0;
            transform: translateX(0);
          }
        }
      }
    }

    &:nth-child(odd) {
      .steps {
        &__description,
        &__title {
          padding-right: 100px;

          @include screen-sm {
            padding-right: 70px;
          }

          @include screen-xs {
            padding-left: 60px;
            padding-right: 0;
          }
        }

        &__icon {
          left: 100%;
          right: unset;

          @include screen-xs {
            left: 0;
            transform: translateX(0);
          }
        }
      }
    }
  }

  &__content {
    //padding-right: 47px;
    width: 100%;

    @include screen-sm {
      //padding-right: 25px;
    }

    @include screen-xs {
      //padding-left: 25px;
      padding-right: 0;
      position: relative;
    }
  }

  &__title {
    @include screen-max {
    }

    @include screen-lg {
    }
    color: $global-border;
    margin-bottom: 10px;
    word-break: break-word;
    font-size: 24px;

    @include screen-xs {
      margin-bottom: 5px;
    }
  }

  &__description {
    color: $graphite;
    position: relative;

    .p3 {
      font-weight: 300;
      position: relative;
      font-size: 14px;
    }

    @include screen-xs {
      position: static;
    }
  }

  &__line {
    background-color: $mint;
    background-position: center top;
    background-repeat: repeat-y;
    background-size: 1px auto;
    height: calc(100% - 150px);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1px;

    @include screen-xs {
      bottom: 0;
      height: calc(100% - 30px);
      left: unset;
      right: 100%;
      top: unset;
      transform: translate(25px, 0);
    }
  }

  &__icon {
    align-items: center;
    background-color: #fafafa;
    display: flex;
    flex-shrink: 0;
    height: 40px;
    justify-content: center;
    margin-top: -6px;
    position: absolute;
    right: 100%;
    text-align: center;
    top: 0;
    transform: translateX(-50%);
    width: 46px;

    @include screen-xs {
      height: auto;
      //margin-top: -24px;
      transform: translateX(0);
      top: -3px;
    }

    &-index {
      color: $forest;
      display: inline-flex;
      font-family: $heading-font;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 1;
      padding-top: 4px;

      @include screen-xs {
        font-size: 20px;
      }
    }
  }

  &--white {
    .steps {
      &__icon {
        background: #fff;
      }

      &__content {
        //padding-right: 30px;
      }

      &__title {
        a {
          color: $font-color;
          transition: all 0.16s;

          &:hover {
            color: $apple;
          }
        }
      }
    }
  }

  &--right-aligned {
    width: 400px;
    @include screen-sm {
      padding-bottom: 0;
    }

    @include screen-xs {
      width: 300px;
    }

    .steps {
      &__line {
        left: 26px;
        top: 0;
        transform: none;
        background-color: $black;
        width: 2px;
        height: calc(100% - 148px);
        @include screen-sm {
          height: calc(100% - 120px);
          top: 8px;
        }

      }

      &__list {
        padding-left: 56px;
        gap: 0px;
      }

      &__inner {
        align-items: flex-start;
        flex-basis: 100%;
      }

      &__item:nth-child(odd),
      &__item:nth-child(even) {
        justify-content: flex-start;

        .steps__icon {
          left: -23px;
          transform: none;
          background: $white;
          margin-top: 0;

          @include screen-xs {
            left: -53px;
          }

          &::after {
            content: attr(data-index);
            position: absolute;
            margin-top: 4px;
            font-weight: 700;
            font-size: 18px;
          }
        }

        .steps__title {
          margin-top: 5px;
        }

        .steps__title,
        .steps__description {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  &--days {
    .steps {
      &__icon {
        background: $grey_6;
        height: 46px;
        padding: 5px;
        width: 46px;

        &-index {
          color: $black;
          font-family: $font-family-secondary;
          font-size: 15px;
          font-weight: 600;
          letter-spacing: 0;
          padding: 0;
        }

        svg {
          height: calc(100% - 10px);
          left: 5px;
          position: absolute;
          top: 5px;
          width: calc(100% - 10px);
        }
      }
    }
  }
}
