.footer {
  backface-visibility: hidden;
  //position: fixed;
  //left: 0;
  //width: 100%;
  //bottom: 0;
  background-color: #fff;
  z-index: 0;
  //transform: translate3d(0, 0, 0);

  @include screen-sm {
    position: static;
  }

  &__top {
    font-family: $heading-font;
    font-size: 18px;
    font-weight: 400;
    line-height: get-line-height(18px, 25px);
    padding: 70px 0 107px;

    @include screen-sm {
      font-size: 18px;
      padding: 60px 0 20px;
    }

    &-holder {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;

      @include screen-sm {
        flex-direction: column;
      }
    }

    &-left {
      @include screen-sm {
        margin-bottom: 40px;
      }
    }

    &-right {
      @include screen-sm {
        flex-direction: column;
        width: 100%;
      }
    }

    .footer-social {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 33px;

      @include screen-sm {
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 30px;
      }

      @include screen-xs {
        justify-content: space-between;
      }

      &__list {
        align-items: center;
        display: flex;
        margin-left: 14px;

        @include screen-xs {
          margin-left: 0;
        }
      }

      .clutch-widget {
        flex: 0 0 auto;
        flex-basis: 195px;
        height: 52px;
        overflow: hidden;
        width: 195px;
      }

      &__item {
        color: $grey_4;
        font-size: 36px;
        margin-left: 19px;
        text-decoration: none;
        transition: all 0.2s;

        @include screen-xs {
          font-size: 26px;
          margin-left: 15px;
        }

        &:hover {
          color: $black;
        }
      }
    }

    &-navs {
      display: flex;

      @include screen-sm {
        border-top: 1px solid rgba(23, 34, 60, 0.2);
        justify-content: flex-start;
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 25px;
      }
    }

    &-nav {
      &:first-child {
        margin-right: 38px;
      }

      li {
        margin-bottom: 8px;

        a {
          @extend .p3;
          color: $stone;
          font-weight: normal;
          padding-left: 0;
          padding-right: 0;

          &:hover {
            color: $apple;
          }

          &::after {
            display: none;
          }
        }
      }
    }

    .list {
      color: rgba(21, 34, 60, 0.5);
      flex-direction: column;
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 30px;

      @include screen-sm {
        font-size: 16px;
        letter-spacing: 0.89px;
        line-height: get-line-height(16px, 24px);
      }

      li {
        margin-bottom: 12px;
        margin-left: 0;

        @include screen-sm {
          margin-bottom: 20px;
        }
      }

      &.list--footer-top {
        margin-bottom: 0;
      }
    }
  }

  &__address {
    color: $graphite;
    margin-bottom: 20px;
  }

  &__bottom {
    background: $grey_6;
    color: $font-color-secondary;
    font-family: $paragraph-font-family;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.075em;
    padding: 25px 0;

    @include screen-lg {
      font-size: 12px;
    }

    &-nav {
      align-items: center;
      display: flex;
      padding-right: 3px;

      li {
        margin-left: 9px;

        @include screen-sm {
          margin-left: 0;
          margin-right: 9px;
        }
      }

      .footer-separator {
        background: $font-color-secondary;
        height: 12px;
        margin-top: 0;
        opacity: 0.4;
        width: 1px;

        &:last-child {
          display: none;
        }
      }
    }

    .container {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include screen-sm {
        align-items: flex-start;
        flex-direction: column;
      }
    }
  }

  &__copyright {
    @include screen-sm {
      margin-bottom: 20px;
    }
  }

  .logo-container {
    margin-bottom: 20px;
  }

  .contact-links {

    @include screen-max {
    }

    @include screen-lg {
    }
    display: flex;
    flex-direction: column;

    a {
      color: $stone;
      text-decoration: underline;
      transition: all 0.2s;

      &::before {
        bottom: 0;
      }

      &:hover {
        color: $apple;
      }
    }
  }

  .current-menu-item a {
    color: $apple;
    opacity: 1;
  }

}
