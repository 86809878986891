.layout-flex {
  display: flex;
  justify-content: space-between;

  @include screen-sm {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  @include screen-sm-custom {
    margin-left: 0;
    margin-right: 0;
  }

  .card-item {

    @include screen-md {
      flex-basis: 50%;
      margin-bottom: 30px;
      padding-left: 20px;
      padding-right: 20px;
    }
    @include screen-sm {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding-left: 5px;
      padding-right: 5px;
      text-align: center;
    }

    &.card-item--working-together {
      flex-basis: 33.333%;

      @include screen-sm {
        flex-basis: 50%;
        margin-bottom: 25px;
      }

      @include screen-sm-custom {
        flex-basis: 100%;
      }

      img {
        height: 160px;
        max-width: 100%;
        width: auto;
      }
    }
  }
}

.columns {
  display: flex;
  justify-content: space-between;
  margin-left: -40px;
  margin-right: -40px;

  @include screen-md {
    margin-left: -20px;
    margin-right: -20px;
  }

  @include screen-sm-custom {
    margin-left: 0;
    margin-right: 0;
  }

  @include screen-md {
    flex-wrap: wrap;
  }

  &__item {
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 40px;
    padding-right: 40px;

    @include screen-md {
      padding-left: 20px;
      padding-right: 20px;
    }

    @include screen-sm-custom {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.columns-2 {
    .columns {
      &__item {
        flex-basis: 50%;

        @include screen-sm-custom {
          flex-basis: 100%;
        }
      }
    }
  }

  &--gallery {
    padding-top: 70px;

    @include screen-sm {
      padding-top: 50px;
    }

    .columns {
      &__item {

        @include screen-md {
          margin-bottom: 0;
        }

        &:last-child {
          padding-top: 84px;

          @include screen-sm {
            padding-top: 0;
          }
        }
      }
    }
  }
}
