.faq {
  padding-bottom: 80px;
  padding-top: 100px;

  @include screen-sm {
    padding-bottom: 60px;
    padding-top: 80px;
  }

  @include screen-xs {
    padding-bottom: 40px;
    padding-top: 60px;
  }

  &__inner {

  }

  .content-heading {
    margin-bottom: 50px;
  }

  &__title {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 18px 30px;

    &-text {
      align-self: flex-start;
      padding-right: 106px;
      user-select: none;

      @include screen-sm {
        padding-right: 40px;
      }
    }

    &:hover {
      .faq__icon {
        color: $forest;
      }

      .faq__title-text {
        color: $brand-color;
      }

      .faq__item {
        background: $grey_5;
      }
    }
  }

  &__icon {
    color: $stone;
    display: inline-flex;
    flex-shrink: 0;
    font-size: 32px;
    transition: color 0.16s, transform 0.3s;
  }

  &__item {
    background: $grey_6;
    margin-bottom: 20px;
    transition: background-color 0.16s;

    &.is-active {
      background: $grey_5;

      .faq__content {
        &-inner {
          opacity: 1;
        }
      }

      .faq__icon {
        color: $forest;
        transform: rotate(180deg);
      }
    }
  }

  &__content {
    display: none;
    max-width: 660px;
    padding: 30px 60px;

    @include screen-xs {
      padding: 5px 30px 25px;
    }

    &-inner {
      opacity: 0;
      transition: 0.2s;
    }

    p {
      margin-bottom: 0;
    }
  }

}
