.tags {
  display: flex;
  flex-wrap: wrap;

  &__item {
    position: relative;

    &::after {
      color: $grey_2;
      content: "/";
      display: inline-flex;
      height: 16px;
      margin-left: 7px;
      margin-right: 7px;

      @include screen-lg {
        height: 13px;
      }
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }

  &--tertiary {
    margin: 24px 0;
    gap: 10px;

    .tags__item {
      background: #e8e8e8;
      padding: 8px 16px;
      border-radius: 4px;
      font-size: 11px;

      &::after {
        content: none;
      }
    }
  }

  &__link {
    color: $forest;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      color: $black;
    }
  }

  &--dark {
    .tags {
      &__item {
        color: $grey_2;
        &::after {
          color: $mint;
        }
      }

      &__link {
        font-weight: normal;
        color: $mint;

        &:hover {
          color: $white;
        }
      }
    }
  }

  &__section {
    img {
      width: 100%
    }

    p {
      font-family: $font-family-secondary;
      font-weight: 300;
      font-size: 16px;
    }
  }
}

.tag {
  color: #fff;
  font-family: $heading-font;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;

  @include screen-md {
    font-size: 16px;
  }

  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}
