.case-study {
  max-width: 1024px;
  margin: 0 auto;
  &__header {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 40px 80px;
    gap: 10px;

    @include screen-sm {
      padding: 32px;
    }

    .heading {
      color: $forest;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
  &__details {
    padding: 32px 140px 64px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: space-between;

    @include screen-sm {
      padding: 32px;
      grid-template-columns: repeat(2, auto);
      grid-gap: 30px;
      // justify-content: initial;
    }

    @include screen-xs {
      grid-template-columns: auto;
      grid-gap: 32px;
    }
  }

  &__detail {
    display: flex;
    flex-direction: column;

    &-key {
      font-weight: 500;
    }

    &-value {
      color: $graphite;
      font-family: $font-family-secondary;
      font-size: 14px;
      font-weight: 300;
    }

    &.services-provided {
      grid-row: 1 / span 2;
      grid-column: 2;

      @include screen-sm {
        grid-row: auto;
        grid-column: auto;
      }
    }

    &.technologies-used {
      flex-direction: column;
      grid-row: 1;
      grid-column: 3;

      @include screen-sm {
        grid-row: auto;
        grid-column: auto;
      }

      .case-study__detail-value li {
        display: inline-block;
      }

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__comparison {
    display: flex;
    justify-content: center;
    position: relative;

    .beer-reveal[data-beer-label]:after,
    .beer-slider[data-beer-label]:after {
      background-color: $grey_5;
      font-family: $font-family-secondary;
      padding: 8px;
      font-size: 11px;
      line-height: 15px;
    }
  }

  &__stats {
    padding: 64px 80px;
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;

    &--spaced {
      gap: 46px;
    }

    &--p0 {
      padding: 0;
    }
  }

  &__stat {
    text-align: center;
    max-width: 222px;
    gap: 8px;
    display: flex;

    flex-direction: column;

    &-icon img {
      margin: 0 auto;
      width: 58px;
      height: 58px;
    }

    &-title {
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 120%;

    }

    &-description {
      font-family: $font-family-secondary;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 160%;
      letter-spacing: 0.56px;
      color: $black;
      margin-top: 5px;

      a {
        font-weight: 400;
      }
    }
  }


  &__quote {
    // margin: 0 auto;
    padding: 60px 130px;
    display: flex;
    justify-content: center;
    background: $grey_7;

    @include screen-sm {
      padding: 60px 50px 60px 90px;
    }

    &--p0 {
      padding: 0;
    }

    &-inner {
      display: flex;
      flex-direction: column;
      gap: 40px;
      max-width: 100%;
      position: relative;
    }

    &-inner, p {
      font-family: $font-family-secondary;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 140%;
      letter-spacing: 0.03em;

      @include screen-sm {
        font-size: 16px;
      }

      position: relative;
    }

    .icon-quote-top-filled,
    .icon-quote-bottom-filled {
      position: absolute;
      color: $apple;
      font-size: 25px;
    }

    .icon-quote-top-filled {
      left: -45px;
      top: -7px;
    }

    .icon-quote-bottom-filled {
      bottom: -10px;
      right: -25px;
    }

    .avatar {
      border-radius: 50%;
      overflow: hidden;
      width: 64px;
      height: 64px;
    }

    &-author {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;

      .titles {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .role,
      .name {
        width: 100%;
      }

      .name {
        font-size: 18px;
        font-weight: 500;
      }

      .role {
        font-family: $font-family-secondary;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 140%;
      }
    }
  }
}

.section--dark {
  .case-study__quote {
    background: none;
  }
}
