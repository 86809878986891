span.wpcf7-list-item,
.checkbox {
  padding-left: 25px;

  label {
    position: relative;
    cursor: pointer;
  }

  input[type="checkbox"] {
    position: absolute;
    left: -23px;
    top: 0;
    visibility: hidden;
  }

  input[type="checkbox"] + span:before {
    content: "";
    position: absolute;
    left: -23px;
    top: 1px;
    width: 16px;
    height: 16px;
    background: $white;
    border: 1px solid $grey_4;
    border-radius: 3px;
    display: block;
  }

  input[type="checkbox"]:checked + span:before {
    background: $black;
  }

  input[type="checkbox"]:checked + span:after {
    content: "";
    position: absolute;
    left: -19px;
    top: 5px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nOCcgaGVpZ2h0PSc4JyB2aWV3Qm94PScwIDAgOCA4JyBmaWxsPSdub25lJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGQ9J003LjcgMS4zQzcuMyAwLjkgNi43IDAuOSA2LjMgMS4zTDMgNC42TDEuNyAzLjNDMS4zIDIuOSAwLjcgMi45IDAuMyAzLjNDLTAuMSAzLjcgLTAuMSA0LjMgMC4zIDQuN0wyLjMgNi43QzIuNSA2LjkgMi44IDcgMyA3QzMuMiA3IDMuNSA2LjkgMy43IDYuN0w3LjcgMi43QzguMSAyLjMgOC4xIDEuNyA3LjcgMS4zWicgZmlsbD0nd2hpdGUnLz48L3N2Zz4K");
    width: 8px;
    height: 8px;
    background-repeat: no-repeat;
    background-size: 8px;
  }

  &--primary {
    input[type="checkbox"]:checked + span:before {
      background: $apple;
      border-color: $apple;
    }
  }
}

label.checkbox {
  display: flex;
  padding-bottom: 20px;
}
