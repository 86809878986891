.list {
  &__item {
    color: $font-color;
    font-size: 18px;
    font-weight: normal;

    a {
      color: $font-color;
      cursor: pointer;
      text-decoration: none;
    }
  }
}

.capabilities ul li,
.list--dotted ul li {
  color: $font-color-secondary;
  font-size: 18px;
  font-weight: normal;
  //white-space: nowrap;
  line-height: 1.5;
  margin-bottom: 6px;
  padding-left: 30px;
  position: relative;

  &::before {
    color: $apple;
    content: '\e92d';
    font-family: icomoon !important;
    font-size: 8px;
    left: 0;
    position: absolute;
    top: 9px;

    @include screen-xs {
      top: 7px;
    }
  }

  &.list-item--no-link {
    &::before {
      color: $graphite;
    }
  }

  a {
    font-family: $font-family-secondary;
    text-decoration: underline;

    &:hover {
      color: $apple;
    }
  }
}

.list--dotted {
  &--xs {
    ul {
      li {
        color: $graphite;
        font-family: $font-family-secondary;
        font-weight: 300;
        font-size: 16px;
        line-height: get-line-height(11px, 18px);
        margin-bottom: 1px;
        padding-left: 16px;

        @include screen-max {
          font-size: 14px;
          
        }

        @include screen-lg {
          font-size: 12px;
        }

        a {
          color: $stone;
          font-family: $font-family-secondary;
        }

        &::before {
          font-size: 8px;
          top: 6px;

          @include screen-max {
            top: 5px;
          }

          @include screen-lg {
            top: 3px;
          }
        }
      }
    }
  }
}

.list--checked {
  .list__item {
    &::before {
      align-items: center;
      background: $brand-color;
      border-radius: 50%;
      color: #fff;
      content: '\e902';
      display: inline-flex;
      font-family: 'icomoon' !important;
      font-size: 6px;
      height: 16px;
      justify-content: center;
      margin-right: 10px;
      vertical-align: middle;
      width: 16px;
    }
  }
}

.list--vertical {
  display: flex;
  @include screen-sm-custom {
    margin-bottom: 10px;
  }

  li {
    line-height: normal;
  }

  li:last-child a {
    border-right: none;

    &::after {
      display: none;
    }
  }

  a {
    color: $font-color-secondary;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    padding: 0 8px;
    position: relative;
    text-decoration: none;

    &::after {
      background-color: rgba(21, 34, 60, 0.5);
      content: '';
      height: 12px;
      margin-top: 1px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
    }
  }
}

.service-list {
  margin-bottom: 40px;
  padding-top: 120px;

  @include screen-md {
    margin-bottom: 30px;
    padding-top: 100px;
  }

  @include screen-sm {
    margin-bottom: 10px;
    padding-top: 20px;
  }

  .card-item {
    &__image {
      img {
        height: 130px;
        width: auto;
      }
    }
  }

}
