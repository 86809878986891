.testimonials {
  background-color: $graphite;
  padding: 0;
  position: relative;

  @include screen-md {
    padding: 0;
  }

  &-background {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    img {
      display: block;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    &::after {
      background: linear-gradient(0deg, #333a3c, #333a3c);
      background-blend-mode: multiply;
      content: "";
      height: 100%;
      left: 0;
      mix-blend-mode: multiply;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .container {
    height: 100%;

    @include screen-lg {
      padding-left: 46px;
      padding-right: 46px;
    }
  }

  &-swiper {
    position: relative;
  }

  &-inner {
    height: 100%;
    padding: 112px 0;
    position: relative;

    @include screen-md {
      padding-top: 70px;
    }

    @include screen-sm-custom {
      padding-top: 50px;
    }
  }

  &-logo {
    flex-basis: 242px;
    flex-shrink: 0;
    height: 112px;
    transform: translate(0, -62px);

    @include screen-lg {
      transform: translate(0, 0);
    }

    @include screen-md {
      flex-basis: 180px;
      height: 80px;
    }

    @include screen-sm-custom {
      flex-basis: auto;
      margin-bottom: 30px;
      max-width: 180px;
    }

    @include screen-xs {
      max-width: 140px;
    }

    img {
      height: 100%;
      object-fit: contain;
      object-position: right center;
      width: 100%;

      @include screen-sm-custom {
        object-position: left top;
      }
    }
  }

  &-content {
    padding-left: 20px;
    transition: all 0.2s;
  }

  &-top {
    display: flex;
    justify-content: space-between;

    @include screen-sm-custom {
      flex-direction: column-reverse;
      margin-bottom: 30px;
    }
  }

  &-item {
    color: #fff;
    height: auto;
    outline: none;
  }

  &-image {
    border-radius: 50%;
    display: inline-block;
    flex-shrink: 0;
    height: 63px;
    margin-right: 10px;
    overflow: hidden;
    position: relative;
    width: 63px;

    img {
      display: block;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &-bottom {
    bottom: 40px;
    opacity: 0;
    position: absolute;
    right: 0;
    transform: translate(0, 0);
    transition: opacity 0.2s;
    visibility: hidden;
    z-index: 2;

    @media (hover: none) and (pointer: coarse) {
      opacity: 1;
      visibility: visible;
    }

    @include screen-lg {
      transform: translate(0, 0);
    }

    @include screen-sm-custom {
      padding-left: 20px;
      position: static;
    }
  }

  &-desc {
    margin-bottom: 32px;
    max-width: 650px;
    padding-right: 50px;
    position: relative;

    @include screen-max {
      max-width: 600px;
    }

    @include screen-lg {
      max-width: 520px;
    }

    @include screen-sm-custom {
      padding-right: 0;
    }

    &::before {
      color: $mint;
      content: "\e922";
      font-family: "icomoon" !important;
      font-size: 25px;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: absolute;
      right: calc(100% + 12px);
      top: -20px;
    }

    .quote {
      p {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 28px */
        letter-spacing: 0.8px;
      }
    }
  }

  &-text {
    @include screen-xs {
    }
  }

  &-profile {
    align-items: center;
    display: flex;
  }

  &-position {
    font-family: $heading-font;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.05em;
    line-height: get-line-height(14px, 21px);
    margin-bottom: 0;

    @include screen-lg {
      font-size: 14px;
    }
  }

  .testimonials-nav {
    bottom: 40px;
    opacity: 0;
    padding-left: 20px;
    position: absolute;
    transition: opacity 0.2s;
    visibility: hidden;
    z-index: 2;

    @media (hover: none) and (pointer: coarse) {
      opacity: 1;
      visibility: visible;
    }

    &-counter {
      color: $white;
      font-weight: 500;
      //font-size: 18px;
      letter-spacing: 0.02em;

      & > span {
        min-width: 10px;
      }
    }
  }

  &:not(.testimonials--single):not(.testimonials--circle-bg):hover {
    .testimonials {
      &-content {
        transform: translateY(-10px);
        @media (hover: none) and (pointer: coarse) {
          transform: translateY(0);
        }
      }

      &-bottom,
      &-nav {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &--secondary {
    .testimonials {
      &-desc {
        max-width: 800px;

        @include screen-max {
          max-width: 700px;
        }

        @include screen-lg {
          max-width: 705px;
        }

        &::before {
          color: $mint;
        }
      }

      &-text {
        p {
          &::after {
            color: $mint;
          }
        }
      }

      &-background {
        background-color: $graphite;

        &::after {
          display: none;
        }
      }

      &-image {
        margin-right: 15px;
      }

      &-name {
        font-size: 24px;
        font-weight: 500;

        @include screen-sm-custom {
          font-size: 20px;
        }
      }

      &-position {
        font-family: $font-family-secondary;
        font-size: 16px;
        font-weight: 300;

        @include screen-sm-custom {
          font-size: 14px;
        }
      }
    }
  }

  &--circle-bg {
    .testimonials-background {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $graphite;

      &::after {
        content: none;
      }

      svg {
        opacity: .2;
        max-width: 906px;
        display: block;
        height: 906px;
      }
    }

    // Show all the time
    .testimonials-nav,
    .testimonials-bottom {
      opacity: 1;
      visibility: visible;
    }
  }
}

.testimonial-single {
  overflow: hidden;
  padding-bottom: 100px;
  padding-top: 130px;
  position: relative;

  @include screen-md {
    padding-bottom: 80px;
    padding-top: 100px;
  }

  @include screen-sm {
    padding-bottom: 40px;
    padding-top: 10px;
  }

  &--no-background {
    padding-top: 0;
  }

  &--top-background {
    padding-bottom: 80px;
    padding-top: 80px;
  }

  &--bottom-background {
    padding-bottom: 80px;
    padding-top: 80px;

    &::before {
      background: $grey_5;
      bottom: 0;
      content: "";
      height: 70%;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 0;
    }
  }

  &__inner {
    position: relative;
  }

  &__rect {
    background: $grey_6;
    height: 100%;
    position: absolute;
    top: 100px;
    width: 100%;
  }

  &__holder {
    position: relative;
  }

  &__background {
    @include screen-lg {
      height: auto;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__foreground {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;

    @include screen-sm {
      position: relative;
    }
  }

  &__card {
    background: $graphite;
    bottom: 46px;
    color: #fff;
    left: 40px;
    max-width: 480px;
    padding: 46px 60px;
    position: absolute;

    &--inverse {
      right: 40px;
      left: unset;
    }

    @include screen-max {
      max-width: 550px;
    }

    @include screen-lg {
      max-width: 500px;
    }

    @include screen-sm {
      bottom: 0;
      left: 0;
      max-width: unset;
      position: relative;
    }

    @include screen-xs {
      padding: 35px 45px;
    }
  }

  &__text {
    //font-size: 20px;
    //font-family: $heading-font;
    //line-height: get-line-height(20px, 28px);
    margin-bottom: 34px;
    //font-weight: 500;
    position: relative;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;

    &::before {
      color: $mint;
      content: "\e922";
      font-family: "icomoon" !important;
      font-size: 20px;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: absolute;
      right: calc(100% + 7px);
      top: -12px;
    }

    a {
      color: $mint;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__user {
    align-items: center;
    display: flex;
  }

  &__user-photo {
    border-radius: 50%;
    flex: 0 0 auto;
    height: 64px;
    margin-right: 15px;
    overflow: hidden;
    width: 64px;

    img {
      border-radius: 50%;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__user-info {
    &.p2 {
      font-size: 14px;
    }

    .author-text {
      font-family: $font-family-primary;
      font-weight: 500;
      display: block;
      font-size: 18px;
    }
  }

  &--post {
    padding-bottom: 52px;
    padding-top: 62px;

    @include screen-md {
      padding-bottom: 30px;
      padding-top: 40px;
    }

    @include screen-sm {
      padding-bottom: 20px;
      padding-top: 30px;
    }
  }

  &--flexible {
    margin-bottom: 70px;
    margin-top: 70px;
    padding-bottom: 0;
    padding-top: 0;

    @include screen-sm {
      margin: 50px 0;
    }

    @include screen-xs {
      margin: 40px 0;
    }
  }

  &--no-indent {
    margin-bottom: 0;
  }
}

.testimonials-gallery {
  min-width: 100%;
  width: 0;

  &-holder {
    min-height: 0;
    min-width: 0;
    position: relative;
  }

  &__item {
    border: none !important;
    position: relative;

    &-holder {
      display: flex;
      padding-bottom: 50px;
      padding-top: 64px;

      @include screen-sm {
        align-items: center;
        flex-direction: column-reverse;
        justify-content: center;
      }
    }
  }

  &__photo {
    align-self: flex-start;
    flex-basis: 242px;
    flex-shrink: 0;
    margin-right: 45px;
    width: 242px;

    @include screen-md {
      flex-basis: 160px;
      width: 160px;
    }

    @include screen-sm {
      align-self: center;
      margin-bottom: 30px;
      margin-right: 0;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__content {
    color: #fff;
    padding-right: 52px;

    @include screen-sm {
      padding-right: 0;
      text-align: center;
    }
  }

  &__text {
    font-family: $heading-font;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: get-line-height(24px, 35px);
    margin-bottom: 30px;
    padding-left: 88px;
    padding-top: 20px;
    position: relative;

    @include screen-md {
      font-size: 22px;
    }

    @include screen-sm {
      font-size: 20px;
      padding: 0 20px;
    }

    @include screen-xs {
      font-size: 18px;
      padding: 0;
    }

    &::before {
      bottom: 100%;
      color: #fff;
      content: "\e91a";
      font-family: "icomoon" !important;
      font-size: 59px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: normal;
      left: 0;
      line-height: 1;
      pointer-events: none;
      position: absolute;
      transform: translateY(44px) translateX(0);
      z-index: -1;

      @include screen-sm {
        display: none;
      }
    }
  }

  &__position {
    color: $font-color-secondary;
    font-family: $heading-font;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2px;
    padding-left: 88px;

    @include screen-sm {
      font-size: 16px;
      padding: 0;
    }

    @include screen-xs {
      font-size: 14px;
    }
  }

  &__progress-bar {
    bottom: 0;
    height: 2px;
    left: 0;
    margin-left: calc((100vw - 100%) / -2);
    position: absolute;
    width: calc(100% + (100vw - 100%) / 2);

    @include screen-sm {
      width: 100vw;
    }

    div {
      background: $brand-color;
      display: block;
      height: 100%;
      width: 0;
    }
  }
}
