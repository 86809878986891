@charset "UTF-8";
@import 'vendor/normalize.css';
@import 'vendor/jquery.fancybox.min.css';
@import "vendor/BeerSlider.css";
/* Slider */
@import 'vendor/swiper-bundle.css';
@import '../fonts/icomoon/style.css';
@import '../fonts/stylesheet.css';
.slick-slider {
  box-sizing: border-box;
  display: block;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  touch-action: pan-y;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
}

.slick-list {
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  display: block;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 0;
}
.slick-track::before, .slick-track::after {
  content: "";
  display: table;
}
.slick-track::after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  border: 1px solid transparent;
  display: block;
  height: auto;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  align-items: center;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
  color: transparent;
  cursor: pointer;
  display: inline-flex;
  font-size: 0;
  height: 28px;
  justify-content: center;
  line-height: 0px;
  outline: none;
  padding: 0 0 0 2px;
  position: relative;
  width: 28px;
}
.slick-prev:hover,
.slick-next:hover {
  background: #0e7a73;
  border-color: #0e7a73;
  outline: none;
}
.slick-prev:hover::before,
.slick-next:hover::before {
  color: #292f37;
}
.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  background-color: transparent;
  border-color: #fff;
  opacity: 0.7;
}
.slick-prev.slick-disabled::before,
.slick-next.slick-disabled::before {
  color: #fff;
}
.slick-prev::before,
.slick-next::before {
  color: #fff;
  font-family: "icomoon";
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
}

.slick-prev {
  transform: rotate(180deg);
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev::before {
  content: "\e900";
}
[dir=rtl] .slick-prev::before {
  content: "\e900";
}

.slick-next {
  margin-left: 9px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next::before {
  content: "\e900";
}
[dir=rtl] .slick-next::before {
  content: "\e900";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  bottom: -25px;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
.slick-dots li {
  cursor: pointer;
  display: inline-block;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  position: relative;
  width: 20px;
}
.slick-dots li button {
  background: transparent;
  border: 0;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 20px;
  line-height: 0px;
  outline: none;
  padding: 5px;
  width: 20px;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover::before, .slick-dots li button:focus::before {
  opacity: 1;
}
.slick-dots li button::before {
  color: black;
  content: "•";
  font-family: "icomoon";
  font-size: 6px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 20px;
  left: 0;
  line-height: 20px;
  opacity: 0.25;
  position: absolute;
  text-align: center;
  top: 0;
  width: 20px;
}
.slick-dots li.slick-active button::before {
  color: black;
  opacity: 0.75;
}

/* Icomoon icons */
/* Main fonts */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  height: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::selection {
  background: #0e7a73;
  color: #fff;
  text-shadow: none;
}

body {
  background-color: #fff;
  color: #212322;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  line-height: 1.6;
  padding-top: 102px;
}
@media (min-width: 961px) {
  body.main-nav-page {
    padding-top: 0;
  }
}
body.overflow-hidden {
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.page-main {
  background-color: #fff;
  position: relative;
  z-index: 3;
}
@media (max-width: 768px) {
  .page-main {
    margin-bottom: 0 !important;
  }
}

img {
  display: block;
  height: auto;
  max-width: 100%;
}

ul,
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

h1,
.h1,
h2,
.h2,
.post-content__inner .body-text h2,
.description__content h2,
.content-heading,
h3,
.h3,
.post-content__inner .body-text h3,
h4,
.h4,
.post-content__inner .body-text h4,
h5,
.h5,
.post-content__inner .body-text h5,
.post-content__inner .body-text h6,
.description__content h5,
.description__content h6,
h6,
.h6,
.h5u {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 1em;
}

h1,
.h1 {
  font-size: 58px;
  line-height: 1.2083333333;
}
@media (max-width: 1280px) {
  h1,
  .h1 {
    font-size: 54px;
  }
}
@media (max-width: 1024px) {
  h1,
  .h1 {
    font-size: 48px;
  }
}
@media (max-width: 768px) {
  h1,
  .h1 {
    font-size: 44px;
  }
}
@media (max-width: 480px) {
  h1,
  .h1 {
    font-size: 36px;
  }
}

h2,
.h2,
.post-content__inner .body-text h2,
.description__content h2,
.content-heading {
  font-size: 44px;
  letter-spacing: 0.03em;
  line-height: 1.2222222222;
  margin-bottom: 1.25rem;
}
@media (max-width: 1280px) {
  h2,
  .h2,
  .post-content__inner .body-text h2,
  .description__content h2,
  .content-heading {
    font-size: 40px;
  }
}
@media (max-width: 1024px) {
  h2,
  .h2,
  .post-content__inner .body-text h2,
  .description__content h2,
  .content-heading {
    font-size: 36px;
  }
}
@media (max-width: 768px) {
  h2,
  .h2,
  .post-content__inner .body-text h2,
  .description__content h2,
  .content-heading {
    font-size: 32px;
  }
}
@media (max-width: 480px) {
  h2,
  .h2,
  .post-content__inner .body-text h2,
  .description__content h2,
  .content-heading {
    font-size: 28px;
  }
}

h3,
.h3,
.post-content__inner .body-text h3 {
  font-size: 30px;
  letter-spacing: 0.02em;
  line-height: 1.25;
}
@media (max-width: 1280px) {
  h3,
  .h3,
  .post-content__inner .body-text h3 {
    font-size: 28px;
  }
}
@media (max-width: 1024px) {
  h3,
  .h3,
  .post-content__inner .body-text h3 {
    font-size: 24px;
  }
}
@media (max-width: 768px) {
  h3,
  .h3,
  .post-content__inner .body-text h3 {
    font-size: 22px;
  }
}

h4,
.h4,
.post-content__inner .body-text h4 {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 1.2;
  text-transform: uppercase;
}
@media (max-width: 1280px) {
  h4,
  .h4,
  .post-content__inner .body-text h4 {
    font-size: 22px;
  }
}
@media (max-width: 1024px) {
  h4,
  .h4,
  .post-content__inner .body-text h4 {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  h4,
  .h4,
  .post-content__inner .body-text h4 {
    font-size: 18px;
  }
}

h5,
.h5,
.post-content__inner .body-text h5,
.post-content__inner .body-text h6,
.description__content h5,
.description__content h6 {
  font-size: 24px;
  letter-spacing: 0.02em;
  line-height: 1.2222222222;
}
@media (max-width: 1280px) {
  h5,
  .h5,
  .post-content__inner .body-text h5,
  .post-content__inner .body-text h6,
  .description__content h5,
  .description__content h6 {
    font-size: 22px;
  }
}
@media (max-width: 1024px) {
  h5,
  .h5,
  .post-content__inner .body-text h5,
  .post-content__inner .body-text h6,
  .description__content h5,
  .description__content h6 {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  h5,
  .h5,
  .post-content__inner .body-text h5,
  .post-content__inner .body-text h6,
  .description__content h5,
  .description__content h6 {
    font-size: 16px;
  }
}
@media (max-width: 480px) {
  h5,
  .h5,
  .post-content__inner .body-text h5,
  .post-content__inner .body-text h6,
  .description__content h5,
  .description__content h6 {
    font-size: 18px;
  }
}

h6,
.h6 {
  font-size: 18px;
  line-height: 1.1428571429;
}
@media (max-width: 1280px) {
  h6,
  .h6 {
    font-size: 18px;
  }
}
@media (max-width: 1024px) {
  h6,
  .h6 {
    font-size: 14px;
  }
}
@media (max-width: 480px) {
  h6,
  .h6 {
    font-size: 16px;
  }
}

.h5u {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.2;
}
@media (max-width: 1280px) {
  .h5u {
    font-size: 22px;
  }
}
@media (max-width: 1024px) {
  .h5u {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .h5u {
    font-size: 16px;
  }
}

.p1,
.p2,
.p3,
.footer__top-nav li a,
.p4,
.p5,
.p3u {
  font-family: "Roboto Slab", serif;
}

.p1 {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.04em;
  line-height: 162%;
}
@media (max-width: 1280px) {
  .p1 {
    font-size: 17px;
  }
}
@media (max-width: 1024px) {
  .p1 {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .p1 {
    font-size: 15px;
  }
}
@media (max-width: 480px) {
  .p1 {
    font-size: 16px;
  }
}

.p2 {
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.04em;
  line-height: 167%;
}
@media (max-width: 1280px) {
  .p2 {
    font-size: 14px;
  }
}
@media (max-width: 1024px) {
  .p2 {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .p2 {
    font-size: 12px;
  }
}
@media (max-width: 480px) {
  .p2 {
    font-size: 14px;
  }
}

.p3, .footer__top-nav li a {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 150%;
}
@media (max-width: 1280px) {
  .p3, .footer__top-nav li a {
    font-size: 14px;
  }
}
@media (max-width: 1024px) {
  .p3, .footer__top-nav li a {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .p3, .footer__top-nav li a {
    font-size: 12px;
  }
}
@media (max-width: 480px) {
  .p3, .footer__top-nav li a {
    font-size: 14px;
  }
}

.p4 {
  font-size: 13px;
  font-weight: 400;
  line-height: 145%;
}
@media (max-width: 1280px) {
  .p4 {
    font-size: 12px;
  }
}
@media (max-width: 1024px) {
  .p4 {
    font-size: 11px;
  }
}
@media (max-width: 768px) {
  .p4 {
    font-size: 11px;
  }
}
@media (max-width: 480px) {
  .p4 {
    font-size: 12px;
  }
}

.p5 {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
}
@media (max-width: 1280px) {
  .p5 {
    font-size: 11px;
  }
}
@media (max-width: 1024px) {
  .p5 {
    font-size: 10px;
  }
}
@media (max-width: 768px) {
  .p5 {
    font-size: 10px;
  }
}
@media (max-width: 480px) {
  .p5 {
    font-size: 11px;
  }
}

.p3u {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 167%;
}
@media (max-width: 1280px) {
  .p3u {
    font-size: 14px;
  }
}
@media (max-width: 1024px) {
  .p3u {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .p3u {
    font-size: 12px;
  }
}
@media (max-width: 480px) {
  .p3u {
    font-size: 11px;
  }
}

.quote, .post-content__inner .body-text blockquote, .description blockquote {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 140%;
}
@media (max-width: 1280px) {
  .quote, .post-content__inner .body-text blockquote, .description blockquote {
    font-size: 26px;
  }
}
@media (max-width: 1024px) {
  .quote, .post-content__inner .body-text blockquote, .description blockquote {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .quote, .post-content__inner .body-text blockquote, .description blockquote {
    font-size: 18px;
  }
}
@media (max-width: 480px) {
  .quote, .post-content__inner .body-text blockquote, .description blockquote {
    font-size: 18px;
  }
}
.quote--bold, .post-content__inner .body-text blockquote, .description blockquote {
  font-weight: 500;
}

input,
textarea,
button {
  border: none;
}
input:focus,
textarea:focus,
button:focus {
  outline: none;
}

button {
  background-color: transparent;
  color: inherit;
  font: inherit;
}

h1 {
  margin-bottom: 14px;
}

a {
  outline: none;
  color: #333a3c;
  font-weight: 500;
}
a:hover {
  color: #20b59a;
}

.container {
  margin: 0 auto;
  max-width: 1070px;
  padding-left: 50px;
  padding-right: 50px;
}
@media (max-width: 768px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 480px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.container--post {
  max-width: 990px;
}
.container--small {
  max-width: 900px;
}
.container--medium {
  max-width: 990px;
}
.container--wider {
  max-width: 1180px;
}
.container--large {
  max-width: 1220px;
}
.container--max {
  max-width: 1280px;
}
.container--header {
  max-width: 1420px;
}
.container--p0 {
  padding: 0;
}

.p0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.top-p0 {
  padding-top: 0 !important;
}

.bottom-p0 {
  padding-bottom: 0 !important;
}

.p20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.top-p20 {
  padding-top: 20px !important;
}

.bottom-p20 {
  padding-bottom: 20px !important;
}

.p40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
@media (max-width: 768px) {
  .p40 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}

.top-p40 {
  padding-top: 40px !important;
}
@media (max-width: 768px) {
  .top-p40 {
    padding-top: 20px !important;
  }
}

.bottom-p40 {
  padding-bottom: 40px !important;
}
@media (max-width: 768px) {
  .bottom-p40 {
    padding-bottom: 20px !important;
  }
}

.p80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.top-p80 {
  padding-top: 80px !important;
}

.bottom-p80 {
  padding-bottom: 80px !important;
}

.top-p100 {
  padding-top: 100px !important;
}

.p100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.bottom-p100 {
  padding-bottom: 100px !important;
}

.bottom-p140 {
  padding-bottom: 140px !important;
}

.p120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.m0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.top-m0 {
  margin-top: 0 !important;
}

.bottom-m0 {
  margin-bottom: 0 !important;
}

.m20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.top-m20 {
  margin-top: 20px !important;
}

.bottom-m20 {
  margin-bottom: 20px !important;
}

.m40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.top-m40 {
  margin-top: 40px !important;
}

.bottom-m40 {
  margin-bottom: 40px !important;
}

.m80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.top-m80 {
  margin-top: 80px !important;
}

.bottom-m80 {
  margin-bottom: 80px !important;
}

.list {
  line-height: 36px;
}

.disable-hover,
.disable-hover * {
  pointer-events: none !important;
}

.list-dotted, .site-content ul {
  color: #5b6770;
  margin-bottom: 40px;
  padding-top: 3px;
}
@media (max-width: 480px) {
  .list-dotted, .site-content ul {
    margin-bottom: 30px;
  }
}
.list-dotted li, .site-content ul li {
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 4px;
  padding-left: 16px;
  position: relative;
}
.list-dotted li p, .site-content ul li p {
  color: #333a3c;
  margin-bottom: 0;
}
.list-dotted li::before, .site-content ul li::before {
  color: #5b6770;
  content: "\e92d";
  font-family: icomoon !important;
  font-size: 8px;
  left: 0;
  position: absolute;
  top: 6px;
}
@media (max-width: 1280px) {
  .list-dotted li::before, .site-content ul li::before {
    top: 5px;
  }
}
@media (max-width: 1024px) {
  .list-dotted li::before, .site-content ul li::before {
    top: 4px;
  }
}
@media (max-width: 480px) {
  .list-dotted li::before, .site-content ul li::before {
    top: 7px;
  }
}

.site-content {
  color: #333a3c;
  padding-bottom: 80px;
  padding-top: 0;
}
@media (max-width: 960px) {
  .site-content {
    padding-bottom: 40px;
  }
}
@media (max-width: 960px) {
  .site-content {
    padding-top: 0;
  }
}
.site-content h1,
.site-content h2,
.site-content h3,
.site-content h4,
.site-content h5,
.site-content h6 {
  color: #212322;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
.site-content h2 {
  margin-bottom: 0.4em;
}
.site-content p {
  margin-bottom: 0.7em;
}
.site-content p + h2 {
  margin-top: 1.5em;
}
.site-content h3 {
  margin-bottom: 20px;
  margin-top: 20px;
  padding-top: 15px;
}
.site-content a {
  color: #212322;
  font-weight: 500;
  text-decoration: underline;
}
.site-content a:hover {
  color: #20b59a;
}
.site-content ol {
  color: #5b6770;
  list-style-position: inside;
  margin-bottom: 40px;
  padding-left: 0;
  padding-top: 3px;
}
.site-content ol li {
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 4px;
  padding-left: 0;
  position: relative;
}
.site-content .nav-404 {
  list-style: none;
}
.site-content--template {
  padding-bottom: 0;
  padding-top: 0;
}

.holder-404 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 32px 0 0;
}
@media (max-width: 768px) {
  .holder-404 {
    flex-direction: column;
  }
}
.holder-404 h1 {
  color: #212322;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 60px;
  margin-bottom: 30px;
}
@media (max-width: 1024px) {
  .holder-404 h1 {
    font-size: 40px;
  }
}
@media (max-width: 960px) {
  .holder-404 h1 {
    font-size: 30px;
    margin-bottom: 15px;
  }
}
@media (max-width: 480px) {
  .holder-404 h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
}
.holder-404 p {
  color: #333a3c;
  line-height: 1.8;
  margin-bottom: 40px;
}
@media (max-width: 480px) {
  .holder-404 p {
    font-size: 16px;
    line-height: 1.5625;
    margin-bottom: 30px;
  }
}
.holder-404 .actions {
  align-items: center;
  display: flex;
  padding-top: 5px;
}
.holder-404 .actions a {
  color: #212322;
  display: inline-flex;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
}
.holder-404 .actions a:hover {
  color: #20b59a;
}
.holder-404 .actions span {
  display: inline-flex;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: -8px;
}
@media (max-width: 480px) {
  .holder-404 .actions span {
    font-size: 16px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: -6px;
  }
}
.holder-404 img {
  width: 100%;
}

.not-found__content {
  flex-shrink: 0;
  padding-right: 40px;
}
@media (max-width: 768px) {
  .not-found__content {
    margin-bottom: 50px;
    padding-right: 0;
    width: 100%;
  }
}
.not-found__image {
  max-width: 513px;
  padding-right: 105px;
}
@media (max-width: 1024px) {
  .not-found__image {
    padding-right: 0;
  }
}
@media (max-width: 768px) {
  .not-found__image {
    max-width: 400px;
  }
}

body.error404 .site-content {
  background: #eef1f6;
}

.video-layout .fancybox-slide {
  background-color: #000;
  padding: 0;
}
.video-layout .fancybox-slide.--video {
  padding: 0;
}
.video-layout .fancybox-bg {
  background-color: #000;
  display: none;
  opacity: 1;
}
.video-layout .fancybox-toolbar {
  opacity: 1;
  padding: 22px;
  visibility: visible;
}
.video-layout .fancybox-button {
  background-color: #fff;
  border-radius: 50%;
  color: #333a3c;
  cursor: pointer;
  font-size: 32px;
  height: 36px;
  padding: 0;
  width: 36px;
  z-index: 99999;
}
.video-layout .fancybox-button:hover {
  color: #000;
}
.video-layout .fancybox-button span {
  display: block;
}

.fancybox-navigation .fancybox-button,
.fancybox-navigation .featured-button,
.featured-navigation .fancybox-button,
.featured-navigation .featured-button {
  background: 0 0;
  border-radius: 50%;
  color: #333a3c;
  height: 100px;
  padding: 0;
  top: calc(50% - 50px);
  width: 42px;
}
.fancybox-navigation .fancybox-button--arrow_left > div,
.fancybox-navigation .featured-button--arrow_left > div,
.featured-navigation .fancybox-button--arrow_left > div,
.featured-navigation .featured-button--arrow_left > div {
  right: 0;
}
.fancybox-navigation .fancybox-button--arrow_left > div::before,
.fancybox-navigation .featured-button--arrow_left > div::before,
.featured-navigation .fancybox-button--arrow_left > div::before,
.featured-navigation .featured-button--arrow_left > div::before {
  right: 21px;
  transform: rotate(180deg);
}
.fancybox-navigation .fancybox-button--arrow_right > div,
.fancybox-navigation .featured-button--arrow_right > div,
.featured-navigation .fancybox-button--arrow_right > div,
.featured-navigation .featured-button--arrow_right > div {
  left: 0;
}
.fancybox-navigation .fancybox-button--arrow_right > div::before,
.fancybox-navigation .featured-button--arrow_right > div::before,
.featured-navigation .fancybox-button--arrow_right > div::before,
.featured-navigation .featured-button--arrow_right > div::before {
  left: 21px;
}
.fancybox-navigation .fancybox-button > div,
.fancybox-navigation .featured-button > div,
.featured-navigation .fancybox-button > div,
.featured-navigation .featured-button > div {
  background-color: #fff;
  border-radius: 50%;
  font-size: 13px;
  height: 100px;
  position: absolute;
  top: 0;
  transition: background-color 0.25s, color 0.25s, transform 0.25s;
  width: 100px;
}
.fancybox-navigation .fancybox-button > div::before,
.fancybox-navigation .featured-button > div::before,
.featured-navigation .fancybox-button > div::before,
.featured-navigation .featured-button > div::before {
  content: "\e924";
  font-family: "icomoon" !important;
  font-size: 7px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: -4px;
  position: absolute;
  top: 50%;
}
.fancybox-navigation .fancybox-button svg,
.fancybox-navigation .featured-button svg,
.featured-navigation .fancybox-button svg,
.featured-navigation .featured-button svg {
  display: none;
}
.fancybox-navigation .fancybox-button:not([disabled]):hover,
.fancybox-navigation .featured-button:not([disabled]):hover,
.featured-navigation .fancybox-button:not([disabled]):hover,
.featured-navigation .featured-button:not([disabled]):hover {
  color: #20b59a;
}
.fancybox-navigation .fancybox-button:not([disabled]):hover > div,
.fancybox-navigation .featured-button:not([disabled]):hover > div,
.featured-navigation .fancybox-button:not([disabled]):hover > div,
.featured-navigation .featured-button:not([disabled]):hover > div {
  background-color: #fff;
}
.fancybox-navigation .fancybox-button:not([disabled]):hover.fancybox-button--arrow_left > div,
.fancybox-navigation .featured-button:not([disabled]):hover.fancybox-button--arrow_left > div,
.featured-navigation .fancybox-button:not([disabled]):hover.fancybox-button--arrow_left > div,
.featured-navigation .featured-button:not([disabled]):hover.fancybox-button--arrow_left > div {
  transform: translateX(4px);
}
.fancybox-navigation .fancybox-button:not([disabled]):hover.fancybox-button--arrow_right > div,
.fancybox-navigation .featured-button:not([disabled]):hover.fancybox-button--arrow_right > div,
.featured-navigation .fancybox-button:not([disabled]):hover.fancybox-button--arrow_right > div,
.featured-navigation .featured-button:not([disabled]):hover.fancybox-button--arrow_right > div {
  transform: translateX(-4px);
}
.fancybox-navigation .fancybox-button:disabled:hover,
.fancybox-navigation .featured-button:disabled:hover,
.featured-navigation .fancybox-button:disabled:hover,
.featured-navigation .featured-button:disabled:hover {
  color: #909eb1;
}
.fancybox-navigation--dark .fancybox-button > div,
.fancybox-navigation--dark .featured-button > div,
.featured-navigation--dark .fancybox-button > div,
.featured-navigation--dark .featured-button > div {
  background: #333a3c;
  color: #20b59a;
}

.icon-close.icon-close-fancybox-mobile {
  background: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  color: #333a3c;
  cursor: pointer;
  display: none;
  font-size: 32px;
  position: fixed;
  right: 20px;
  top: 27px;
  transition: color 0.2s;
  z-index: 999999;
}
.icon-close.icon-close-fancybox-mobile:hover {
  color: #000;
}

.fancybox-active .header {
  transform: translateY(-100px);
}

.project-layout .fancybox-bg {
  background-color: #333a3c;
  mix-blend-mode: multiply;
  opacity: 0.8;
}

@media (max-width: 960px) {
  .disable-scroll {
    overflow: hidden;
  }
}

@keyframes leftToRight {
  0% {
    left: auto;
    right: 0;
    width: 100%;
  }
  49% {
    left: auto;
    right: 0;
    width: 0;
  }
  50% {
    left: 0;
    right: auto;
    width: 0;
  }
  51% {
    left: 0;
    right: auto;
    width: 0;
  }
  100% {
    left: 0;
    right: auto;
    width: 100%;
  }
}
.underline,
.underline-inverse {
  position: relative;
}

.underline::before,
.underline-inverse::before {
  background-color: currentColor;
  bottom: -0.3rem;
  content: "";
  height: 2px;
  pointer-events: none;
  position: absolute;
  width: 100%;
}

.underline-inverse::before {
  width: 0;
}

.underline:hover::before,
.underline-inverse:hover::before {
  animation: leftToRight 0.4s ease forwards;
}

/* Custom animation */
.fancybox-fx-slide-in-out.fancybox-slide--previous {
  animation: close-modal 0.5s ease-in-out;
}

.fancybox-fx-slide-in-out.fancybox-slide--current {
  animation: open-modal 0.4s ease-in-out;
}

@keyframes close-modal {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}
@keyframes open-modal {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.input-control {
  position: relative;
}
.input-control input {
  appearance: none;
  background: transparent;
  border-bottom: 1px solid #c1c3c3;
  border-radius: 0;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  height: 26px;
  padding-bottom: 1px;
  padding-left: 0;
  transition: all 0.2s;
  width: 100%;
}
.input-control label {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  left: 0;
  line-height: 1;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.2s;
}
.input-control:hover input {
  border-color: #5b6770;
}
.input-control .label--is-active, .input-control.is-error label, .input-control.is-filled label, .input-control.is-focused label {
  color: #9da0a1;
  font-size: 9px;
  font-weight: normal;
  text-transform: uppercase;
  top: 0;
  transform: translateY(-100%);
}
.input-control.is-focused input {
  border-color: #5b6770;
}
.input-control.is-error input {
  border-color: #f44336;
}
.input-control.is-error label {
  color: #f44336;
}

.loader-spinner {
  animation: cssload-spin 575ms infinite linear;
  border: 2px solid #000;
  border-left-color: transparent;
  border-radius: 50%;
  display: none;
  height: 16px;
  margin: 0 auto;
  width: 16px;
}

@keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
  }
}
#wpadminbar {
  display: none;
}

.heading--secondary {
  color: #212322;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 1.5px;
  margin-bottom: 100px;
  padding-left: 100px;
}
@media (max-width: 960px) {
  .heading--secondary {
    font-size: 38px;
    margin-bottom: 50px;
    padding-left: 0;
  }
}
@media (max-width: 768px) {
  .heading--secondary {
    font-size: 28px;
    margin-bottom: 0;
  }
}
.heading--decorate {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 1.5px;
  margin-bottom: 48px;
  position: relative;
}
@media (max-width: 960px) {
  .heading--decorate {
    font-size: 38px;
  }
}
@media (max-width: 768px) {
  .heading--decorate {
    font-size: 32px;
    margin-bottom: 30px;
  }
}
.heading--decorate::after {
  bottom: -10px;
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  width: 40px;
}
.heading--light {
  color: #fff;
}
.heading--light::after {
  background-color: #0e7a73;
}

hr {
  background-color: #c1c3c3;
  border: 0;
  height: 1px;
  margin: 54px 0;
}

.l2 {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: #0e7a73;
}

img.img-contain {
  object-fit: contain !important;
}

.grey-bg {
  background-color: #f9f9f9;
}

.dark-grey-bg {
  background-color: #f2f2f2;
}

.graphite-bg {
  background-color: #333a3c;
}

.center-heading {
  text-align: center;
  padding: 0 50px;
  margin: 40px auto 80px;
}
@media (max-width: 768px) {
  .center-heading {
    padding: 20px;
  }
}
.center-heading h4 {
  margin-bottom: 16px;
  text-transform: uppercase;
  color: #0e7a73;
  font-size: 18px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.36px;
}
.center-heading h2 {
  font-size: 36px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.72px;
  color: #212322;
  margin-bottom: 0px;
}
.center-heading p {
  text-align: center;
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px; /* 162.5% */
  letter-spacing: 0.64px;
  padding: 0 50px;
  max-width: 970px;
  margin: 12px auto 0;
}
.center-heading.dark h4 {
  color: #5ae6bd;
}
.center-heading.dark h2, .center-heading.dark p {
  color: white;
}

.cursor-pointer {
  cursor: pointer;
}

@keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.cssload-speeding-wheel {
  animation: cssload-spin 575ms infinite linear;
  border: 2px solid #fff;
  border-left-color: #000;
  border-radius: 50%;
  height: 40px;
  margin: 0 auto;
  position: relative;
  width: 40px;
  z-index: 21;
}

.process-loader .cssload-speeding-wheel {
  border: 3px solid #333a3c;
  border-left-color: #fff;
  display: block;
}

@media (max-width: 768px) {
  .process-loader::after {
    background: #fff;
    content: "";
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 20;
  }
}

.btn {
  align-items: center;
  background-color: #20b59a;
  border: 2px solid #20b59a;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  font-weight: normal;
  height: 46px;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.16s;
  user-select: none;
  white-space: nowrap;
}
@media (max-width: 1024px) {
  .btn {
    height: 47px;
    padding-left: 46px;
    padding-right: 46px;
  }
}
@media (max-width: 768px) {
  .btn {
    height: 40px;
    padding-left: 39px;
    padding-right: 39px;
  }
}
.btn:hover, .btn.hover-state {
  background-color: #0e7a73;
  border-color: #0e7a73;
  color: #fff;
}
.btn.hover-state:hover {
  opacity: 0.9;
}
.btn .loader-spinner {
  border-color: #fff;
  border-left-color: transparent;
  position: absolute;
}
.btn:disabled {
  background: #c1c3c3;
  border-color: #c1c3c3;
  pointer-events: none;
}
.btn.loading-state {
  background-color: #20b59a;
}
.btn.loading-state .btn__caption {
  opacity: 0;
}
.btn.loading-state .loader-spinner {
  display: block;
  opacity: 1;
}
.btn--small {
  border-width: 1px;
  font-size: 14px;
  height: 39px;
  padding-left: 20px;
  padding-right: 20px;
}
@media (max-width: 1024px) {
  .btn--small {
    font-size: 12px;
    height: 35px;
  }
}
@media (max-width: 768px) {
  .btn--small {
    height: 33px;
    padding-left: 18px;
    padding-right: 18px;
  }
}
.btn--secondary {
  background: transparent;
  border-color: #333a3c;
  color: #333a3c;
}
.btn--secondary:hover {
  background: transparent;
  border-color: #212322;
  color: #212322;
}
.btn--secondary:disabled {
  border-color: #9da0a1;
  color: #9da0a1;
}
.btn--secondary-inverse {
  background: transparent;
  border-color: #fff;
  color: #fff;
}
.btn--secondary-inverse:hover {
  background: transparent;
  border-color: #f2f2f2;
  color: #f2f2f2;
  opacity: 0.9;
}
.btn--contact {
  border-color: #60d2af;
  color: #60d2af;
  font-size: 24px;
  height: 60px;
  text-transform: none;
}
.btn--contact:hover {
  background: #60d2af;
  border-color: #60d2af;
  color: #fff;
}
.btn--transparent {
  border-color: #fff;
  color: #fff;
  font-size: 24px;
  height: 60px;
  text-transform: none;
}
.btn--transparent:hover {
  background: #fff;
  border-color: #fff;
  color: #212322;
}
.btn + .btn {
  margin-left: 1.5rem;
}
@media (max-width: 768px) {
  .btn + .btn {
    margin-top: 1.5rem;
    margin-left: 0;
    text-align: center;
  }
}

.section {
  padding: 80px 0;
  position: relative;
}
@media (max-width: 960px) {
  .section {
    padding: 80px 0;
  }
}
@media (max-width: 768px) {
  .section {
    padding: 60px 0;
  }
}
@media (max-width: 480px) {
  .section {
    padding: 50px 0;
  }
}
.section--accent {
  background-color: #f9f9f9;
}
.section--template {
  padding-bottom: 0;
  padding-top: 0;
}
.section--hide-overflow, .section--sprints {
  overflow: hidden;
}
.section.about--inner, .section--services-inner {
  padding-bottom: 0;
  padding-top: 63px;
}
@media (max-width: 768px) {
  .section.about--inner, .section--services-inner {
    padding-top: 20px;
  }
}
.section--principles-inner {
  background-color: #f9f9f9;
  padding-top: 100px;
}
@media (max-width: 768px) {
  .section--principles-inner {
    padding-top: 60px;
  }
}
@media (max-width: 480px) {
  .section--principles-inner {
    padding-top: 40px;
  }
}
.section.services--sprints {
  padding-bottom: 0;
  padding-top: 36px;
}
.section--team-landing, .section--team-single {
  background-color: #fff;
  padding-bottom: 0;
  padding-top: 0;
}
.section--decorate {
  position: relative;
}
.section--p0 {
  padding: 0 !important;
}
.section--ph {
  padding: 40px 0;
}
.section--pbh {
  padding: 80px 0 40px;
}
.section--pth {
  padding: 40px 0 80px;
}
.section--pt0 {
  padding-top: 0 !important;
}
.section--pb0 {
  padding-bottom: 0 !important;
}
.section__header {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 64px;
  font-weight: 500;
  line-height: 1.2037037037;
  margin-bottom: 28px;
}
@media (max-width: 1280px) {
  .section__header {
    font-size: 64px;
  }
}
@media (max-width: 1024px) {
  .section__header {
    font-size: 54px;
  }
}
@media (max-width: 768px) {
  .section__header {
    font-size: 48px;
  }
}
@media (max-width: 480px) {
  .section__header {
    font-size: 44px;
  }
}
.section--team-single {
  padding-bottom: 0;
}
.section--team-projects {
  padding-bottom: 0;
  padding-top: 70px;
}
@media (max-width: 960px) {
  .section--team-projects {
    padding-top: 50px;
  }
}
@media (max-width: 768px) {
  .section--team-projects {
    padding-top: 50px;
  }
}
.section--team-projects .container {
  position: relative;
}
.section--team-projects::before {
  background: #fafafa;
  content: "";
  height: 80%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (max-width: 768px) {
  .section--team-projects::before {
    height: 100%;
  }
}
.section--team-columns {
  overflow: hidden;
  padding-bottom: 104px;
  padding-top: 0;
}
@media (max-width: 768px) {
  .section--team-columns {
    padding-bottom: 50px;
    padding-top: 30px;
  }
}
@media (max-width: 480px) {
  .section--team-columns {
    padding-top: 10px;
  }
}
.section--project-single {
  background-color: #fafafa;
  padding-bottom: 0;
}
.section--featured-projects-gallery {
  overflow: hidden;
  padding-bottom: 0;
  padding-top: 0;
}
.section--featured-projects-gallery .featured-projects-gallery__slider::after {
  display: none;
}
.section--bg {
  background-color: #fafafa;
}
.section--dark {
  background-color: #333a3c;
  color: #fff;
}
.section--black {
  background-color: #212322;
  color: #fff;
}
.section--single-service {
  margin-bottom: 100px;
  overflow: hidden;
  padding-bottom: 0;
  padding-top: 42px;
}
@media (max-width: 960px) {
  .section--single-service {
    margin-bottom: 80px;
    padding-top: 20px;
  }
}
@media (max-width: 768px) {
  .section--single-service {
    margin-bottom: 50px;
  }
}
.section__content {
  color: #fff;
  font-family: "Roboto Slab", serif;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.05em;
  line-height: 1.4444444444;
  margin-bottom: 66px;
}
@media (max-width: 1280px) {
  .section__content {
    font-size: 24px;
  }
}
@media (max-width: 1024px) {
  .section__content {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .section__content {
    font-size: 16px;
  }
}
@media (max-width: 480px) {
  .section__content {
    font-size: 14px;
  }
}
.section__content--contact {
  margin-bottom: 60px;
}
.section--flexible {
  padding-bottom: 90px;
  padding-top: 0;
}
@media (max-width: 768px) {
  .section--flexible {
    padding-bottom: 50px;
  }
}
.section--flexible .content-text, .section--flexible .title__caption {
  max-width: 820px;
}
.section--flexible .content-text h3, .section--flexible .title__caption h3 {
  color: #212322;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.5;
  margin-bottom: 20px;
  margin-top: 70px;
}
@media (max-width: 960px) {
  .section--flexible .content-text h3, .section--flexible .title__caption h3 {
    font-size: 22px;
    margin-bottom: 15px;
    margin-top: 40px;
  }
}
.section--flexible .content-text--smaller h3 {
  margin-top: 0;
}
.section--flexible .content-text--smaller p {
  margin-bottom: 1em;
}
.section--flexible .content-text--smaller p:last-child {
  margin-bottom: 0;
}
.section--flexible .content-text--smaller p:last-child img {
  margin-bottom: 60px;
}
@media (max-width: 768px) {
  .section--flexible .content-text--smaller p:last-child img {
    margin-bottom: 30px;
  }
}
.section--flexible .content-text--smaller p:last-child img:last-child {
  margin-bottom: 0;
}
@media (max-width: 960px) {
  .section--flexible .columns__item {
    margin-bottom: 30px;
  }
  .section--flexible .columns__item:last-child {
    margin-bottom: 0;
  }
}
.section--flexible .columns__item img {
  display: block;
  height: auto;
  margin-bottom: 60px;
  width: 100%;
}
.section--flexible .section__inner {
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(64, 54, 78, 0.1);
  padding: 100px 0 80px;
}
@media (max-width: 960px) {
  .section--flexible .section__inner {
    padding-bottom: 55px;
    padding-top: 70px;
  }
}
@media (max-width: 768px) {
  .section--flexible .section__inner {
    padding-bottom: 40px;
    padding-top: 50px;
  }
}
.section--flexible .section__inner--transparent {
  background-color: transparent;
  box-shadow: unset;
}
.section--flexible .section__inner--rounded {
  border-radius: 9px;
}
.section--flexible .section__inner--nopb {
  padding-bottom: 0;
}
.section--flexible .section__container {
  padding-left: 100px;
  padding-right: 100px;
}
@media (max-width: 960px) {
  .section--flexible .section__container {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media (max-width: 768px) {
  .section--flexible .section__container {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (max-width: 480px) {
  .section--flexible .section__container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 480px) {
  .section--flexible > .container {
    padding-left: 0;
    padding-right: 0;
  }
}
.section--flexible-secondary {
  margin-bottom: 70px;
  margin-top: 70px;
  padding-bottom: 0;
  padding-top: 0;
}
@media (max-width: 768px) {
  .section--flexible-secondary {
    margin-bottom: 50px;
    margin-top: 50px;
  }
}
.section--flexible-secondary-bigger {
  margin-bottom: 100px;
}
@media (max-width: 960px) {
  .section--flexible-secondary-bigger {
    margin-bottom: 70px;
  }
}
@media (max-width: 768px) {
  .section--flexible-secondary-bigger {
    margin-bottom: 50px;
  }
}
.section--flexible-secondary-no-indent {
  margin-bottom: 0;
}
@media (max-width: 480px) {
  .section--flexible-secondary .container {
    padding-left: 0;
    padding-right: 0;
  }
}
.section--flexible-bottom-overflow {
  margin-bottom: -100px;
  padding-bottom: 170px;
}
@media (max-width: 960px) {
  .section--flexible-bottom-overflow {
    padding-bottom: 150px;
  }
}
.section--flexible-bottom-overflow .section__inner {
  padding-bottom: 0;
}
.section--flexible-bottom-overflow + .section {
  margin-top: 0;
}
.section--flexible-bottom-overflow + .section .featured-gallery--flexible {
  margin-top: 0;
}
.section--intro {
  padding-bottom: 0;
}

.contact {
  padding: 100px 0 !important;
}
@media (max-width: 960px) {
  .contact {
    padding: 50px 0 70px !important;
  }
}
.contact .row {
  justify-content: space-between;
}
.contact .row .col {
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.contact .row img {
  margin: 0 0 0 auto;
}
@media (max-width: 960px) {
  .contact .row {
    flex-direction: column;
    gap: 60px;
  }
}
.contact.work .section__header {
  font-size: 36px;
  line-height: 120%;
  letter-spacing: 0.72px;
}
.contact.work .section__content {
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.64px;
  margin-bottom: 80px;
  max-width: 350px;
}
@media (max-width: 960px) {
  .contact.work .section__content {
    margin-bottom: 40px;
    max-width: 600px;
  }
}
.contact .btn--contact {
  width: 192px;
}
.contact img.greyscale {
  filter: grayscale(1);
}
.contact__background {
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}
.contact__background svg {
  height: 100%;
  object-fit: cover;
  opacity: 0.2;
  width: 100%;
}

.section--inverse {
  background-color: #333a3c;
  color: #fff;
  padding: 110px 0 70px;
}
@media (max-width: 960px) {
  .section--inverse {
    padding-bottom: 80px;
    padding-top: 80px;
  }
}
@media (max-width: 480px) {
  .section--inverse {
    padding: 60px 0;
  }
}
.section--inverse [href*=tel] {
  white-space: nowrap;
}
.section--inverse a:not(.btn) {
  color: #fff;
  font-weight: 400;
}
.section--inverse a:not(.btn):hover {
  text-decoration: none;
}

.section--content-center {
  text-align: center;
}

.section--img {
  padding: 0;
  position: relative;
}
.section--img img {
  height: 517px;
  object-fit: cover;
  width: 100%;
}
@media (max-width: 768px) {
  .section--img img {
    height: 350px;
  }
}
@media (max-width: 480px) {
  .section--img img {
    height: 200px;
  }
}

.section--padding {
  padding-bottom: 0;
}

.section--team {
  padding-bottom: 68px;
  padding-top: 75px;
}

.capabilities {
  padding-bottom: 260px;
  padding-top: 180px;
}
@media (max-width: 1024px) {
  .capabilities {
    padding-bottom: 140px;
    padding-top: 140px;
  }
}
@media (max-width: 960px) {
  .capabilities {
    padding-bottom: 100px;
    padding-top: 100px;
  }
}
@media (max-width: 768px) {
  .capabilities {
    padding-bottom: 30px;
    padding-top: 30px;
  }
}
.pattern-dotted {
  background-image: url("../img/dotted-pattern.svg?v=2");
  background-position: center top 10px;
  background-size: auto;
}

.section-bg {
  position: relative;
}

.section-bg--bottom::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 160px;
  background-color: #f9f9f9;
}

.section-bg--bottom-small::after {
  height: 80px;
}

.section-bg--top::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 160px;
  background-color: #f9f9f9;
}

.section-bg--top-small::before {
  height: 80px;
}

.section-bg--z1 {
  z-index: 1;
}

.bg--grey {
  background-color: #f9f9f9;
}

@keyframes topbar-x {
  0% {
    top: 0;
    transform: rotate(0);
  }
  45% {
    top: 25%;
    transform: rotate(145deg);
  }
  75% {
    transform: rotate(130deg);
  }
  100% {
    transform: rotate(135deg);
  }
}
@keyframes topbar-back {
  0% {
    top: 25%;
    transform: rotate(135deg);
  }
  45% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    top: 0;
    transform: rotate(0);
  }
}
@keyframes bottombar-x {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  45% {
    bottom: 25%;
    transform: rotate(-145deg);
  }
  75% {
    transform: rotate(-130deg);
  }
  100% {
    transform: rotate(-135deg);
  }
}
@keyframes bottombar-back {
  0% {
    bottom: 25%;
    transform: rotate(-135deg);
  }
  45% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}
@keyframes show-header {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes hide-header {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
.header {
  background-color: #fff;
  height: 102px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  will-change: transform, height;
  z-index: 11;
}
.header svg, .header path {
  fill: #212322;
}
.header--transparent ul.header-nav > li.current-page-item > a, .header--transparent ul.header-nav > li.current-page-ancestor > a {
  color: #212322 !important;
}
.header--transparent ul.header-nav > li.current-page-item > a::after, .header--transparent ul.header-nav > li.current-page-ancestor > a::after {
  content: "";
  margin: auto;
  position: absolute;
  bottom: 7px;
  left: 10px;
  right: 10px;
  width: calc(100% - 20px);
  height: 1px;
  background: #5ae6bd;
}
@media (max-width: 768px) {
  .header--transparent ul.header-nav > li.current-page-item > a::after, .header--transparent ul.header-nav > li.current-page-ancestor > a::after {
    display: none;
  }
}
.header--transparent ul.header-nav > li .logo-image svg, .header--transparent ul.header-nav > li .logo-image path {
  fill: #5ae6bd;
}
.header--transparent ul.header-nav > li .logo-name svg, .header--transparent ul.header-nav > li .logo-name path {
  fill: #fff;
}
.header--transparent:not(.is-scroll) {
  background-color: transparent;
  color: #fff;
}
.header--transparent:not(.is-scroll) ul.header-nav > li.current-page-item > a, .header--transparent:not(.is-scroll) ul.header-nav > li.current-page-ancestor > a {
  color: #fff !important;
}
@media (max-width: 768px) {
  .header--transparent:not(.is-scroll) ul.header-nav > li.current-page-item > a, .header--transparent:not(.is-scroll) ul.header-nav > li.current-page-ancestor > a {
    color: #5b6770 !important;
  }
  .header--transparent:not(.is-scroll) ul.header-nav > li.current-page-item > a:hover, .header--transparent:not(.is-scroll) ul.header-nav > li.current-page-ancestor > a:hover {
    color: #212322 !important;
  }
}
.header--transparent:not(.is-scroll) .logo-image svg, .header--transparent:not(.is-scroll) .logo-image path {
  fill: #5ae6bd;
}
.header--transparent:not(.is-scroll) .logo-name svg, .header--transparent:not(.is-scroll) .logo-name path {
  fill: #fff;
}
.header--transparent:not(.is-scroll).header .hamburger .menu-bar {
  background: #fff;
  border-color: #fff;
}
.header--transparent:not(.is-scroll).header .hamburger.toggled .menu-bar {
  background: transparent;
  border-color: #fff;
}
.header--transparent:not(.is-scroll).header .hamburger.toggled .menu-bar.bar1, .header--transparent:not(.is-scroll).header .hamburger.toggled .menu-bar.bar3 {
  background: white;
}
.header--transparent:not(.is-scroll).header:not(.header-nav-active) .header-nav a {
  color: #c1c3c3;
}
.header--transparent:not(.is-scroll).header:not(.header-nav-active) .sub-menu {
  background: #212322;
}
.header--transparent:not(.is-scroll).header:not(.header-nav-active) .sub-menu li a:hover, .header--transparent:not(.is-scroll).header:not(.header-nav-active) .sub-menu li.current_page_item a {
  background: #333a3c;
}
.header.is-scroll {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.05);
  position: fixed;
}
@media (min-width: 961px) {
  .header.is-scroll:not(.disable-nav-hide) .show-contact-form {
    background: #20b59a;
    color: #fff;
    padding: 12px 20px;
    margin-top: 4px;
  }
  .header.is-scroll:not(.disable-nav-hide) .show-contact-form:hover {
    background: #0e7a73;
  }
}
.header.is-scroll:not(.header-nav-active):not(.disable-nav-hide), .header.is-scroll:not(.header-always-show):not(.disable-nav-hide) {
  transform: translateY(-100%);
}
.header.is-scroll:not(.header-nav-active):not(.disable-nav-hide).nav-up, .header.is-scroll:not(.header-always-show):not(.disable-nav-hide).nav-up {
  transform: translateY(-100%);
  transition: box-shadow 0.16s, height 0.16s, transform 0.16s;
}
.header.is-scroll:not(.header-nav-active):not(.disable-nav-hide).nav-down, .header.is-scroll:not(.header-always-show):not(.disable-nav-hide).nav-down {
  transform: translateY(0);
  transition: box-shadow 0.16s, height 0.16s, transform 0.16s;
}
.header.is-scroll:not(.header-nav-active):not(.disable-nav-hide).nav-down .hamburger, .header.is-scroll:not(.header-always-show):not(.disable-nav-hide).nav-down .hamburger {
  opacity: 1;
  visibility: visible;
}
.header.is-scroll:not(.header-nav-active):not(.disable-nav-hide).nav-down .btn, .header.is-scroll:not(.header-always-show):not(.disable-nav-hide).nav-down .btn {
  opacity: 0;
  visibility: hidden;
}
@media (min-width: 961px) {
  .header.is-scroll:not(.header-nav-active):not(.disable-nav-hide).nav-down .header-nav.is-active, .header.is-scroll:not(.header-always-show):not(.disable-nav-hide).nav-down .header-nav.is-active {
    opacity: 1;
    visibility: visible;
  }
}
.header.header-nav-active, .header.header-always-show {
  opacity: 1 !important;
  position: fixed;
  transform: translateY(0) !important;
}
.header .container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
}
@media (max-width: 480px) {
  .header .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.header-right {
  display: flex;
  height: 100%;
  position: relative;
  align-items: center;
}
@media (max-width: 960px) {
  .header-right {
    align-items: center;
  }
}
.header .hamburger {
  display: none;
  height: 23px;
  margin: 0;
  padding-top: 0;
  transition: 0s;
  width: 29px;
  z-index: 999;
  cursor: pointer;
}
@media (max-width: 768px) {
  .header .hamburger {
    display: block;
  }
}
.header .hamburger .menu-bar {
  background: #212322;
  display: block;
  height: 1px;
  margin: 0 auto;
  position: relative;
  transition: 0s;
  width: 29px;
}
.header .hamburger .menu-bar.bar1 {
  animation: topbar-back 0s;
  animation-fill-mode: both;
  animation-play-state: initial !important;
  top: 0;
}
.header .hamburger .menu-bar.bar2 {
  opacity: 1;
}
.header .hamburger .menu-bar.bar3 {
  animation: bottombar-back 0s;
  animation-fill-mode: both;
  animation-play-state: initial !important;
  bottom: 0;
}
.header .hamburger.toggled .bar1 {
  animation: topbar-x 0s;
  animation-fill-mode: both;
  background: #333a3c;
  top: 12px;
  width: 10px;
}
.header .hamburger.toggled .bar2 {
  background: transparent;
  border: 1px solid #333a3c;
  border-radius: 50%;
  height: 24px;
  top: -11px;
  width: 24px;
}
.header .hamburger.toggled .bar3 {
  animation: bottombar-x 0s;
  animation-fill-mode: both;
  background: #333a3c;
  top: -33px;
  width: 10px;
}
.header .hamburger .menu-bar + .menu-bar {
  margin-top: 10px;
}
.header-nav {
  align-items: center;
  display: flex;
  margin-right: -10px;
  opacity: 1;
  transform: translateY(0);
  transition: all 0.16s;
}
@media (max-width: 768px) {
  .header-nav {
    align-items: flex-start;
    background-color: #fff;
    flex-direction: column;
    height: auto;
    justify-content: flex-start;
    left: 0;
    opacity: 0;
    overflow-y: auto;
    padding-bottom: 50px;
    padding-left: 27px;
    padding-right: 27px;
    padding-top: 8px;
    pointer-events: none;
    position: fixed;
    top: 102px;
    transition: all 0.3s;
    visibility: hidden;
    width: 100%;
    z-index: 100;
  }
}
.header-nav > li {
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  max-width: 240px;
}
.header-nav > li.current_page_item > a, .header-nav > li.current_page_ancestor > a {
  color: #212322;
}
@media (max-width: 768px) {
  .header-nav > li.current_page_item > a, .header-nav > li.current_page_ancestor > a {
    background: #f2f2f2;
  }
}
.header-nav > li.current_page_item > a::after, .header-nav > li.current_page_ancestor > a::after {
  content: "";
  margin: auto;
  position: absolute;
  bottom: 7px;
  left: 10px;
  right: 10px;
  width: calc(100% - 20px);
  height: 1px;
  background: #5ae6bd;
}
@media (max-width: 768px) {
  .header-nav > li.current_page_item > a::after, .header-nav > li.current_page_ancestor > a::after {
    display: none;
  }
}
@media (max-width: 768px) {
  .header-nav > li.current_page_ancestor > a {
    background: #fff;
    color: #212322 !important;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .header-nav > li.current_page_item > a {
    background: #f2f2f2;
    color: #5b6770;
  }
}
.header-nav li {
  position: relative;
}
@media (max-width: 768px) {
  .header-nav li {
    align-items: flex-start;
    flex-direction: column;
    height: auto;
    padding-right: 0;
    width: 100%;
  }
}
.header-nav li:last-child {
  padding-right: 0;
}
.header-nav li:last-child::after {
  display: none;
}
.header-nav li:hover .sub-menu {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}
@media (max-width: 768px) {
  .header-nav__item a {
    color: #fff;
  }
}
.header-nav__item.active a {
  color: #0e7a73;
}
@media (max-width: 768px) {
  .header-nav__item.active a {
    color: #fff;
  }
}
@media (max-width: 768px) {
  .header-nav.is-active {
    height: calc(100vh - 102px);
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }
  .header-nav.is-active a {
    opacity: 1;
    width: 100%;
  }
}
.header-nav a {
  color: #5b6770;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  margin: 0 6px;
  padding: 15px 10px 10px;
  position: relative;
  text-decoration: none;
  transition: color 0.16s;
  line-height: normal;
  letter-spacing: 0.9px;
}
@media (max-width: 1024px) {
  .header-nav a {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .header-nav a {
    display: block;
    font-size: 16px;
    letter-spacing: 0;
    margin: 0;
    min-width: 168px;
    opacity: 0;
    padding: 10px;
    text-align: left;
    transition: 0.2s;
  }
}
.header-nav a:hover {
  color: #212322;
}
.header .btn:not(.cta) {
  margin-left: 19px;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 2px;
  transform: translateY(-25%);
  transition: all 0.1s;
  visibility: hidden;
}
@media (max-width: 768px) {
  .header .btn:not(.cta) {
    position: static;
    top: unset;
    transform: none;
  }
}
@media (max-width: 480px) {
  .header .btn:not(.cta) {
    font-size: 15px;
    height: 39px;
    margin-left: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.logo {
  align-items: center;
  display: flex;
  text-decoration: none;
}
.logo-image {
  display: block;
  margin-right: 20px;
  width: 35px;
}
@media (max-width: 480px) {
  .logo-image {
    margin-right: 0px;
  }
}
.logo-name {
  display: block;
  width: 144px;
}
@media (max-width: 480px) {
  .logo-name {
    display: none;
  }
}
.logo img {
  height: 100%;
  max-height: 100%;
}

.section-info {
  align-items: center;
  display: flex;
}
.section-info__icon-holder {
  display: flex;
  margin-right: 18px;
  position: relative;
}
@media (max-width: 768px) {
  .section-info__icon-holder {
    margin-right: 14px;
  }
}
@media (max-width: 480px) {
  .section-info__icon-holder {
    margin-right: 10px;
  }
}
.section-info__icon-progress {
  border: 2px solid #5ae6bd;
  border-radius: 50%;
  box-shadow: 0 0 0 3px #fff inset;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}
.section-info__icon-progress svg {
  display: block;
  height: 100%;
  width: 100%;
}
.section-info__icon-progress.contact-flag path {
  fill: #0e7a73;
}
.section-info__icon {
  align-items: center;
  color: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  display: flex;
  font-size: 40px;
  height: 40px;
  justify-content: center;
  transition: color 0.16s;
  width: 40px;
}
.section-info__icon .icon-capabilities {
  font-size: 30px;
}
.section-info__icon .icon-skillset-1 {
  font-size: 17px;
}
.section-info__icon .icon-projects {
  font-size: 21px;
}
.section-info__icon .icon-testimonials {
  font-size: 16px;
}
.section-info__icon a {
  color: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  display: inline-flex;
  text-decoration: none;
}
.section-info__index {
  color: #212322;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
}
@media (max-width: 768px) {
  .section-info__index {
    font-size: 20px;
  }
}
@media (max-width: 480px) {
  .section-info__index {
    font-size: 18px;
  }
}
@media (max-width: 374px) {
  .section-info__index {
    font-size: 16px;
  }
}
.section-info__title {
  color: #333a3c;
  display: flex;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  letter-spacing: 0.05em;
  line-height: normal;
  transform: translateY(1px);
}
@media (max-width: 480px) {
  .section-info__title {
    font-size: 16px;
  }
}
.section-info .section-title__index {
  font-weight: normal;
  margin-right: 9px;
  text-transform: uppercase;
  user-select: none;
}
.section-info .section-title__delimiter {
  background: #5ae6bd;
  height: 20px;
  margin-right: 9px;
  margin-top: -1px;
  user-select: none;
  width: 1px;
}
.section-info .section-title__delimiter.is-hidden {
  display: none;
}
.section-info .section-title__name {
  font-weight: normal;
  text-transform: uppercase;
  user-select: none;
}
.section-info .section-title__name.is-hidden {
  display: none;
}

.logo-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.logo__text {
  margin-right: 20px;
  width: 174px;
}
@media (max-width: 480px) {
  .logo__text {
    width: 170px;
  }
}

.sub-menu {
  background: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  min-width: 200px;
  opacity: 0;
  padding: 8px 7px;
  position: absolute;
  top: 100%;
  visibility: hidden;
}
@media (max-width: 768px) {
  .sub-menu {
    box-shadow: unset;
    margin-bottom: 0px;
    min-width: unset;
    opacity: 1;
    padding: 0 0 0 30px;
    position: static;
    visibility: visible;
    width: 100%;
  }
}
.sub-menu li {
  padding: 0;
  position: relative;
}
.sub-menu li a {
  display: block;
  font-size: 15px;
  letter-spacing: initial;
  margin: 0;
  padding: 10px;
  text-transform: inherit;
  white-space: nowrap;
}
@media (max-width: 1280px) {
  .sub-menu li a {
    font-size: 15px;
  }
}
@media (max-width: 1024px) {
  .sub-menu li a {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .sub-menu li a {
    font-size: 16px;
  }
}
.sub-menu li a:hover {
  background: #f2f2f2;
}
.sub-menu li.current_page_item a {
  background: #f2f2f2;
}

body.overflow-hidden .header .cta {
  display: none;
}
@media (max-width: 768px) {
  body.overflow-hidden.page-template-legacy-tech-landing-template .contact-form {
    top: 127px;
  }
}

.hero {
  background-color: #fff;
  padding-bottom: 90px;
  padding-top: 100px;
}
@media (max-width: 768px) {
  .hero {
    padding-bottom: 40px;
    padding-top: 50px;
  }
}
@media (max-width: 480px) {
  .hero {
    padding-top: 40px;
  }
}
@media (max-width: 480px) {
  .hero-content {
    padding-left: 0;
  }
}
.hero-content--navigation {
  padding-right: 50px;
  position: relative;
}
.hero__title {
  font-weight: 500;
  margin-bottom: 14px;
  font-size: 48px;
  line-height: 120%;
  font-weight: 500;
}
@media (max-width: 768px) {
  .hero__title {
    font-size: 40px;
  }
}
.hero .container {
  height: 100%;
}
.hero__caption {
  color: #333a3c;
  margin-bottom: 24px;
  max-width: 670px;
}
@media (max-width: 1280px) {
  .hero__caption {
    margin-bottom: 22px;
    max-width: 600px;
  }
}
@media (max-width: 1024px) {
  .hero__caption {
    margin-bottom: 20px;
    max-width: 532px;
  }
}
.hero__caption-link {
  color: #333a3c;
  text-decoration: underline;
  transition: all 0.16s;
}
.hero__caption-link:hover {
  color: #0e7a73;
}
.hero__caption--team-landing {
  letter-spacing: 0;
  margin-bottom: 13px;
  max-width: 940px;
}
.hero-content-left {
  flex-basis: 50%;
}
@media (max-width: 960px) {
  .hero-content-left {
    flex-basis: 100%;
    padding-bottom: 70px;
  }
}
@media (max-width: 768px) {
  .hero-content-left {
    padding-bottom: 50px;
  }
}
.hero-content-right {
  flex-basis: 50%;
  padding-left: 70px;
  position: relative;
  text-align: right;
  width: 50%;
}
@media (max-width: 960px) {
  .hero-content-right {
    flex-basis: 100%;
    padding-left: 0;
    width: 100%;
  }
}
.hero--inner {
  background-color: #212322;
  height: 500px;
  padding: 0;
  position: relative;
}
@media (max-width: 960px) {
  .hero--inner {
    height: 300px;
  }
}
@media (max-width: 768px) {
  .hero--inner {
    height: 200px;
  }
}
.hero--bigger {
  height: 600px;
}
@media (max-width: 1024px) {
  .hero--bigger {
    height: 500px;
  }
}
@media (max-width: 960px) {
  .hero--bigger {
    height: 400px;
  }
}
@media (max-width: 768px) {
  .hero--bigger {
    height: 250px;
  }
}
.hero--post {
  height: 500px;
}
@media (max-width: 1024px) {
  .hero--post {
    height: 400px;
  }
}
@media (max-width: 960px) {
  .hero--post {
    height: 300px;
  }
}
@media (max-width: 768px) {
  .hero--post {
    height: 200px;
  }
}
@media (max-width: 480px) {
  .hero--post {
    height: 150px;
  }
}
.hero--xl {
  height: 660px;
}
@media (max-width: 1024px) {
  .hero--xl {
    height: auto;
  }
}
.hero--secondary {
  background: transparent;
  padding-bottom: 40px;
  padding-top: 22px;
}
.hero--secondary .hero__caption {
  letter-spacing: 0;
  margin-bottom: 0;
  max-width: 960px;
}
.hero--secondary .hero__caption p {
  margin-bottom: 1em;
}
.hero--secondary .hero__caption p:last-child {
  margin-bottom: 0;
}
@media (max-width: 480px) {
  .hero--secondary .hero__caption {
    font-size: 15px;
  }
}
.hero--secondary .hero-content {
  position: relative;
}
.hero--post-single {
  background-color: transparent;
  margin-bottom: 55px;
  padding-bottom: 0;
  padding-top: 0;
}
@media (max-width: 768px) {
  .hero--post-single {
    margin-bottom: 30px;
  }
}
.hero--tertiary {
  background-color: transparent;
  padding-top: 0;
  position: relative;
  z-index: 2;
}
.hero--tertiary .hero-content {
  position: relative;
}
.hero--tertiary .hero-content::before {
  background-color: #333a3c;
  content: "";
  height: 586px;
  left: 0;
  margin-left: -9999px;
  padding-left: 9999px;
  position: absolute;
  right: 0;
  top: -100px;
}
.hero--tertiary .hero__title {
  color: #fff;
  line-height: 1.171875;
  max-width: 763px;
  position: relative;
  z-index: 1;
}
.hero__background {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: -150px;
  top: 0;
  width: 100%;
  z-index: 1;
}
@media (max-width: 960px) {
  .hero__background {
    right: -300px;
  }
}
.hero__background img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.hero__background {
  z-index: 2;
}
.hero--side-image {
  padding: 0;
  height: 600px;
  position: relative;
}
.hero--side-image::after {
  content: "";
  position: absolute;
  background-color: #f9f9f9;
  height: 110px;
  width: 100%;
  left: 0;
  bottom: 0;
}
.hero--side-image .hero__container {
  max-width: 1024px;
  margin: 0 auto;
  height: 100%;
}
.hero--side-image .hero__inner {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  padding: 0 80px;
}
@media (max-width: 768px) {
  .hero--side-image .hero__inner {
    padding: 0 40px;
    align-items: flex-start;
  }
}
.hero--side-image .hero__background {
  position: absolute;
}
.hero--side-image .hero__background-image {
  width: 100%;
  height: 100%;
}
.hero--side-image .hero__background-image img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.hero--side-image .hero__foreground {
  max-width: 368px;
  position: relative;
  z-index: 3;
}
@media (max-width: 1024px) {
  .hero--side-image .hero__foreground {
    padding-bottom: 100px;
    padding-top: 180px;
  }
}
@media (max-width: 960px) {
  .hero--side-image .hero__foreground {
    padding-bottom: 80px;
    padding-top: 80px;
  }
}
@media (max-width: 768px) {
  .hero--side-image .hero__foreground {
    max-width: unset;
    padding-top: 63px;
  }
}
.hero--side-image .hero__title {
  color: #fff;
  max-width: 750px;
  font-weight: 500;
  font-size: 48px;
  line-height: 58px;
}
@media (max-width: 768px) {
  .hero--side-image .hero__title {
    font-size: 24px;
    line-height: 1;
  }
}
.hero--side-image .hero__caption {
  color: #fff;
  margin-bottom: 0;
  max-width: 520px;
}
@media (max-width: 768px) {
  .hero--side-image .hero__caption {
    font-size: 14px;
  }
}
.hero--side-image-darken {
  background-color: #7b899c;
  background-image: linear-gradient(-21deg, #7b899c 0%, #40506a 100%);
}
.hero--side-image-darken .hero__background-image {
  width: 44%;
}
.hero--side-image-darken .hero__foreground {
  max-width: 56%;
}
@media (max-width: 768px) {
  .hero--side-image-darken .hero__foreground {
    max-width: unset;
  }
}
.hero--side-image-darken .hero__foreground .container {
  padding-right: 20px;
}
.hero--project-logo {
  height: 100vh;
  max-height: 660px;
}
@media (max-width: 960px) {
  .hero--project-logo {
    height: calc(100vh - 90px);
    max-height: 500px;
  }
}
@media (max-width: 768px) {
  .hero--project-logo {
    height: auto;
    max-height: unset;
    padding-bottom: 80px;
    padding-top: 80px;
  }
}
.hero--project-logo .hero__background-image {
  height: 100%;
}
.hero--project-logo .hero__background::after {
  background-color: #4e5d76;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
}
.hero--project-logo .hero__foreground {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  z-index: 3;
}
.hero--project-logo .hero__foreground-holder {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.hero--project-logo .hero__title {
  font-size: 48px;
  letter-spacing: 1px;
  line-height: 1.2;
  margin-bottom: 15px;
}
@media (max-width: 960px) {
  .hero--project-logo .hero__title {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .hero--project-logo .hero__title {
    font-size: 28px;
  }
}
@media (max-width: 480px) {
  .hero--project-logo .hero__title {
    font-size: 24px;
  }
}
.hero--project-logo .hero__caption {
  color: #fff;
  font-size: 24px;
  letter-spacing: 0;
  margin-bottom: 0;
}
@media (max-width: 960px) {
  .hero--project-logo .hero__caption {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .hero--project-logo .hero__caption {
    font-size: 18px;
  }
}
.hero--project-logo .hero__project-logo {
  margin-bottom: 35px;
  max-width: 170px;
}
.hero--centered {
  text-align: center;
}
.hero--centered .hero__container {
  max-width: 1180px;
}
.hero--centered .hero__caption {
  margin: 0 auto 24px;
}
.hero--centered .hero__caption.p1 {
  font-size: 16px;
}
.hero--centered .hero__title {
  font-size: 36px;
  line-height: 120%;
}
@media (max-width: 768px) {
  .hero--centered .hero__title {
    font-size: 28px;
  }
}
.hero--centered.hero--side-image .hero__inner {
  flex-direction: column;
  justify-content: center;
}
.hero--centered.hero--side-image .hero__foreground {
  max-width: 550px;
}
.hero--centered.hero--side-image .hero__logo {
  margin-left: auto;
  margin-right: auto;
}
.hero--centered.hero--side-image .hero__background::after {
  content: "";
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.hero--home, .hero--capability, .hero--location {
  height: 768px;
  background-color: #333a3c;
  overflow: hidden;
  position: relative;
}
.hero--home.white-bg, .hero--capability.white-bg, .hero--location.white-bg {
  background-color: white;
}
@media (max-width: 960px) {
  .hero--home, .hero--capability, .hero--location {
    height: 664px;
  }
}
@media (max-width: 768px) {
  .hero--home, .hero--capability, .hero--location {
    height: 564px;
    padding-top: 102px;
  }
}
.hero--home .hero__background, .hero--capability .hero__background, .hero--location .hero__background {
  max-width: 1024px;
  margin: 0 auto;
}
.hero--home .hero__background svg, .hero--capability .hero__background svg, .hero--location .hero__background svg {
  position: absolute;
  right: 0;
}
.hero--home .hero__background svg path, .hero--capability .hero__background svg path, .hero--location .hero__background svg path {
  fill: #212322;
}
.hero--home .hero__background::after, .hero--capability .hero__background::after, .hero--location .hero__background::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 50vw;
  right: -50vw;
  background-color: #212322;
}
.hero--home .hero__foreground, .hero--capability .hero__foreground, .hero--location .hero__foreground {
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 1184px;
  padding: 0 80px;
  margin: 0 auto;
  height: 100%;
  color: #fff;
  z-index: 10;
}
@media (max-width: 480px) {
  .hero--home .hero__foreground, .hero--capability .hero__foreground, .hero--location .hero__foreground {
    padding: 0 40px;
  }
}
@media (max-width: 768px) {
  .hero--home .hero__foreground .hero-content-right, .hero--capability .hero__foreground .hero-content-right, .hero--location .hero__foreground .hero-content-right {
    display: none;
  }
}
.hero--home .hero__breadcrumb, .hero--capability .hero__breadcrumb, .hero--location .hero__breadcrumb {
  font-family: "Ciutadella";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: #5ae6bd;
  margin-bottom: 0px;
}
.hero--home .hero__title, .hero--capability .hero__title, .hero--location .hero__title {
  font-size: 56px;
  max-width: 368px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 57.6px */
  margin: 10px 0 !important;
}
@media (max-width: 1024px) {
  .hero--home .hero__title, .hero--capability .hero__title, .hero--location .hero__title {
    font-size: 48px;
  }
}
@media (max-width: 768px) {
  .hero--home .hero__title, .hero--capability .hero__title, .hero--location .hero__title {
    font-size: 40px;
    max-width: 420px;
  }
}
.hero--home .hero__caption, .hero--capability .hero__caption, .hero--location .hero__caption {
  color: #fff;
  max-width: 350px;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%; /* 28.8px */
  letter-spacing: 0.72px;
}
@media (max-width: 768px) {
  .hero--home .hero__caption, .hero--capability .hero__caption, .hero--location .hero__caption {
    max-width: 420px;
  }
}
.hero--home .hero-content-right, .hero--home .hero-content-left, .hero--capability .hero-content-right, .hero--capability .hero-content-left, .hero--location .hero-content-right, .hero--location .hero-content-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: 24px;
  padding: 0 !important;
}
.hero--home .hero__gallery, .hero--capability .hero__gallery, .hero--location .hero__gallery {
  height: 100%;
  position: relative;
  z-index: 10;
}
@media (max-width: 960px) {
  .hero--home .hero__gallery, .hero--capability .hero__gallery, .hero--location .hero__gallery {
    transform: scale(0.8);
    right: -40px;
  }
}
.hero--home .hero__gallery svg, .hero--home .hero__gallery-circle, .hero--capability .hero__gallery svg, .hero--capability .hero__gallery-circle, .hero--location .hero__gallery svg, .hero--location .hero__gallery-circle {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -170px;
  z-index: 10;
  width: 340px;
  height: 340px;
  max-width: 340px;
}
.hero--home .hero__gallery-circle, .hero--capability .hero__gallery-circle, .hero--location .hero__gallery-circle {
  overflow: hidden;
  border-radius: 50%;
  right: 80px;
}
@media (max-width: 1024px) {
  .hero--home .hero__gallery-circle, .hero--capability .hero__gallery-circle, .hero--location .hero__gallery-circle {
    right: 54px !important;
  }
}
.hero--home .hero__gallery-circle img, .hero--capability .hero__gallery-circle img, .hero--location .hero__gallery-circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(0px);
  transition: all 250ms ease-out, blur 200ms linear;
}
.hero--home .hero__gallery-outline, .hero--capability .hero__gallery-outline, .hero--location .hero__gallery-outline {
  right: 160px !important;
}
@media (max-width: 1024px) {
  .hero--home .hero__gallery-outline, .hero--capability .hero__gallery-outline, .hero--location .hero__gallery-outline {
    right: 108px !important;
  }
}
.hero--home .hero__gallery-logo, .hero--capability .hero__gallery-logo, .hero--location .hero__gallery-logo {
  width: 244px;
  height: 42px;
  position: absolute;
  bottom: 40px;
  right: -10px;
  display: flex;
  align-items: center;
}
@media (max-width: 960px) {
  .hero--home .hero__gallery-logo, .hero--capability .hero__gallery-logo, .hero--location .hero__gallery-logo {
    bottom: 10px;
    right: 10px;
  }
}
.hero--home .hero__gallery-logo img, .hero--capability .hero__gallery-logo img, .hero--location .hero__gallery-logo img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.hero--home .hero__gallery .hide, .hero--capability .hero__gallery .hide, .hero--location .hero__gallery .hide {
  opacity: 0;
  filter: blur(15px);
}

.hero__logo {
  max-width: 240px;
  margin-bottom: 20px;
}
.hero__logo img {
  max-height: 80px;
  width: auto;
  margin: 0 auto;
}

.hero--capability,
.hero--location {
  height: auto;
  padding-bottom: 0;
}
.hero--capability .hero__foreground,
.hero--location .hero__foreground {
  height: 542px;
}
@media (max-width: 768px) {
  .hero--capability .hero__foreground,
  .hero--location .hero__foreground {
    height: 442px;
  }
}
.hero--capability .hero__foreground .hero-content-left,
.hero--location .hero__foreground .hero-content-left {
  gap: 24px;
  margin-top: -30px;
}
.hero--capability .hero__foreground .hero-content-left .hero__title,
.hero--location .hero__foreground .hero-content-left .hero__title {
  font-size: 48px;
  margin: 0 !important;
}
.hero--capability .hero__foreground .hero-content-right,
.hero--location .hero__foreground .hero-content-right {
  max-width: 480px;
  margin-top: -30px;
}
.hero--capability .hero__foreground .hero-content-right .hero__gallery,
.hero--location .hero__foreground .hero-content-right .hero__gallery {
  height: auto;
}
.hero--capability .hero__logos,
.hero--location .hero__logos {
  background: #212322;
  width: 100%;
}
.hero--capability .hero__logos.white-bg,
.hero--location .hero__logos.white-bg {
  background-color: white;
}
.hero--capability .hero__logos .container,
.hero--location .hero__logos .container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 64px 50px;
}
@media (max-width: 1024px) {
  .hero--capability .hero__logos .container,
  .hero--location .hero__logos .container {
    justify-content: center;
    gap: 50px;
  }
  .hero--capability .hero__logos .container img:nth-child(3),
  .hero--location .hero__logos .container img:nth-child(3) {
    display: none;
  }
}
@media (max-width: 960px) {
  .hero--capability .hero__logos .container,
  .hero--location .hero__logos .container {
    gap: 40px;
  }
  .hero--capability .hero__logos .container img:nth-child(5),
  .hero--location .hero__logos .container img:nth-child(5) {
    display: none;
  }
}
@media (max-width: 576px) {
  .hero--capability .hero__logos .container img:nth-child(5),
  .hero--location .hero__logos .container img:nth-child(5) {
    display: none;
  }
}
@media (max-width: 768px) {
  .hero--capability .hero__logos .container,
  .hero--location .hero__logos .container {
    padding: 34px 30px;
  }
}
@media (max-width: 480px) {
  .hero--capability .hero__logos .container img:nth-child(4),
  .hero--location .hero__logos .container img:nth-child(4) {
    display: none;
  }
}

@media (max-width: 768px) {
  .hero--location {
    padding-top: 10px;
  }
}

.location-hero {
  margin-top: -62px;
  max-height: 682px;
  min-height: 682px;
  height: 100%;
  width: 100%;
}
.location-hero .background-image {
  position: relative;
}
.location-hero .background-image img {
  width: 100%;
  object-fit: cover;
  max-height: 682px;
  min-height: 682px;
}
.location-hero .background-image::after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.location-hero .foreground {
  position: absolute;
  top: 0;
  left: 0 !important;
  width: 100%;
  height: 100%;
  left: 100%;
  z-index: 1;
  padding: 80px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 480px) {
  .location-hero .foreground {
    padding: 60px;
  }
}
.location-hero .foreground .logo {
  transform: scale(1.15);
}
.location-hero .foreground h1, .location-hero .foreground p {
  max-width: 515px;
  color: white;
}
.location-hero .foreground h1 {
  font-size: 48px;
  padding-top: 56px;
  line-height: 58px;
  margin-bottom: 22px;
}
@media (max-width: 576px) {
  .location-hero .foreground h1 {
    font-size: 36px;
    line-height: 1.2;
    padding-top: 36px;
  }
}
.location-hero .foreground p {
  font-size: 16px;
  line-height: 26px;
  font-family: "Roboto Slab", serif;
  font-weight: 300;
}
.location-hero .foreground .awards-with-laurels {
  display: flex;
  flex-direction: row;
  gap: 45px;
  align-items: center;
  justify-content: center;
  margin-top: 90px;
  height: 101px;
}
@media (max-width: 768px) {
  .location-hero .foreground .awards-with-laurels {
    margin-top: 60px;
    gap: 15px;
  }
}
@media (max-width: 480px) {
  .location-hero .foreground .awards-with-laurels {
    gap: 0px;
  }
}
.location-hero .foreground .awards-with-laurels .laurel {
  opacity: 0.3;
  transform: scaleX(1.4) scaleY(1.4);
}
.location-hero .foreground .awards-with-laurels > :last-child {
  transform: scaleX(-1.4) scaleY(1.4);
}
.location-hero .foreground .awards {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 45px;
  width: 100%;
  max-width: 630px;
}
.location-hero .foreground .awards .award {
  width: 112px;
}
.location-hero .foreground .awards .award .title, .location-hero .foreground .awards .award .result {
  text-transform: uppercase;
}
.location-hero .foreground .awards .award .title {
  color: white;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  padding: 0;
  line-height: 1.2;
}
.location-hero .foreground .awards .award .result {
  color: #fdee9f;
  font-size: 14px;
  font-weight: 700;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 8px;
}
.location-hero .foreground .awards .award .result.winner {
  color: #5ae6bd;
}
@media (max-width: 768px) {
  .location-hero .foreground .awards .award:last-child {
    display: none;
  }
}
@media (max-width: 480px) {
  .location-hero .foreground .awards .award:first-child {
    display: none;
  }
}

.paragraph, .popup__content-text {
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.04em;
  line-height: 1.6666666667;
}

.share {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  min-width: 182px;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.16s;
  user-select: none;
}
@media (max-width: 960px) {
  .share {
    min-width: 140px;
  }
}
.share__button {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 15px;
  width: 100%;
}
.share__button-icon {
  display: inline-flex;
  font-size: 22px;
  margin-right: 14px;
}
.share__button-caption {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  padding-top: 2px;
}
.share__button-toggle-collapsed {
  display: none;
}
.share__button-toggle-open {
  display: none;
}
.share__button-toggle-icon {
  color: #c1c3c3;
  display: inline-flex;
  font-size: 20px;
  margin-left: auto;
}
.share__button-toggle-icon:hover {
  color: #212322;
}
.share__button:hover {
  opacity: 0.8;
}
.share__list {
  background: #fff;
  opacity: 0;
  padding: 7px;
  visibility: hidden;
  width: 100%;
}
.share__item-link {
  align-items: center;
  color: #5b6770;
  display: flex;
  font-size: 14px;
  padding: 10px;
  text-decoration: none;
  transition: all 0.16s;
}
.share__item-link:hover {
  background: #f2f2f2;
  color: #212322;
}
.share__item-link:hover .share__item-icon {
  color: #212322;
}
.share__item-icon {
  color: #c1c3c3;
  display: inline-flex;
  font-size: 18px;
  justify-content: center;
  margin-right: 10px;
  text-align: center;
  transition: all 0.16s;
  width: 18px;
}
.share__item-icon .icon-share-email {
  font-size: 13px;
}
.share__item-icon .icon-share-twitter {
  font-size: 15px;
}
.share.is-active {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.share.is-active .share__button-toggle-collapsed {
  display: none;
}
.share.is-active .share__button-toggle-open {
  display: inline-flex;
}
.share.is-active .share__list {
  opacity: 1;
  visibility: visible;
}

.share-buttons {
  backdrop-filter: blur(3px);
  backface-visibility: hidden;
  background-color: rgba(42, 53, 82, 0.7);
  display: block;
  height: 100vh;
  left: 0;
  opacity: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: all 0.2s;
  visibility: hidden;
  width: 100vw;
  z-index: 20;
}
@media (max-width: 480px) {
  .share-buttons {
    backdrop-filter: unset;
    background: #fff;
  }
}
@media (max-width: 768px) {
  .share-buttons {
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
  }
}
.share-buttons.is-active {
  opacity: 1;
  visibility: visible;
}
.share-buttons__container {
  align-items: center;
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  padding: 50px;
}
@media (max-width: 768px) {
  .share-buttons__container {
    padding: 30px;
  }
}
@media (max-width: 480px) {
  .share-buttons__container {
    padding: 0;
  }
}
.share-buttons__holder {
  align-self: center;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 12px 44px 0 rgba(41, 50, 71, 0.58);
  flex-basis: 423px;
  height: auto;
  max-width: 423px;
  width: 100%;
}
@media (max-width: 480px) {
  .share-buttons__holder {
    align-self: flex-start;
    border-radius: 0;
    box-shadow: none;
    flex-basis: 100%;
    max-width: unset;
  }
}
.share-buttons__inner {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
@media (max-width: 480px) {
  .share-buttons__inner {
    box-shadow: none;
  }
}
.share-buttons__header {
  align-items: center;
  background: #fff;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 2px 8px 0 rgba(51, 58, 60, 0.2);
  display: flex;
  justify-content: space-between;
  padding: 26px 30px;
}
@media (max-width: 480px) {
  .share-buttons__header {
    border-radius: 0;
  }
}
.share-buttons__title {
  color: #212322;
  font-size: 36px;
  font-weight: 600;
}
.share-buttons .icon-close {
  color: #333a3c;
  cursor: pointer;
  font-size: 32px;
  transition: color 0.2s;
}
.share-buttons .icon-close:hover {
  color: #212322;
}
.share-buttons__content {
  padding: 0 30px;
}
.share-buttons__item {
  border-bottom: 1px solid rgba(33, 35, 34, 0.3);
}
.share-buttons__item:last-child {
  border-bottom: none;
}
.share-buttons__link {
  align-items: center;
  display: flex;
  padding: 26px 0;
  text-decoration: none;
}
.share-buttons__link-icon {
  align-items: center;
  color: #333a3c;
  display: inline-flex;
  font-size: 25px;
  justify-content: center;
  transition: color 0.16s;
  width: 25px;
}
.share-buttons__link-caption {
  color: #212322;
  font-size: 20px;
  margin-left: 20px;
}
.share-buttons__link .icon-share-email {
  font-size: 18px;
}
.share-buttons__link .icon-share-twitter {
  font-size: 22px;
}
.share-buttons__link .icon-share-linkedin {
  font-size: 24px;
}
.share-buttons__link:hover .share-buttons__link-icon {
  color: #212322;
}

.content-text, .title__caption {
  color: #333a3c;
}
.content-text--limited {
  max-width: 600px;
}
.content-text--arrows-indent {
  padding-right: 100px;
}
.content-text p, .title__caption p {
  margin-bottom: 1.4em;
}
@media (max-width: 768px) {
  .content-text p, .title__caption p {
    margin-bottom: 1em;
  }
}
.content-text p:last-child, .title__caption p:last-child {
  margin-bottom: 0;
}
.content-text p:last-child img, .title__caption p:last-child img {
  margin-bottom: 0;
}

.content-heading {
  margin-bottom: 21px;
}
@media (max-width: 960px) {
  .content-heading {
    margin-bottom: 15px;
  }
}
@media (max-width: 480px) {
  .content-heading {
    margin-bottom: 10px;
  }
}
.content-heading--limited {
  max-width: 380px;
}
@media (max-width: 1024px) {
  .content-heading--limited {
    max-width: 340px;
  }
}
@media (max-width: 768px) {
  .content-heading--limited {
    max-width: unset;
  }
}
.content-heading--small {
  font-size: 24px;
  margin-bottom: 20px;
}

.container-condensed {
  padding-left: 40px;
  padding-right: 40px;
}
@media (max-width: 1024px) {
  .container-condensed {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 768px) {
  .container-condensed {
    padding-left: 0;
    padding-right: 0;
  }
}

.default-template {
  background-color: #f9f9f9;
  padding-bottom: 140px;
  padding-top: 80px;
}
.default-template__container {
  max-width: 884px;
}
@media (max-width: 1024px) {
  .default-template__container {
    max-width: 684px;
  }
}
.default-template__container > *:first-child {
  margin-top: 0;
  padding-top: 0;
}

.navigation-arrows {
  bottom: 0;
  display: flex;
  padding-bottom: 10px;
  position: absolute;
  right: 0;
}
@media (max-width: 576px) {
  .navigation-arrows {
    display: none;
  }
}
.navigation-arrows__button {
  align-items: center;
  color: #5b6770;
  cursor: pointer;
  display: flex;
  font-size: 9px;
  height: 14px;
  justify-content: center;
  text-decoration: none;
  width: 14px;
}
@media (max-width: 1024px) {
  .navigation-arrows__button {
    font-size: 7px;
  }
}
@media (max-width: 768px) {
  .navigation-arrows__button {
    font-size: 9px;
  }
}
.navigation-arrows__button.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.navigation-arrows__button:hover {
  color: #0e7a73;
}
.navigation-arrows__button:hover::before {
  color: #20b59a;
}
.navigation-arrows__button-next {
  margin-left: 12px;
}

.full-width-image {
  margin-bottom: 100px;
}
@media (max-width: 960px) {
  .full-width-image {
    margin-bottom: 80px;
  }
}
@media (max-width: 768px) {
  .full-width-image {
    margin-bottom: 50px;
  }
}
.full-width-image__holder img {
  height: auto;
  width: 100%;
}
.full-width-image--flexible {
  margin-bottom: 70px;
  padding-top: 35px;
}
@media (max-width: 960px) {
  .full-width-image--flexible {
    margin-bottom: 50px;
    padding-top: 20px;
  }
}
@media (max-width: 768px) {
  .full-width-image--flexible {
    margin-bottom: 30px;
    padding-top: 15px;
  }
}
.full-width-image--bg {
  position: relative;
}
.full-width-image--bg::before {
  background-color: #fafafa;
  content: "";
  height: 70%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.full-width-image--bg .full-width-image__holder {
  position: relative;
  z-index: 1;
}
.full-width-image--no-indent {
  padding-top: 0;
}

.image-max img {
  height: auto;
  width: 100%;
}
.image-max--goal {
  padding-top: 142px;
  position: relative;
}
@media (max-width: 768px) {
  .image-max--goal {
    padding-top: 80px;
  }
}
@media (max-width: 480px) {
  .image-max--goal {
    padding-top: 40px;
  }
}
.image-max--goal::before {
  background-color: #f9f9f9;
  bottom: 0;
  content: "";
  height: 60%;
  left: 0;
  position: absolute;
  width: 100%;
}
.image-max--goal .container {
  position: relative;
}

.image_text {
  margin-bottom: 100px;
}
@media (max-width: 960px) {
  .image_text {
    margin-bottom: 80px;
  }
}
@media (max-width: 768px) {
  .image_text {
    margin-bottom: 50px;
  }
}
@media (max-width: 480px) {
  .image_text {
    margin-bottom: 30px;
  }
}
.image_text__holder {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .image_text__holder {
    flex-direction: column-reverse;
  }
}
.image_text__image {
  flex-basis: 52%;
  flex-shrink: 0;
  width: 52%;
}
@media (max-width: 960px) {
  .image_text__image {
    flex-basis: 40%;
    width: 40%;
  }
}
@media (max-width: 768px) {
  .image_text__image {
    flex-basis: 100%;
    width: 100%;
  }
}
.image_text__image img {
  height: auto;
  width: 100%;
}
.image_text__content {
  padding-left: 60px;
}
@media (max-width: 768px) {
  .image_text__content {
    margin-bottom: 40px;
    padding-left: 0;
  }
}
@media (max-width: 480px) {
  .image_text__content {
    margin-bottom: 30px;
  }
}
.image_text__content .content-text p:last-child, .image_text__content .title__caption p:last-child {
  margin-bottom: 0;
}

.title {
  margin-bottom: 30px;
  padding-bottom: 17px;
  position: relative;
}
@media (max-width: 960px) {
  .title {
    margin-bottom: 25px;
  }
}
@media (max-width: 480px) {
  .title {
    margin-bottom: 20px;
  }
}
.title__main-title {
  color: #212322;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 1.25;
}
@media (max-width: 1024px) {
  .title__main-title {
    font-size: 40px;
  }
}
@media (max-width: 960px) {
  .title__main-title {
    font-size: 30px;
  }
}
@media (max-width: 480px) {
  .title__main-title {
    font-size: 24px;
  }
}
.title__icon {
  height: 55px;
  margin-bottom: 30px;
  width: 55px;
}
.title__icon img,
.title__icon svg {
  display: block;
  height: 100%;
  object-fit: contain;
  width: 100%;
}
.title__top-title {
  color: #0e7a73;
  display: block;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.75px;
  margin-bottom: 5px;
}
@media (max-width: 1024px) {
  .title__top-title {
    font-size: 22px;
  }
}
@media (max-width: 960px) {
  .title__top-title {
    font-size: 20px;
  }
}
@media (max-width: 480px) {
  .title__top-title {
    font-size: 18px;
  }
}
.title__bottom-title {
  display: block;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 1.67px;
  margin-top: 10px;
}
.title__caption {
  display: block;
  margin-top: 5px;
}
.title--decorate {
  position: relative;
}
.title--decorate::after {
  background: #0e7a73;
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  top: calc(100% + 8px);
  width: 36px;
}

.list--decorate ul {
  color: #333a3c;
  font-size: 16px;
  margin-bottom: 15px;
}
.list--decorate ul li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}
.list--decorate ul li::before {
  content: "\e90d";
  font-family: icomoon !important;
  font-size: 6px;
  left: 0;
  position: absolute;
  top: 8px;
}

.team-avatars {
  display: flex;
}
.team-avatars__item {
  margin-right: -10px;
  position: relative;
  z-index: 1;
}
.team-avatars__item:nth-child(1) {
  z-index: 9;
}
.team-avatars__item:nth-child(2) {
  z-index: 8;
}
.team-avatars__item:nth-child(3) {
  z-index: 7;
}
.team-avatars__item:nth-child(4) {
  z-index: 6;
}
.team-avatars__item:nth-child(5) {
  z-index: 5;
}
.team-avatars__item:nth-child(6) {
  z-index: 4;
}
.team-avatars__item:nth-child(7) {
  z-index: 3;
}
.team-avatars__item:nth-child(8) {
  z-index: 2;
}
.team-avatars__item:nth-child(9) {
  z-index: 1;
}
.team-avatars__item:nth-child(10) {
  z-index: 0;
}
.team-avatars__item:hover {
  z-index: 11;
}
.team-avatars__photo {
  border: 2px solid #fff;
  border-radius: 50%;
  height: 37px;
  overflow: hidden;
  width: 37px;
}
.team-avatars__photo img {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.hide-desktop {
  display: none;
}
@media (max-width: 768px) {
  .hide-desktop {
    display: block;
  }
}

@media (max-width: 768px) {
  .hide-mobile {
    display: none;
  }
}

.step-image {
  align-items: center;
  display: flex;
}
@media (max-width: 960px) {
  .step-image {
    flex-direction: column;
  }
}
.step-image .steps {
  flex-basis: 338px;
  flex-shrink: 0;
}
@media (max-width: 960px) {
  .step-image .steps {
    margin-bottom: 80px;
  }
}
@media (max-width: 768px) {
  .step-image .steps {
    margin-bottom: 50px;
  }
}
.step-image__image {
  padding-left: 100px;
}
@media (max-width: 1024px) {
  .step-image__image {
    padding-left: 50px;
  }
}
@media (max-width: 960px) {
  .step-image__image {
    padding-left: 0;
  }
}
.step-image__image img {
  height: auto;
  width: 100%;
}
@media (max-width: 960px) {
  .step-image__image img {
    max-width: 100%;
    width: auto;
  }
}

.carousel-nav {
  align-items: center;
  color: #212322;
  display: flex;
  font-size: 16px;
  margin-left: -10px;
  margin-right: -10px;
}
@media (max-width: 1024px) {
  .carousel-nav {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .carousel-nav {
    font-size: 16px;
  }
}
.carousel-nav .swiper-button-prev,
.carousel-nav .swiper-button-next {
  color: #5b6770;
  font-size: 9px;
  height: auto;
  margin-top: -2px;
  padding: 10px;
  position: static;
  width: auto;
}
@media (max-width: 1024px) {
  .carousel-nav .swiper-button-prev,
  .carousel-nav .swiper-button-next {
    font-size: 7px;
  }
}
@media (max-width: 768px) {
  .carousel-nav .swiper-button-prev,
  .carousel-nav .swiper-button-next {
    font-size: 9px;
  }
}
.carousel-nav .swiper-button-prev::after,
.carousel-nav .swiper-button-next::after {
  display: none;
}
.carousel-nav .swiper-button-prev:hover,
.carousel-nav .swiper-button-next:hover {
  color: #212322;
}
.carousel-nav .swiper-button-prev.swiper-button-disabled,
.carousel-nav .swiper-button-next.swiper-button-disabled {
  color: #c1c3c3;
  opacity: 1;
}
.carousel-nav .swiper-button-prev {
  margin-right: 15px;
}
.carousel-nav .swiper-button-next {
  margin-left: 15px;
}
.carousel-nav--green .swiper-button-prev,
.carousel-nav--green .swiper-button-next {
  color: #20b59a;
}
.carousel-nav--green .swiper-button-prev:hover,
.carousel-nav--green .swiper-button-next:hover {
  color: #0e7a73;
}

.nav-counter {
  display: flex;
  font-weight: 500;
}
.nav-counter > span {
  min-width: 9px;
  text-align: center;
}
.nav-counter-delimiter {
  margin-left: 3px;
  margin-right: 3px;
}

.link {
  color: #333a3c;
}
.link-caption {
  font-family: "Roboto Slab", serif;
  font-size: 14px;
  letter-spacing: 0.56px;
  line-height: 1.6666666667;
  text-decoration: underline;
  font-weight: 400;
}
@media (max-width: 1280px) {
  .link-caption {
    font-size: 14px;
  }
}
@media (max-width: 1024px) {
  .link-caption {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .link-caption {
    font-size: 14px;
  }
}
@media (max-width: 480px) {
  .link-caption {
    font-size: 14px;
  }
}
.link--icon {
  align-items: center;
  display: inline-flex;
  text-decoration: none;
}
.link--icon [class*=" icon-"],
.link--icon [class^=icon-] {
  font-size: 7px;
}
.link--arrow [class*=" icon-"],
.link--arrow [class^=icon-] {
  margin-left: 10px;
}

.quick-links__holder {
  padding-bottom: 160px;
}
@media (max-width: 768px) {
  .quick-links__holder {
    padding-bottom: 60px;
  }
}
@media (max-width: 480px) {
  .quick-links__holder {
    padding-bottom: 40px;
  }
}
.quick-links__inner {
  display: flex;
}
@media (max-width: 768px) {
  .quick-links__inner {
    flex-direction: column;
    margin-top: -28px;
  }
}
.quick-links__item {
  flex-basis: 50%;
  margin-top: -28px;
  position: relative;
}
@media (max-width: 768px) {
  .quick-links__item {
    flex-basis: 100%;
    margin-bottom: 20px;
    margin-top: 0;
  }
}
.quick-links__link {
  display: block;
  position: relative;
}
.quick-links__link::after {
  background: #5ae6bd;
  bottom: 0;
  content: "";
  height: 6px;
  left: 0;
  opacity: 0;
  position: absolute;
  transition: opacity 0.2s;
  width: 100%;
}
.quick-links__link:hover::after {
  opacity: 1;
}
.quick-links__link:hover .quick-links__title {
  text-decoration: underline;
}
.quick-links__background {
  display: block;
  height: 0;
  padding-bottom: 65%;
  position: relative;
  width: 100%;
}
.quick-links__background img {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.quick-links__background::after {
  background: #212322;
  background-blend-mode: multiply;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
}
.quick-links__foreground {
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  left: 0;
  padding: 20px 25px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.quick-links__tag {
  font-weight: 500;
  margin-bottom: 11px;
  text-transform: uppercase;
}
.user-hero {
  margin-bottom: 97px;
}
@media (max-width: 768px) {
  .user-hero {
    margin-bottom: 30px;
  }
}
.user-hero--post-single {
  margin-bottom: 180px;
}
@media (max-width: 768px) {
  .user-hero--post-single {
    margin-bottom: 110px;
  }
}
@media (max-width: 480px) {
  .user-hero--post-single {
    margin-bottom: 80px;
  }
}
.user-hero__inner {
  background: #f9f9f9;
  box-shadow: 4px 4px 24px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  min-height: 412px;
  position: relative;
}
@media (max-width: 768px) {
  .user-hero__inner {
    background: transparent;
    box-shadow: unset;
    flex-direction: column;
    min-height: unset;
  }
}
.user-hero__background {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 45%;
}
@media (max-width: 768px) {
  .user-hero__background {
    margin-bottom: 30px;
    position: static;
    width: 100%;
  }
}
.user-hero__background img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
@media (max-width: 768px) {
  .user-hero__background img {
    height: auto;
    max-height: none;
  }
}
.user-hero__background svg {
  display: block;
  height: 100%;
  left: 0;
  margin-left: -1px;
  position: absolute;
  top: 0;
  width: auto;
}
@media (max-width: 768px) {
  .user-hero__background svg {
    display: none;
  }
}
.user-hero__content {
  height: auto;
  position: relative;
  width: 55%;
}
@media (max-width: 768px) {
  .user-hero__content {
    width: auto;
  }
}
.user-hero__content-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 40px 40px 60px;
}
@media (max-width: 768px) {
  .user-hero__content-inner {
    padding: 0;
  }
}
.user-hero__action {
  padding-top: 20px;
}
.user-hero__heading {
  margin-bottom: 30px;
}
.user-hero__description {
  color: #333a3c;
}
@media (max-width: 768px) {
  .user-hero__description {
    max-width: unset;
  }
}
.user-hero__description a {
  color: #212322;
  font-weight: 400;
}
.user-hero__description a:hover {
  color: #20b59a;
}
.user-hero__description p {
  margin-bottom: 1.3em;
}
.user-hero__description p:last-child {
  margin-bottom: 0;
}

.article-hero {
  padding-bottom: 73px;
  padding-top: 54px;
}
@media (max-width: 768px) {
  .article-hero {
    padding-bottom: 30px;
    padding-top: 30px;
  }
}
.article-hero__inner {
  background: #f9f9f9;
  display: flex;
  position: relative;
}
@media (max-width: 960px) {
  .article-hero__inner {
    background: transparent;
    box-shadow: unset;
    min-height: unset;
    flex-direction: column-reverse;
  }
}
.article-hero__background {
  height: 100%;
  min-width: 45%;
}
@media (max-width: 960px) {
  .article-hero__background {
    position: static;
    width: 100%;
    height: auto;
    max-height: 480px;
  }
}
.article-hero__background img {
  height: 100%;
  min-height: 440px;
  max-height: 440px;
  object-fit: cover;
  object-position: center;
  width: auto;
}
@media (max-width: 960px) {
  .article-hero__background img {
    height: auto;
    width: 100%;
    max-height: 480px;
    min-height: unset;
  }
}
.article-hero__content {
  height: auto;
  overflow: hidden;
  position: relative;
  background-color: #333a3c;
  min-width: 45%;
}
@media (max-width: 960px) {
  .article-hero__content {
    background-color: #333a3c;
    min-width: unset;
    width: auto;
  }
}
.article-hero__content-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px 40px;
  position: relative;
  height: 100%;
}
.article-hero__content-inner .h1 {
  font-size: 48px;
}
@media (max-width: 768px) {
  .article-hero__content-inner {
    padding: 24px 26px;
  }
}
.article-hero__content > svg {
  height: 100%;
  position: absolute;
  right: 0;
}
@media (max-width: 768px) {
  .article-hero__content > svg {
    display: none;
  }
}
.article-hero__tags {
  margin-bottom: 62px;
}
@media (max-width: 960px) {
  .article-hero__tags {
    margin-bottom: 30px;
  }
}
.article-hero__heading {
  color: #fff;
  margin-bottom: 60px;
}
@media (max-width: 960px) {
  .article-hero__heading {
    margin-bottom: 30px;
    font-size: 36px !important;
  }
}
.article-hero__heading a {
  color: #fff;
  text-decoration-line: none;
  text-decoration-thickness: 4px;
}
.article-hero__heading a:hover {
  text-decoration-line: underline;
}
.article-hero__author {
  margin-top: auto;
}
.article-hero__featured {
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  color: #212322;
  padding: 8px 16px;
  margin: 10px;
  text-transform: uppercase;
  font-size: 18px;
  user-select: none;
  cursor: pointer;
}
.article-hero--single {
  padding-top: 56px;
  position: relative;
  z-index: 3;
}
@media (max-width: 960px) {
  .article-hero--single {
    padding-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .article-hero--single {
    padding-top: 30px;
  }
}
.article-hero--single .article-hero__inner {
  background: transparent;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}
@media (max-width: 960px) {
  .article-hero--single .article-hero__inner {
    min-height: unset;
    flex-wrap: wrap;
    flex-direction: column;
  }
}
.article-hero--single .article-hero__background {
  min-width: unset;
}
@media (max-width: 960px) {
  .article-hero--single .article-hero__background {
    width: 100%;
  }
}
.article-hero--single .article-hero__background img {
  object-position: center;
}
@media (max-width: 1024px) {
  .article-hero--single .article-hero__background img {
    max-width: 400px;
  }
}
@media (max-width: 960px) {
  .article-hero--single .article-hero__background img {
    max-height: 480px;
    min-height: unset;
    max-width: unset;
    width: 100%;
  }
}
.article-hero--single .article-hero__content {
  background-color: #fff;
  min-width: unset;
  overflow: visible;
  width: 50%;
}
@media (max-width: 960px) {
  .article-hero--single .article-hero__content {
    background: transparent;
    width: 100%;
  }
}
.article-hero--single .article-hero__content-inner {
  padding: 20px 20px 20px 40px;
  width: auto;
}
@media (max-width: 960px) {
  .article-hero--single .article-hero__content-inner {
    padding: 24px 0px;
  }
}
.article-hero--single .article-hero__content .author__details {
  color: #212322;
}
.article-hero--single .article-hero__content .author__details a {
  color: #212322;
}
.article-hero--single .article-hero__tags {
  margin-bottom: 36px;
}
@media (max-width: 960px) {
  .article-hero--single .article-hero__tags {
    margin-bottom: 20px;
  }
}
.article-hero--single .article-hero__heading {
  color: #212322;
  margin-bottom: 40px;
  padding-right: 40px;
  font-size: 48px;
  line-height: 120%;
}
@media (max-width: 960px) {
  .article-hero--single .article-hero__heading {
    padding-right: 0;
    margin-bottom: 0px;
    font-size: 36px;
  }
}
.article-hero--single .article-hero__group {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-right: 130px;
  position: relative;
  margin-top: 32px;
}
@media (max-width: 960px) {
  .article-hero--single .article-hero__group {
    margin-top: 20px;
  }
}

.section-backgrounds {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.section-backgrounds__circle-large {
  background-image: url("../img/circle-decoration-3.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 100%;
  max-width: 1004px;
  position: absolute;
  right: 0;
  top: 50px;
  width: 80%;
}
.section-backgrounds__circle-big {
  background-image: url("../img/circle-decoration-1.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 100%;
  left: 0;
  max-width: 840px;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 58%;
}
.section-backgrounds__circle-small {
  background-image: url("../img/circle-decoration-2.svg");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;
  bottom: 0;
  height: 100%;
  max-width: 317px;
  position: absolute;
  right: 0;
  width: 31%;
}
.section-backgrounds__wt1 {
  background-image: url("../img/wt-1.svg");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;
  bottom: 0;
  height: 100%;
  max-width: 716px;
  opacity: 0.2;
  position: absolute;
  right: 0;
  width: 70%;
}
.section-backgrounds__wt2 {
  background-image: url("../img/wt-2.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 100%;
  left: 0;
  max-width: 381px;
  opacity: 0.1;
  position: absolute;
  top: 48px;
  width: 57%;
}

.content-gallery__inner {
  display: flex;
  margin: -10px;
}
@media (max-width: 480px) {
  .content-gallery__inner {
    margin: -5px;
  }
}
.content-gallery__background {
  padding: 10px;
}
@media (max-width: 480px) {
  .content-gallery__background {
    padding: 5px;
  }
}
.content-gallery__background img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.content-gallery__background-holder {
  height: 100%;
}
.content-gallery__background-holder-inner {
  height: 100%;
}
.content-gallery__background--left {
  flex-basis: 62%;
  flex-grow: 0;
  flex-shrink: 0;
}
@media (max-width: 768px) {
  .content-gallery__background--left {
    flex-basis: 50%;
  }
}
.content-gallery__background--right {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.content-gallery__background--right .content-gallery__background-holder {
  display: flex;
  flex-direction: column;
  margin: -10px;
}
@media (max-width: 480px) {
  .content-gallery__background--right .content-gallery__background-holder {
    margin: -5px;
  }
}
.content-gallery__background--right .content-gallery__background-holder-inner {
  padding: 10px;
}
@media (max-width: 480px) {
  .content-gallery__background--right .content-gallery__background-holder-inner {
    padding: 5px;
  }
}
.content-gallery__background--right .content-gallery__background-holder-inner:first-child {
  flex-basis: 42%;
}
.content-gallery__background--right .content-gallery__background-holder-inner:last-child {
  flex-grow: 1;
}

.tippy-box {
  background-color: #212322;
  border-radius: 26px;
}

.tippy-content {
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  line-height: 1.375;
  max-width: 280px;
  padding: 10px 26px;
  text-align: center;
}
@media (max-width: 1280px) {
  .tippy-content {
    font-size: 14px;
    padding: 8px 22px;
  }
}
@media (max-width: 1024px) {
  .tippy-content {
    font-size: 12px;
    padding: 6px 20px;
  }
}

#gtx-trans {
  display: none !important;
}

.dropdown__button {
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 8px 10px;
  touch-action: manipulation;
  width: 130px;
}
@media (max-width: 1024px) {
  .dropdown__button {
    width: 110px;
  }
}
.dropdown__button-icon {
  color: #333a3c;
  display: flex;
  font-size: 6px;
  transition: all 0.16s;
}
@media (max-width: 1024px) {
  .dropdown__button-icon {
    font-size: 5px;
  }
}
.dropdown__button-caption {
  color: #575c5e;
  display: block;
  font-size: 14px;
  overflow: hidden;
  padding-right: 10px;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
}
.dropdown__button:hover {
  background-color: #f2f2f2;
}
.dropdown__button.is-active {
  background-color: #333a3c;
}
.dropdown__button.is-active .dropdown__button-icon {
  color: #5ae6bd;
  transform: rotate(180deg);
}
.dropdown__button.is-active .dropdown__button-caption {
  color: #fff;
}
.dropdown__viewport {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 1000;
}
.dropdown__content {
  pointer-events: auto;
  background: #fff;
  box-shadow: 0 0 18px 3px rgba(0, 0, 0, 0.1);
  margin-top: 15px;
  max-width: fit-content;
  opacity: 0;
  padding: 20px 25px;
  position: absolute;
  right: 0;
  transform: translateY(10px);
  transition: all 0.16s;
  visibility: hidden;
  width: 100%;
  z-index: 1;
}
@media (max-width: 1024px) {
  .dropdown__content {
    max-width: 630px;
  }
}
.dropdown__content.is-active {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}
.dropdown__content-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.dropdown__content-clear {
  cursor: pointer;
}
.dropdown__content-clear:hover {
  color: #0e7a73;
}
.dropdown__content-clear.is-hidden {
  opacity: 0;
  visibility: hidden;
}
.dropdown__content-title {
  color: #212322;
}
.dropdown__content-list {
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
}
.dropdown__content-item {
  flex-basis: 16.6666%;
  flex-shrink: 0;
  padding: 8px;
}
@media (max-width: 768px) {
  .dropdown__content-item {
    flex-basis: 20%;
  }
}
@media (max-width: 576px) {
  .dropdown__content-item {
    flex-basis: 25%;
  }
}
@media (max-width: 480px) {
  .dropdown__content-item {
    flex-basis: 33.3333%;
  }
}
.dropdown__content-item input {
  position: absolute;
  visibility: hidden;
}
.dropdown__content-item input:checked + label {
  background-color: #fafafa;
}
.dropdown__content-item input:checked + label path,
.dropdown__content-item input:checked + label circle,
.dropdown__content-item input:checked + label line,
.dropdown__content-item input:checked + label rect {
  stroke: #0e7a73;
}
.dropdown__content-item input:checked + label ellipse {
  fill: #0e7a73;
}
.dropdown__content-item-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-bottom: 10px;
  padding: 15px 0 5px;
  text-align: center;
  transition: background-color 0.16s;
  width: 100%;
}
.dropdown__content-item-button:hover {
  background-color: #fafafa;
}
.dropdown__content-item-icon {
  height: 24px;
  margin-bottom: 12px;
  width: 24px;
}
.dropdown__content-item-icon img {
  height: 100%;
  object-fit: contain;
  width: 100%;
}
.dropdown__content-item-title {
  align-items: center;
  color: #333a3c;
  display: flex;
  font-size: 16px;
  line-height: 1.1666666667;
  min-height: 28px;
  padding: 0 5px;
}
@media (max-width: 1280px) {
  .dropdown__content-item-title {
    font-size: 14px;
  }
}
@media (max-width: 1024px) {
  .dropdown__content-item-title {
    font-size: 12px;
    padding: 0;
  }
}
.dropdown__item {
  padding: 10px;
  margin: 0 -13px;
}

.quote-card {
  color: #fff;
  display: flex;
  margin-bottom: 44px;
  padding-left: 22px;
  padding-right: 22px;
  width: 50%;
}
@media (max-width: 768px) {
  .quote-card {
    margin-bottom: 22px;
    padding-left: 11px;
    padding-right: 11px;
  }
}
@media (max-width: 576px) {
  .quote-card {
    width: 100%;
  }
}
.quote-card__inner {
  height: 100%;
  width: 100%;
}
.quote-card__title {
  color: #5ae6bd;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1;
  margin-bottom: 20px;
}
.quote-card--success-story .quote-card__inner {
  background-color: #333a3c;
  background-image: url("../img/success-story-curve.svg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 30px 75px 40px;
}
@media (max-width: 768px) {
  .quote-card--success-story .quote-card__inner {
    padding: 30px 25px 50px;
  }
}
.quote-card--success-story .quote-card__logo {
  margin-bottom: 30px;
  opacity: 0.8;
  text-align: right;
}
.quote-card--success-story .quote-card__logo img {
  display: inline-block;
  max-width: 112px;
}
.quote-card--success-story .quote-card__content {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1.2666666667;
  margin-bottom: 30px;
  max-width: 286px;
}
@media (max-width: 768px) {
  .quote-card--success-story .quote-card__content {
    font-size: 26px;
  }
}
.quote-card--quote .quote-card__inner {
  background-color: #0e7a73;
  padding: 30px 36px 48px 40px;
}
@media (max-width: 768px) {
  .quote-card--quote .quote-card__inner {
    padding: 30px 25px 50px;
  }
}
.quote-card--quote .quote-card__inner ::selection {
  background: #0b635d;
}
.quote-card--quote .quote-card__logo {
  margin-bottom: 33px;
  text-align: right;
}
.quote-card--quote .quote-card__logo img {
  display: inline-block;
  max-width: 106px;
}
.quote-card--quote .quote-card__icon {
  color: #5ae6bd;
  display: flex;
  font-size: 42px;
  margin-bottom: 20px;
}
.quote-card--quote .quote-card__content {
  color: #fff;
  margin-bottom: 6px;
}
.quote-card--quote .quote-card__caption {
  color: #f9f9f9;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.4545454545;
}
@media (max-width: 1280px) {
  .quote-card--quote .quote-card__caption {
    font-size: 17px;
  }
}
@media (max-width: 1024px) {
  .quote-card--quote .quote-card__caption {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .quote-card--quote .quote-card__caption {
    font-size: 15px;
  }
}
@media (max-width: 480px) {
  .quote-card--quote .quote-card__caption {
    font-size: 14px;
  }
}

.video-link {
  align-items: center;
  color: #20b59a;
  display: inline-flex;
  line-height: 1;
  text-decoration: none;
}
.video-link:hover {
  color: #0e7a73;
}
.video-link:hover .video-link__title {
  border-color: #0e7a73;
}
.video-link:hover .video-link__title:before {
  background-color: #0e7a73;
  animation: leftToRight 0.6s ease forwards;
}
.video-link__icon {
  font-size: 40px;
  transition: all 0.16s;
}
.video-link__title {
  display: flex;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.02em;
  line-height: 18px;
  margin-left: 20px;
  margin-top: -3px;
  padding-bottom: 0;
  text-decoration-line: none;
  text-transform: uppercase;
  transition: all 0.16s;
  position: relative;
}
.video-link__title:before {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: -2px;
  background-color: #20b59a;
  pointer-events: none;
  transition: background-color 0.16s;
}

.avatar {
  border-radius: 50%;
  overflow: hidden;
  width: 38px;
  height: 38px;
  padding: 0;
}
.avatar img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

@keyframes fade {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  to {
    opacity: 1;
  }
}
@keyframes placeload {
  0% {
    background-position: -600px 0;
  }
  100% {
    background-position: 600px 0;
  }
}
.loading-animation, .articles-card--preloader .articles-card__placeholder, .projects__item--preloader .projects__holder {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeload;
  animation-timing-function: linear;
  background-image: linear-gradient(to right, #fafafa 0%, #f0f0f0 18%, #fafafa 33%) !important;
  background-size: 1200px 104px !important;
}

.projects {
  background-color: #fff;
  padding-bottom: 78px;
  padding-top: 0;
}
@media (max-width: 768px) {
  .projects {
    padding-bottom: 40px;
  }
}
@media (max-width: 480px) {
  .projects.projects--blurred .projects__item {
    filter: unset;
  }
}
.projects__header {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  position: relative;
}
.projects__count {
  padding-top: 11px;
  position: relative;
  top: 10px;
}
@media (max-width: 768px) {
  .projects__count {
    top: 0;
  }
}
.projects__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -22px;
  margin-right: -22px;
}
@media (max-width: 768px) {
  .projects__list {
    margin-left: -11px;
    margin-right: -11px;
  }
}
@media (max-width: 576px) {
  .projects__list {
    margin-left: 0;
    margin-right: 0;
  }
}
.projects__list--preloader {
  display: none;
}
.projects__list--preloader.is-visible {
  display: flex;
}
.projects__list--masonry {
  overflow: hidden;
}
.projects__list--masonry .projects__item:nth-child(2) {
  margin-top: 44px;
}
@media (max-width: 768px) {
  .projects__list--masonry .projects__item:nth-child(2) {
    margin-top: 0;
  }
}
.projects__list.is-hidden {
  display: none;
}
.projects__item {
  backface-visibility: hidden;
  display: block;
  flex-basis: calc(50% - 44px);
  margin-bottom: 44px;
  margin-left: 22px;
  margin-right: 22px;
  position: relative;
  transform: translate3d(0, 0, 0);
  width: calc(50% - 44px);
}
.projects__item.hidden {
  display: none;
}
@media (max-width: 768px) {
  .projects__item {
    flex-basis: calc(50% - 22px);
    margin-bottom: 22px;
    margin-left: 11px;
    margin-right: 11px;
    width: calc(50% - 22px);
  }
}
@media (max-width: 576px) {
  .projects__item {
    flex-basis: 100%;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
.projects__item-holder {
  align-items: flex-start;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 30px 25px;
  position: absolute;
  transition: all 0.2s;
  width: 100%;
}
.projects__item-title {
  color: #fff;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1.2222222222;
  margin-bottom: 5px;
}
@media (max-width: 1280px) {
  .projects__item-title {
    font-size: 20px;
  }
}
@media (max-width: 1024px) {
  .projects__item-title {
    font-size: 18px;
  }
}
.projects__item-title span {
  color: rgba(64, 54, 78, 0.5);
  font-size: 14px;
}
.projects__item-title a {
  font-size: 14px;
}
.projects__item-caption {
  color: #f2f2f2;
  margin-bottom: 16px;
}
.projects__item .background-gallery {
  height: 100%;
}
.projects__item .background-gallery .slick-list,
.projects__item .background-gallery .slick-track,
.projects__item .background-gallery .slick-slide,
.projects__item .background-gallery .slick-slide > div {
  height: 100%;
}
.projects__item .background-gallery .slick-slide {
  position: relative;
}
.projects__item .background-gallery__item {
  height: 100%;
}
.projects__item .background-gallery__item img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.projects__item .background-overlay {
  align-items: center;
  background: #333a3c;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  mix-blend-mode: multiply;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: 0.16s;
  width: 100%;
}
.projects__item-member-list {
  display: flex;
  opacity: 0;
  padding-right: 10px;
  transition: 0.16s;
}
.projects__item-member-list--technologies {
  opacity: 1;
}
.projects__item-member-list--technologies .projects__item-member-item {
  margin-right: -5px;
}
.projects__item-member-list--technologies .projects__item-member-photo {
  height: 37px;
  width: 37px;
}
.projects__item-member-item {
  margin-right: -10px;
  position: relative;
  z-index: 1;
}
.projects__item-member-item:nth-child(1) {
  z-index: 9;
}
.projects__item-member-item:nth-child(2) {
  z-index: 8;
}
.projects__item-member-item:nth-child(3) {
  z-index: 7;
}
.projects__item-member-item:nth-child(4) {
  z-index: 6;
}
.projects__item-member-item:nth-child(5) {
  z-index: 5;
}
.projects__item-member-item:nth-child(6) {
  z-index: 4;
}
.projects__item-member-item:nth-child(7) {
  z-index: 3;
}
.projects__item-member-item:nth-child(8) {
  z-index: 2;
}
.projects__item-member-item:nth-child(9) {
  z-index: 1;
}
.projects__item-member-item:nth-child(10) {
  z-index: 0;
}
.projects__item-member-item:hover {
  z-index: 11;
}
.projects__item-member-photo {
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  overflow: hidden;
  width: 50px;
}
.projects__item-member-photo img {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.projects__item-link {
  background-color: #fff;
  color: #212322;
  opacity: 0;
  transition: 0.16s;
}
.projects__item-link:hover {
  background-color: #fff;
  color: #212322;
}
.projects__item-info {
  opacity: 0;
  transition: 0.16s;
}
.projects__item:hover .projects__item-holder, .projects__item.hover .projects__item-holder {
  border-color: #ecf4f7;
}
.projects__item:hover .projects__item-corner, .projects__item.hover .projects__item-corner {
  opacity: 1;
}
.projects__item:hover .projects__item-info, .projects__item.hover .projects__item-info {
  opacity: 1;
}
.projects__item:hover .projects__item-member-list, .projects__item.hover .projects__item-member-list {
  opacity: 1;
}
.projects__item:hover .projects__item-link, .projects__item.hover .projects__item-link {
  opacity: 1;
}
.projects__item:hover .background-overlay, .projects__item.hover .background-overlay {
  opacity: 0.8;
}
.projects__item--full-width {
  flex-basis: 100%;
}
.projects__item--full-width .background-overlay {
  opacity: 0.8;
}
.projects__item--full-width:hover .projects__item-holder {
  border-color: transparent;
  overflow: hidden;
}
.projects__item--full-width:hover .background-overlay {
  opacity: 0.7;
}
@media (max-width: 960px) {
  .projects__item--preview .projects__holder {
    height: 320px;
  }
}
@media (max-width: 768px) {
  .projects__item--preview .projects__holder {
    height: 300px;
  }
}
@media (max-width: 480px) {
  .projects__item--preview .projects__holder {
    height: 250px;
  }
}
.projects__item--preview .projects__item-corner {
  background-color: #fafafa;
}
.projects__item--preview-full-width .projects__item-background img {
  height: 100%;
  max-height: unset;
  max-width: unset;
  object-fit: cover;
  width: 100%;
}
.projects__item--preloader {
  pointer-events: none;
}
.projects__item--preloader .projects__item {
  pointer-events: none;
}
.projects__item--preloader:hover .projects__holder {
  box-shadow: unset !important;
}
.projects__holder {
  display: flex;
  height: 0;
  padding-bottom: 126.8%;
  position: relative;
}
@media (max-width: 960px) {
  .projects__item.hover .projects__holder {
    background-color: #fff;
    border-radius: 6px 6px 6px 55px;
    box-shadow: 0 2px 8px 0 rgba(64, 54, 78, 0.2);
  }
  .projects__item.hover .projects__item-corner {
    opacity: 1;
  }
}
@media (min-width: 961px) {
  .projects__item:hover .projects__holder, .projects__item:active .projects__holder, .projects__item:focus .projects__holder {
    background-color: #fff;
  }
}
.projects--inner {
  background: #fff;
}
.projects--inner .projects__item-corner {
  background-color: #fff;
}

.projects__holder.gradient {
  background: linear-gradient(180deg, #333a3c 0%, #7b7f80 100%);
}
.projects__holder.gradient--graphite-dark {
  background: linear-gradient(180deg, #333a3c 0%, #7b7f80 100%);
}
.projects__holder.gradient--stone-dark {
  background: linear-gradient(180deg, #5b6770 0%, #939a9f 100%);
}
.projects__holder.gradient--steel-dark {
  background: linear-gradient(180deg, #53778a 0%, #8ea4af 100%);
}
.projects__holder.gradient--graphite-light {
  background: linear-gradient(0, #7b7f80 0%, #9da0a1 100%);
}
.projects__holder.gradient--stone-light {
  background: linear-gradient(0, #939a9f 0%, #adb2b6 100%);
}
.projects__holder.gradient--steel-light {
  background: linear-gradient(0, #8ea4af 0%, #aab9c0 100%);
}
.projects__item-background {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}
.projects__item-background img {
  bottom: 0;
  display: block;
  height: 100%;
  object-fit: contain;
  object-position: bottom right;
  position: absolute;
  right: 0;
  width: 100%;
}
.projects__item-background--contain img {
  height: calc(100% - 100px);
  width: calc(100% - 60px);
}
.projects__item-background--cover img {
  object-fit: cover;
  object-position: center;
}

.projects-item__info {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1024px) {
  .projects-item__info {
    margin-bottom: 10px;
  }
}
@media (max-width: 480px) {
  .projects-item__info {
    align-items: flex-start;
  }
}
.projects-item__info-item {
  align-items: center;
  background-color: transparent;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  color: #f2f2f2;
  display: inline-flex;
  font-family: "Roboto Slab", serif;
  margin-bottom: 8px;
  margin-right: 8px;
  padding: 7px 6px;
}
.projects-item__info-icon {
  align-self: center;
  display: inline-flex;
  margin-right: 8px;
}
.projects-item__info-icon img {
  height: 13px;
  max-height: 13px;
}
.projects-item__info-icon .icon-slug-mac-os-app {
  margin-top: -2px;
}
.projects-item__info-title {
  font-size: 13px;
  line-height: 1;
}

.projects-item-hidden {
  display: none;
  filter: drop-shadow(4px 4px 30px rgba(0, 0, 0, 0.4));
  height: calc(100% - 100px);
  max-height: 800px;
  max-width: 1275px;
  padding: 0;
  position: relative;
  width: calc(100% - 120px);
  z-index: 1;
}
@media (max-width: 1280px) {
  .projects-item-hidden {
    max-height: 590px;
    max-width: 1060px;
  }
}
@media (max-width: 1024px) {
  .projects-item-hidden {
    max-height: 517px;
    max-width: 904px;
  }
}
@media (max-width: 960px) {
  .projects-item-hidden {
    max-height: unset;
  }
}
@media (max-width: 480px) {
  .projects-item-hidden {
    border-radius: 0;
    height: auto;
    width: 100%;
  }
}
.projects-item-hidden .fancybox-close-small {
  display: none;
}

@media (max-width: 480px) {
  .fancybox-navigation {
    display: none;
  }
  .fancybox-slide--html {
    padding: 0;
  }
}
.projects-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media (max-width: 960px) {
  .projects-modal {
    display: block;
    overflow: auto;
  }
}
.projects-modal .slick-projects-gallery {
  flex-grow: 1;
  height: auto;
}
.projects-modal__inner {
  width: 100%;
  height: 100%;
}
.projects-modal__layout {
  display: flex;
  height: 100%;
  min-width: 0;
  overflow: hidden;
  width: 100%;
}
@media (max-width: 960px) {
  .projects-modal__layout {
    flex-direction: column;
  }
}
.projects-modal__left-part {
  background-color: #333a3c;
  display: flex;
  flex-basis: 60%;
  flex-direction: column;
  min-width: 0;
  width: 60%;
}
@media (max-width: 960px) {
  .projects-modal__left-part {
    flex-basis: auto;
    margin-bottom: 30px;
    width: 100%;
  }
}
.projects-modal__right-part {
  flex-basis: 40%;
  min-width: 0;
  overflow: hidden;
  width: 40%;
}
@media (max-width: 960px) {
  .projects-modal__right-part {
    flex-basis: auto;
    width: 100%;
  }
}
.projects-modal .icon-close {
  color: #c1c3c3;
  cursor: pointer;
  display: flex;
  font-size: 24px;
  position: absolute;
  right: 15px;
  top: 15px;
  transition: color 0.2s;
  z-index: 3;
}
@media (max-width: 480px) {
  .projects-modal .icon-close {
    position: absolute;
  }
}
.projects-modal .icon-close:hover {
  color: #212322;
}
.projects-modal__header {
  display: none;
  min-width: 0;
  position: relative;
}
@media (max-width: 960px) {
  .projects-modal__header {
    display: block;
    padding: 30px 37px 20px 25px;
  }
}
.projects-modal__header.is-hidden {
  visibility: hidden;
}
.projects-modal__content {
  display: flex;
  flex-grow: 1;
  height: 100%;
  min-width: 0;
}
@media (max-width: 960px) {
  .projects-modal__content {
    height: auto;
  }
  .projects-modal__content .icon-close,
  .projects-modal__content .projects-item__info {
    display: none;
  }
}
.projects-modal__content .projects-modal__right-part {
  position: relative;
}
.projects-modal__content .projects-modal__right-part-inner {
  height: 100%;
  overflow: auto;
  padding-bottom: 60px;
}
@media (max-width: 960px) {
  .projects-modal__content .projects-modal__right-part-inner {
    position: relative;
  }
}
.projects-modal__content.is-hidden {
  visibility: hidden;
}
.projects-modal .projects__item-title {
  color: #212322;
  margin-bottom: 10px;
}
.projects-modal .projects__item-caption {
  color: #333a3c;
  margin-bottom: 0;
}
.projects-modal .projects-item__info-item {
  background-color: #71798d;
}
.projects-modal__item {
  padding: 0 25px 20px;
}
@media (max-width: 480px) {
  .projects-modal__item {
    padding-left: 15px;
    padding-right: 40px;
  }
}
.projects-modal__item.no-border {
  border-bottom: none;
}
.projects-modal__item-title {
  margin-bottom: 10px;
}
.projects-modal__item.projects-modal__item--header {
  border-bottom: none;
  padding: 30px 50px 33px 25px;
  position: relative;
}
@media (max-width: 960px) {
  .projects-modal__item.projects-modal__item--header {
    display: none;
  }
}
.projects-modal__item.projects-modal__item--description {
  padding: 0 68px 0 40px;
}
@media (max-width: 480px) {
  .projects-modal__item.projects-modal__item--description {
    padding: 0 15px;
  }
}
.projects-modal__item.projects-modal__item--technologies {
  padding: 0 35px 32px 25px;
}
.projects-modal__item.projects-modal__item--testimonial {
  border-bottom: none;
  margin-bottom: 30px;
  padding-bottom: 0;
}
.projects-modal__description-holder {
  padding-bottom: 15px;
  padding-top: 10px;
}
.projects-modal__highlight {
  background: #0e7a73;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1.2222222222;
  padding: 24px 30px;
}
.projects-modal__description {
  color: #333a3c;
  font-size: 16px;
  line-height: 1.375;
  overflow: hidden;
}
.projects-modal__description.limited {
  max-height: 45px;
}
.projects-modal__description.limited.limited--secondary {
  max-height: 177px;
}
.projects-modal__description.limited.is-active {
  max-height: none;
}
.projects-modal__description p {
  margin-bottom: 10px;
}
.projects-modal__access-denied {
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 0 15px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 14;
}
.projects-modal__access-denied div {
  text-align: center;
}
.projects-modal__access-denied div p {
  color: #212322;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}
.projects-modal__loader {
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 15;
}
@media (max-width: 768px) {
  .projects-modal__loader {
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 20;
  }
}
.projects-modal__hire {
  background: #fff;
  border-top: 1px solid #f2f2f2;
  bottom: 0;
  left: 0;
  padding: 11px 40px 12px;
  position: absolute;
  right: 0;
  text-align: center;
  transition: 0.3s;
  width: 100%;
  z-index: 13;
}
@media (max-width: 480px) {
  .projects-modal__hire {
    transform: none;
  }
}
.projects-modal__read-more-link {
  color: #212322;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.125em;
  overflow: hidden;
  position: relative;
  right: -3px;
  text-decoration: none;
}
.projects-modal__read-more-link::before {
  bottom: 1px;
  height: 2px;
}
.projects-modal__read-more-link.is-active {
  display: none;
}
.projects-modal__technologies {
  display: flex;
  justify-content: space-between;
}
.projects-modal .technologies__left {
  padding-right: 30px;
}
.projects-modal .technologies__right {
  width: 130px;
}
@media (max-width: 1280px) {
  .projects-modal .technologies__right {
    width: 120px;
  }
}
@media (max-width: 1024px) {
  .projects-modal .technologies__right {
    width: 92px;
  }
}
@media (max-width: 1024px) {
  .projects-modal .technologies__right {
    padding-left: 0;
  }
}
.projects-modal .technologies__right .project-length {
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  letter-spacing: 0.04em;
  line-height: 1.5;
}
@media (max-width: 1280px) {
  .projects-modal .technologies__right .project-length {
    font-size: 14px;
  }
}
@media (max-width: 1024px) {
  .projects-modal .technologies__right .project-length {
    font-size: 12px;
  }
}
.projects-modal .technologies__title {
  color: #0e7a73;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.1428571429;
  margin-bottom: 10px;
  text-transform: none;
}
@media (max-width: 1280px) {
  .projects-modal .technologies__title {
    font-size: 18px;
  }
}
@media (max-width: 1024px) {
  .projects-modal .technologies__title {
    font-size: 16px;
  }
}
@media (max-width: 1024px) {
  .projects-modal .technologies__title {
    font-size: 14px;
  }
}
.projects-modal .technologies__icons-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-left: -5px;
  margin-right: -5px;
}
.projects-modal .technologies__icons-list li {
  flex-basis: 33.3333%;
  flex-shrink: 0;
  margin-bottom: 15px;
  padding: 0 5px;
}
.projects-modal__testimonial {
  color: #333a3c;
  display: flex;
  font-size: 16px;
}
@media (max-width: 1280px) {
  .projects-modal__testimonial {
    font-size: 14px;
  }
}
@media (max-width: 1024px) {
  .projects-modal__testimonial {
    font-size: 12px;
  }
}
.projects-modal__testimonial-content {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3333333333;
  margin-bottom: 20px;
  padding-left: 29px;
  position: relative;
}
@media (max-width: 1280px) {
  .projects-modal__testimonial-content {
    font-size: 18px;
  }
}
@media (max-width: 1024px) {
  .projects-modal__testimonial-content {
    font-size: 16px;
  }
}
.projects-modal__testimonial-content::before {
  color: #0e7a73;
  content: "\e922";
  font-family: icomoon !important;
  font-size: 15px;
  left: 0;
  position: absolute;
  top: -7px;
}
.projects-modal__testimonial-name {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  line-height: 1.3333333333;
  padding-left: 29px;
}
.projects-modal .slick-active .nav-item,
.projects-modal .slick-active .slick-projects-nav__item {
  opacity: 1;
}
.projects-modal .slick-projects-nav {
  padding: 12px 0 6px;
}
.projects-modal .slick-projects-nav .slick-list {
  padding: 0 !important;
}
.projects-modal .slick-projects-nav .slick-track {
  flex-wrap: nowrap;
  font-size: 0;
}
.projects-modal .slick-projects-nav .slick-slide {
  outline: none;
}
.projects-modal .slick-projects-nav .slick-slide.slick-current {
  opacity: 1;
}
.projects-modal .slick-projects-nav .slick-slide.slick-current .slick-projects-nav__item,
.projects-modal .slick-projects-nav .slick-slide.slick-current .slick-projects-nav__item:hover,
.projects-modal .slick-projects-nav .slick-slide.slick-current .nav-item {
  opacity: 1;
}
.projects-modal .slick-projects-nav__item {
  cursor: pointer;
  display: block !important;
  opacity: 0.5;
  outline: none;
  padding: 0 5px;
}
.projects-modal .slick-projects-nav__item:hover {
  opacity: 0.7;
}
.projects-modal .slick-projects-nav__item.slick-current {
  opacity: 1;
}
.projects-modal .slick-projects-nav__item.slick-current .nav-item {
  opacity: 1;
}
.projects-modal .slick-projects-nav__item img {
  height: 36px;
  margin-bottom: 6px;
  object-fit: cover;
  width: auto;
}
@media (min-width: 961px) {
  .projects-modal .slick-projects-gallery {
    height: calc(100% - 83px);
  }
  .projects-modal .slick-projects-gallery .slick-list,
  .projects-modal .slick-projects-gallery .slick-track {
    height: 100%;
  }
  .projects-modal .slick-projects-gallery .slick-slide {
    height: 100%;
  }
  .projects-modal .slick-projects-gallery .slick-slide > div {
    height: 100%;
  }
}
.projects-modal .slick-projects-gallery__item {
  align-items: center;
  display: flex !important;
  height: 100%;
  justify-content: center;
}
.projects-modal .slick-projects-gallery__item > img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.projects-modal .nav-item {
  background-color: #fff;
  height: 2px;
  opacity: 0;
  width: 100%;
}
.projects-modal .nav-item .progress-line {
  background-color: #5ae6bd;
  height: 100%;
  width: 0;
}
.projects-modal--single-project {
  height: 740px;
}
@media (max-width: 1024px) {
  .projects-modal--single-project {
    height: 600px;
  }
}
@media (max-width: 960px) {
  .projects-modal--single-project {
    height: auto;
  }
}
.projects-modal--single-project .projects-modal__layout {
  overflow: visible;
}
.projects-modal--single-project .projects-modal__right-part {
  border-radius: 0 0 5px;
  box-shadow: 0 2px 8px 0 rgba(64, 54, 78, 0.2);
}
@media (max-width: 960px) {
  .projects-modal--single-project .projects-modal__right-part {
    border-radius: 0;
    box-shadow: unset;
  }
}
.projects-modal--single-project-protected {
  height: 240px;
}

.slide-info {
  align-items: center;
  color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: hidden;
  padding-left: 25px;
  padding-right: 25px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
}
@media (max-width: 768px) {
  .slide-info {
    position: static;
  }
}
@media (max-width: 960px) {
  .slide-info {
    padding-bottom: 0;
  }
}
@media (max-width: 768px) {
  .slide-info {
    padding-left: 0;
    padding-right: 0;
  }
  .slide-info .fancybox-button {
    display: none;
  }
}
.slide-info--modal {
  background-color: rgba(33, 35, 34, 0.5);
  position: absolute;
}
@media (max-width: 768px) {
  .slide-info--modal {
    padding: 0 15px !important;
    position: absolute;
  }
}
@media (max-width: 480px) {
  .slide-info--modal {
    padding: 0 10px !important;
    position: absolute !important;
  }
}
.slide-info--item .slide-info__inner {
  transform: translateY(65px);
  transition: 0.26s;
}
@media (max-width: 768px) {
  .slide-info--item .slide-info__inner {
    padding: 0 25px;
    transform: translateY(0);
  }
}
.projects__item:hover .slide-info--item .slide-info__inner {
  transform: translateY(0);
}
.slide-info--item .slide-info__title {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
}
@media (max-width: 960px) {
  .slide-info--item .slide-info__title {
    font-size: 20px;
  }
}
.slide-info--item .slide-info__subtitle {
  font-size: 18px;
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .slide-info--item .slide-info__subtitle {
    margin-bottom: 20px;
  }
}
.slide-info--item .fancybox-button {
  color: #fff;
  opacity: 1;
  top: calc(50% - 69px);
  visibility: visible;
  width: 50px;
}
.slide-info--item .fancybox-button--arrow_right > div::before {
  left: 22px;
}
.slide-info--item .fancybox-button--arrow_left > div::before {
  right: 22px;
}
@media (max-width: 480px) {
  .slide-info:not(.slide-info--item) {
    padding: 0;
    position: relative;
  }
}
.slide-info__logo {
  display: inline-block !important;
  height: auto;
  margin-bottom: 20px;
  max-width: 300px;
  width: auto;
}
@media (max-width: 960px) {
  .slide-info__logo {
    margin-bottom: 10px;
    max-width: 200px;
  }
}
@media (max-width: 768px) {
  .slide-info__logo {
    height: auto;
    max-height: 60px;
    max-width: 190px !important;
    width: auto;
  }
}
.slide-info__title {
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 0.021em;
  line-height: 1.2;
  margin-bottom: 10px;
}
.slide-info__subtitle {
  font-size: 20px;
  line-height: 1.2;
}
@media (max-width: 960px) {
  .slide-info__subtitle {
    font-size: 16px;
  }
}

.slide-video {
  min-width: 100%;
  width: 0;
}
.slide-video-holder {
  min-height: 0;
  min-width: 0;
  position: relative;
}
.slide-video__item {
  border: none !important;
  height: 100%;
  position: relative;
}
.slide-video__item-inner {
  height: 100%;
  position: relative;
  width: 100%;
}
.slide-video__placeholder {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.2s;
  width: 100%;
  z-index: 2;
}
.slide-video__placeholder img {
  height: 100%;
  object-fit: cover;
  position: absolute;
  width: 100%;
}
.slide-video__placeholder.is-hidden {
  opacity: 0;
  visibility: hidden;
}
.slide-video__overlay {
  align-items: center;
  background-color: rgba(51, 58, 60, 0.7);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.slide-video__video {
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 3;
}
.slide-video__video video {
  display: block;
  width: 100%;
}
.slide-video__watch-video {
  align-items: center;
  color: #fff;
  cursor: pointer;
  display: flex;
  padding: 15px;
  transition: color 0.2s;
}
.slide-video__watch-video__icon {
  display: inline-flex;
  font-size: 40px;
}
.slide-video__watch-video__caption {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.02em;
  line-height: 24px;
  margin-left: 20px;
  text-decoration-line: underline;
  text-transform: uppercase;
  white-space: nowrap;
}
@media (max-width: 768px) {
  .slide-video__watch-video__caption {
    font-size: 20px;
    margin-left: 20px;
  }
}
@media (max-width: 480px) {
  .slide-video__watch-video__caption {
    font-size: 18px;
    letter-spacing: 1.5px;
  }
}
.slide-video__watch-video:hover {
  color: #5ae6bd;
}

.protip-container {
  z-index: 99999;
}

.protip-skin-default--scheme-pro.protip-container {
  background-color: #212322;
  border-radius: 30px;
  font-family: "Roboto Slab", serif;
  font-size: 11px;
  line-height: 1.6363636364;
  padding: 4px 21px;
}

.protip-arrow {
  display: none;
}

.slick-no-slide .slick-track {
  text-align: center;
  transform: inherit !important;
  width: 100% !important;
}

.slick-no-slide .slick-slide {
  display: inline-flex;
  flex-direction: column;
  float: none;
}

.load-more-holder {
  text-align: center;
  transition: all 0.16s;
}
@media (max-width: 960px) {
  .load-more-holder {
    padding-bottom: 60px;
  }
}
@media (max-width: 768px) {
  .load-more-holder {
    padding-bottom: 50px;
  }
}
@media (max-width: 480px) {
  .load-more-holder {
    padding-bottom: 40px;
  }
}
.load-more-holder.is-hidden {
  opacity: 0;
  visibility: hidden;
}
.load-more-holder:empty {
  display: none;
}

.single-project-container {
  padding-top: 75px;
}
@media (max-width: 960px) {
  .single-project-container {
    padding-top: 60px;
  }
}
@media (max-width: 768px) {
  .single-project-container {
    padding-top: 50px;
  }
}
@media (max-width: 480px) {
  .single-project-container {
    padding-top: 40px;
  }
}

.single-project h2 {
  font-size: 36px;
}
.single-project .p2 {
  font-size: 16px;
}

.testimonials {
  background-color: #333a3c;
  padding: 0;
  position: relative;
}
@media (max-width: 960px) {
  .testimonials {
    padding: 0;
  }
}
.testimonials-background {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.testimonials-background img {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.testimonials-background::after {
  background: linear-gradient(0deg, #333a3c, #333a3c);
  background-blend-mode: multiply;
  content: "";
  height: 100%;
  left: 0;
  mix-blend-mode: multiply;
  position: absolute;
  top: 0;
  width: 100%;
}
.testimonials .container {
  height: 100%;
}
@media (max-width: 1024px) {
  .testimonials .container {
    padding-left: 46px;
    padding-right: 46px;
  }
}
.testimonials-swiper {
  position: relative;
}
.testimonials-inner {
  height: 100%;
  padding: 112px 0;
  position: relative;
}
@media (max-width: 960px) {
  .testimonials-inner {
    padding-top: 70px;
  }
}
@media (max-width: 576px) {
  .testimonials-inner {
    padding-top: 50px;
  }
}
.testimonials-logo {
  flex-basis: 242px;
  flex-shrink: 0;
  height: 112px;
  transform: translate(0, -62px);
}
@media (max-width: 1024px) {
  .testimonials-logo {
    transform: translate(0, 0);
  }
}
@media (max-width: 960px) {
  .testimonials-logo {
    flex-basis: 180px;
    height: 80px;
  }
}
@media (max-width: 576px) {
  .testimonials-logo {
    flex-basis: auto;
    margin-bottom: 30px;
    max-width: 180px;
  }
}
@media (max-width: 480px) {
  .testimonials-logo {
    max-width: 140px;
  }
}
.testimonials-logo img {
  height: 100%;
  object-fit: contain;
  object-position: right center;
  width: 100%;
}
@media (max-width: 576px) {
  .testimonials-logo img {
    object-position: left top;
  }
}
.testimonials-content {
  padding-left: 20px;
  transition: all 0.2s;
}
.testimonials-top {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .testimonials-top {
    flex-direction: column-reverse;
    margin-bottom: 30px;
  }
}
.testimonials-item {
  color: #fff;
  height: auto;
  outline: none;
}
.testimonials-image {
  border-radius: 50%;
  display: inline-block;
  flex-shrink: 0;
  height: 63px;
  margin-right: 10px;
  overflow: hidden;
  position: relative;
  width: 63px;
}
.testimonials-image img {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.testimonials-bottom {
  bottom: 40px;
  opacity: 0;
  position: absolute;
  right: 0;
  transform: translate(0, 0);
  transition: opacity 0.2s;
  visibility: hidden;
  z-index: 2;
}
@media (hover: none) and (pointer: coarse) {
  .testimonials-bottom {
    opacity: 1;
    visibility: visible;
  }
}
@media (max-width: 1024px) {
  .testimonials-bottom {
    transform: translate(0, 0);
  }
}
@media (max-width: 576px) {
  .testimonials-bottom {
    padding-left: 20px;
    position: static;
  }
}
.testimonials-desc {
  margin-bottom: 32px;
  max-width: 650px;
  padding-right: 50px;
  position: relative;
}
@media (max-width: 1280px) {
  .testimonials-desc {
    max-width: 600px;
  }
}
@media (max-width: 1024px) {
  .testimonials-desc {
    max-width: 520px;
  }
}
@media (max-width: 576px) {
  .testimonials-desc {
    padding-right: 0;
  }
}
.testimonials-desc::before {
  color: #5ae6bd;
  content: "\e922";
  font-family: "icomoon" !important;
  font-size: 25px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: calc(100% + 12px);
  top: -20px;
}
.testimonials-desc .quote p, .testimonials-desc .post-content__inner .body-text blockquote p, .post-content__inner .body-text .testimonials-desc blockquote p, .testimonials-desc .description blockquote p, .description .testimonials-desc blockquote p {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  letter-spacing: 0.8px;
}
.testimonials-profile {
  align-items: center;
  display: flex;
}
.testimonials-position {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.5;
  margin-bottom: 0;
}
@media (max-width: 1024px) {
  .testimonials-position {
    font-size: 14px;
  }
}
.testimonials .testimonials-nav {
  bottom: 40px;
  opacity: 0;
  padding-left: 20px;
  position: absolute;
  transition: opacity 0.2s;
  visibility: hidden;
  z-index: 2;
}
@media (hover: none) and (pointer: coarse) {
  .testimonials .testimonials-nav {
    opacity: 1;
    visibility: visible;
  }
}
.testimonials .testimonials-nav-counter {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.02em;
}
.testimonials .testimonials-nav-counter > span {
  min-width: 10px;
}
.testimonials:not(.testimonials--single):not(.testimonials--circle-bg):hover .testimonials-content {
  transform: translateY(-10px);
}
@media (hover: none) and (pointer: coarse) {
  .testimonials:not(.testimonials--single):not(.testimonials--circle-bg):hover .testimonials-content {
    transform: translateY(0);
  }
}
.testimonials:not(.testimonials--single):not(.testimonials--circle-bg):hover .testimonials-bottom, .testimonials:not(.testimonials--single):not(.testimonials--circle-bg):hover .testimonials-nav {
  opacity: 1;
  visibility: visible;
}
.testimonials--secondary .testimonials-desc {
  max-width: 800px;
}
@media (max-width: 1280px) {
  .testimonials--secondary .testimonials-desc {
    max-width: 700px;
  }
}
@media (max-width: 1024px) {
  .testimonials--secondary .testimonials-desc {
    max-width: 705px;
  }
}
.testimonials--secondary .testimonials-desc::before {
  color: #5ae6bd;
}
.testimonials--secondary .testimonials-text p::after {
  color: #5ae6bd;
}
.testimonials--secondary .testimonials-background {
  background-color: #333a3c;
}
.testimonials--secondary .testimonials-background::after {
  display: none;
}
.testimonials--secondary .testimonials-image {
  margin-right: 15px;
}
.testimonials--secondary .testimonials-name {
  font-size: 24px;
  font-weight: 500;
}
@media (max-width: 576px) {
  .testimonials--secondary .testimonials-name {
    font-size: 20px;
  }
}
.testimonials--secondary .testimonials-position {
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-weight: 300;
}
@media (max-width: 576px) {
  .testimonials--secondary .testimonials-position {
    font-size: 14px;
  }
}
.testimonials--circle-bg .testimonials-background {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333a3c;
}
.testimonials--circle-bg .testimonials-background::after {
  content: none;
}
.testimonials--circle-bg .testimonials-background svg {
  opacity: 0.2;
  max-width: 906px;
  display: block;
  height: 906px;
}
.testimonials--circle-bg .testimonials-nav,
.testimonials--circle-bg .testimonials-bottom {
  opacity: 1;
  visibility: visible;
}

.testimonial-single {
  overflow: hidden;
  padding-bottom: 100px;
  padding-top: 130px;
  position: relative;
}
@media (max-width: 960px) {
  .testimonial-single {
    padding-bottom: 80px;
    padding-top: 100px;
  }
}
@media (max-width: 768px) {
  .testimonial-single {
    padding-bottom: 40px;
    padding-top: 10px;
  }
}
.testimonial-single--no-background {
  padding-top: 0;
}
.testimonial-single--top-background {
  padding-bottom: 80px;
  padding-top: 80px;
}
.testimonial-single--bottom-background {
  padding-bottom: 80px;
  padding-top: 80px;
}
.testimonial-single--bottom-background::before {
  background: #f2f2f2;
  bottom: 0;
  content: "";
  height: 70%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 0;
}
.testimonial-single__inner {
  position: relative;
}
.testimonial-single__rect {
  background: #fafafa;
  height: 100%;
  position: absolute;
  top: 100px;
  width: 100%;
}
.testimonial-single__holder {
  position: relative;
}
@media (max-width: 1024px) {
  .testimonial-single__background {
    height: auto;
  }
}
.testimonial-single__background img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.testimonial-single__foreground {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
@media (max-width: 768px) {
  .testimonial-single__foreground {
    position: relative;
  }
}
.testimonial-single__card {
  background: #333a3c;
  bottom: 46px;
  color: #fff;
  left: 40px;
  max-width: 480px;
  padding: 46px 60px;
  position: absolute;
}
.testimonial-single__card--inverse {
  right: 40px;
  left: unset;
}
@media (max-width: 1280px) {
  .testimonial-single__card {
    max-width: 550px;
  }
}
@media (max-width: 1024px) {
  .testimonial-single__card {
    max-width: 500px;
  }
}
@media (max-width: 768px) {
  .testimonial-single__card {
    bottom: 0;
    left: 0;
    max-width: unset;
    position: relative;
  }
}
@media (max-width: 480px) {
  .testimonial-single__card {
    padding: 35px 45px;
  }
}
.testimonial-single__text {
  margin-bottom: 34px;
  position: relative;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
.testimonial-single__text::before {
  color: #5ae6bd;
  content: "\e922";
  font-family: "icomoon" !important;
  font-size: 20px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: calc(100% + 7px);
  top: -12px;
}
.testimonial-single__text a {
  color: #5ae6bd;
  text-decoration: none;
}
.testimonial-single__text a:hover {
  text-decoration: underline;
}
.testimonial-single__user {
  align-items: center;
  display: flex;
}
.testimonial-single__user-photo {
  border-radius: 50%;
  flex: 0 0 auto;
  height: 64px;
  margin-right: 15px;
  overflow: hidden;
  width: 64px;
}
.testimonial-single__user-photo img {
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.testimonial-single__user-info.p2 {
  font-size: 14px;
}
.testimonial-single__user-info .author-text {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  display: block;
  font-size: 18px;
}
.testimonial-single--post {
  padding-bottom: 52px;
  padding-top: 62px;
}
@media (max-width: 960px) {
  .testimonial-single--post {
    padding-bottom: 30px;
    padding-top: 40px;
  }
}
@media (max-width: 768px) {
  .testimonial-single--post {
    padding-bottom: 20px;
    padding-top: 30px;
  }
}
.testimonial-single--flexible {
  margin-bottom: 70px;
  margin-top: 70px;
  padding-bottom: 0;
  padding-top: 0;
}
@media (max-width: 768px) {
  .testimonial-single--flexible {
    margin: 50px 0;
  }
}
@media (max-width: 480px) {
  .testimonial-single--flexible {
    margin: 40px 0;
  }
}
.testimonial-single--no-indent {
  margin-bottom: 0;
}

.testimonials-gallery {
  min-width: 100%;
  width: 0;
}
.testimonials-gallery-holder {
  min-height: 0;
  min-width: 0;
  position: relative;
}
.testimonials-gallery__item {
  border: none !important;
  position: relative;
}
.testimonials-gallery__item-holder {
  display: flex;
  padding-bottom: 50px;
  padding-top: 64px;
}
@media (max-width: 768px) {
  .testimonials-gallery__item-holder {
    align-items: center;
    flex-direction: column-reverse;
    justify-content: center;
  }
}
.testimonials-gallery__photo {
  align-self: flex-start;
  flex-basis: 242px;
  flex-shrink: 0;
  margin-right: 45px;
  width: 242px;
}
@media (max-width: 960px) {
  .testimonials-gallery__photo {
    flex-basis: 160px;
    width: 160px;
  }
}
@media (max-width: 768px) {
  .testimonials-gallery__photo {
    align-self: center;
    margin-bottom: 30px;
    margin-right: 0;
  }
}
.testimonials-gallery__photo img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.testimonials-gallery__content {
  color: #fff;
  padding-right: 52px;
}
@media (max-width: 768px) {
  .testimonials-gallery__content {
    padding-right: 0;
    text-align: center;
  }
}
.testimonials-gallery__text {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.4583333333;
  margin-bottom: 30px;
  padding-left: 88px;
  padding-top: 20px;
  position: relative;
}
@media (max-width: 960px) {
  .testimonials-gallery__text {
    font-size: 22px;
  }
}
@media (max-width: 768px) {
  .testimonials-gallery__text {
    font-size: 20px;
    padding: 0 20px;
  }
}
@media (max-width: 480px) {
  .testimonials-gallery__text {
    font-size: 18px;
    padding: 0;
  }
}
.testimonials-gallery__text::before {
  bottom: 100%;
  color: #fff;
  content: "\e91a";
  font-family: "icomoon" !important;
  font-size: 59px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  left: 0;
  line-height: 1;
  pointer-events: none;
  position: absolute;
  transform: translateY(44px) translateX(0);
  z-index: -1;
}
@media (max-width: 768px) {
  .testimonials-gallery__text::before {
    display: none;
  }
}
.testimonials-gallery__position {
  color: #333a3c;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2px;
  padding-left: 88px;
}
@media (max-width: 768px) {
  .testimonials-gallery__position {
    font-size: 16px;
    padding: 0;
  }
}
@media (max-width: 480px) {
  .testimonials-gallery__position {
    font-size: 14px;
  }
}
.testimonials-gallery__progress-bar {
  bottom: 0;
  height: 2px;
  left: 0;
  margin-left: calc((100vw - 100%) / -2);
  position: absolute;
  width: calc(100% + (100vw - 100%) / 2);
}
@media (max-width: 768px) {
  .testimonials-gallery__progress-bar {
    width: 100vw;
  }
}
.testimonials-gallery__progress-bar div {
  background: #0e7a73;
  display: block;
  height: 100%;
  width: 0;
}

.footer {
  backface-visibility: hidden;
  background-color: #fff;
  z-index: 0;
}
@media (max-width: 768px) {
  .footer {
    position: static;
  }
}
.footer__top {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.3888888889;
  padding: 70px 0 107px;
}
@media (max-width: 768px) {
  .footer__top {
    font-size: 18px;
    padding: 60px 0 20px;
  }
}
.footer__top-holder {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .footer__top-holder {
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .footer__top-left {
    margin-bottom: 40px;
  }
}
@media (max-width: 768px) {
  .footer__top-right {
    flex-direction: column;
    width: 100%;
  }
}
.footer__top .footer-social {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 33px;
}
@media (max-width: 768px) {
  .footer__top .footer-social {
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 30px;
  }
}
@media (max-width: 480px) {
  .footer__top .footer-social {
    justify-content: space-between;
  }
}
.footer__top .footer-social__list {
  align-items: center;
  display: flex;
  margin-left: 14px;
}
@media (max-width: 480px) {
  .footer__top .footer-social__list {
    margin-left: 0;
  }
}
.footer__top .footer-social .clutch-widget {
  flex: 0 0 auto;
  flex-basis: 195px;
  height: 52px;
  overflow: hidden;
  width: 195px;
}
.footer__top .footer-social__item {
  color: #c1c3c3;
  font-size: 36px;
  margin-left: 19px;
  text-decoration: none;
  transition: all 0.2s;
}
@media (max-width: 480px) {
  .footer__top .footer-social__item {
    font-size: 26px;
    margin-left: 15px;
  }
}
.footer__top .footer-social__item:hover {
  color: #212322;
}
.footer__top-navs {
  display: flex;
}
@media (max-width: 768px) {
  .footer__top-navs {
    border-top: 1px solid rgba(23, 34, 60, 0.2);
    justify-content: flex-start;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 25px;
  }
}
.footer__top-nav:first-child {
  margin-right: 38px;
}
.footer__top-nav li {
  margin-bottom: 8px;
}
.footer__top-nav li a {
  color: #5b6770;
  font-weight: normal;
  padding-left: 0;
  padding-right: 0;
}
.footer__top-nav li a:hover {
  color: #20b59a;
}
.footer__top-nav li a::after {
  display: none;
}
.footer__top .list {
  color: rgba(21, 34, 60, 0.5);
  flex-direction: column;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .footer__top .list {
    font-size: 16px;
    letter-spacing: 0.89px;
    line-height: 1.5;
  }
}
.footer__top .list li {
  margin-bottom: 12px;
  margin-left: 0;
}
@media (max-width: 768px) {
  .footer__top .list li {
    margin-bottom: 20px;
  }
}
.footer__top .list.list--footer-top {
  margin-bottom: 0;
}
.footer__address {
  color: #333a3c;
  margin-bottom: 20px;
}
.footer__bottom {
  background: #fafafa;
  color: #333a3c;
  font-family: "Roboto Slab", serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.075em;
  padding: 25px 0;
}
@media (max-width: 1024px) {
  .footer__bottom {
    font-size: 12px;
  }
}
.footer__bottom-nav {
  align-items: center;
  display: flex;
  padding-right: 3px;
}
.footer__bottom-nav li {
  margin-left: 9px;
}
@media (max-width: 768px) {
  .footer__bottom-nav li {
    margin-left: 0;
    margin-right: 9px;
  }
}
.footer__bottom-nav .footer-separator {
  background: #333a3c;
  height: 12px;
  margin-top: 0;
  opacity: 0.4;
  width: 1px;
}
.footer__bottom-nav .footer-separator:last-child {
  display: none;
}
.footer__bottom .container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .footer__bottom .container {
    align-items: flex-start;
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .footer__copyright {
    margin-bottom: 20px;
  }
}
.footer .logo-container {
  margin-bottom: 20px;
}
.footer .contact-links {
  display: flex;
  flex-direction: column;
}
.footer .contact-links a {
  color: #5b6770;
  text-decoration: underline;
  transition: all 0.2s;
}
.footer .contact-links a::before {
  bottom: 0;
}
.footer .contact-links a:hover {
  color: #20b59a;
}
.footer .current-menu-item a {
  color: #20b59a;
  opacity: 1;
}

.text {
  line-height: 1.61;
  max-width: 690px;
}
@media (max-width: 960px) {
  .text {
    max-width: 100% !important;
  }
}
.text p:not(:last-child) {
  margin-bottom: 36px;
}
@media (max-width: 480px) {
  .text p:not(:last-child) {
    margin-bottom: 18px;
  }
}
.text a {
  color: #212322;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  letter-spacing: normal;
  line-height: normal;
}
.text--white {
  color: #fff;
}
.text--narrow {
  max-width: 500px;
}
.text--full-width {
  max-width: unset;
}
.text--center {
  text-align: center;
}

.popup {
  align-items: center;
  backface-visibility: hidden;
  bottom: 0;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow-y: auto;
  padding: 15px;
  position: fixed;
  right: 0;
  top: 0;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: 0.3s;
  visibility: hidden;
  width: 100vw;
  z-index: 1000;
}
@media (max-width: 480px) {
  .popup {
    padding: 0;
  }
}
.popup::before {
  background-color: #333a3c;
  content: "";
  height: 100%;
  left: 0;
  mix-blend-mode: multiply;
  opacity: 0.8;
  position: absolute;
  top: 0;
  width: 100%;
}
.popup--contact {
  padding: 15px;
}
.popup__inner {
  background-color: #fff;
  box-shadow: 0 0 18px 3px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  max-width: 860px;
  opacity: 0;
  overflow: hidden;
  position: relative;
  transform: translateY(-40px);
  transition: 0.3s;
  width: 100%;
}
@media (max-width: 480px) {
  .popup__inner {
    border-radius: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}
.popup__inner--contact {
  max-width: 447px;
  min-height: unset;
  padding-bottom: 46px;
  padding-top: 60px;
}
.popup__inner--contact .popup__header {
  margin-bottom: 15px;
  position: static;
}
.popup__inner--contact .popup__content {
  padding-bottom: 0;
  padding-top: 0;
}
.popup__header {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
}
.popup__title {
  color: #212322;
  margin-bottom: 15px;
  padding: 0 20px;
  text-align: center;
}
.popup--project-password .popup__inner {
  max-width: 488px;
}
.popup--project-password .popup__header {
  height: 90px;
}
.popup--project-password .popup__content {
  padding: 60px 20px 0;
}
.popup--project-password .popup__footer {
  color: #9da0a1;
  font-family: "Roboto Slab", serif;
  font-size: 13px;
  padding-bottom: 20px;
  padding-top: 0;
  text-align: center;
}
.popup--project-password .popup__footer a {
  color: #575c5e;
  margin-left: 1px;
}
.popup--project-password .popup__footer a:hover {
  color: #20b59a;
}
.popup--project-password .password-form__holder {
  margin: auto;
  max-width: 220px;
  padding-bottom: 40px;
  padding-top: 30px;
}
.popup__content {
  padding: 60px 20px;
  text-align: center;
}
.popup__response-message {
  color: #212322;
  display: none;
  padding: 10px 0;
  text-align: center;
}
.popup__content-text {
  color: #333a3c;
  margin: auto;
  max-width: 620px;
}
.popup__content-text a {
  color: #212322;
}
.popup__content-actions {
  padding-top: 45px;
}
@media (max-width: 480px) {
  .popup__content-actions {
    padding-top: 30px;
  }
}
.popup__close {
  color: #c1c3c3;
  cursor: pointer;
  display: flex;
  font-size: 24px;
  position: absolute;
  right: 15px;
  top: 15px;
  transition: color 0.2s;
  z-index: 3;
}
.popup__close:hover {
  color: #212322;
}
.popup.is-active {
  opacity: 1;
  transition: 0.3s;
  visibility: visible;
}
.popup.is-active .popup__inner {
  opacity: 1;
  transform: translateY(0);
  transition: 0.3s;
}
@supports (-webkit-overflow-scrolling: touch) {
  .popup {
    -webkit-overflow-scrolling: touch;
  }
}

.list__item {
  color: #212322;
  font-size: 18px;
  font-weight: normal;
}
.list__item a {
  color: #212322;
  cursor: pointer;
  text-decoration: none;
}

.capabilities ul li,
.list--dotted ul li {
  color: #333a3c;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 6px;
  padding-left: 30px;
  position: relative;
}
.capabilities ul li::before,
.list--dotted ul li::before {
  color: #20b59a;
  content: "\e92d";
  font-family: icomoon !important;
  font-size: 8px;
  left: 0;
  position: absolute;
  top: 9px;
}
@media (max-width: 480px) {
  .capabilities ul li::before,
  .list--dotted ul li::before {
    top: 7px;
  }
}
.capabilities ul li.list-item--no-link::before,
.list--dotted ul li.list-item--no-link::before {
  color: #333a3c;
}
.capabilities ul li a,
.list--dotted ul li a {
  font-family: "Roboto Slab", serif;
  text-decoration: underline;
}
.capabilities ul li a:hover,
.list--dotted ul li a:hover {
  color: #20b59a;
}

.list--dotted--xs ul li {
  color: #333a3c;
  font-family: "Roboto Slab", serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.6363636364;
  margin-bottom: 1px;
  padding-left: 16px;
}
@media (max-width: 1280px) {
  .list--dotted--xs ul li {
    font-size: 14px;
  }
}
@media (max-width: 1024px) {
  .list--dotted--xs ul li {
    font-size: 12px;
  }
}
.list--dotted--xs ul li a {
  color: #5b6770;
  font-family: "Roboto Slab", serif;
}
.list--dotted--xs ul li::before {
  font-size: 8px;
  top: 6px;
}
@media (max-width: 1280px) {
  .list--dotted--xs ul li::before {
    top: 5px;
  }
}
@media (max-width: 1024px) {
  .list--dotted--xs ul li::before {
    top: 3px;
  }
}

.list--checked .list__item::before {
  align-items: center;
  background: #0e7a73;
  border-radius: 50%;
  color: #fff;
  content: "\e902";
  display: inline-flex;
  font-family: "icomoon" !important;
  font-size: 6px;
  height: 16px;
  justify-content: center;
  margin-right: 10px;
  vertical-align: middle;
  width: 16px;
}

.list--vertical {
  display: flex;
}
@media (max-width: 576px) {
  .list--vertical {
    margin-bottom: 10px;
  }
}
.list--vertical li {
  line-height: normal;
}
.list--vertical li:last-child a {
  border-right: none;
}
.list--vertical li:last-child a::after {
  display: none;
}
.list--vertical a {
  color: #333a3c;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  padding: 0 8px;
  position: relative;
  text-decoration: none;
}
.list--vertical a::after {
  background-color: rgba(21, 34, 60, 0.5);
  content: "";
  height: 12px;
  margin-top: 1px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
}

.service-list {
  margin-bottom: 40px;
  padding-top: 120px;
}
@media (max-width: 960px) {
  .service-list {
    margin-bottom: 30px;
    padding-top: 100px;
  }
}
@media (max-width: 768px) {
  .service-list {
    margin-bottom: 10px;
    padding-top: 20px;
  }
}
.service-list .card-item__image img {
  height: 130px;
  width: auto;
}

.skillset {
  background-color: #f9f9f9;
  padding: 102px 0 181px;
}
@media (max-width: 960px) {
  .skillset {
    padding-bottom: 80px;
    padding-top: 60px;
  }
}
@media (max-width: 768px) {
  .skillset {
    padding-bottom: 60px;
    padding-top: 40px;
  }
}
.skillset .h2, .skillset .post-content__inner .body-text h2, .post-content__inner .body-text .skillset h2, .skillset .description__content h2, .description__content .skillset h2, .skillset .content-heading {
  color: #0e7a73;
  margin-bottom: 120px;
}
@media (max-width: 768px) {
  .skillset .h2, .skillset .post-content__inner .body-text h2, .post-content__inner .body-text .skillset h2, .skillset .description__content h2, .description__content .skillset h2, .skillset .content-heading {
    margin-bottom: 70px;
  }
}
.skillset-holder {
  display: flex;
}
@media (max-width: 768px) {
  .skillset-holder {
    flex-direction: column;
  }
}
.skillset-image {
  flex-basis: 50%;
  flex-shrink: 0;
  max-width: 500px;
  padding-left: 20px;
  padding-right: 46px;
  padding-top: 7px;
}
@media (max-width: 768px) {
  .skillset-image {
    margin-bottom: 40px;
    max-width: 300px;
    padding-left: 0;
    padding-right: 0;
  }
}
.skillset-image svg {
  overflow: visible;
}
.skillset-image-card {
  cursor: pointer;
  transition: all 0.3s;
}
.skillset-image-card:hover {
  transform: translateY(-1%);
}
.skillset-image-card.is-active {
  transform: translateY(-11%);
}
.skillset-carousel {
  flex-basis: 50%;
  flex-shrink: 0;
  padding-left: 64px;
  width: 50%;
}
@media (max-width: 960px) {
  .skillset-carousel {
    padding-left: 30px;
  }
}
@media (max-width: 768px) {
  .skillset-carousel {
    flex-basis: 100%;
    max-width: 500px;
    padding-left: 0;
    width: 100%;
  }
}
.skillset-carousel-item {
  opacity: 0 !important;
}
.skillset-carousel-item.swiper-slide-active {
  opacity: 1 !important;
}
.skillset-carousel-title {
  color: #53778a;
  margin-bottom: 20px;
}
.skillset-carousel-description {
  color: #333a3c;
}
.skillset-carousel-description p {
  margin-bottom: 1em;
}
.skillset-carousel-description p:last-child {
  margin-bottom: 0;
}
.skillset-swiper {
  margin-bottom: 20px;
}

.process {
  background-color: #0e7a73;
  padding: 140px 0 102px;
}
@media (max-width: 960px) {
  .process {
    padding-bottom: 60px;
    padding-top: 80px;
  }
}
@media (max-width: 768px) {
  .process {
    padding-bottom: 40px;
    padding-top: 60px;
  }
}
.process .h2, .process .post-content__inner .body-text h2, .post-content__inner .body-text .process h2, .process .description__content h2, .description__content .process h2, .process .content-heading {
  color: #f2f2f2;
  margin-bottom: 30px;
}
.process-holder {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .process-holder {
    align-items: center;
    flex-direction: column-reverse;
  }
}
.process-content {
  max-width: 300px;
}
@media (max-width: 1024px) {
  .process-content {
    max-width: 242px;
  }
}
@media (max-width: 576px) {
  .process-content {
    text-align: center;
  }
}
.process-content ::selection {
  background: #0b635d;
}
.process-description {
  color: #f2f2f2;
  margin-bottom: 40px;
}
.process-action .btn:hover {
  border-color: #5ae6bd;
  color: #5ae6bd;
}
.process-image {
  flex-basis: 50%;
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 600px;
  padding-left: 40px;
  padding-top: 28px;
  text-align: center;
}
@media (max-width: 1024px) {
  .process-image {
    max-width: 500px;
  }
}
@media (max-width: 576px) {
  .process-image {
    margin-bottom: 20px;
    padding: 0;
    width: 100%;
  }
}
.process-image img {
  display: inline-block;
}
@media (max-width: 576px) {
  .process-image img {
    max-width: 280px;
  }
}

.engagement {
  padding: 0;
}
.engagement-home {
  padding-bottom: 160px;
  padding-top: 160px;
}
@media (max-width: 960px) {
  .engagement-home {
    padding-bottom: 67px;
    padding-top: 67px;
  }
}
@media (max-width: 576px) {
  .engagement-home {
    padding-bottom: 46px;
    padding-top: 46px;
  }
}
.engagement-gallery {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-template-rows: repeat(10, 68px);
  margin: -12px;
}
@media (max-width: 1024px) {
  .engagement-gallery {
    grid-template-rows: repeat(10, 54px);
  }
}
@media (max-width: 960px) {
  .engagement-gallery {
    grid-template-columns: repeat(14, 1fr);
    margin-bottom: 10px;
    grid-template-rows: repeat(23, 50px);
  }
}
@media (max-width: 576px) {
  .engagement-gallery {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(20, auto);
  }
}
.engagement-gallery-item {
  padding: 12px;
}
.engagement-gallery-item:nth-child(1) {
  grid-column: 1/6;
  grid-row: 1/7;
}
.engagement-gallery-item:nth-child(2) {
  grid-column: 10/15;
  grid-row: 4/8;
}
.engagement-gallery-item:nth-child(3) {
  grid-column: 6/11;
  grid-row: 1/4;
}
.engagement-gallery-item:nth-child(4) {
  grid-column: 11/15;
  grid-row: 1/4;
}
.engagement-gallery-item:nth-child(5) {
  grid-column: 1/6;
  grid-row: 7/11;
}
.engagement-gallery-item:nth-child(6) {
  grid-column: 6/10;
  grid-row: 4/11;
}
.engagement-gallery-item:nth-child(7) {
  grid-column: 10/15;
  grid-row: 8/11;
}
@media (max-width: 960px) {
  .engagement-gallery-item:nth-child(1) {
    grid-column: 1/9;
    grid-row: 1/9;
  }
  .engagement-gallery-item:nth-child(2) {
    grid-column: 1/9;
    grid-row: 14/19;
  }
  .engagement-gallery-item:nth-child(3) {
    grid-column: 9/15;
    grid-row: 1/5;
  }
  .engagement-gallery-item:nth-child(4) {
    grid-column: 9/15;
    grid-row: 14/19;
  }
  .engagement-gallery-item:nth-child(5) {
    grid-column: 1/9;
    grid-row: 9/14;
  }
  .engagement-gallery-item:nth-child(6) {
    grid-column: 9/15;
    grid-row: 5/14;
  }
  .engagement-gallery-item:nth-child(7) {
    grid-column: 1/15;
    grid-row: 19/24;
  }
}
@media (max-width: 576px) {
  .engagement-gallery-item:nth-child(1) {
    grid-column: 1/2;
    grid-row: 1/3;
  }
  .engagement-gallery-item:nth-child(2) {
    grid-column: 1/9;
    grid-row: 8/12;
  }
  .engagement-gallery-item:nth-child(3) {
    grid-column: 1/1;
    grid-row: 4/8;
  }
  .engagement-gallery-item:nth-child(4), .engagement-gallery-item:nth-child(6), .engagement-gallery-item:nth-child(7) {
    display: none;
  }
  .engagement-gallery-item:nth-child(5) {
    grid-column: 1/9;
    grid-row: 13/19;
  }
}
.engagement-gallery-item img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.engagement-gallery-item--quote {
  color: #fff;
  text-align: center;
}
.engagement-gallery-item--quote p {
  margin-bottom: 10px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
  letter-spacing: 0.8px;
}
.engagement-gallery-item--quote p:last-child {
  margin-bottom: 0;
}
.engagement-gallery-item--quote em {
  color: #5ae6bd;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.5;
}
@media (max-width: 1280px) {
  .engagement-gallery-item--quote em {
    font-size: 17px;
  }
}
@media (max-width: 1024px) {
  .engagement-gallery-item--quote em {
    font-size: 14px;
  }
}
.engagement-gallery-item--quote > div {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 50px;
}
@media (max-width: 576px) {
  .engagement-gallery-item--quote > div {
    padding: 50px 35px;
  }
}
.engagement-gallery-item--quote .quote-dark {
  background: #333a3c;
}
@media (max-width: 960px) {
  .engagement-gallery-item--quote .quote-dark {
    padding: 35px;
  }
}
.engagement-gallery-item--quote .quote-green {
  background: #0e7a73;
}
@media (max-width: 960px) {
  .engagement-gallery-item--quote .quote-green {
    padding: 35px;
  }
}
.engagement-gallery-item--quote .quote-green ::selection {
  background: #0b635d;
}

.services {
  padding-bottom: 180px;
  padding-top: 147px;
}
@media (max-width: 960px) {
  .services {
    padding-bottom: 90px;
    padding-top: 107px;
  }
}
@media (max-width: 576px) {
  .services {
    padding-bottom: 40px;
    padding-top: 57px;
  }
}
@media (max-width: 480px) {
  .services {
    padding-bottom: 20px;
  }
}
.services_title {
  font-size: 36px;
  margin-bottom: 32px;
}
.services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -20px;
  margin-right: -20px;
}
.services-item {
  align-items: center;
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}
@media (max-width: 768px) {
  .services-item {
    flex-basis: 50%;
    margin-bottom: 60px;
  }
}
.services-item-image {
  height: 0;
  margin-bottom: 50px;
  padding: 0 15px 50%;
  position: relative;
  width: 100%;
}
@media (max-width: 768px) {
  .services-item-image {
    margin-bottom: 40px;
    padding: 0 15px 100px;
  }
}
@media (max-width: 480px) {
  .services-item-image {
    margin-bottom: 20px;
    padding: 0 10px 70px;
  }
}
.services-item-image img {
  height: 100%;
  left: 0;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%;
}
.services-item-title {
  margin-bottom: 25px;
}
@media (max-width: 768px) {
  .services-item-title {
    max-width: 160px;
  }
}
@media (max-width: 480px) {
  .services-item-title {
    max-width: 130px;
  }
}
.services-item-list ul li {
  line-height: 1;
  margin-bottom: 10px;
  padding-left: 16px;
  position: relative;
}
.services-item-list ul li::before {
  color: #20b59a;
  content: "\e92d";
  font-family: icomoon !important;
  font-size: 8px;
  left: 1px;
  position: absolute;
  top: 9px;
}
@media (max-width: 1280px) {
  .services-item-list ul li::before {
    top: 8px;
  }
}
@media (max-width: 1024px) {
  .services-item-list ul li::before {
    top: 7px;
  }
}
@media (max-width: 480px) {
  .services-item-list ul li::before {
    top: 7px;
  }
}
.services-item-list ul li a {
  color: #5b6770;
  text-decoration-line: underline;
}
.services-item-list ul li a:hover {
  color: #20b59a;
}
.services--secondary {
  background: #f9f9f9;
  padding-top: 213px;
}
@media (max-width: 576px) {
  .services--secondary {
    padding-top: 80px;
  }
}
@media (max-width: 480px) {
  .services--secondary {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}
.services--secondary .services-item {
  align-items: flex-start;
  text-align: left;
}
@media (max-width: 480px) {
  .services--secondary .services-item {
    flex-basis: 100%;
  }
  .services--secondary .services-item:last-child {
    margin-bottom: 0;
  }
}
.services--secondary .services-item-title {
  font-size: 22px;
  line-height: 1.2222222222;
  margin-bottom: 34px;
  text-align: left;
}
@media (max-width: 1280px) {
  .services--secondary .services-item-title {
    font-size: 20px;
  }
}
@media (max-width: 1024px) {
  .services--secondary .services-item-title {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .services--secondary .services-item-title {
    max-width: unset;
  }
}
.services--secondary .services-item-title a {
  color: #212322;
}
.services--secondary .services-item-title a:hover {
  color: #0e7a73;
}
.services--secondary .services-item-image {
  margin-bottom: 75px;
}
@media (max-width: 768px) {
  .services--secondary .services-item-image {
    margin-bottom: 40px;
  }
}
@media (max-width: 480px) {
  .services--secondary .services-item-image {
    margin-bottom: 20px;
    padding: 0 10px 100px;
  }
}
@media (max-width: 768px) {
  .services--secondary .services-item-image img {
    object-position: left;
  }
}
.services--inner {
  padding-bottom: 20px;
  padding-top: 60px;
}
.services--block {
  display: flex;
  padding: 0;
  gap: 32px;
  max-width: 1002px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .services--block {
    flex-wrap: wrap;
  }
}
.services--block .services__single {
  display: flex;
  gap: 22px;
  align-items: flex-start;
  min-height: 280px;
  background: #333a3c;
  padding: 40px 32px 50px;
  width: 302px;
}
@media (max-width: 960px) {
  .services--block .services__single {
    width: 600px;
  }
}
@media (max-width: 768px) {
  .services--block .services__single {
    width: 100%;
    margin: auto;
  }
}
.services--block .services__single img {
  width: 140px !important;
  height: auto !important;
}
@media (max-width: 960px) {
  .services--block .services__single img {
    width: 100px !important;
  }
}
@media (max-width: 768px) {
  .services--block .services__single img {
    width: 126px !important;
  }
}
@media (max-width: 480px) {
  .services--block .services__single img {
    width: 75px !important;
  }
}
.services--block .services__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.services--block .services__content h2 {
  font-size: 24px;
  letter-spacing: 0.72px;
  margin-top: 0px;
}
@media (max-width: 960px) {
  .services--block .services__content h2 {
    min-height: 90px;
  }
}
@media (max-width: 768px) {
  .services--block .services__content h2 {
    min-height: unset;
  }
}
.services--block .services__content .btn {
  margin-top: 37px;
  margin-bottom: 0px;
  max-width: fit-content;
  color: #212322;
  font-size: 14px;
  padding: 11px 20px;
}

.service-card {
  margin-bottom: 100px;
}
@media (max-width: 768px) {
  .service-card {
    margin-bottom: 60px;
  }
}
@media (max-width: 480px) {
  .service-card {
    margin-bottom: 40px;
  }
}
.service-card__inner {
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.15);
  display: flex;
  min-height: 412px;
}
@media (max-width: 768px) {
  .service-card__inner {
    flex-direction: column;
  }
}
.service-card__background {
  flex-basis: 45%;
  flex-shrink: 0;
}
.service-card__background-holder {
  height: 100%;
}
.service-card__background-holder img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.service-card__content {
  flex-grow: 1;
  padding: 40px;
}
@media (max-width: 480px) {
  .service-card__content {
    padding: 25px;
  }
}
.service-card__heading {
  margin-bottom: 30px;
}
.service-card__description {
  margin-bottom: 20px;
}
.service-card__description p {
  margin-bottom: 1.3em;
}
.service-card__description p:last-child {
  margin-bottom: 0;
}
.service-card__description a {
  color: #212322;
  font-weight: 400;
}
.service-card__description a:hover {
  color: #20b59a;
}
.service-card--inverse .service-card__inner {
  flex-direction: row-reverse;
}
@media (max-width: 768px) {
  .service-card--inverse .service-card__inner {
    flex-direction: column;
  }
}
.service-card--small .service-card__inner {
  min-height: auto;
  max-height: 320px;
}
.service-card--small .service-card__background {
  flex-basis: 33%;
}

.process-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service-block {
  width: 100%;
  max-width: 864px;
  margin: auto;
  display: flex;
  gap: 20px;
  color: white;
}
.service-block h2 {
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 0.72px;
  line-height: 120%;
  display: flex;
  align-items: center;
}
.service-block h2 .number {
  min-width: 48px;
  width: 48px;
  height: 48px;
  background-image: url(/wp-content/themes/fullclarity/assets/src/img/white-circle.svg);
  background-size: 48px;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
.service-block h2 .number span {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 2px;
  display: block;
}
.service-block h4 {
  text-transform: none;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.48px;
  line-height: 120%;
}
.service-block p {
  font-size: 16px;
  font-family: "Roboto Slab", serif;
  font-weight: 300;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.64px;
  margin: 19px 0 36px;
}
.service-block img {
  aspect-ratio: 420/258;
  object-fit: cover;
}
.service-block ul {
  margin-left: 12px;
  margin-bottom: 30px;
}
.service-block li {
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.56px;
  font-family: "Roboto Slab", serif;
  margin-bottom: 8px;
  padding-inline-start: 4px;
  list-style-image: url(/wp-content/themes/fullclarity/assets/src/img/bullet.svg);
}
.service-block li a {
  color: white;
}
.service-block li a:hover {
  color: #5ae6bd;
}
.service-block .btn {
  color: white;
  border-color: white;
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 400;
  border-width: 1px;
  transition: all 250ms;
}
.service-block .btn:hover {
  color: #5ae6bd;
  border-color: #5ae6bd;
}
.service-block .col:nth-child(2) {
  padding: 64px 80px 0;
  max-width: 422px;
}
@media (max-width: 1024px) {
  .service-block .col:nth-child(2) {
    padding: 64px 40px 0 80px;
  }
}
@media (max-width: 960px) {
  .service-block .col:nth-child(2) {
    padding: 64px 0px 0 40px;
    max-width: 260px;
  }
}
@media (max-width: 768px) {
  .service-block .col:nth-child(2) {
    padding: 30px 0px 0;
    max-width: 260px;
  }
}
@media (max-width: 768px) {
  .service-block {
    flex-direction: column;
    margin: -30px 0;
    padding: 0 20px;
  }
}

.competencies-block .flex-grid {
  display: flex;
  justify-content: center;
  gap: 40px 16px;
  flex-wrap: wrap;
  max-width: 848px;
  margin: 0 auto;
}
.competencies-block .flex-grid img {
  margin-bottom: 32px;
  height: 42px;
  width: auto;
  max-width: 42px;
}
.competencies-block .flex-grid > div {
  width: 200px;
  height: 176px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.32px;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .competencies-block .flex-grid {
    gap: 20px 16px;
  }
  .competencies-block .flex-grid > div {
    height: 150px;
  }
  .competencies-block .flex-grid img {
    margin-bottom: 20px;
  }
}
.competencies-block.grey .flex-grid > div {
  background: #f9f9f9;
}

.workflow-block {
  display: flex;
  margin: 0 auto;
  max-width: 964px;
  justify-content: space-between;
  align-items: center;
  gap: 90px;
  padding: 32px 50px;
}
@media (max-width: 1024px) {
  .workflow-block {
    gap: 40px;
  }
}
@media (max-width: 768px) {
  .workflow-block {
    flex-wrap: wrap;
    justify-content: center;
    gap: 80px;
    padding: 0px 50px;
  }
}
.workflow-block .col {
  max-width: 348px;
}
@media (max-width: 768px) {
  .workflow-block .col {
    max-width: 500px;
  }
}
.workflow-block .col .wheel-item {
  background: #f9f9f9;
  min-height: 170px;
}
.workflow-block .col h4 {
  color: #0e7a73;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 21.6px */
  letter-spacing: 0.36px;
  margin-bottom: 12px;
}
.workflow-block .col h3 {
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 43.2px */
  letter-spacing: 0.72px;
  margin-bottom: 20px;
}
.workflow-block .col p {
  color: #333a3c;
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.64px;
  margin-bottom: 40px;
}

.col-right {
  min-width: 416px;
  height: 416px;
  position: relative;
}
@media (max-width: 768px) {
  .col-right {
    height: 360px;
  }
}

.sprint-wheel {
  position: relative;
  padding: 44px;
  transform: scale(0.77);
  top: -40px;
  left: -80px;
}
@media (max-width: 1024px) {
  .sprint-wheel {
    transform: scale(0.66);
    top: 0;
    left: -120px;
  }
}
@media (max-width: 960px) {
  .sprint-wheel {
    transform: scale(0.5);
    top: 0;
    left: -120px;
  }
}
@media (max-width: 768px) {
  .sprint-wheel {
    transform: scale(0.66);
    top: -40px;
    left: -90px;
  }
}
@media (max-width: 480px) {
  .sprint-wheel {
    transform: scale(0.5);
    top: -40px;
    left: -70px;
  }
}
.sprint-wheel .center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.sprint-wheel .center .numbers, .sprint-wheel .center .units {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 42.108px;
  letter-spacing: 0.842px;
  line-height: 100%;
}
.sprint-wheel .center .numbers {
  font-size: 132px;
  line-height: 100%;
  margin: 18px 0 -18px;
}
.sprint-wheel svg g {
  transform: translate(0, 0);
  transition: all 250ms;
}
.sprint-wheel svg g.radial-text.selected[data-wheel-value="1"] {
  transform: translate(1px, -10px);
}
.sprint-wheel svg g.radial-text.selected[data-wheel-value="2"] {
  transform: translate(8px, -5px);
}
.sprint-wheel svg g.radial-text.selected[data-wheel-value="3"] {
  transform: translate(10px, 4px);
}
.sprint-wheel svg g.radial-text.selected[data-wheel-value="4"] {
  transform: translate(-4px, 9px);
}
.sprint-wheel svg g.radial-text.selected[data-wheel-value="5"] {
  transform: translate(-7px, -10px);
}
.sprint-wheel svg g.radial-text.selected[data-wheel-value="6"] {
  transform: translate(-2px, -12px);
}
.sprint-wheel .overlay {
  z-index: 100;
  position: absolute;
}
.sprint-wheel .overlay path {
  pointer-events: none;
  fill: black;
}
.sprint-wheel .overlay path.trigger {
  pointer-events: all;
  cursor: pointer;
}
.sprint-wheel .overlay path.selected {
  fill: white;
}
.sprint-wheel .outer, .sprint-wheel .middle {
  position: absolute;
  width: 540px;
  height: 540px;
  z-index: 0;
}
.sprint-wheel .outer .mask, .sprint-wheel .outer .mask2, .sprint-wheel .outer .mask3, .sprint-wheel .middle .mask, .sprint-wheel .middle .mask2, .sprint-wheel .middle .mask3 {
  position: absolute;
  width: 460px;
  height: 460px;
  left: 40px;
  top: 40px;
  border-radius: 50%;
  background-color: white;
  z-index: 1;
  pointer-events: none;
}
.sprint-wheel .outer .scaler.selected .wrapper, .sprint-wheel .middle .scaler.selected .wrapper {
  transform: rotate(0deg) scale(1.085);
}
.sprint-wheel .outer .scaler.selected .wrapper .sector, .sprint-wheel .middle .scaler.selected .wrapper .sector {
  background: #0e7a73;
}
.sprint-wheel .outer .scaler .wrapper, .sprint-wheel .middle .scaler .wrapper {
  width: 540px;
  height: 540px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  transition: all 250ms;
  transform: rotate(0deg) scale(1);
}
.sprint-wheel .outer .scaler .wrapper .sector, .sprint-wheel .middle .scaler .wrapper .sector {
  position: absolute;
  transform-origin: 100% 100%;
  background: transparent;
  width: 5000px;
  height: 5000px;
  left: 50%;
  top: 50%;
  margin-top: -5000px;
  margin-left: -5000px;
  background: #F2F2F2;
  z-index: 0;
  transition: all 250ms;
  cursor: pointer;
}
.sprint-wheel .outer .scaler .wrapper .sector-1, .sprint-wheel .middle .scaler .wrapper .sector-1 {
  transform: rotate(90deg) skew(54deg);
}
.sprint-wheel .outer .scaler .wrapper .sector-2, .sprint-wheel .middle .scaler .wrapper .sector-2 {
  transform: rotate(126deg) skew(18deg);
}
.sprint-wheel .outer .scaler .wrapper .sector-3, .sprint-wheel .middle .scaler .wrapper .sector-3 {
  transform: rotate(198deg) skew(54deg);
}
.sprint-wheel .outer .scaler .wrapper .sector-4a, .sprint-wheel .middle .scaler .wrapper .sector-4a {
  transform: rotate(234deg) skew(0deg);
}
.sprint-wheel .outer .scaler .wrapper .sector-4b, .sprint-wheel .middle .scaler .wrapper .sector-4b {
  transform: rotate(323deg) skew(35deg);
}
.sprint-wheel .outer .scaler .wrapper .sector-5, .sprint-wheel .middle .scaler .wrapper .sector-5 {
  transform: rotate(378deg) skew(54deg);
}
.sprint-wheel .outer .scaler .wrapper .sector-6, .sprint-wheel .middle .scaler .wrapper .sector-6 {
  transform: rotate(414deg) skew(54deg);
}
.sprint-wheel .middle {
  z-index: 10;
  pointer-events: none;
  left: 90px;
  top: 90px;
  width: 448px;
  height: 448px;
}
.sprint-wheel .middle .mask {
  width: 368px;
  height: 368px;
  left: 40px;
  top: 40px;
  opacity: 0.6;
}
.sprint-wheel .middle .mask2 {
  width: 298px;
  height: 298px;
  left: 75px;
  top: 75px;
  z-index: 15;
  border: 4px solid #f2f2f2;
}
.sprint-wheel .middle .mask3 {
  width: 258px;
  height: 258px;
  left: 95px;
  top: 95px;
  z-index: 35;
}
.sprint-wheel .middle .scaler.selected .wrapper {
  transform: rotate(0deg) scale(1);
}
.sprint-wheel .middle .scaler.selected .wrapper .sector {
  background: #20b59a;
}
.sprint-wheel .middle .scaler .wrapper {
  width: 448px;
  height: 448px;
  transform: rotate(0deg) scale(1);
}
.sprint-wheel .middle .scaler .wrapper .sector {
  background: #C1C3C3;
}
.sprint-wheel .middle .ticks {
  position: absolute;
  top: 224px;
  left: 224px;
  z-index: 10;
  pointer-events: none;
}
.sprint-wheel .middle .ticks.phase-ticks {
  z-index: 30;
}
.sprint-wheel .middle .ticks .tick {
  width: 452px;
  position: absolute;
}
.sprint-wheel .middle .ticks .tick .line {
  height: 0.5px;
  position: relative;
  background: white;
  top: -0.25px;
}
.sprint-wheel .middle .ticks .tick.thick .line {
  height: 2px;
}
.sprint-wheel .middle .ticks .tick.phase-tick {
  background: transparent;
  width: 304px;
}
.sprint-wheel .middle .ticks .tick.phase-tick .line {
  height: 2px;
  position: relative;
  background: #9da0a1;
  transform: translateX(-50%);
}

.studio__background img {
  height: auto;
  width: 100%;
}
.studio__content {
  background: #f9f9f9;
  padding-bottom: 70px;
}
@media (max-width: 576px) {
  .studio__content {
    background: #fff;
    padding-bottom: 30px;
  }
}
.studio__inner {
  background: #fff;
  box-shadow: 0 0 18px 3px rgba(0, 0, 0, 0.1);
  margin-top: -80px;
  padding: 50px;
  position: relative;
}
.studio__inner h3 {
  font-size: 28px !important;
}
.studio__inner p {
  font-size: 14px !important;
  line-height: 24px !important;
}
@media (max-width: 768px) {
  .studio__inner {
    margin-top: 0;
    padding: 30px;
  }
}
@media (max-width: 576px) {
  .studio__inner {
    box-shadow: unset;
    padding: 30px 0;
  }
}
.studio__heading {
  margin-bottom: 15px;
}
.studio__text {
  color: #333a3c;
}
.studio__text a {
  font-weight: 400;
}
.studio__text.flex {
  gap: 72px;
}
.studio__text.flex > div:first-child {
  flex: 1;
}
.studio__text.flex > div:last-child {
  width: 290px;
}
@media (max-width: 960px) {
  .studio__text.flex > div:last-child {
    width: 200px;
  }
}
.studio__text.flex > div:last-child p:last-child {
  margin-top: 17px;
}
@media (max-width: 768px) {
  .studio__text.flex {
    flex-direction: column;
    gap: 30px;
  }
  .studio__text.flex > div {
    width: 100%;
  }
}

.competencies {
  background: #f9f9f9;
  padding-bottom: 60px;
  padding-top: 80px;
}
@media (max-width: 768px) {
  .competencies {
    padding-bottom: 40px;
    padding-top: 60px;
  }
}
@media (max-width: 480px) {
  .competencies {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}
.competencies__title {
  margin-bottom: 48px;
  text-align: center;
}
@media (max-width: 960px) {
  .competencies__title {
    margin-bottom: 40px;
  }
}
.competencies__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.competencies__item {
  align-items: center;
  display: flex;
  flex-basis: 25%;
  flex-direction: column;
  margin-bottom: 45px;
  padding-bottom: 25px;
  padding-top: 50px;
  text-align: center;
}
@media (max-width: 768px) {
  .competencies__item {
    flex-basis: 33.3333%;
    margin-bottom: 30px;
  }
}
@media (max-width: 480px) {
  .competencies__item {
    flex-basis: 50%;
    margin-bottom: 20px;
    padding-bottom: 5px;
    padding-top: 20px;
  }
}
.competencies__item-icon {
  display: inline-block;
  height: 52px;
  margin-bottom: 30px;
  width: 52px;
}
@media (max-width: 1024px) {
  .competencies__item-icon {
    height: 46px;
    width: 46px;
  }
}
.competencies__item-icon img {
  height: 100%;
  object-fit: contain;
  opacity: 0.8;
  width: 100%;
}

.working-remotely {
  background-color: #f2f2f2;
  padding-bottom: 90px;
  padding-top: 60px;
}
@media (max-width: 768px) {
  .working-remotely {
    padding-bottom: 60px;
    padding-top: 40px;
  }
}
@media (max-width: 480px) {
  .working-remotely {
    padding-top: 20px;
  }
}
.working-remotely__title {
  margin-bottom: 22px;
}
.working-remotely__description {
  margin-bottom: 90px;
  max-width: 950px;
}
@media (max-width: 1024px) {
  .working-remotely__description {
    max-width: 757px;
  }
}
@media (max-width: 768px) {
  .working-remotely__description {
    margin-bottom: 40px;
  }
}
.working-remotely__holder {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .working-remotely__holder {
    flex-direction: column;
  }
}
.working-remotely__image {
  flex-basis: 50%;
  flex-grow: 0;
}
@media (max-width: 768px) {
  .working-remotely__image {
    flex-basis: auto;
    margin-bottom: 30px;
  }
}
.working-remotely__image img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.working-remotely__list {
  flex-basis: 50%;
  padding-left: 60px;
}
@media (max-width: 768px) {
  .working-remotely__list {
    flex-basis: 100%;
    padding-left: 0;
  }
}
.working-remotely__item {
  margin-bottom: 48px;
}
.working-remotely__item:last-child {
  margin-bottom: 0;
}
.working-remotely__item-title {
  margin-bottom: 10px;
}

.layout-flex {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .layout-flex {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
@media (max-width: 576px) {
  .layout-flex {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 960px) {
  .layout-flex .card-item {
    flex-basis: 50%;
    margin-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 768px) {
  .layout-flex .card-item {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
  }
}
.layout-flex .card-item.card-item--working-together {
  flex-basis: 33.333%;
}
@media (max-width: 768px) {
  .layout-flex .card-item.card-item--working-together {
    flex-basis: 50%;
    margin-bottom: 25px;
  }
}
@media (max-width: 576px) {
  .layout-flex .card-item.card-item--working-together {
    flex-basis: 100%;
  }
}
.layout-flex .card-item.card-item--working-together img {
  height: 160px;
  max-width: 100%;
  width: auto;
}

.columns {
  display: flex;
  justify-content: space-between;
  margin-left: -40px;
  margin-right: -40px;
}
@media (max-width: 960px) {
  .columns {
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 576px) {
  .columns {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 960px) {
  .columns {
    flex-wrap: wrap;
  }
}
.columns__item {
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 40px;
  padding-right: 40px;
}
@media (max-width: 960px) {
  .columns__item {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 576px) {
  .columns__item {
    padding-left: 0;
    padding-right: 0;
  }
}
.columns.columns-2 .columns__item {
  flex-basis: 50%;
}
@media (max-width: 576px) {
  .columns.columns-2 .columns__item {
    flex-basis: 100%;
  }
}
.columns--gallery {
  padding-top: 70px;
}
@media (max-width: 768px) {
  .columns--gallery {
    padding-top: 50px;
  }
}
@media (max-width: 960px) {
  .columns--gallery .columns__item {
    margin-bottom: 0;
  }
}
.columns--gallery .columns__item:last-child {
  padding-top: 84px;
}
@media (max-width: 768px) {
  .columns--gallery .columns__item:last-child {
    padding-top: 0;
  }
}

.card-item {
  flex-basis: 0;
}
.card-item__photo {
  border-radius: 50%;
  height: 175px;
  margin-bottom: 38px;
  overflow: hidden;
  width: 175px;
}
@media (max-width: 1024px) {
  .card-item__photo {
    height: 140px;
    width: 140px;
  }
}
@media (max-width: 768px) {
  .card-item__photo {
    margin-bottom: 27px;
  }
}
@media (max-width: 480px) {
  .card-item__photo {
    height: 100px;
    width: 100px;
  }
}
.card-item--team {
  flex-basis: auto;
}
@media (max-width: 1024px) {
  .card-item--team {
    flex-basis: 0;
  }
}
.card-item__photo-link {
  border-radius: 50%;
  display: inline-block;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.card-item__photo-link img {
  height: 100%;
  object-fit: cover;
  transition: filter 0.16s;
  width: 100%;
}
.card-item__photo-link::after {
  border: 5px solid #fff;
  border-radius: 50%;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s;
}
.card-item__photo-link:hover::after {
  opacity: 0.3;
}
.card-item__hover-info {
  align-items: flex-end;
  background-color: rgba(0, 160, 214, 0.2);
  border-radius: 50%;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.16s;
}
.card-item__subheader {
  color: #212322;
  margin-bottom: 5px;
}
.card-item__header {
  color: #0e7a73;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .card-item__header {
    margin-bottom: 15px;
  }
}
.card-item--team .card-item__header {
  margin-bottom: 10px;
}
.card-item__header span {
  color: #0e7a73;
  display: none;
  margin-left: 20px;
}
.card-item__header span::before {
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
}
.card-item__header--bold {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 24px;
  font-weight: 600;
}
.card-item__header-link {
  color: #212322;
  font-weight: 600;
  position: relative;
  text-decoration: underline;
}
.card-item__header-link::before {
  color: #333a3c;
  content: "\e90f";
  display: none;
  font-family: "icomoon" !important;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  pointer-events: none;
  position: absolute;
  right: -12px;
  top: 50%;
  transform: translateY(-50%) translateX(100%);
}
.card-item__header-link:hover::before {
  color: #0e7a73;
}
.card-item__link {
  opacity: 0;
}
.card-item:hover .card-item__link {
  opacity: 1;
}
.card-item__image {
  align-items: center;
  background: #fff;
  display: flex;
  height: 140px;
  justify-content: flex-start;
  margin-bottom: 70px;
  max-width: 200px;
  position: relative;
}
@media (max-width: 768px) {
  .card-item__image {
    margin-bottom: 50px;
  }
}
@media (max-width: 480px) {
  .card-item__image {
    margin-bottom: 30px;
  }
}
.card-item__image svg {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  width: auto;
}
.card-item__info {
  margin-bottom: 32px;
}
.card-item__info, .card-item__info a {
  color: #333a3c;
  line-height: 1.5;
  text-decoration: none;
}
.card-item--team .card-item__info, .card-item--team .card-item__info a {
  line-height: 1.7777777778;
}
.card-item__info a:hover {
  text-decoration: underline;
}

.step-animation__item {
  position: relative;
}
.step-animation__card {
  left: 0;
  opacity: 0;
  position: relative;
  top: 0;
  transition: all 0.3s;
  visibility: hidden;
}
.step-animation__card svg {
  display: inline-block;
}
.step-animation__card .blue-circle-svg {
  stroke: #bdccdb;
}
.step-animation__card .blue-circle-svg.is-highlighted {
  stroke: #00addb;
}
.step-animation__card .blue-circle {
  background: #fff;
  border: 2px solid #5ae6bd;
  border-radius: 50%;
  height: 12px;
  left: -5px;
  position: absolute;
  top: -5px;
  width: 12px;
}
.step-animation__card .blue-circle.is-active {
  border-color: #5ae6bd;
}
.step-animation__card .absolute-layer {
  left: 0;
  position: absolute;
  top: 0;
}
.step-animation__card:first-child {
  opacity: 1;
  position: relative;
  visibility: visible;
}
.step-animation__card.is-visible {
  opacity: 1;
  visibility: visible;
}

.price-card {
  display: flex;
  flex-basis: 33.33333%;
  margin-bottom: 38px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (max-width: 960px) {
  .price-card {
    flex-basis: 50%;
    max-width: 340px;
  }
}
@media (max-width: 480px) {
  .price-card {
    padding-left: 0;
    padding-right: 0;
  }
}
.price-card--popular .price-card__inner {
  border-color: #0e7a73;
}
.price-card__sticker {
  background: #0e7a73;
  color: #fff;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.89px;
  padding-top: 2px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transform: rotate(42deg) translate(29px, -11px);
  transform-origin: center;
  width: 120px;
}
.price-card__holder {
  border-radius: 9px;
  box-shadow: 0 2px 12px 0 rgba(64, 54, 78, 0.1);
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}
.price-card__inner {
  background: #fff;
  border-top: 6px solid #0e7a73;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding: 42px 30px 50px;
  position: relative;
  text-align: center;
}
@media (max-width: 480px) {
  .price-card__inner {
    padding: 31px 15px 30px;
  }
}
.price-card__price {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 12px;
}
@media (max-width: 480px) {
  .price-card__price {
    margin-bottom: 5px;
  }
}
.price-card__price sup {
  font-size: 28px;
  margin-left: 3px;
  margin-right: 3px;
  top: -0.45em;
}
.price-card__title {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.35px;
  line-height: 1.3333333333;
  margin-bottom: 30px;
}
@media (max-width: 480px) {
  .price-card__title {
    margin-bottom: 20px;
  }
}
.price-card__description {
  color: #333a3c;
  font-size: 16px;
  margin-bottom: 56px;
  text-align: left;
}
@media (max-width: 480px) {
  .price-card__description {
    margin-bottom: 30px;
  }
}
.price-card__description ul li {
  margin-bottom: 10px;
  padding-left: 25px;
  position: relative;
}
@media (max-width: 480px) {
  .price-card__description ul li {
    margin-bottom: 8px;
  }
}
.price-card__description ul li::before {
  content: "\e90d";
  font-family: icomoon !important;
  font-size: 6px;
  left: 7px;
  position: absolute;
  top: 7px;
}
.price-card__action {
  padding-left: 10px;
  padding-right: 10px;
}
.price-card__action .btn {
  width: 100%;
}

@keyframes highlight {
  0% {
    color: #333a3c;
    stroke: #333a3c;
  }
  50% {
    color: #0e7a73;
    stroke: #0e7a73;
  }
  100% {
    color: #333a3c;
    stroke: #333a3c;
  }
}
@keyframes spinner {
  0% {
    stroke-dashoffset: 19;
    transform: rotate(24deg);
  }
  50% {
    stroke-dashoffset: 120;
    transform: rotate(500deg);
  }
  100% {
    stroke-dashoffset: 19;
    transform: rotate(744deg);
  }
}
svg.spinner {
  height: 40px;
  viewBox: 0 0 40 40;
  width: 40px;
  x: 0;
  y: 0;
}
svg.spinner.spinner--dark {
  position: absolute;
}
svg.spinner.spinner--dark circle {
  stroke: #212322;
  stroke-dashoffset: 126;
  transition: all 0.6s;
}
svg.spinner circle {
  fill: transparent;
  stroke: #333a3c;
  stroke-dasharray: 125.6;
  stroke-dashoffset: 19;
  stroke-linecap: round;
  stroke-width: 2.5;
  transform: rotate(24deg);
  transform-origin: 20px 20px 0;
  transition: all 0.16s;
}

.contact-form {
  backface-visibility: hidden;
  background-color: #fff;
  height: 100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: all 0.3s;
  visibility: hidden;
  width: 100vw;
  z-index: 20;
}
@media (max-width: 768px) {
  .contact-form {
    background-color: #f9f9f9;
    height: calc(100% - 102px);
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    top: 102px;
  }
}
.contact-form.is-active {
  opacity: 1;
  visibility: visible;
}
.contact-form__inner {
  background: #fff;
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
@media (max-width: 768px) {
  .contact-form__inner {
    display: block;
    flex-direction: column;
    height: auto;
  }
}
.contact-form__aside {
  color: #fff;
  max-width: 320px;
  min-width: 320px;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  position: relative;
  transform: translateX(-20px);
  transition: all 0.3s;
  width: 100%;
}
@media (max-width: 768px) {
  .contact-form__aside {
    color: #212322;
  }
}
@media (max-width: 960px) {
  .contact-form__aside {
    min-width: 280px;
  }
}
@media (max-width: 768px) {
  .contact-form__aside {
    max-width: unset;
    overflow: visible;
    -webkit-overflow-scrolling: auto;
    transform: none;
    width: 100%;
    z-index: 1;
  }
}
.contact-form__aside-header {
  align-items: center;
  color: #212322;
  display: none;
  justify-content: space-between;
  padding: 38px 30px 14px;
}
@media (max-width: 768px) {
  .contact-form__aside-header {
    background-color: #f9f9f9;
    display: flex;
  }
}
.contact-form__aside-content {
  height: 100%;
  position: relative;
}
@media (max-width: 768px) {
  .contact-form__aside-content {
    padding-bottom: 36px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
.contact-form__aside-footer {
  align-items: center;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.5);
  display: none;
  height: 90px;
  justify-content: center;
}
@media (max-width: 768px) {
  .contact-form__aside-footer {
    display: flex;
  }
}
@media (max-width: 480px) {
  .contact-form__aside-footer {
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
  }
}
.contact-form__aside-footer .btn {
  margin-left: 10px;
  margin-right: 10px;
}
@media (max-width: 480px) {
  .contact-form__aside-footer .btn {
    flex-grow: 1;
    justify-content: center;
    text-align: center;
  }
}
.contact-form__aside-mobile {
  display: none;
}
@media (max-width: 768px) {
  .contact-form__aside-mobile {
    display: block;
  }
}
.contact-form__aside-mobile .contact-form__aside-group {
  margin-bottom: 0;
}
.contact-form__aside-mobile .contact-form__aside-group p {
  margin-bottom: 28px;
}
.contact-form__aside-background {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.contact-form__aside-background-image {
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
@media (max-width: 768px) {
  .contact-form__aside-background-image {
    display: block;
  }
}
.contact-form__aside-background-image img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.contact-form__aside-background-overlay {
  background: #333a3c;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
@media (max-width: 768px) {
  .contact-form__aside-background-overlay {
    background-color: #f9f9f9;
  }
}
.contact-form__aside-foreground {
  height: 100%;
  overflow: auto;
  position: relative;
}
.contact-form__aside-top {
  padding: 40px;
}
.contact-form__aside-bottom {
  border-top: 1px solid rgba(87, 92, 94, 0.5);
  padding: 40px 32px 45px;
  position: relative;
}
.contact-form__aside-desktop {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .contact-form__aside-desktop {
    display: none;
  }
}
.contact-form__aside-title {
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .contact-form__aside-title {
    font-size: 24px;
    margin-bottom: 0;
  }
}
.contact-form__aside-group {
  margin-bottom: 56px;
}
.contact-form__aside-group a {
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.029em;
  line-height: 1.6666666667;
  text-decoration: underline;
}
@media (max-width: 1024px) {
  .contact-form__aside-group a {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .contact-form__aside-group a {
    color: #333a3c;
  }
}
.contact-form__aside-group a:hover {
  color: #20b59a;
}
.contact-form__aside-group--contact-us {
  padding-top: 50px;
}
.contact-form__aside-group--find-us {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.6666666667;
  margin-bottom: 42px;
  padding-top: 0;
}
@media (max-width: 1024px) {
  .contact-form__aside-group--find-us {
    font-size: 12px;
  }
}
.contact-form__aside-group--map {
  margin-bottom: 15px;
}
.contact-form__aside-group--map a {
  padding-left: 16px;
  position: relative;
}
.contact-form__aside-group--map a::before {
  color: #20b59a;
  content: "\e92d";
  font-family: icomoon !important;
  font-size: 8px;
  left: 0;
  position: absolute;
  top: 3px;
}
@media (max-width: 1024px) {
  .contact-form__aside-group--map a::before {
    top: 2px;
  }
}
.contact-form__aside-testimonial {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
.contact-form__aside-testimonial-content {
  color: #c1c3c3;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: initial;
  line-height: 1.1666666667;
  margin-bottom: 10px;
}
@media (max-width: 1280px) {
  .contact-form__aside-testimonial-content {
    font-size: 14px;
    margin-bottom: 9px;
  }
}
@media (max-width: 1024px) {
  .contact-form__aside-testimonial-content {
    font-size: 12px;
    margin-bottom: 8px;
  }
}
.contact-form__aside-testimonial-caption {
  color: #fafafa;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.2727272727;
  padding-right: 70px;
}
@media (max-width: 1280px) {
  .contact-form__aside-testimonial-caption {
    font-size: 13px;
    padding-right: 65px;
  }
}
@media (max-width: 1024px) {
  .contact-form__aside-testimonial-caption {
    font-size: 11px;
    padding-right: 60px;
  }
}
.contact-form__aside-testimonial-logo {
  bottom: 30px;
  height: 100%;
  max-height: 32px;
  max-width: 70px;
  position: absolute;
  right: 30px;
  width: 100%;
}
@media (max-width: 1280px) {
  .contact-form__aside-testimonial-logo {
    max-height: 30px;
    max-width: 60px;
  }
}
@media (max-width: 1024px) {
  .contact-form__aside-testimonial-logo {
    max-height: 26px;
    max-width: 56px;
  }
}
.contact-form__aside-testimonial-logo img {
  height: 100%;
  object-fit: contain;
  width: 100%;
}
.contact-form__aside-content {
  font-family: "Roboto Slab", serif;
  letter-spacing: 0.04em;
}
.contact-form__aside-content ul {
  padding-left: 0;
}
@media (max-width: 768px) {
  .contact-form__aside-content ul {
    padding-left: 10px;
  }
}
.contact-form__aside-content ul li {
  line-height: 1;
  margin-bottom: 10px;
  padding-left: 16px;
  position: relative;
}
.contact-form__aside-content ul li::before {
  color: #20b59a;
  content: "\e92d";
  font-family: icomoon !important;
  font-size: 8px;
  left: 0;
  position: absolute;
  top: 9px;
}
@media (max-width: 1024px) {
  .contact-form__aside-content ul li::before {
    top: 8px;
  }
}
.contact-form__close {
  margin-right: 15px;
}
.contact-form__content {
  background-color: #fff;
  flex-grow: 3;
  overflow: hidden;
  padding-bottom: 90px;
  position: relative;
  width: 65%;
}
@media (max-width: 960px) {
  .contact-form__content {
    padding-bottom: 0;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .contact-form__content {
    background-color: #f9f9f9;
  }
}
.contact-form__content::before {
  background: linear-gradient(to bottom, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 70px;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
@media (max-width: 960px) {
  .contact-form__content::before {
    display: none;
  }
}
.contact-form__content-title {
  margin-bottom: 13px;
}
.contact-form__content-subtitle {
  margin-bottom: 40px;
}
@media (max-width: 960px) {
  .contact-form__content-subtitle {
    margin-bottom: 57px;
  }
}
.contact-form__content-inner {
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  padding-top: 90px;
}
@media (max-width: 960px) {
  .contact-form__content-inner {
    -webkit-overflow-scrolling: auto;
    padding-bottom: 88px;
    padding-top: 0;
  }
}
.contact-form__content-container {
  height: 100%;
  max-width: 800px;
  padding-bottom: 40px;
  padding-left: 55px;
  padding-right: 50px;
  width: 100%;
}
@media (max-width: 960px) {
  .contact-form__content-container {
    max-width: unset;
    padding: 30px 30px 10px 40px;
  }
}
.contact-form__content-footer {
  align-items: center;
  background: #fff;
  border-top: 1px solid #f1f1f1;
  bottom: 0;
  display: flex;
  height: 75px;
  justify-content: flex-end;
  left: 0;
  padding-left: 25px;
  padding-right: 25px;
  position: absolute;
  width: 100%;
  z-index: 10;
}
@media (max-width: 768px) {
  .contact-form__content-footer {
    background-color: #f9f9f9;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
  }
}
@media (max-width: 480px) {
  .contact-form__content-footer {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 480px) {
  .contact-form__content-footer .btn-transparent {
    display: none;
  }
}

.contact-form.is-active .contact-form__aside {
  transform: translateX(0);
}

.modal-form {
  padding-bottom: 40px;
}
@media (max-width: 960px) {
  .modal-form {
    padding-bottom: 0;
  }
}
.modal-form__field {
  flex-grow: 1;
  position: relative;
}
.modal-form__step {
  align-items: center;
  color: #333a3c;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 32px;
  justify-content: center;
  margin-right: 16px;
  position: relative;
  user-select: none;
  width: 32px;
}
@media (max-width: 768px) {
  .modal-form__step {
    margin-right: 15px;
  }
}
.modal-form__step-index {
  display: inline-flex;
  font-family: "Roboto Slab", serif;
  font-size: 18px;
  font-weight: 600;
  position: absolute;
  transition: all 0.2s;
}
.modal-form__step-circle {
  display: inline-flex;
  font-size: 40px;
  position: absolute;
  transition: all 0.2s;
}
.modal-form__step-circle svg circle {
  transition: all 0.2s;
}
.modal-form__step-checkmark {
  display: inline-flex;
  font-size: 12px;
  opacity: 0;
  position: absolute;
  transform: scale(0);
  transition: all 0.2s;
  visibility: hidden;
}
.modal-form__input {
  appearance: none;
  border-bottom: 1px solid transparent;
  border-bottom: 1px solid #c1c3c3;
  border-radius: 0;
  color: #333a3c;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  height: 28px;
  margin-top: 4px;
  min-width: 220px;
  padding-bottom: 1px;
  padding-left: 0;
  transition: all 0.2s;
  user-select: none;
  width: 220px;
}
@media (max-width: 768px) {
  .modal-form__input {
    background-color: #f9f9f9;
    font-size: 16px;
  }
}
.modal-form__input::selection {
  background: #0e7a73;
  color: #fff !important;
  text-shadow: none;
}
.modal-form__input:-webkit-autofill, .modal-form__input:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px white inset;
  -webkit-text-fill-color: #333;
}
.modal-form__input--readonly {
  border: none !important;
  cursor: default;
  pointer-events: none;
}
.modal-form__input--readonly::placeholder {
  opacity: 0.7 !important;
}
.modal-form__input--readonly:focus::placeholder {
  opacity: 1 !important;
}
.modal-form__input::placeholder {
  color: #333a3c;
  opacity: 0;
  transition: all 0.2s;
}
.modal-form__input:focus::placeholder {
  opacity: 0;
}
.modal-form__input-buffer {
  left: -1000px;
  position: absolute;
  top: -1000px;
  visibility: hidden;
  white-space: nowrap;
}
.modal-form__label {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  pointer-events: none;
  position: absolute;
  top: 4px;
  transition: all 0.2s;
}
.modal-form__label--title {
  color: #333a3c;
}
.modal-form__label--error {
  color: #333a3c;
  display: none;
}
.modal-form__label--persist {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.44px;
  opacity: 1;
  text-transform: uppercase;
  top: -12px;
}
.modal-form__group {
  margin-bottom: 40px;
}
.modal-form__group .modal-form__textarea {
  resize: none;
}
.modal-form__group:last-child {
  margin-bottom: 0;
}
.modal-form__group:first-child .modal-form__step::after {
  display: none;
}
.modal-form__group-top {
  display: flex;
  max-width: 100%;
}
.modal-form__group-bottom {
  display: none;
  padding-left: 48px;
  padding-top: 18px;
}
@media (max-width: 768px) {
  .modal-form__group-bottom {
    padding-top: 30px;
  }
}
.modal-form__group:hover .modal-form__step {
  color: #212322;
}
.modal-form__group:hover .modal-form__step-circle .spinner.spinner--dark circle {
  stroke: #212322;
  stroke-dashoffset: 19;
}
.modal-form__group:hover.group-complete .modal-form__input {
  color: #212322;
}
.modal-form__group:hover.group-complete .modal-form__input[type=text]::placeholder {
  color: #212322;
  opacity: 1 !important;
}
.modal-form__group:hover.group-complete .modal-form__textarea {
  border-bottom: 1px solid rgba(51, 58, 60, 0.3);
  color: #333a3c;
}
.modal-form__group:hover.group-complete .modal-form__textarea:hover {
  border-bottom: 1px solid #333a3c;
}
.modal-form__group:hover.group-complete .modal-form__textarea:focus {
  border-bottom-color: #5b6770;
  color: #212322;
}
.modal-form__group.group-allow .modal-form__input {
  user-select: inherit;
}
.modal-form__group.group-incomplete:not(.group-focus) .modal-form__label--title {
  font-size: 11px;
  font-weight: 400;
  opacity: 0;
  text-transform: uppercase;
  top: -9px;
}
.modal-form__group.group-complete:not(.group-focus) .modal-form__label--title {
  font-size: 11px;
  font-weight: 400;
  opacity: 1;
  text-transform: uppercase;
  top: -9px;
}
.modal-form__group.group-complete:not(.group-focus) .modal-form__label--persist {
  opacity: 1;
}
.modal-form__group.group-complete .modal-form__step-checkmark {
  color: #0e7a73;
  opacity: 1;
  transform: scale(1);
  visibility: visible;
}
.modal-form__group.group-complete .modal-form__step-index {
  opacity: 0;
  transform: scale(0);
  visibility: hidden;
}
.modal-form__group.group-complete .modal-form__step-circle svg circle {
  stroke: #0e7a73;
}
.modal-form__group.group-complete .modal-form__textarea {
  width: 100%;
}
.modal-form__group.group-focus .modal-form__step {
  color: #0e7a73;
}
.modal-form__group.group-focus .modal-form__step svg circle {
  stroke: #0e7a73;
}
.modal-form__group.group-focus .modal-form__label--title {
  color: #9da0a1;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  top: -9px;
}
.modal-form__group.group-focus .modal-form__label--readonly {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
  top: unset;
}
.modal-form__group.group-focus .modal-form__input {
  border-bottom-color: #0e7a73;
}
.modal-form__group.group-focus .modal-form__textarea {
  width: 100%;
}
.modal-form__group.group-error .modal-form__label {
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
  top: -4px;
}
.modal-form__group.group-error .modal-form__label--error {
  display: block;
}
.modal-form__group.group-error .modal-form__label--title {
  display: none;
}
.modal-form__group.group-highlight .modal-form__step > * {
  animation: highlight 800ms ease-out;
  animation-iteration-count: 2;
}
.modal-form__choices-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  width: 640px;
}
@media (max-width: 768px) {
  .modal-form__choices-list {
    margin-left: -3px;
    margin-right: -3px;
  }
}
.modal-form__choices-item {
  flex-basis: 33.3333%;
  margin-bottom: 6px;
  padding-left: 5px;
  padding-right: 5px;
}
@media (max-width: 960px) {
  .modal-form__choices-item {
    flex-basis: 50%;
  }
}
@media (max-width: 768px) {
  .modal-form__choices-item {
    padding-left: 3px;
    padding-right: 3px;
  }
}
.modal-form__choices-item input {
  display: none;
}
.modal-form__choices-item input:checked + label {
  background-color: #5b6770;
  border-color: #5b6770;
  color: #fff;
}
.modal-form__choices-item label {
  align-items: center;
  border: 1px solid #c1c3c3;
  border-radius: 8px;
  color: #7b7f80;
  cursor: pointer;
  display: flex;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 36px;
  justify-content: center;
  line-height: 1.1428571429;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
  text-align: center;
  transition: all 0.1s;
  user-select: none;
  width: 200px;
}
.modal-form__choices-item label:hover {
  background-color: #5b6770;
  border-color: #5b6770;
  color: #fff;
}

div.wpcf7-response-output {
  display: none !important;
}
div.wpcf7 .ajax-loader {
  display: none;
}

.breadcrumbs {
  padding-top: 80px;
}
@media (max-width: 1024px) {
  .breadcrumbs {
    padding-top: 85px;
  }
}
@media (max-width: 960px) {
  .breadcrumbs {
    padding-top: 80px;
  }
}
@media (max-width: 768px) {
  .breadcrumbs {
    padding-top: 60px;
  }
}
@media (max-width: 480px) {
  .breadcrumbs {
    padding-top: 50px;
  }
}
@media (max-width: 480px) {
  .breadcrumbs__inner {
    padding-left: 0;
  }
}
.breadcrumbs__list {
  display: flex;
  flex-wrap: wrap;
}
.breadcrumbs__item {
  align-items: center;
  color: #0e7a73;
  display: flex;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.8px;
  line-height: 1.1875;
  text-decoration: none;
  text-transform: uppercase;
}
@media (max-width: 1024px) {
  .breadcrumbs__item {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .breadcrumbs__item {
    font-size: 15px;
  }
}
.breadcrumbs__item::before {
  content: "/";
  margin-left: 4px;
  margin-right: 4px;
}
.breadcrumbs__item a {
  color: #0e7a73;
}
.breadcrumbs__item:first-child::before {
  display: none;
}
.breadcrumbs__item:nth-child(3) {
  display: none;
}
.breadcrumbs__item:last-child:not(:first-child)::after {
  display: none;
}
.breadcrumbs__item a {
  color: #0e7a73;
  text-decoration: none;
  transition: all 0.16s;
}
.breadcrumbs__item a:hover {
  color: #20b59a;
}
.breadcrumbs__remove {
  display: flex;
  height: 11px;
  margin-left: 7px;
  margin-top: -2px;
  width: 11px;
}
.breadcrumbs__remove svg {
  display: block;
  height: 100%;
  object-fit: contain;
  width: 100%;
}
.breadcrumbs__remove svg * {
  fill: #333a3c;
  transition: all 0.16s;
}
.breadcrumbs__remove:hover svg * {
  fill: #0e7a73;
}
.breadcrumbs__inner--project-single {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.breadcrumbs__inner--project-single .navigation-arrows {
  bottom: unset;
  top: 2px;
}
.breadcrumbs__inner--with-navigation {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.breadcrumbs--white {
  margin-bottom: 32px;
  padding-top: 0;
}
@media (max-width: 768px) {
  .breadcrumbs--white {
    margin-bottom: 0;
    padding-top: 40px;
  }
}
.breadcrumbs--white .breadcrumbs__item {
  color: #fff;
}
.breadcrumbs--white .breadcrumbs__item a {
  color: #fff;
}
.breadcrumbs--white .breadcrumbs__item a:hover {
  color: #fff;
  opacity: 0.9;
}
.breadcrumbs--team-landing {
  margin-bottom: 60px;
  padding-top: 40px;
}
@media (max-width: 768px) {
  .breadcrumbs--team-landing {
    margin-bottom: 50px;
    padding-top: 20px;
  }
}
@media (max-width: 480px) {
  .breadcrumbs--team-landing {
    margin-bottom: 30px;
    padding-top: 10px;
  }
}
.breadcrumbs--bg {
  background-color: #fafafa;
}

.feature {
  overflow: hidden;
  position: relative;
}
.feature__inner {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 768px) {
  .feature__inner {
    flex-direction: column;
  }
}
.feature__background {
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
}
@media (max-width: 768px) {
  .feature__background {
    overflow: hidden;
    position: relative;
  }
}
.feature__background-overlay {
  height: 100%;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
}
@media (max-width: 768px) {
  .feature__background-overlay {
    display: none;
  }
}
.feature__background-overlay::after {
  background: #f9f9f9;
  content: "";
  height: 100%;
  left: 100%;
  position: absolute;
  top: 0;
  width: 100vw;
}
.feature__background-overlay svg {
  display: block;
  height: 100%;
  object-fit: contain;
  object-position: left center;
}
.feature__background-overlay svg path {
  fill: #f2f2f2;
}
.feature__background-holder {
  height: 100%;
  max-width: 100%;
  position: relative;
}
.feature__background-image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.feature__content {
  max-width: 62%;
  padding-bottom: 64px;
  padding-top: 64px;
  position: relative;
}
@media (max-width: 768px) {
  .feature__content {
    max-width: 100%;
    padding-bottom: 0;
    padding-top: 0;
  }
}
.feature__content-inner {
  background: #fff;
  box-shadow: 0 0 18px 3px rgba(0, 0, 0, 0.1);
  padding: 40px;
}
@media (max-width: 768px) {
  .feature__content-inner {
    box-shadow: unset;
    padding: 30px 0;
  }
}
.feature__heading {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-bottom: 34px;
}
.feature__description {
  color: #333a3c;
}
.feature__description p {
  margin-bottom: 1em;
}
.feature__description p:last-child {
  margin-bottom: 0;
}
.feature__social a {
  color: #333a3c;
}
.feature__social a:hover {
  color: #212322;
}
.feature.feature--container .container {
  height: 100%;
  position: relative;
}
.feature.feature--reverse .feature__background-overlay {
  left: 0;
  right: unset;
  width: 64%;
}
@media (max-width: 768px) {
  .feature.feature--reverse .feature__background-overlay {
    width: 37%;
  }
}
.feature.feature--reverse .feature__background-overlay svg {
  position: absolute;
  right: 0;
}
.feature.feature--reverse .feature__background-image {
  margin-left: auto;
  width: auto;
}
.feature.feature--reverse .feature__inner {
  justify-content: flex-start;
}
.feature.feature--reverse .feature__content {
  max-width: 60%;
}
@media (max-width: 768px) {
  .feature.feature--reverse .feature__content {
    max-width: 100%;
  }
}
.feature.feature--secondary {
  overflow: visible;
}
.feature.feature--secondary .feature__inner {
  box-shadow: 4px 4px 24px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
@media (max-width: 960px) {
  .feature.feature--secondary .feature__inner {
    border-radius: 0;
    box-shadow: unset;
    flex-direction: column;
  }
}
.feature.feature--secondary .feature__background {
  flex-basis: 49%;
  height: auto;
  position: relative;
  width: 49%;
}
@media (max-width: 960px) {
  .feature.feature--secondary .feature__background {
    flex-basis: 100%;
    height: 0;
    margin: 0;
    padding-bottom: 50%;
    width: 100%;
  }
}
.feature.feature--secondary .feature__background-holder {
  max-width: unset;
  transform: unset;
}
@media (max-width: 960px) {
  .feature.feature--secondary .feature__background-holder {
    left: 0;
    position: absolute;
    top: 0;
  }
}
.feature.feature--secondary .feature__background-holder::after {
  background: #fff;
  box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.5);
  content: "";
  height: 100%;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  transform: skew(13deg, 0deg) translateX(100%);
  transform-origin: 0 100%;
  width: 50%;
}
@media (max-width: 960px) {
  .feature.feature--secondary .feature__background-holder::after {
    border-radius: 0;
    bottom: 0;
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.5);
    height: 30%;
    left: 0;
    right: unset;
    top: unset;
    transform: skew(0deg, -8deg) translateY(0);
    transform-origin: 100% 100%;
    width: 100%;
  }
}
.feature.feature--secondary .feature__background-image {
  transform: unset;
}
.feature.feature--secondary .feature__content {
  flex-basis: 51%;
  min-height: 585px;
  padding-bottom: 0;
  padding-top: 0;
  width: 51%;
}
@media (max-width: 960px) {
  .feature.feature--secondary .feature__content {
    flex-basis: 100%;
    margin-bottom: 30px;
    min-height: unset;
    order: -1;
    width: 100%;
  }
}
.feature.feature--secondary .feature__content-inner {
  box-shadow: unset;
  padding: 52px 50px 52px 62px;
}
@media (max-width: 960px) {
  .feature.feature--secondary .feature__content-inner {
    padding: 0;
  }
}
.feature.feature--secondary .feature__heading {
  font-weight: 600;
  margin-bottom: 41px;
  position: relative;
}
@media (max-width: 768px) {
  .feature.feature--secondary .feature__heading {
    margin-bottom: 30px;
  }
}
.feature.feature--secondary .feature__heading::after {
  background-color: #0e7a73;
  bottom: -10px;
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  width: 40px;
}
.feature.feature--secondary .feature__description {
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .feature.feature--secondary .feature__description {
    margin-bottom: 10px;
  }
}
.feature.feature--secondary .feature__description p {
  margin-bottom: 30px;
}
.feature.feature--secondary .feature__description a {
  color: #212322;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0.042em;
  text-decoration: underline;
}
.feature.feature--secondary .feature__description a:hover {
  color: #0e7a73;
}
.feature.feature--secondary .feature__link a {
  color: #212322;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: underline;
}
.feature.feature--secondary .feature__link a:hover {
  color: #0e7a73;
}
.feature.feature--inverse .feature__background {
  order: 1;
}
.feature.feature--inverse .feature__background-holder::after {
  left: 0;
  right: unset;
  transform: skew(-13deg, 0deg) translateX(-100%);
}
@media (max-width: 960px) {
  .feature.feature--inverse .feature__background-holder::after {
    transform: skew(0deg, -8deg) translateY(0);
  }
}
@media (max-width: 960px) {
  .feature.feature--inverse .feature__content {
    order: -1;
  }
}
.feature.feature--inverse .feature__content-inner {
  box-shadow: unset;
  padding: 52px 65px 52px 50px;
}
@media (max-width: 960px) {
  .feature.feature--inverse .feature__content-inner {
    padding: 0;
  }
}
.feature.feature--full {
  background-color: #333a3c;
  margin-bottom: 0;
}
.feature.feature--full .feature__inner {
  border-radius: 0;
  box-shadow: unset;
}
@media (max-width: 960px) {
  .feature.feature--full .feature__inner {
    overflow: visible;
  }
}
.feature.feature--full .feature__background {
  flex-basis: 50%;
  height: 100%;
  position: absolute;
  width: 50%;
}
@media (max-width: 960px) {
  .feature.feature--full .feature__background {
    margin-left: calc((100vw - 100%) / -2);
    overflow: visible;
    padding-bottom: 0;
    position: static;
    width: 100vw;
  }
}
@media (max-width: 960px) {
  .feature.feature--full .feature__background-holder {
    position: relative;
  }
}
.feature.feature--full .feature__background-holder::after {
  background-color: #333a3c;
  left: unset;
  right: 0;
  transform: skew(-13deg, 0deg) translateX(100%);
  transform-origin: 100% 0;
}
@media (max-width: 960px) {
  .feature.feature--full .feature__background-holder::after {
    background-color: #fff;
    border-radius: 0;
    bottom: 0;
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.5);
    height: 15%;
    left: 0;
    right: unset;
    top: unset;
    transform: skew(0deg, -8deg) translateY(0);
    transform-origin: 100% 100%;
    width: 100%;
  }
}
.feature.feature--full .feature__content {
  flex-basis: 50%;
  min-height: unset;
  padding-bottom: 140px;
  padding-top: 150px;
  width: 50%;
}
@media (max-width: 960px) {
  .feature.feature--full .feature__content {
    background-color: #333a3c;
    flex-basis: 100%;
    padding-bottom: 30px;
    padding-top: 30px;
    width: 100%;
  }
}
.feature.feature--full .feature__content-inner {
  background-color: transparent;
  padding: 52px 10px 52px 50px;
}
@media (max-width: 960px) {
  .feature.feature--full .feature__content-inner {
    padding: 0;
  }
}
.feature.feature--full .feature__heading {
  color: #fff;
}
.feature.feature--full .feature__heading::after {
  background-color: #fff;
}
.feature.feature--full .feature__description {
  color: #fff;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.48;
  margin-bottom: 58px;
}
@media (max-width: 960px) {
  .feature.feature--full .feature__description {
    margin-bottom: 30px;
  }
}
.feature.feature--full .feature__position {
  color: #fff;
  font-size: 16px;
}
.feature--image-gallery {
  height: 100vh;
  margin-bottom: 100px;
  max-height: 630px;
  min-height: 400px;
}
@media (max-width: 1024px) {
  .feature--image-gallery {
    max-height: 500px;
  }
}
@media (max-width: 960px) {
  .feature--image-gallery {
    height: auto;
    margin-bottom: 80px;
    max-height: unset;
    min-height: unset;
  }
}
@media (max-width: 768px) {
  .feature--image-gallery {
    margin-bottom: 50px;
  }
}
@media (max-width: 480px) {
  .feature--image-gallery {
    margin-bottom: 20px;
  }
}
.feature--image-gallery .container {
  height: 100%;
}
@media (max-width: 960px) {
  .feature--image-gallery .container {
    padding: 0;
  }
}
.feature--image-gallery .feature__inner {
  height: 100%;
  overflow: hidden;
  position: relative;
}
@media (max-width: 960px) {
  .feature--image-gallery .feature__inner {
    justify-content: flex-start;
  }
}
.feature--image-gallery .feature__background--left {
  width: 70%;
}
@media (max-width: 960px) {
  .feature--image-gallery .feature__background--left {
    width: 100%;
  }
}
.feature--image-gallery .feature__background--left .feature__background-holder {
  border-right: 30px solid rgb(255, 255, 255);
  padding-left: 0;
}
@media (max-width: 960px) {
  .feature--image-gallery .feature__background--left .feature__background-holder {
    border-right: unset;
    height: 0;
    padding-bottom: 50%;
  }
}
@media (max-width: 960px) {
  .feature--image-gallery .feature__background--right .feature__background-holder {
    height: auto;
    min-height: unset;
  }
}
.feature--image-gallery .feature__background--right .feature__background-holder-inner {
  height: 50%;
  right: 0;
}
@media (max-width: 960px) {
  .feature--image-gallery .feature__background--right .feature__background-holder-inner {
    height: 100%;
    width: 50%;
  }
}
.feature--image-gallery .feature__background--right .feature__background-holder-inner:first-child {
  padding-bottom: 15px;
}
@media (max-width: 960px) {
  .feature--image-gallery .feature__background--right .feature__background-holder-inner:first-child {
    height: 0;
    left: 0;
    padding-bottom: 40%;
    position: relative;
    width: 50%;
  }
}
.feature--image-gallery .feature__background--right .feature__background-holder-inner:last-child {
  bottom: 0;
  left: unset;
  padding-top: 15px;
  right: 0;
  width: 100%;
}
@media (max-width: 960px) {
  .feature--image-gallery .feature__background--right .feature__background-holder-inner:last-child {
    height: 0;
    padding-bottom: 40%;
    padding-top: 0;
    position: relative;
    width: 50%;
  }
}
.feature--image-gallery .feature__background-holder {
  pointer-events: none;
}
.feature--image-gallery .feature__background-holder::before {
  display: none;
}
.feature__title-block {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}
.feature__count {
  width: 28px;
  height: 28px;
  background-color: transparent;
  border: 2px solid #0e7a73;
  color: #0e7a73;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  padding-top: 3px;
}

.logos {
  padding-bottom: 50px;
  padding-top: 80px;
}
@media (max-width: 960px) {
  .logos {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}
.logos__title {
  margin-bottom: 66px;
  text-align: center;
}
@media (max-width: 960px) {
  .logos__title {
    margin-bottom: 40px;
  }
}
.logos__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.logos__item {
  flex-basis: 20%;
  margin-bottom: 43px;
  padding-bottom: 0;
  padding-top: 0;
  text-align: center;
}
@media (max-width: 960px) {
  .logos__item {
    flex-basis: 25%;
    margin-bottom: 30px;
  }
}
@media (max-width: 576px) {
  .logos__item {
    flex-basis: 33.3333%;
    margin-bottom: 20px;
  }
}
.logos__item img {
  display: inline-flex;
  height: 70px;
  max-width: unset;
  width: auto;
}
@media (max-width: 576px) {
  .logos__item img {
    height: 50px;
  }
}

.sprints-overview {
  padding-bottom: 50px;
  padding-top: 65px;
}
@media (max-width: 768px) {
  .sprints-overview {
    padding-bottom: 30px;
    padding-top: 40px;
  }
}
.sprints-overview__columns {
  display: flex;
  flex-wrap: wrap;
  margin-left: -38px;
  margin-right: -38px;
  padding-left: 20px;
  padding-right: 20px;
}
@media (max-width: 768px) {
  .sprints-overview__columns {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media (max-width: 576px) {
  .sprints-overview__columns {
    padding-left: 0;
    padding-right: 0;
  }
}
.sprints-overview__column {
  flex-basis: 50%;
  padding-left: 38px;
  padding-right: 38px;
}
@media (max-width: 768px) {
  .sprints-overview__column {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 576px) {
  .sprints-overview__column {
    flex-basis: 100%;
  }
}
@media (max-width: 576px) {
  .sprints-overview__column:first-child {
    margin-bottom: 28px;
  }
}
.sprints-overview__title {
  margin-bottom: 15px;
}
.sprints-overview__description {
  color: #333a3c;
  margin-bottom: 40px;
}
.sprints-overview__description p {
  margin-bottom: 1.3em;
}
.sprints-overview__description p:last-child {
  margin-bottom: 0;
}
.sprints-overview__gallery {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}
.sprints-overview__gallery-item {
  overflow: hidden;
  position: relative;
}
.sprints-overview__gallery-item img {
  height: 100%;
  object-fit: cover;
  position: absolute;
  width: 100%;
}
@media (max-width: 576px) {
  .sprints-overview__gallery-item img {
    position: static;
  }
}
.sprints-overview__gallery-item:first-child {
  flex-basis: 38%;
  margin-bottom: 28px;
}
@media (max-width: 576px) {
  .sprints-overview__gallery-item:first-child {
    flex-basis: auto;
  }
}
.sprints-overview__gallery-item:last-child {
  flex-grow: 1;
}

.working-together {
  background: #333a3c;
  overflow: hidden;
  padding-bottom: 158px;
  padding-top: 100px;
}
@media (max-width: 960px) {
  .working-together {
    margin-bottom: 50px;
    padding-bottom: 50px;
    padding-top: 80px;
  }
}
@media (max-width: 768px) {
  .working-together {
    margin-bottom: 40px;
    padding-bottom: 20px;
  }
}
.working-together__inner {
  position: relative;
}
.working-together__inner .working-together__card-inner {
  background: #fff;
}
.working-together__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -11px;
  margin-right: -11px;
}
.working-together__card {
  flex-basis: 33.3333%;
  flex-shrink: 0;
  padding: 11px;
}
@media (max-width: 768px) {
  .working-together__card {
    flex-basis: 100%;
  }
}
.working-together__card-group {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.working-together__card-inner {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 30px 20px;
  text-align: center;
}
@media (max-width: 576px) {
  .working-together__card-inner {
    margin: auto;
    max-width: 340px;
  }
}
.working-together__card-image {
  margin-bottom: 20px;
  max-width: 160px;
  position: relative;
  width: 100%;
  margin-top: -20px;
}
.working-together__card-heading {
  margin-bottom: 15px;
  font-size: 18px !important;
}
.working-together__card-info {
  margin-bottom: 30px;
}
.working-together__card-info .p2 {
  font-size: 14px !important;
}
.working-together__heading {
  color: #fff;
  margin-bottom: 36px;
  font-size: 48px;
}
@media (max-width: 960px) {
  .working-together__heading {
    margin-bottom: 15px;
  }
}
@media (max-width: 480px) {
  .working-together__heading {
    margin-bottom: 15px;
  }
}
.working-together__description {
  color: #fff;
  margin-bottom: 52px;
}
@media (max-width: 480px) {
  .working-together__description {
    margin-bottom: 30px;
  }
}
.principles {
  background: #fafafa;
  overflow: hidden;
  padding-bottom: 33px;
  padding-top: 136px;
}
@media (max-width: 1024px) {
  .principles {
    padding-top: 120px;
  }
}
@media (max-width: 960px) {
  .principles {
    padding-bottom: 20px;
    padding-top: 50px;
  }
}
.principles__heading {
  margin-bottom: 30px;
}
.principles__description {
  color: #333a3c;
  margin-bottom: 0;
}
.principles__link {
  padding-top: 30px;
}
.principles__columns {
  display: flex;
  justify-content: space-between;
  margin-left: -30px;
  margin-right: -30px;
  overflow: hidden;
}
@media (max-width: 1024px) {
  .principles__columns {
    flex-wrap: wrap;
    max-width: 960px;
  }
}
.principles__column {
  flex-basis: 33.3333%;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 25px;
}
@media (max-width: 960px) {
  .principles__column {
    flex-basis: 50%;
  }
}
@media (max-width: 576px) {
  .principles__column {
    flex-basis: 100%;
  }
}
.principles__column--first {
  padding-right: 30px;
  padding-top: 0;
}
@media (max-width: 960px) {
  .principles__column--first {
    flex-basis: 100%;
    margin-bottom: 30px;
  }
}
.principles__item {
  padding-bottom: 70px;
}
@media (max-width: 960px) {
  .principles__item {
    padding-bottom: 50px;
  }
}
@media (max-width: 576px) {
  .principles__item {
    padding-bottom: 25px;
  }
}
.principles__title {
  margin-bottom: 10px;
}
@media (max-width: 960px) {
  .principles__title {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .principles__title {
    font-size: 18px;
  }
}

.principles-card {
  margin-bottom: 100px;
}
@media (max-width: 768px) {
  .principles-card {
    margin-bottom: 60px;
  }
}
@media (max-width: 480px) {
  .principles-card {
    margin-bottom: 40px;
  }
}
.principles-card__inner {
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.15);
  display: flex;
  min-height: 412px;
}
@media (max-width: 768px) {
  .principles-card__inner {
    flex-direction: column;
  }
}
.principles-card__background {
  flex-basis: 45%;
  flex-shrink: 0;
}
.principles-card__background-holder {
  height: 100%;
  position: relative;
}
.principles-card__background-holder img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.principles-card__content {
  align-items: flex-start;
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  padding: 40px;
}
@media (max-width: 480px) {
  .principles-card__content {
    padding: 25px;
  }
}
.principles-card__heading {
  margin-bottom: 30px;
}
.principles-card__description p {
  margin-bottom: 1.3em;
}
.principles-card__description p:last-child {
  margin-bottom: 0;
}
.principles-card__description a {
  color: #212322;
  font-weight: 400;
}
.principles-card__description a:hover {
  color: #0e7a73;
}
.principles-card__link {
  padding-top: 20px;
}
.principles-card--inverse .principles-card__inner {
  flex-direction: row-reverse;
}
@media (max-width: 768px) {
  .principles-card--inverse .principles-card__inner {
    flex-direction: column;
  }
}

.team {
  background: #f9f9f9;
  padding-bottom: 152px;
  padding-top: 128px;
}
@media (max-width: 960px) {
  .team {
    padding-bottom: 67px;
    padding-top: 67px;
  }
}
@media (max-width: 576px) {
  .team {
    padding-bottom: 46px;
    padding-top: 46px;
  }
}
.team--about {
  padding-bottom: 144px;
  padding-top: 100px;
}
.team--about h2 {
  margin-bottom: 20px;
}
.team__description {
  color: #333a3c;
  margin-bottom: 30px;
  font-weight: 300;
}
.team__action {
  margin-bottom: 77px;
}
.team-holder {
  display: flex;
  padding: 0 10px 0 20px;
}
@media (max-width: 768px) {
  .team-holder {
    flex-direction: column;
    padding: 0;
  }
}
.team-content {
  flex-basis: 330px;
  flex-grow: 0;
  padding-top: 6px;
}
@media (max-width: 1024px) {
  .team-content {
    flex-basis: 260px;
  }
}
@media (max-width: 768px) {
  .team-content {
    flex-basis: auto;
    margin-bottom: 50px;
  }
}
.team-title {
  margin-bottom: 30px;
}
.team-list {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  padding-left: 47px;
}
@media (max-width: 768px) {
  .team-list {
    justify-content: center;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 0;
  }
}
.team-item {
  align-self: flex-start;
  margin-left: 50px;
  max-width: 250px;
  overflow: hidden;
  width: 100%;
}
@media (max-width: 960px) {
  .team-item {
    margin-left: 30px;
  }
}
@media (max-width: 768px) {
  .team-item {
    margin-left: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.team-item-link {
  display: block;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}
.team-item-link > div {
  border-radius: 50%;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}
.team-item-link > div::after {
  border: 5px solid #fff;
  border-radius: 50%;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s;
}
.team-item-link img {
  height: 100%;
  object-fit: cover;
  transition: transform 0.1s;
  width: 100%;
}
.team-item-link:hover div::after {
  opacity: 0.3;
}
.team-description {
  color: #333a3c;
  font-weight: normal;
  margin-bottom: 20px;
}

.team-group {
  padding-top: 50px;
  padding-bottom: 64px;
  position: relative;
}
@media (max-width: 960px) {
  .team-group {
    padding-top: 60px;
  }
}
@media (max-width: 768px) {
  .team-group {
    padding-top: 30px;
  }
}
.team-group--leadership::before {
  background: #f9f9f9;
  bottom: 0;
  content: "";
  height: 114px;
  position: absolute;
  width: 100%;
  z-index: 0;
}
.team-group--leadership .team-cards__item {
  position: relative;
}
.team-group--leadership .container {
  position: relative;
}
.team-group--leadership .link::before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.team-group__background {
  position: relative;
}
.team-group__links {
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.team-group__links a {
  flex: 0 0 50%;
}
.team-group__title {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 0.023em;
  line-height: 1.7708333333;
  margin-bottom: 20px;
}
@media (max-width: 960px) {
  .team-group__title {
    font-size: 28px;
  }
}
@media (max-width: 768px) {
  .team-group__title {
    font-size: 26px;
  }
}
.team-group__title--indent {
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  .team-group__title--indent {
    margin-bottom: 30px;
  }
}
.team-group--white {
  background-color: #f9f9f9;
  padding-top: 152px;
}
@media (max-width: 960px) {
  .team-group--white {
    padding-top: 72px;
  }
}
@media (max-width: 960px) {
  .team-group--leadership {
    padding-bottom: 0;
  }
}
.team-group--remote {
  padding-top: 0;
}
@media (max-width: 768px) {
  .team-group--remote {
    padding-top: 0;
  }
}
.team-group--remote .team-cards {
  padding-bottom: 30px;
}

.team-cards {
  overflow: hidden;
  padding-bottom: 90px;
  position: relative;
}
@media (max-width: 960px) {
  .team-cards {
    padding-bottom: 60px;
  }
}
@media (max-width: 768px) {
  .team-cards {
    padding-bottom: 30px;
  }
}
.team-cards--leadership {
  bottom: 0;
  left: 0;
  padding-bottom: 0;
  position: absolute;
  width: 100%;
}
@media (max-width: 960px) {
  .team-cards--leadership {
    background: #fff;
    position: relative;
  }
}
@media (max-width: 960px) {
  .team-cards--leadership .container {
    padding: 0;
  }
}
.team-cards__list {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.team-cards__list--secondary {
  margin-left: -50px;
  margin-right: -50px;
}
@media (max-width: 960px) {
  .team-cards__list--secondary {
    margin-left: -30px;
    margin-right: -30px;
  }
}
.team-cards__list--leadership {
  background: #fff;
}
.team-cards__item {
  flex: 1 1 0;
}
.team-cards__item.columns-2 {
  background: #fff;
  padding: 42px 50px;
}
@media (max-width: 576px) {
  .team-cards__item.columns-2 {
    flex-basis: 100%;
    margin-bottom: 0;
    padding: 30px;
    width: 100%;
  }
}
.team-cards__item.columns-3 {
  flex-basis: 33.3333%;
  flex-grow: 0;
  margin-bottom: 104px;
  padding: 0 50px;
  width: 33.3333%;
}
@media (max-width: 960px) {
  .team-cards__item.columns-3 {
    padding: 0 30px;
  }
}
@media (max-width: 768px) {
  .team-cards__item.columns-3 {
    flex-basis: 50%;
    margin-bottom: 50px;
    width: 50%;
  }
}
@media (max-width: 480px) {
  .team-cards__item.columns-3 {
    flex-basis: 100%;
    width: 100%;
  }
}
.team-cards__item--careers {
  padding: 0 25px !important;
}
.team-cards__item--careers .team-cards__holder {
  align-items: flex-end;
  background: #0e7a73;
  color: #fff;
  display: flex;
  height: calc(100% - 55px);
  padding: 0 25px;
  width: 100%;
}
@media (max-width: 768px) {
  .team-cards__item--careers .team-cards__holder {
    height: auto;
    padding: 25px;
  }
}
.team-cards__item--careers .team-cards__content {
  padding-bottom: 42px;
}
@media (max-width: 768px) {
  .team-cards__item--careers .team-cards__content {
    padding-bottom: 0;
  }
}
.team-cards__item--careers .team-cards__content .careers-title {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.2058823529;
  margin-bottom: 14px;
}
@media (max-width: 1024px) {
  .team-cards__item--careers .team-cards__content .careers-title {
    font-size: 34px;
  }
}
.team-cards__item--careers .team-cards__content .careers-description {
  font-family: "Roboto Slab", serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.04em;
  line-height: 1.8181818182;
  margin-bottom: 50px;
}
@media (max-width: 1024px) {
  .team-cards__item--careers .team-cards__content .careers-description {
    font-size: 11px;
  }
}
.team-cards__item--careers .team-cards__content a {
  color: #fff;
  font-weight: normal;
}
.team-cards__item--careers .team-cards__content a:hover {
  text-decoration: none;
}
.team-cards__item--secondary .team-cards__position {
  margin-bottom: 25px;
}
.team-cards__item--secondary .team-cards__excerpt {
  color: #333a3c;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
}
@media (max-width: 1024px) {
  .team-cards__item--secondary .team-cards__excerpt {
    font-size: 11px;
  }
}
.team-cards__item--secondary .team-cards__action {
  opacity: 0;
  transition: opacity 0.2s;
}
@media (hover: none) and (pointer: coarse) {
  .team-cards__item--secondary .team-cards__action {
    opacity: 1;
  }
}
.team-cards__item--secondary .team-cards__photo {
  position: relative;
}
.team-cards__item--secondary .team-cards__photo::after {
  border: 5px solid #fff;
  border-radius: 50%;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s;
}
.team-cards__item--secondary:hover .team-cards__action {
  opacity: 1;
}
.team-cards__item--secondary:hover .team-cards__photo::after {
  opacity: 0.3;
}
.team-cards__photo {
  border-radius: 50%;
  display: flex;
  height: 175px;
  margin-bottom: 40px;
  overflow: hidden;
  position: relative;
  width: 175px;
}
@media (max-width: 1024px) {
  .team-cards__photo {
    height: 140px;
    width: 140px;
  }
}
@media (max-width: 768px) {
  .team-cards__photo {
    margin-bottom: 20px;
  }
}
.team-cards__photo--remote {
  margin-bottom: 40px;
}
.team-cards__photo img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.team-cards__details--limited-small {
  max-width: unset;
  padding-right: 30px;
}
.team-cards__name {
  color: #212322;
  margin-bottom: 5px;
  margin-top: 0;
}
.team-cards__position {
  color: #0e7a73;
  margin-bottom: 27px;
  position: relative;
}
@media (max-width: 960px) {
  .team-cards__position {
    font-size: 20px;
    margin-bottom: 15px;
  }
}
@media (max-width: 768px) {
  .team-cards__position {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
.team-cards__excerpt {
  color: #333a3c;
  margin-bottom: 50px;
}
@media (max-width: 576px) {
  .team-cards__excerpt {
    margin-bottom: 20px;
  }
}
.team-cards__link {
  align-items: center;
  color: #212322;
  display: inline-flex;
  text-decoration: none;
  transition: 0.16s;
}
.team-cards__link .icon-modal-arrow {
  color: #333a3c;
  font-size: 14px;
  margin-right: 10px;
  padding: 10px;
  transition: 0.16s;
}
.team-cards__link-caption {
  color: #212322;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  position: relative;
  text-decoration: underline;
  transition: 0.16s;
}
.team-cards__link:hover .team-cards__link-caption {
  color: #0e7a73;
}
.team-cards__link:hover .icon-modal-arrow {
  color: #0e7a73;
}
.team-cards--no-indent {
  padding-bottom: 0;
}

.clients {
  background: #fff;
  overflow: hidden;
  padding-bottom: 78px;
  padding-top: 130px;
}
@media (max-width: 1024px) {
  .clients {
    padding-top: 120px;
  }
}
@media (max-width: 960px) {
  .clients {
    padding-bottom: 20px;
    padding-top: 50px;
  }
}
.clients__heading {
  margin-bottom: 15px;
}
.clients__description {
  color: #333a3c;
  font-size: 18px;
  line-height: 1.7777777778;
  margin-bottom: 24px;
}
@media (max-width: 480px) {
  .clients__description {
    font-size: 16px;
    line-height: 1.5625;
  }
}
.clients__link a {
  color: #212322;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
}
.clients__link a:hover {
  color: #0e7a73;
}
.clients__columns {
  display: flex;
  justify-content: space-between;
  margin-left: -40px;
  margin-right: -40px;
}
@media (max-width: 960px) {
  .clients__columns {
    flex-wrap: wrap;
  }
}
.clients__column {
  padding-left: 40px;
  padding-right: 40px;
}
.clients__column--first {
  flex-basis: 33.3333%;
  flex-shrink: 0;
}
@media (max-width: 960px) {
  .clients__column--first {
    flex-basis: 100%;
    margin-bottom: 50px;
    padding-right: 34px;
  }
}
.clients__column--expanded {
  flex-grow: 1;
}
.clients .logos__item {
  align-items: center;
  display: flex;
  flex-basis: 33.3333%;
  justify-content: center;
  margin-bottom: 60px;
  padding: 10px 15px;
}
@media (max-width: 1280px) {
  .clients .logos__item {
    margin-bottom: 50px;
  }
}
@media (max-width: 1024px) {
  .clients .logos__item {
    margin-bottom: 48px;
  }
}
@media (max-width: 768px) {
  .clients .logos__item {
    margin-bottom: 50px;
  }
}
.clients .logos__item img {
  height: 100%;
  object-fit: contain;
  width: 100%;
}
.clients .logos__item-inner {
  height: 100%;
  max-height: 70px;
  max-width: 168px;
}
@media (max-width: 1280px) {
  .clients .logos__item-inner {
    max-height: 60px;
    max-width: 150px;
  }
}
@media (max-width: 1024px) {
  .clients .logos__item-inner {
    max-height: 55px;
    max-width: 140px;
  }
}
@media (max-width: 960px) {
  .clients .logos__item-inner {
    max-height: 55px;
    max-width: 140px;
  }
}
@media (max-width: 768px) {
  .clients .logos__item-inner {
    max-height: 55px;
    max-width: 140px;
  }
}
.clients .logos__item-inner--savy {
  max-height: 38px;
}
@media (max-width: 1024px) {
  .clients .logos__item-inner--savy {
    max-height: 30px;
  }
}
.clients .logos__item-inner--red-whale {
  max-height: 23px;
}
@media (max-width: 1024px) {
  .clients .logos__item-inner--red-whale {
    max-height: 18px;
  }
}
.clients .logos__item-inner--har {
  max-height: 57px;
}
@media (max-width: 1024px) {
  .clients .logos__item-inner--har {
    max-height: 45px;
  }
}
.clients .logos__item-inner--hos {
  max-height: 39px;
}
@media (max-width: 1024px) {
  .clients .logos__item-inner--hos {
    max-height: 35px;
  }
}

.locations {
  padding: 137px 0;
}
@media (max-width: 1024px) {
  .locations {
    padding: 100px 0;
  }
}
@media (max-width: 960px) {
  .locations {
    padding: 80px 0;
  }
}
@media (max-width: 768px) {
  .locations {
    padding: 50px 0;
  }
}
.locations .container {
  max-width: 1100px;
}
.locations__item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 85px;
  width: 100%;
}
@media (max-width: 576px) {
  .locations__item {
    flex-direction: column;
    margin-bottom: 62px;
  }
}
.locations__item:last-child {
  margin-bottom: 0;
}
.locations__column--image {
  max-width: 480px;
  padding-top: 10px;
  position: relative;
  width: 100%;
}
@media (max-width: 1024px) {
  .locations__column--image {
    max-width: 400px;
  }
}
@media (max-width: 576px) {
  .locations__column--image {
    height: 260px;
    margin-left: calc((100vw - 100%) / -2);
    max-width: unset;
    width: 100vw;
  }
}
.locations__column--info {
  padding-right: 40px;
}
@media (max-width: 576px) {
  .locations__column--info {
    margin-bottom: 30px;
    padding-right: 0;
  }
}
.locations__heading {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 48px;
  letter-spacing: 0.023em;
  line-height: 1.2916666667;
  margin-bottom: 20px;
}
@media (max-width: 1024px) {
  .locations__heading {
    font-size: 40px;
  }
}
@media (max-width: 960px) {
  .locations__heading {
    font-size: 30px;
  }
}
@media (max-width: 768px) {
  .locations__heading {
    font-size: 24px;
  }
}
@media (max-width: 480px) {
  .locations__heading {
    font-size: 24px;
    margin-bottom: 28px;
  }
}
.locations__description {
  color: #333a3c;
  font-size: 20px;
  letter-spacing: 0.063em;
  line-height: 1.8;
  margin-bottom: 30px;
}
@media (max-width: 960px) {
  .locations__description {
    font-size: 18px;
    line-height: 2;
  }
}
.locations__action a {
  color: #212322;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.063em;
}
.locations__action a[href^=tel] {
  text-decoration: none;
}
.locations__action a:hover {
  color: #0e7a73;
}
.locations__image {
  height: 100%;
  padding-right: 22px;
  position: absolute;
  width: 100%;
}
@media (max-width: 576px) {
  .locations__image {
    padding-right: 0;
  }
}
.locations__image img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.description {
  background: #fff;
  margin-bottom: 100px;
  position: relative;
  z-index: 2;
}
@media (max-width: 768px) {
  .description {
    margin-bottom: 50px;
  }
}
@media (max-width: 480px) {
  .description {
    margin-bottom: 15px;
  }
}
.description__subtitle {
  color: #fff;
  font-size: 25px;
  letter-spacing: -0.015em;
  line-height: 1.5;
  max-width: 940px;
  padding-bottom: 106px;
  position: relative;
  z-index: 2;
}
@media (max-width: 768px) {
  .description__subtitle {
    font-size: 20px;
    padding-bottom: 50px;
  }
}
@media (max-width: 480px) {
  .description__subtitle {
    font-size: 18px;
    padding-bottom: 30px;
  }
}
.description__title {
  background-color: transparent;
  padding-top: 0;
  position: relative;
  z-index: 2;
}
.description__title-holder {
  position: relative;
}
.description__title-holder::before {
  background-color: #333a3c;
  content: "";
  height: 586px;
  left: 0;
  margin-left: -9999px;
  padding-left: 9999px;
  position: absolute;
  right: 0;
  top: -100px;
}
@media (max-width: 960px) {
  .description__title-holder::before {
    height: 400px;
    top: -50px;
  }
}
@media (max-width: 768px) {
  .description__title-holder::before {
    height: 100%;
    margin-right: -9999px;
    top: 0;
  }
}
.description__title-text {
  color: #fff;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 64px;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 1.171875;
  margin-bottom: 14px;
  max-width: 763px;
  padding-right: 15px;
  position: relative;
  z-index: 1;
}
@media (max-width: 960px) {
  .description__title-text {
    font-size: 50px;
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .description__title-text {
    font-size: 32px;
    margin-bottom: 0;
    padding-bottom: 10px;
    padding-right: 0;
    padding-top: 30px;
  }
}
.description__content {
  background: #fff;
  padding: 82px 0 30px;
  position: relative;
  z-index: 2;
}
@media (max-width: 960px) {
  .description__content {
    padding: 52px 0 30px;
  }
}
@media (max-width: 768px) {
  .description__content {
    padding: 30px 0 20px;
  }
}
.description__content h2 {
  font-size: 36px;
}
@media (max-width: 1024px) {
  .description__content h2 {
    margin-bottom: 30px;
    margin-top: 60px;
    font-size: 28px;
  }
}
@media (max-width: 960px) {
  .description__content h2 {
    margin-bottom: 15px;
    padding-top: 20px;
    font-size: 28px;
  }
}
@media (max-width: 480px) {
  .description__content h2 {
    margin-bottom: 10px;
    margin-top: 10px;
    padding-top: 10px;
    font-size: 28px;
  }
}
.description__content h2.no-top-indent {
  margin-top: 0;
  padding-top: 0;
}
.description__content h5, .description__content h6 {
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 43.2px */
  letter-spacing: 0.72px;
  margin-top: 100px;
  margin-bottom: 18px;
}
.description__content h5:first-child, .description__content h6:first-child {
  margin-top: 40px;
}
@media (max-width: 1024px) {
  .description__content h5, .description__content h6 {
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 80px;
  }
}
.description__content h6 {
  font-size: 24px;
}
@media (max-width: 1024px) {
  .description__content h6 {
    font-size: 18px;
  }
}
.description__content p {
  margin-bottom: 1.3em;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.64px;
}
@media (max-width: 768px) {
  .description__content p {
    font-size: 14px;
  }
}
.description__content p span {
  font-weight: 300 !important;
}
.description__content a {
  color: #212322;
  font-weight: 500 !important;
  text-decoration: underline;
}
.description__content a span {
  font-weight: inherit !important;
}
.description__content a:hover {
  color: #0e7a73;
}
.description__image {
  position: relative;
  z-index: 2;
}
.description__image img {
  display: block;
  height: auto;
  max-width: 100%;
}
.description--post {
  background-color: transparent;
  margin-top: -100px;
}
@media (max-width: 768px) {
  .description--post {
    background-color: #333a3c;
    margin-top: 0;
  }
}
.description--post .description__inner {
  background-color: #333a3c;
  padding: 50px 60px 45px;
}
@media (max-width: 768px) {
  .description--post .description__inner {
    padding: 30px 0;
  }
}
.description--post .description__title-holder::before {
  display: none;
}
.description--post .description__title-text {
  margin-bottom: 33px;
}
.description--text {
  margin-bottom: 40px;
}
@media (max-width: 960px) {
  .description--text {
    margin-bottom: 30px;
  }
}
@media (max-width: 480px) {
  .description--text {
    margin-bottom: 15px;
  }
}
.description--text .description__content {
  padding-bottom: 0;
  padding-top: 0;
}
@media (max-width: 960px) {
  .description--text .description__content {
    padding: 30px 0 0;
  }
}
.description__header-group {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .description__header-group {
    margin-bottom: 0;
  }
}
.description--sprints {
  margin-bottom: 0;
}
.description--sprints .description {
  color: #333a3c;
}
.description--sprints .description__content {
  padding-bottom: 47px;
  padding-top: 80px;
}
@media (max-width: 768px) {
  .description--sprints .description__content {
    padding-bottom: 20px;
    padding-top: 40px;
  }
}
.description blockquote {
  margin: 70px 100px 50px 163px;
  padding: 0 63px 0 0;
  position: relative;
}
@media (max-width: 768px) {
  .description blockquote {
    margin: 70px 30px 50px 93px;
  }
}
@media (max-width: 480px) {
  .description blockquote {
    padding: 0 20px 0 0;
  }
}
.description blockquote::before {
  bottom: 100%;
  color: #20b59a;
  content: "\e922";
  font-family: "icomoon" !important;
  font-size: 31px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  line-height: 1;
  pointer-events: none;
  position: absolute;
  right: 100%;
  transform: translateY(6px) translateX(-25px);
  z-index: -1;
}
.description blockquote p {
  color: #212322;
  margin-bottom: 0;
  color: #333a3c;
  font-family: "Roboto Slab", serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%; /* 51.2px */
  letter-spacing: 0.64px;
}
@media (max-width: 768px) {
  .description blockquote p {
    font-size: 18px;
  }
}
.description blockquote cite {
  text-align: right;
  display: block;
  font-weight: 400;
  margin-top: 10px;
  font-size: 18px;
}
@media (max-width: 768px) {
  .description blockquote cite {
    font-size: 14px;
  }
}
.description blockquote cite::before {
  content: "—";
}

.goal {
  margin-bottom: 100px;
  padding-top: 85px;
}
@media (max-width: 768px) {
  .goal {
    margin-bottom: 50px;
  }
}
.goal__inner {
  background: #fafafa;
  padding-bottom: 90px;
  padding-top: 100px;
}
@media (max-width: 960px) {
  .goal__inner {
    padding-bottom: 50px;
  }
}
.goal__image {
  margin-bottom: 70px;
  margin-top: -200px;
}
@media (max-width: 768px) {
  .goal__image {
    margin-bottom: 40px;
  }
}
.goal__image img {
  height: auto;
  max-width: 100%;
}
.goal__header {
  margin-bottom: 70px;
}
@media (max-width: 768px) {
  .goal__header {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 480px) {
  .goal__header {
    margin-bottom: 40px;
  }
}
@media (max-width: 960px) {
  .goal__title {
    font-size: 30px;
  }
}
@media (max-width: 768px) {
  .goal__title {
    margin-bottom: 15px;
    padding-right: 0;
  }
}
@media (max-width: 480px) {
  .goal__title {
    font-size: 24px;
  }
}
.goal__description {
  color: #333a3c;
}
@media (max-width: 768px) {
  .goal__description {
    padding-left: 0;
  }
}
.goal__content {
  display: flex;
  justify-content: center;
}
.goal__content .steps__item {
  border-left: none !important;
}
.goal__content .steps__line {
  left: 47.5% !important;
}
.goal--white .goal__inner {
  background: #fff;
}
.goal--single-team {
  margin-bottom: 60px;
}
@media (max-width: 768px) {
  .goal--single-team {
    margin-bottom: 20px;
    padding-top: 50px;
  }
}
.goal--single-team .goal__header {
  margin-bottom: 78px;
}
@media (max-width: 960px) {
  .goal--single-team .goal__header {
    margin-bottom: 60px;
  }
}
@media (max-width: 768px) {
  .goal--single-team .goal__header {
    margin-bottom: 40px;
  }
}
@media (max-width: 480px) {
  .goal--single-team .goal__header {
    margin-bottom: 30px;
  }
}
.goal--single-team .goal__inner {
  padding-bottom: 0;
  padding-top: 0;
}
.goal--sprints {
  margin-bottom: 62px;
  padding-bottom: 0;
  padding-top: 0;
}
@media (max-width: 768px) {
  .goal--sprints {
    margin-bottom: 30px;
  }
}
.goal--sprints .goal__inner {
  padding-top: 80px;
}
@media (max-width: 768px) {
  .goal--sprints .goal__inner {
    padding-top: 50px;
  }
}
.goal--sprints .goal__header {
  display: flex;
}
.goal--sprints .goal__title {
  flex-basis: 50%;
  max-width: 460px;
  padding-right: 25px;
}
@media (max-width: 1280px) {
  .goal--sprints .goal__title {
    max-width: 380px;
  }
}
@media (max-width: 768px) {
  .goal--sprints .goal__title {
    max-width: unset;
  }
}
.goal--sprints .goal__description {
  flex-basis: 50%;
  max-width: 520px;
  padding-left: 5px;
}
@media (max-width: 1280px) {
  .goal--sprints .goal__description {
    max-width: 380px;
  }
}
@media (max-width: 768px) {
  .goal--sprints .goal__description {
    max-width: unset;
  }
}
.goal--sprints .goal__description p {
  margin-bottom: 1.3em;
}
.goal--sprints .goal__description p:last-child {
  margin-bottom: 0;
}

.steps {
  max-width: 575px;
  padding-bottom: 30px;
  padding-top: 0;
  position: relative;
}
@media (max-width: 480px) {
  .steps {
    padding-bottom: 0;
    padding-top: 0;
  }
}
.steps__list {
  display: flex;
  flex-direction: column;
}
.steps__inner {
  align-items: center;
  display: flex;
  flex-basis: 50%;
}
@media (max-width: 480px) {
  .steps__inner {
    align-items: flex-start;
    flex-basis: 100%;
    flex-direction: row-reverse;
    padding-right: 25px;
  }
}
.steps__item {
  display: flex;
  min-height: 132px;
  position: relative;
  border-left: 2px solid #212322;
  padding-left: 30px;
  padding-bottom: 52px;
  left: -30px;
}
@media (max-width: 480px) {
  .steps__item {
    min-height: unset;
  }
}
.steps__item:last-child {
  padding-bottom: 0px;
  border-left: none;
}
@media (max-width: 480px) {
  .steps__item:last-child {
    margin-bottom: 0;
  }
}
.steps__item:nth-child(even) {
  justify-content: flex-end;
}
@media (max-width: 480px) {
  .steps__item:nth-child(even) {
    justify-content: flex-start;
  }
}
.steps__item:nth-child(even) .steps__inner {
  flex-direction: row-reverse;
}
.steps__item:nth-child(even) .steps__description, .steps__item:nth-child(even) .steps__title {
  padding-left: 100px;
}
@media (max-width: 768px) {
  .steps__item:nth-child(even) .steps__description, .steps__item:nth-child(even) .steps__title {
    padding-left: 70px;
  }
}
@media (max-width: 480px) {
  .steps__item:nth-child(even) .steps__description, .steps__item:nth-child(even) .steps__title {
    padding-left: 60px;
  }
}
.steps__item:nth-child(even) .steps__content {
  padding-right: 0;
}
@media (max-width: 480px) {
  .steps__item:nth-child(even) .steps__content {
    padding-right: 0;
  }
}
.steps__item:nth-child(even) .steps__icon {
  transform: translateX(50%);
}
@media (max-width: 480px) {
  .steps__item:nth-child(even) .steps__icon {
    left: 0;
    transform: translateX(0);
  }
}
.steps__item:nth-child(odd) .steps__description, .steps__item:nth-child(odd) .steps__title {
  padding-right: 100px;
}
@media (max-width: 768px) {
  .steps__item:nth-child(odd) .steps__description, .steps__item:nth-child(odd) .steps__title {
    padding-right: 70px;
  }
}
@media (max-width: 480px) {
  .steps__item:nth-child(odd) .steps__description, .steps__item:nth-child(odd) .steps__title {
    padding-left: 60px;
    padding-right: 0;
  }
}
.steps__item:nth-child(odd) .steps__icon {
  left: 100%;
  right: unset;
}
@media (max-width: 480px) {
  .steps__item:nth-child(odd) .steps__icon {
    left: 0;
    transform: translateX(0);
  }
}
.steps__content {
  width: 100%;
}
@media (max-width: 480px) {
  .steps__content {
    padding-right: 0;
    position: relative;
  }
}
.steps__title {
  color: #15223c;
  margin-bottom: 10px;
  word-break: break-word;
  font-size: 24px;
}
@media (max-width: 480px) {
  .steps__title {
    margin-bottom: 5px;
  }
}
.steps__description {
  color: #333a3c;
  position: relative;
}
.steps__description .p3, .steps__description .footer__top-nav li a, .footer__top-nav li .steps__description a {
  font-weight: 300;
  position: relative;
  font-size: 14px;
}
@media (max-width: 480px) {
  .steps__description {
    position: static;
  }
}
.steps__line {
  background-color: #5ae6bd;
  background-position: center top;
  background-repeat: repeat-y;
  background-size: 1px auto;
  height: calc(100% - 150px);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1px;
}
@media (max-width: 480px) {
  .steps__line {
    bottom: 0;
    height: calc(100% - 30px);
    left: unset;
    right: 100%;
    top: unset;
    transform: translate(25px, 0);
  }
}
.steps__icon {
  align-items: center;
  background-color: #fafafa;
  display: flex;
  flex-shrink: 0;
  height: 40px;
  justify-content: center;
  margin-top: -6px;
  position: absolute;
  right: 100%;
  text-align: center;
  top: 0;
  transform: translateX(-50%);
  width: 46px;
}
@media (max-width: 480px) {
  .steps__icon {
    height: auto;
    transform: translateX(0);
    top: -3px;
  }
}
.steps__icon-index {
  color: #0e7a73;
  display: inline-flex;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1;
  padding-top: 4px;
}
@media (max-width: 480px) {
  .steps__icon-index {
    font-size: 20px;
  }
}
.steps--white .steps__icon {
  background: #fff;
}
.steps--white .steps__title a {
  color: #212322;
  transition: all 0.16s;
}
.steps--white .steps__title a:hover {
  color: #20b59a;
}
.steps--right-aligned {
  width: 400px;
}
@media (max-width: 768px) {
  .steps--right-aligned {
    padding-bottom: 0;
  }
}
@media (max-width: 480px) {
  .steps--right-aligned {
    width: 300px;
  }
}
.steps--right-aligned .steps__line {
  left: 26px;
  top: 0;
  transform: none;
  background-color: #212322;
  width: 2px;
  height: calc(100% - 148px);
}
@media (max-width: 768px) {
  .steps--right-aligned .steps__line {
    height: calc(100% - 120px);
    top: 8px;
  }
}
.steps--right-aligned .steps__list {
  padding-left: 56px;
  gap: 0px;
}
.steps--right-aligned .steps__inner {
  align-items: flex-start;
  flex-basis: 100%;
}
.steps--right-aligned .steps__item:nth-child(odd), .steps--right-aligned .steps__item:nth-child(even) {
  justify-content: flex-start;
}
.steps--right-aligned .steps__item:nth-child(odd) .steps__icon, .steps--right-aligned .steps__item:nth-child(even) .steps__icon {
  left: -23px;
  transform: none;
  background: #fff;
  margin-top: 0;
}
@media (max-width: 480px) {
  .steps--right-aligned .steps__item:nth-child(odd) .steps__icon, .steps--right-aligned .steps__item:nth-child(even) .steps__icon {
    left: -53px;
  }
}
.steps--right-aligned .steps__item:nth-child(odd) .steps__icon::after, .steps--right-aligned .steps__item:nth-child(even) .steps__icon::after {
  content: attr(data-index);
  position: absolute;
  margin-top: 4px;
  font-weight: 700;
  font-size: 18px;
}
.steps--right-aligned .steps__item:nth-child(odd) .steps__title, .steps--right-aligned .steps__item:nth-child(even) .steps__title {
  margin-top: 5px;
}
.steps--right-aligned .steps__item:nth-child(odd) .steps__title,
.steps--right-aligned .steps__item:nth-child(odd) .steps__description, .steps--right-aligned .steps__item:nth-child(even) .steps__title,
.steps--right-aligned .steps__item:nth-child(even) .steps__description {
  padding-left: 0;
  padding-right: 0;
}
.steps--days .steps__icon {
  background: #fafafa;
  height: 46px;
  padding: 5px;
  width: 46px;
}
.steps--days .steps__icon-index {
  color: #212322;
  font-family: "Roboto Slab", serif;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  padding: 0;
}
.steps--days .steps__icon svg {
  height: calc(100% - 10px);
  left: 5px;
  position: absolute;
  top: 5px;
  width: calc(100% - 10px);
}

.outcomes {
  margin-bottom: 40px;
  padding-top: 80px;
}
@media (max-width: 768px) {
  .outcomes {
    margin-bottom: 20px;
    padding-top: 40px;
  }
}
.outcomes__header {
  display: flex;
  padding-bottom: 44px;
}
@media (max-width: 768px) {
  .outcomes__header {
    flex-direction: column;
    padding-bottom: 30px;
  }
}
.outcomes__text {
  flex-basis: 50%;
  padding-right: 30px;
}
@media (max-width: 768px) {
  .outcomes__text {
    margin-bottom: 15px;
    padding-right: 0;
  }
}
.outcomes__title {
  color: #15223c;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .outcomes__title {
    margin-bottom: 15px;
    padding-right: 0;
  }
}
.outcomes__description {
  color: #333a3c;
}
@media (max-width: 768px) {
  .outcomes__description {
    padding-left: 0;
  }
}
.outcomes__description p {
  margin-bottom: 1.3em;
}
.outcomes__description p:last-child {
  margin-bottom: 0;
}
.outcomes__image {
  flex-basis: 50%;
  padding-left: 24px;
  padding-right: 17px;
}
@media (max-width: 768px) {
  .outcomes__image {
    padding-left: 0;
  }
}
.outcomes__image img {
  height: auto;
}
.outcomes__content {
  padding: 120px 50px;
}
@media (max-width: 960px) {
  .outcomes__content {
    padding: 120px 0;
  }
}
@media (max-width: 768px) {
  .outcomes__content {
    padding: 60px 0;
  }
}
@media (max-width: 480px) {
  .outcomes__content {
    padding: 40px 0;
  }
}
.outcomes__list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -50px;
  margin-left: -14px;
  margin-right: -14px;
}
@media (max-width: 768px) {
  .outcomes__list {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.outcomes__item {
  flex-basis: 33.3333%;
  margin-bottom: 50px;
  padding-left: 14px;
  padding-right: 14px;
}
@media (max-width: 768px) {
  .outcomes__item {
    flex-basis: 50%;
    margin-bottom: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 576px) {
  .outcomes__item {
    flex-basis: 100%;
    margin-bottom: 25px;
  }
}
.outcomes__item-group {
  display: flex;
  flex-direction: column;
}
.outcomes__item-link {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 20px;
  text-decoration: none;
  transition: background-color 0.16s;
}
.outcomes__item-link .link {
  color: #5b6770;
}
.outcomes__item-link:hover {
  background-color: #f1f1f1;
}
.outcomes__item-link:hover .link {
  color: #20b59a;
}
.outcomes__item-link:hover .outcomes__item-title-caption {
  text-decoration: underline;
}
.outcomes__item-title {
  color: #212322;
  display: flex;
  margin-bottom: 33px;
  padding-top: 15px;
}
.outcomes__item-title-caption {
  padding-left: 19px;
}
.outcomes__item-title-index {
  align-items: center;
  display: flex;
  font-family: "Roboto Slab", serif;
  font-size: 15px;
  font-weight: 600;
  height: 42px;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 42px;
}
.outcomes__item-title-index svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.outcomes__item-description {
  color: #333a3c;
  margin-bottom: 19px;
  min-height: 93px;
}
@media (max-width: 576px) {
  .outcomes__item-description {
    min-height: unset;
  }
}
.outcomes--team-single {
  background-color: #fff;
  margin-bottom: 0;
  padding-bottom: 60px;
  padding-top: 100px;
}
@media (max-width: 768px) {
  .outcomes--team-single {
    padding-bottom: 30px;
    padding-top: 60px;
  }
}
@media (max-width: 768px) {
  .outcomes--team-single .benefits__header {
    margin-bottom: 30px;
  }
}
@media (max-width: 480px) {
  .outcomes--team-single .benefits__header {
    margin-bottom: 10px;
  }
}
.outcomes--team-single .benefits__list {
  margin-left: -45px;
  margin-right: -45px;
}
@media (max-width: 768px) {
  .outcomes--team-single .benefits__list {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.outcomes--team-single .benefits__item {
  flex-basis: 50%;
  margin-bottom: 82px;
  padding-left: 45px;
  padding-right: 45px;
}
@media (max-width: 768px) {
  .outcomes--team-single .benefits__item {
    margin-bottom: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 576px) {
  .outcomes--team-single .benefits__item {
    flex-basis: 100%;
    margin-bottom: 25px;
  }
}
.outcomes--team-single .benefits__title {
  margin-bottom: 0;
}

.showreel {
  margin-bottom: 56px;
  padding-top: 62px;
}
@media (max-width: 768px) {
  .showreel {
    padding-top: 20px;
  }
}
.showreel__inner {
  overflow: hidden;
  padding-bottom: 20px;
  padding-top: 20px;
  position: relative;
}
@media (max-width: 768px) {
  .showreel__inner {
    padding-top: 60px;
  }
}
.showreel__background {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.showreel__background::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(43.21deg, #53778a, #8ea4af);
}
.showreel__background-circle-big {
  background-image: url("../img/showreel-circle-big.svg");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 90%;
  position: absolute;
  right: 9%;
  top: 2.5%;
  width: 58%;
  z-index: 1;
}
@media (max-width: 768px) {
  .showreel__background-circle-big {
    right: -20%;
    top: 6.5%;
    width: 80%;
  }
}
.showreel__background-circle-small {
  background-image: url("../img/showreel-circle-small.svg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 90%;
  left: 5%;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 28%;
  z-index: 1;
}
.showreel__content {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  padding-left: 76px;
  position: relative;
  z-index: 2;
}
@media (max-width: 768px) {
  .showreel__content {
    align-items: flex-start;
    flex-direction: column;
    padding-left: 20px;
  }
}
.showreel__group {
  flex-basis: 300px;
  flex-shrink: 0;
  padding-bottom: 112px;
}
@media (max-width: 768px) {
  .showreel__group {
    flex-basis: auto;
  }
}
@media (max-width: 480px) {
  .showreel__group {
    padding-bottom: 40px;
  }
}
.showreel__image {
  flex-grow: 1;
  padding-left: 47px;
}
@media (max-width: 480px) {
  .showreel__image {
    padding-left: 0;
  }
}
.showreel__title {
  color: #fff;
  font-size: 64px;
  line-height: 1.125;
}
@media (max-width: 768px) {
  .showreel__title {
    font-size: 58px;
  }
}
.showreel__caption {
  color: #c1c3c3;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 30px;
  margin-bottom: 72px;
}
@media (max-width: 768px) {
  .showreel__caption {
    margin-bottom: 30px;
  }
}
.showreel__action .video-link {
  color: #fff;
}
.showreel__action .video-link__title {
  border-color: #fff;
}
.showreel__action .video-link:hover {
  color: #20b59a;
}
.showreel__action .video-link:hover .video-link__title {
  border-color: #20b59a;
}

.prices {
  background: #fafafa;
  padding-bottom: 100px;
  padding-top: 100px;
}
@media (max-width: 768px) {
  .prices {
    padding-bottom: 50px;
    padding-top: 50px;
  }
}
.prices__title {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 64px;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 1.328125;
  margin-bottom: 10px;
  text-align: center;
}
@media (max-width: 960px) {
  .prices__title {
    font-size: 50px;
  }
}
@media (max-width: 768px) {
  .prices__title {
    font-size: 32px;
  }
}
.prices__description {
  color: #333a3c;
  font-size: 24px;
  margin-bottom: 35px;
  text-align: center;
}
@media (max-width: 960px) {
  .prices__description {
    font-size: 20px;
    line-height: 1.45;
  }
}
@media (max-width: 480px) {
  .prices__description {
    font-size: 18px;
    line-height: 1.45;
    margin-bottom: 15px;
  }
}
.prices__cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1110px;
  padding: 20px 50px 0;
}
@media (max-width: 960px) {
  .prices__cards {
    padding: 20px 0 0;
  }
}
@media (max-width: 576px) {
  .prices__cards {
    align-items: center;
    flex-direction: column;
  }
}
.prices__note {
  color: #333a3c;
  flex-basis: 100%;
  padding-left: 15px;
}
@media (max-width: 960px) {
  .prices__note {
    padding-left: 0;
    text-align: center;
  }
}

.author {
  align-items: center;
  display: flex;
}
.author__photo {
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: 48px;
  justify-content: center;
  margin-right: 18px;
  overflow: hidden;
  position: relative;
  width: 48px;
}
.author__photo img {
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.author__details {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1.4;
}
@media (max-width: 768px) {
  .author__details {
    font-size: 16px;
  }
}
.author__details a {
  color: #fff;
  display: inline-block;
  line-height: 1.1;
  text-decoration: none;
}
.author__details a:hover {
  text-decoration: underline;
}
.author__time {
  align-items: center;
  display: flex;
  font-family: "Roboto Slab", serif;
  font-size: 12px;
  font-weight: 300;
}
@media (max-width: 768px) {
  .author--mobile-centered {
    flex-direction: column;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .author--mobile-centered .author__photo {
    margin-bottom: 10px;
  }
}

.articles {
  padding-bottom: 80px;
  padding-top: 0px;
}
@media (max-width: 960px) {
  .articles {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}
.articles--static {
  padding-bottom: 0px;
}
.articles--team-single {
  padding-bottom: 70px;
  padding-top: 80px;
}
@media (max-width: 768px) {
  .articles--team-single {
    padding-top: 60px;
  }
}
@media (max-width: 480px) {
  .articles--team-single {
    padding-top: 40px;
  }
}
.articles--article-single .h2, .articles--article-single .post-content__inner .body-text h2, .post-content__inner .body-text .articles--article-single h2, .articles--article-single .content-heading, .articles--article-single .description__content h2, .description__content .articles--article-single h2 {
  margin-bottom: 50px;
}
@media (max-width: 480px) {
  .articles--article-single .h2, .articles--article-single .post-content__inner .body-text h2, .post-content__inner .body-text .articles--article-single h2, .articles--article-single .content-heading, .articles--article-single .description__content h2, .description__content .articles--article-single h2 {
    margin-bottom: 30px;
  }
}
.articles__list--preloader {
  display: none;
  padding: 0;
}
.articles__list--preloader.is-visible {
  display: block;
}
.articles__list--js:empty {
  display: none;
}
.articles__list--static, .articles__list--js {
  padding-bottom: 0;
}
.articles__item {
  margin-bottom: 60px;
}
@media (max-width: 480px) {
  .articles__item {
    margin-bottom: 40px;
  }
}

.articles-card {
  align-items: center;
  display: flex;
  max-width: 760px;
}
@media (max-width: 768px) {
  .articles-card {
    align-items: flex-start;
  }
}
.articles-card:hover .articles-card__image::after {
  opacity: 1;
}
.articles-card__image {
  flex-shrink: 0;
  height: 148px;
  overflow: hidden;
  position: relative;
  width: 148px;
}
@media (max-width: 480px) {
  .articles-card__image {
    height: 80px;
    width: 80px;
  }
}
.articles-card__image::after {
  background-color: #5ae6bd;
  bottom: 0;
  content: "";
  height: 6px;
  left: 0;
  opacity: 0;
  position: absolute;
  transition: opacity 0.2s;
  width: 100%;
}
.articles-card__image a {
  background-color: #7a8a8e;
  display: block;
  height: 100%;
}
.articles-card__image img {
  height: 100%;
  object-fit: cover;
  transition: all 0.26s;
  width: 100%;
}
.articles-card__details {
  padding-left: 25px;
}
@media (max-width: 480px) {
  .articles-card__details {
    padding-left: 15px;
  }
}
.articles-card__heading {
  margin-bottom: 5px;
}
.articles-card__heading a {
  color: #212322;
  text-decoration: none;
}
.articles-card__heading a:hover {
  text-decoration: underline;
}
.articles-card__tag {
  margin-bottom: 5px;
}
.articles-card__description {
  color: #333a3c;
}
.articles-card--preloader .articles-card__placeholder {
  display: inline-block;
}
.articles-card--preloader .articles-card__details {
  width: 100%;
}
@media (max-width: 480px) {
  .articles-card--preloader .articles-card__image {
    height: 80px;
    margin-bottom: 15px;
    padding-bottom: 0;
    position: relative;
  }
}
.articles-card--preloader .articles-card__image span {
  display: block;
  height: 100%;
  width: 100%;
}
@media (max-width: 480px) {
  .articles-card--preloader .articles-card__image span {
    left: 0;
    position: absolute;
    top: 0;
  }
}
.articles-card--preloader .articles-card__tag span {
  height: 1em;
  width: 60px;
}
.articles-card--preloader .articles-card__heading span {
  height: 1em;
  max-width: 80%;
  width: 100%;
}
.articles-card--preloader .articles-card__description span {
  height: 1em;
  max-width: 60%;
  width: 100%;
}
.articles-card.is-hovered .articles-card__heading a {
  text-decoration: underline;
}

.load-more-holder {
  height: 1px;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
}

.jobs .articles-card__details {
  position: relative;
}
.jobs .career-hero .career_open_tab {
  top: -41px;
}
.jobs .career_open_tab {
  position: absolute;
  top: 0;
  right: 0;
  color: #333a3c;
  text-align: center;
  font-family: "Roboto Slab", serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 8px;
  border-radius: 2px;
  background: #f2f2f2;
}
.jobs .career_open_tab.open {
  background: #5ae6bd;
}
@media (max-width: 480px) {
  .jobs .career_open_tab {
    position: static;
    width: auto;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.summary-block {
  font-family: "Roboto Slab", serif;
}
.summary-block h2 {
  font-size: 36px;
}
.summary-block p {
  color: #212322;
  margin-bottom: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%;
  letter-spacing: 0.64px;
}

.video-section,
.video__section {
  padding: 80px 45.5px;
}
@media (max-width: 768px) {
  .video-section,
  .video__section {
    padding: 50px 0;
  }
}
.video-section h1,
.video__section h1 {
  font-size: 48px;
}
.video-section-intro,
.video__section-intro {
  font-size: 18px;
  max-width: 773px;
  margin: 12px auto 32px;
}
.video-section__inner,
.video__section__inner {
  background: #f2f2f2;
  overflow: hidden;
}
.video-section__holder,
.video__section__holder {
  display: flex;
  padding: 112px 0 110px;
  position: relative;
  width: 100%;
}
@media (max-width: 768px) {
  .video-section__holder,
  .video__section__holder {
    flex-direction: column;
    padding: 50px 0;
  }
}
.video-section__left,
.video__section__left {
  display: flex;
  flex-basis: 30%;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .video-section__left,
  .video__section__left {
    flex-basis: 100%;
    padding-bottom: 20px;
  }
}
.video-section__left-top,
.video__section__left-top {
  padding-bottom: 20px;
}
.video-section__left-top .heading,
.video__section__left-top .heading {
  margin-bottom: 30px;
}
.video-section__left-top .section-description,
.video__section__left-top .section-description {
  color: #212322;
}
.video-section__left-bottom,
.video__section__left-bottom {
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .video-section__left .slider-next,
  .video__section__left .slider-next {
    display: none;
  }
}
.video-section__right,
.video__section__right {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  padding-left: 100px;
  padding-top: 8px;
  position: relative;
}
@media (max-width: 960px) {
  .video-section__right,
  .video__section__right {
    padding-left: 50px;
  }
}
@media (max-width: 768px) {
  .video-section__right,
  .video__section__right {
    padding-left: 0;
    padding-right: 0;
  }
}
.video-section__right .slider-next,
.video__section__right .slider-next {
  display: none;
  max-width: 215px;
  padding-top: 25px;
}
@media (max-width: 768px) {
  .video-section__right .slider-next,
  .video__section__right .slider-next {
    display: none;
  }
}
.video-section--testimonials,
.video__section--testimonials {
  padding-bottom: 0;
  position: relative;
}
@media (max-width: 768px) {
  .video-section--testimonials,
  .video__section--testimonials {
    padding-top: 0;
  }
}
.video-section--testimonials .video-section__inner,
.video__section--testimonials .video-section__inner {
  position: relative;
}
.video-section--testimonials .video-section__holder,
.video__section--testimonials .video-section__holder {
  padding: 112px 0;
}
@media (max-width: 960px) {
  .video-section--testimonials .video-section__holder,
  .video__section--testimonials .video-section__holder {
    padding: 80px 0;
  }
}
@media (max-width: 768px) {
  .video-section--testimonials .video-section__holder,
  .video__section--testimonials .video-section__holder {
    padding: 40px 0 70px;
  }
}
.video-section--testimonials .video-section__holder::before,
.video__section--testimonials .video-section__holder::before {
  background-color: #fff;
  bottom: 0;
  content: "";
  height: 162px;
  left: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
}
@media (max-width: 768px) {
  .video-section--testimonials .video-section__holder::before,
  .video__section--testimonials .video-section__holder::before {
    display: none;
  }
}
.video-section--testimonials .video-section__right,
.video__section--testimonials .video-section__right {
  padding-left: 0;
  position: static;
}
.video-section--project,
.video__section--project {
  padding-bottom: 0;
  padding-top: 0;
}
.video-section--project .video-section__holder::after,
.video__section--project .video-section__holder::after {
  display: none;
}
.video-section__dots,
.video__section__dots {
  bottom: 18px;
  left: 0;
  position: absolute;
  width: 100%;
}
.video-section__dots .slick-dots,
.video__section__dots .slick-dots {
  display: inline-flex;
  justify-content: center;
  position: static;
}
.video-section__dots .slick-dots > li,
.video__section__dots .slick-dots > li {
  height: auto;
  margin: 0;
  padding: 5px;
  width: auto;
}
.video-section__dots .slick-dots > li button,
.video__section__dots .slick-dots > li button {
  background: #c1c3c3;
  height: 3px;
  padding: 0;
  transition: color 0.2s;
  width: 30px;
}
.video-section__dots .slick-dots > li button::before,
.video__section__dots .slick-dots > li button::before {
  display: none;
}
.video-section__dots .slick-dots > li:hover button, .video-section__dots .slick-dots > li.slick-active button,
.video__section__dots .slick-dots > li:hover button,
.video__section__dots .slick-dots > li.slick-active button {
  background: #0e7a73;
}
.video-section__dots .slick-dots > li:hover,
.video__section__dots .slick-dots > li:hover {
  opacity: 0.8;
}

.video-gallery {
  min-width: 100%;
  width: 0;
}
.video-gallery-holder {
  min-height: 0;
  min-width: 0;
  position: relative;
}
.video-gallery__item {
  border: none !important;
  position: relative;
}
.video-gallery__item-inner {
  height: 100%;
  position: relative;
  width: 100%;
}
.video-gallery__placeholder {
  height: 0;
  padding-bottom: 75%;
  position: relative;
  z-index: 2;
}
.video-gallery__placeholder img {
  height: 100%;
  object-fit: cover;
  position: absolute;
  width: 100%;
}
.video-gallery__overlay {
  align-items: center;
  background-color: rgba(51, 58, 60, 0.7);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.video-gallery__video {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: 0.3s;
  visibility: hidden;
  width: 100%;
  z-index: 3;
}
.video-gallery__video video {
  background-size: cover;
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.video--fullscreen .video-gallery__video video {
  object-fit: contain;
}
.video-gallery__video.is-active {
  opacity: 1;
  visibility: visible;
}
.video-gallery__progress-bar {
  background-color: #0e7a73;
  bottom: 0;
  height: 2px;
  left: 0;
  position: absolute;
  width: 0;
  z-index: 3;
}

.video-pagination,
.testimonials-pagination {
  align-items: center;
  bottom: 0;
  color: #333a3c;
  display: inline-flex;
  font-size: 13px;
  opacity: 0;
  position: absolute;
  text-align: center;
  transition: all 0.5s;
  visibility: hidden;
}
@media (max-width: 768px) {
  .video-pagination,
  .testimonials-pagination {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    padding-top: 20px;
    position: static;
  }
}
.video-pagination__current,
.testimonials-pagination__current {
  color: #fff;
}
.video-pagination__delimiter, .video-pagination__current, .video-pagination__total,
.testimonials-pagination__delimiter,
.testimonials-pagination__current,
.testimonials-pagination__total {
  user-select: none;
}
.video-pagination__delimiter,
.testimonials-pagination__delimiter {
  margin-left: 5px;
  margin-right: 5px;
}
.video-pagination__next, .video-pagination__prev,
.testimonials-pagination__next,
.testimonials-pagination__prev {
  cursor: pointer;
  display: block;
  font-size: 11px;
  margin: 0;
  padding: 2px;
}
.video-pagination__next:hover, .video-pagination__prev:hover,
.testimonials-pagination__next:hover,
.testimonials-pagination__prev:hover {
  color: #fff;
}
.video-pagination__prev,
.testimonials-pagination__prev {
  margin-right: 18px;
  transform: rotate(90deg);
}
.video-pagination__next,
.testimonials-pagination__next {
  margin-left: 18px;
  transform: rotate(-90deg);
}
.video-pagination.is-visible,
.testimonials-pagination.is-visible {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 768px) {
  .video-pagination.is-visible,
  .testimonials-pagination.is-visible {
    transform: none;
  }
}

@media (max-width: 768px) {
  .testimonials-pagination {
    justify-content: center;
    padding-top: 0;
  }
}

.gallery-watch-video {
  align-items: center;
  color: #fff;
  cursor: pointer;
  display: flex;
  padding: 15px;
  transition: color 0.2s;
}
.gallery-watch-video__icon {
  display: inline-flex;
  font-size: 40px;
}
.gallery-watch-video__caption {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.02em;
  line-height: 24px;
  margin-left: 20px;
  text-decoration-line: underline;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .gallery-watch-video__caption {
    font-size: 20px;
    margin-left: 20px;
  }
}
@media (max-width: 480px) {
  .gallery-watch-video__caption {
    font-size: 18px;
    letter-spacing: 1.5px;
  }
}
.gallery-watch-video:hover {
  color: #5ae6bd;
}

.slider-next__heading {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 14px;
  text-transform: uppercase;
}
@media (max-width: 1024px) {
  .slider-next__heading {
    font-size: 16px;
  }
}
.slider-next__link {
  appearance: none;
  cursor: pointer;
  display: none;
  height: 0;
  padding: 0;
  padding-bottom: 43%;
  position: relative;
  text-align: left;
  transition: all 0.16s;
  width: calc(100% - 87px);
}
.slider-next__link.active {
  display: block;
}
.slider-next__link.active:hover {
  opacity: 0.9;
}
.slider-next__link.active:hover span {
  color: #0e7a73;
}
.slider-next__image {
  margin-bottom: 4px;
}
.slider-next__image-holder img {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.post-content hr {
  background-color: #e1e1e1;
  margin: 80px 0;
}
.post-content__inner {
  padding-bottom: 60px;
}
@media (max-width: 960px) {
  .post-content__inner {
    padding: 10px 0;
  }
}
.post-content__inner .body-text {
  color: #212322;
}
.post-content__inner .body-text.p2 {
  padding-top: 40px;
}
.post-content__inner .body-text h2 {
  font-size: 36px;
}
@media (max-width: 1024px) {
  .post-content__inner .body-text h2 {
    margin-bottom: 30px;
    margin-top: 60px;
    font-size: 28px;
  }
}
@media (max-width: 960px) {
  .post-content__inner .body-text h2 {
    margin-bottom: 15px;
    padding-top: 20px;
    font-size: 28px;
  }
}
@media (max-width: 480px) {
  .post-content__inner .body-text h2 {
    margin-bottom: 10px;
    margin-top: 10px;
    padding-top: 10px;
    font-size: 28px;
  }
}
.post-content__inner .body-text h2.no-top-indent {
  margin-top: 0;
  padding-top: 0;
}
.post-content__inner .body-text h3 {
  margin-bottom: 15px;
  margin-top: 50px;
}
@media (max-width: 960px) {
  .post-content__inner .body-text h3 {
    margin-bottom: 15px;
  }
}
@media (max-width: 480px) {
  .post-content__inner .body-text h3 {
    margin-bottom: 10px;
  }
}
@media (max-width: 1024px) {
  .post-content__inner .body-text h4 {
    margin-bottom: 20px;
    margin-top: 30px;
  }
}
@media (max-width: 960px) {
  .post-content__inner .body-text h4 {
    margin-bottom: 15px;
  }
}
@media (max-width: 480px) {
  .post-content__inner .body-text h4 {
    margin-bottom: 10px;
  }
}
.post-content__inner .body-text h5, .post-content__inner .body-text h6 {
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 43.2px */
  letter-spacing: 0.72px;
  margin-top: 100px;
  margin-bottom: 18px;
}
.post-content__inner .body-text h5:first-child, .post-content__inner .body-text h6:first-child {
  margin-top: 40px;
}
@media (max-width: 1024px) {
  .post-content__inner .body-text h5, .post-content__inner .body-text h6 {
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 80px;
  }
}
.post-content__inner .body-text h6 {
  font-size: 24px;
}
@media (max-width: 1024px) {
  .post-content__inner .body-text h6 {
    font-size: 18px;
  }
}
.post-content__inner .body-text a {
  color: #212322;
  font-weight: 400 !important;
  text-decoration: underline;
}
.post-content__inner .body-text a span {
  font-weight: inherit !important;
}
.post-content__inner .body-text a:hover {
  color: #20b59a;
}
.post-content__inner .body-text ul {
  color: #212322;
  margin-bottom: 40px;
  padding-top: 20px;
  margin-left: 50px;
}
@media (max-width: 480px) {
  .post-content__inner .body-text ul {
    margin-bottom: 30px;
  }
}
.post-content__inner .body-text ul li {
  font-weight: 300;
  line-height: 160%;
  margin-bottom: 16px;
  padding-left: 16px;
  position: relative;
  font-size: 16px;
}
.post-content__inner .body-text ul li p {
  color: #333a3c;
  margin-bottom: 0;
  font-size: 16px;
}
.post-content__inner .body-text ul li::before {
  color: #20b59a;
  content: "\e92d";
  font-family: icomoon !important;
  font-size: 8px;
  left: 0;
  position: absolute;
  top: 0px;
}
.post-content__inner .body-text ul.links li a {
  color: #333a3c;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
}
.post-content__inner .body-text ul.links li a:hover {
  color: #0e7a73;
}
.post-content__inner .body-text ul.links li::before {
  color: #333a3c;
  content: "\e90f";
  font-size: 9px;
}
.post-content__inner .body-text p {
  color: #212322;
  margin-bottom: 1.3em;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.64px;
}
@media (max-width: 768px) {
  .post-content__inner .body-text p {
    font-size: 14px;
  }
}
.post-content__inner .body-text p span {
  font-weight: 300 !important;
}
.post-content__inner .body-text blockquote {
  position: relative;
  background: #f9f9f9;
  padding: 60px 130px;
  margin: 60px 0;
}
@media (max-width: 768px) {
  .post-content__inner .body-text blockquote {
    margin: 60px 0px 60px 0px;
    padding: 60px 60px;
  }
}
.post-content__inner .body-text blockquote::before {
  bottom: 100%;
  color: #20b59a;
  content: "\e922";
  font-family: "icomoon" !important;
  font-size: 26px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  line-height: 1;
  pointer-events: none;
  position: absolute;
  left: 110px;
  top: 44px;
  transform: translateY(6px) translateX(-25px);
  z-index: 1;
}
@media (max-width: 768px) {
  .post-content__inner .body-text blockquote::before {
    left: 45px;
  }
}
.post-content__inner .body-text blockquote p {
  color: #212322;
  margin-bottom: 0;
  color: #333a3c;
  font-family: "Roboto Slab", serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 51.2px */
  letter-spacing: 0.64px;
}
@media (max-width: 768px) {
  .post-content__inner .body-text blockquote p {
    font-size: 16px;
  }
}
.post-content__inner .body-text blockquote cite {
  text-align: right;
  display: block;
  font-weight: 400;
  margin-top: 10px;
  font-size: 18px;
}
@media (max-width: 768px) {
  .post-content__inner .body-text blockquote cite {
    font-size: 14px;
  }
}
.post-content__inner .body-text blockquote cite::before {
  content: "—";
}
.post-content__inner .body-images {
  margin-bottom: 30px;
  padding-top: 60px;
}
@media (max-width: 480px) {
  .post-content__inner .body-images {
    padding-top: 10px;
  }
}
.post-content__inner .body-images__item img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.post-content__inner .body-images--grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 480px) {
  .post-content__inner .body-images--grid {
    grid-gap: 10px;
  }
}
.post-content--background {
  background: #f2f2f2;
}
.post-content--team-single {
  padding-bottom: 100px;
  padding-top: 100px;
}
@media (max-width: 960px) {
  .post-content--team-single {
    padding: 80px 0;
  }
}
@media (max-width: 768px) {
  .post-content--team-single {
    padding: 60px 0;
  }
}
@media (max-width: 480px) {
  .post-content--team-single {
    padding: 50px 0;
  }
}
.post-content--team-single .post-content__inner {
  padding-bottom: 0;
  padding-left: 100px;
  padding-right: 100px;
}
@media (max-width: 960px) {
  .post-content--team-single .post-content__inner {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (max-width: 768px) {
  .post-content--team-single .post-content__inner {
    padding-left: 0;
    padding-right: 0;
  }
}
.post-content--team-single .post-content__inner .body-text h3 {
  margin-top: 0;
  padding-top: 0;
}
.post-content--team-single .post-content__inner .body-text ul {
  margin-bottom: 0;
}
.post-content--service {
  padding-top: 80px;
}
@media (max-width: 960px) {
  .post-content--service {
    padding-top: 60px;
  }
}
@media (max-width: 480px) {
  .post-content--service {
    padding-top: 40px;
  }
}

.single-post-wrapper {
  background: #f9f9f9;
  padding-bottom: 0;
  padding-top: 70px;
}
@media (max-width: 768px) {
  .single-post-wrapper {
    padding-top: 30px;
  }
}
.single-post-wrapper .quick-links__holder {
  background: transparent;
}
.single-post-wrapper .quick-links__item {
  margin-top: 0;
}

.image-text-left-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 60px;
}
.image-text-left-right.image-right {
  flex-direction: row-reverse;
}
.image-text-left-right__image {
  max-width: 50%;
  flex: 1;
}
.image-text-left-right__text {
  max-width: 50%;
  flex: 1;
}
.image-text-left-right__text h4 {
  margin-top: 20px;
  text-transform: none;
  font-size: 28px;
  font-weight: 500;
}
.image-text-left-right__text p {
  color: #212322;
  font-family: "Roboto Slab", serif;
  margin-bottom: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%;
  letter-spacing: 0.64px;
}
.image-text-left-right__text p a {
  color: #212322;
  font-weight: 400 !important;
}
@media (max-width: 768px) {
  .image-text-left-right {
    gap: 40px;
    flex-direction: column;
  }
  .image-text-left-right__image {
    max-width: 100%;
    width: 100%;
  }
  .image-text-left-right__text {
    max-width: 100%;
    width: 100%;
  }
}

.featured-gallery {
  margin-top: 51px;
  overflow: hidden;
  position: relative;
}
@media (max-width: 768px) {
  .featured-gallery {
    margin-left: -20px;
    margin-right: -20px;
  }
}
.featured-gallery__holder {
  position: relative;
}
.featured-gallery__item-holder {
  height: 0;
  padding-bottom: 55%;
  position: relative;
}
@media (max-width: 768px) {
  .featured-gallery__item-holder {
    padding-bottom: 60%;
  }
}
@media (max-width: 480px) {
  .featured-gallery__item-holder {
    padding-bottom: 80%;
  }
}
.featured-gallery__background {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.featured-gallery__background img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.featured-gallery__background-overlay {
  background: #212322;
  height: 100%;
  left: 0;
  mix-blend-mode: multiply;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
}
.featured-gallery__foreground {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding-left: 30px;
  padding-right: 30px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.featured-gallery__logo {
  margin-bottom: 30px;
  max-width: 300px;
  text-align: center;
  width: 100%;
}
@media (max-width: 960px) {
  .featured-gallery__logo {
    max-width: 220px;
  }
}
@media (max-width: 768px) {
  .featured-gallery__logo {
    margin-bottom: 15px;
    max-width: 150px;
  }
}
@media (max-width: 480px) {
  .featured-gallery__logo {
    margin-bottom: 10px;
    max-width: 100px;
  }
}
.featured-gallery__logo img {
  display: inline-block;
}
.featured-gallery__action {
  display: none;
  padding-top: 50px;
}
.featured-gallery__title {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
}
@media (max-width: 768px) {
  .featured-gallery__title {
    font-size: 20px;
  }
}
@media (max-width: 480px) {
  .featured-gallery__title {
    font-size: 18px;
  }
}
@media (max-width: 480px) {
  .featured-gallery__subtitle {
    font-size: 16px;
  }
}
.featured-gallery__progress-bar {
  bottom: 0;
  height: 5px;
  left: 0;
  position: absolute;
  width: 100%;
}
.featured-gallery__progress-bar div {
  background: #5ae6bd;
  display: block;
  height: 100%;
  width: 0;
}
.featured-gallery--flexible {
  margin: 70px 0;
}
@media (max-width: 768px) {
  .featured-gallery--flexible {
    margin: 50px 0;
  }
}
@media (max-width: 480px) {
  .featured-gallery--flexible {
    margin: 40px 0;
  }
}
.featured-gallery--flexible .featured-gallery__item-holder {
  padding-bottom: 54%;
}
.featured-gallery--flexible .featured-gallery__item:hover {
  cursor: pointer;
}
.featured-gallery:hover .featured-navigation {
  opacity: 1;
}
.featured-gallery:hover .featured-gallery__action {
  display: block;
}

.featured-navigation {
  opacity: 0;
}
@media (max-width: 576px) {
  .featured-navigation {
    display: none;
  }
}
.featured-navigation .featured-button {
  position: absolute;
}
.featured-navigation .featured-button--arrow_left {
  cursor: pointer;
  left: 0;
}
.featured-navigation .featured-button--arrow_left > div {
  right: 0;
}
.featured-navigation .featured-button--arrow_right {
  cursor: pointer;
  right: 0;
}
.featured-navigation .featured-button--arrow_right > div {
  left: 0;
}
.related-projects {
  background-color: #f9f9f9;
  overflow: hidden;
  padding-bottom: 136px;
  padding-top: 100px;
  width: 100%;
}
@media (max-width: 768px) {
  .related-projects {
    padding-bottom: 60px;
    padding-top: 80px;
  }
}
@media (max-width: 480px) {
  .related-projects {
    padding-bottom: 40px;
    padding-top: 60px;
  }
}
.related-projects .content-text, .related-projects .title__caption {
  font-size: 16px;
  max-width: calc(100% - 44px);
}

.featured-projects-gallery {
  padding-top: 40px;
  position: relative;
  overflow: hidden;
  margin-left: -22px;
  margin-right: -22px;
}
@media (max-width: 768px) {
  .featured-projects-gallery {
    padding-top: 30px;
  }
}
.featured-projects-gallery .navigation-arrows {
  margin-top: -29px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-100%);
}
.featured-projects-gallery__heading {
  position: relative;
}
.featured-projects-gallery__holder {
  position: relative;
}
.featured-projects-gallery .projects__item {
  margin: 0;
  width: 432px;
  flex-basis: 432px;
}
.featured-projects-gallery__slider {
  z-index: 2;
}
.featured-projects-gallery .slick-list {
  overflow: visible;
}
.featured-projects-gallery .slick-track {
  display: flex;
  gap: 22px;
}

.cookies-window {
  background-color: #fff;
  bottom: 50px;
  box-shadow: 0 0 18px 3px rgba(0, 0, 0, 0.1);
  max-width: 236px;
  opacity: 0;
  padding: 30px;
  position: fixed;
  right: 50px;
  transform: translateY(10px);
  transition: 0.5s;
  visibility: hidden;
  z-index: 10;
}
@media (max-width: 480px) {
  .cookies-window {
    bottom: 30px;
    left: 30px;
    max-width: unset;
    padding: 30px;
    right: 30px;
  }
}
.cookies-window.is-active {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}
.cookies-window__image {
  margin-bottom: 25px;
  text-align: center;
}
@media (max-width: 480px) {
  .cookies-window__image {
    display: none;
  }
}
.cookies-window__image img {
  display: inline-block;
  max-width: 151px;
  width: 100%;
}
.cookies-window__button {
  text-align: center;
}
.cookies-window__text {
  color: #333a3c;
  margin-bottom: 25px;
}
.cookies-window__text a {
  color: #212322;
  font-weight: bold;
  transition: all 0.16s;
}
.cookies-window__text a:hover {
  color: #0e7a73;
}

.faq {
  padding-bottom: 80px;
  padding-top: 100px;
}
@media (max-width: 768px) {
  .faq {
    padding-bottom: 60px;
    padding-top: 80px;
  }
}
@media (max-width: 480px) {
  .faq {
    padding-bottom: 40px;
    padding-top: 60px;
  }
}
.faq .content-heading {
  margin-bottom: 50px;
}
.faq__title {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 18px 30px;
}
.faq__title-text {
  align-self: flex-start;
  padding-right: 106px;
  user-select: none;
}
@media (max-width: 768px) {
  .faq__title-text {
    padding-right: 40px;
  }
}
.faq__title:hover .faq__icon {
  color: #0e7a73;
}
.faq__title:hover .faq__title-text {
  color: #0e7a73;
}
.faq__title:hover .faq__item {
  background: #f2f2f2;
}
.faq__icon {
  color: #5b6770;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 32px;
  transition: color 0.16s, transform 0.3s;
}
.faq__item {
  background: #fafafa;
  margin-bottom: 20px;
  transition: background-color 0.16s;
}
.faq__item.is-active {
  background: #f2f2f2;
}
.faq__item.is-active .faq__content-inner {
  opacity: 1;
}
.faq__item.is-active .faq__icon {
  color: #0e7a73;
  transform: rotate(180deg);
}
.faq__content {
  display: none;
  max-width: 660px;
  padding: 30px 60px;
}
@media (max-width: 480px) {
  .faq__content {
    padding: 5px 30px 25px;
  }
}
.faq__content-inner {
  opacity: 0;
  transition: 0.2s;
}
.faq__content p {
  margin-bottom: 0;
}

.technologies {
  background-color: #fff;
  margin-bottom: 100px;
  padding-bottom: 100px;
  padding-top: 93px;
}
@media (max-width: 960px) {
  .technologies {
    margin-bottom: 80px;
    padding-bottom: 80px;
    padding-top: 80px;
  }
}
@media (max-width: 768px) {
  .technologies {
    margin-bottom: 50px;
    padding-bottom: 50px;
    padding-top: 50px;
  }
}
@media (max-width: 480px) {
  .technologies {
    margin-bottom: 30px;
    padding-bottom: 30px;
    padding-top: 30px;
  }
}
.technologies__inner {
  display: flex;
  justify-content: space-between;
  padding-left: 63px;
}
@media (max-width: 1024px) {
  .technologies__inner {
    padding-left: 0;
  }
}
@media (max-width: 576px) {
  .technologies__inner {
    flex-direction: column-reverse;
  }
}
.technologies__list {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 960px) {
  .technologies__list {
    width: 280px;
  }
}
@media (max-width: 768px) {
  .technologies__list {
    width: 220px;
  }
}
@media (max-width: 576px) {
  .technologies__list {
    width: 100%;
  }
}
.technologies__item {
  height: 60px;
  margin-bottom: 50px;
  text-align: center;
  width: 33.333%;
}
@media (max-width: 960px) {
  .technologies__item {
    height: 50px;
    margin-bottom: 40px;
  }
}
@media (max-width: 768px) {
  .technologies__item {
    height: 40px;
    margin-bottom: 30px;
  }
}
@media (max-width: 576px) {
  .technologies__item {
    height: 50px;
    margin-bottom: 40px;
  }
}
.technologies__item img {
  display: inline-block;
  height: 100%;
  width: auto;
}
.technologies__content {
  max-width: 530px;
  padding-left: 50px;
}
@media (max-width: 768px) {
  .technologies__content {
    padding-left: 30px;
  }
}
@media (max-width: 576px) {
  .technologies__content {
    margin-bottom: 30px;
    padding-left: 0;
  }
}
.technologies__content p {
  margin-bottom: 15px;
}
.technologies__content p:last-child {
  margin-bottom: 0;
}
.technologies--project {
  margin-bottom: 0;
}
.technologies--project .technologies__content p {
  margin-bottom: 1em;
}
.technologies--project .technologies__content p:last-child {
  margin-bottom: 0;
}

.scroll-cards__inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;
}
.scroll-cards__item {
  flex-basis: 33.3333%;
  padding: 16px;
}
@media (max-width: 1024px) {
  .scroll-cards__item {
    flex-basis: 50%;
  }
}
@media (max-width: 768px) {
  .scroll-cards__item {
    flex-basis: 100%;
  }
}
.scroll-cards__item-holder {
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(64, 54, 78, 0.2);
  display: block;
  height: 100%;
  padding: 40px 30px;
  text-decoration: none;
  transition: all 0.16s;
}
@media (max-width: 960px) {
  .scroll-cards__item-holder {
    padding: 25px 20px;
  }
}
.scroll-cards__item-holder:hover {
  box-shadow: 0 6px 12px 0 rgba(64, 54, 78, 0.18);
}
.scroll-cards__item-holder:hover .scroll-cards__link span {
  text-decoration: none;
}
.scroll-cards__item:nth-child(2) svg {
  transform: rotate(-58deg);
}
.scroll-cards__item:nth-child(3) svg {
  transform: rotate(210deg);
}
.scroll-cards__item:nth-child(4) svg {
  transform: rotate(160deg);
}
.scroll-cards__item:nth-child(5) svg {
  transform: rotate(100deg);
}
.scroll-cards__heading {
  align-items: center;
  display: flex;
  margin-bottom: 30px;
}
@media (max-width: 480px) {
  .scroll-cards__heading {
    margin-bottom: 20px;
  }
}
.scroll-cards__title {
  color: #212322;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1.67px;
  line-height: 1.2;
  padding-left: 30px;
}
@media (max-width: 480px) {
  .scroll-cards__title {
    padding-left: 20px;
  }
}
.scroll-cards__icon {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 46px;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 46px;
}
@media (max-width: 960px) {
  .scroll-cards__icon {
    height: 40px;
    width: 40px;
  }
}
.scroll-cards__icon svg {
  display: block;
  position: absolute;
  width: 100%;
}
.scroll-cards__index {
  color: #0e7a73;
  display: inline-flex;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1;
  padding-top: 3px;
}
@media (max-width: 960px) {
  .scroll-cards__index {
    font-size: 22px;
  }
}
.scroll-cards__description {
  color: #333a3c;
  line-height: 1.7777777778;
  margin-bottom: 10px;
}
@media (max-width: 480px) {
  .scroll-cards__description {
    font-size: 16px;
  }
}
.scroll-cards__link span {
  color: #212322;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: underline;
}
.scroll-cards--row-2 .scroll-cards__inner {
  margin-left: -40px;
  margin-right: -40px;
}
@media (max-width: 1024px) {
  .scroll-cards--row-2 .scroll-cards__inner {
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 960px) {
  .scroll-cards--row-2 .scroll-cards__inner {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.scroll-cards--row-2 .scroll-cards__item {
  flex-basis: 50%;
  padding: 15px 40px;
}
@media (max-width: 1024px) {
  .scroll-cards--row-2 .scroll-cards__item {
    padding: 15px 20px;
  }
}
@media (max-width: 960px) {
  .scroll-cards--row-2 .scroll-cards__item {
    padding: 15px;
  }
}
@media (max-width: 768px) {
  .scroll-cards--row-2 .scroll-cards__item {
    flex-basis: 100%;
  }
}
.scroll-cards--row-2 .scroll-cards__item-holder {
  display: flex;
}
@media (max-width: 480px) {
  .scroll-cards--row-2 .scroll-cards__item-holder {
    flex-direction: column;
  }
}
.scroll-cards--row-2 .scroll-cards__icon {
  flex-shrink: 0;
  height: 55px;
  width: 55px;
}
@media (max-width: 960px) {
  .scroll-cards--row-2 .scroll-cards__icon {
    height: 40px;
    width: 40px;
  }
}
@media (max-width: 480px) {
  .scroll-cards--row-2 .scroll-cards__icon {
    margin-bottom: 15px;
  }
}
.scroll-cards--row-2 .scroll-cards__icon img {
  height: 100%;
  object-fit: contain;
  width: 100%;
}
.scroll-cards--row-2 .scroll-cards__title {
  margin-bottom: 10px;
  padding-left: 0;
}
.scroll-cards--row-2 .scroll-cards__content {
  flex-grow: 1;
  padding-left: 20px;
}
@media (max-width: 480px) {
  .scroll-cards--row-2 .scroll-cards__content {
    padding-left: 0;
  }
}

.user {
  display: flex;
  flex-direction: column;
  min-height: 450px;
  max-width: 180px;
}
.user__avatar {
  margin-bottom: 38px;
  position: relative;
}
@media (max-width: 768px) {
  .user__avatar {
    margin-bottom: 18px;
  }
}
.user__avatar img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
}
.user__avatar:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: 5px solid rgba(249, 249, 249, 0.5);
  z-index: 1;
  content: "";
}
.user__name {
  margin-bottom: 5px;
  font-size: 16px;
}
.user__role {
  color: #0e7a73;
  text-transform: initial;
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 500;
  line-height: 120%; /* 21.6px */
  letter-spacing: 0.36px;
}
@media (max-width: 768px) {
  .user__role {
    margin-bottom: 12px;
  }
}
.user__description {
  flex-grow: 1;
  font-family: "Roboto Slab", serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 160%;
}

.user-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 30px;
  margin: 0 auto;
}
.user-cards .user {
  padding-top: 30px;
}
@media (max-width: 1024px) {
  .user-cards .user {
    max-width: calc(50% - 15px);
    min-height: unset;
  }
}
@media (max-width: 480px) {
  .user-cards .user {
    max-width: 100%;
    min-height: unset;
  }
}
.user-cards .user .user__buttons {
  padding-top: 20px;
}

.video {
  position: relative;
  overflow: hidden;
  max-width: 773px;
  margin: 0 auto;
  aspect-ratio: 16/9;
}
.video iframe,
.video object,
.video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video__overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  background-color: #333a3c;
  z-index: 1;
  cursor: pointer;
}
.video__overlay img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video__overlay::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(0deg, rgba(51, 58, 60, 0.6), rgba(51, 58, 60, 0.6));
}
.video__play {
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 20px;
  cursor: pointer;
  position: relative;
  z-index: 5;
}
.video__play .icon-Play {
  font-size: 40px;
  width: 40px;
  height: 40px;
}
.video:hover .video__overlay::after,
.video:hover .video__play {
  opacity: 1;
}

.project-cards {
  width: 100%;
  max-width: 1184px;
  padding: 0 80px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 80px 20px;
}
@media (max-width: 768px) {
  .project-cards {
    padding: 0 20px;
    gap: 40px 20px;
  }
}
.project-cards.wider {
  max-width: 1180px;
  padding: 0 50px;
}
@media (max-width: 768px) {
  .project-cards.wider {
    padding: 0 20px;
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 20px;
  gap: 10px;
  background-color: #fafafa;
  max-width: 275px;
  min-height: 275px;
}
@media (max-width: 768px) {
  .card {
    min-height: unset;
  }
}
.card .link_on_hover {
  visibility: hidden;
}
.card:hover .link_on_hover {
  visibility: visible;
}
.card__logo {
  margin-bottom: 6px;
  height: 42px;
}
.card__number {
  border: 2px solid #212322;
  border-radius: 50%;
  min-width: 48px;
  width: 48px;
  height: 48px;
  text-align: center;
  position: relative;
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  padding-top: 2px;
  top: -4px;
}
.card__title {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  color: #212322;
}
@media (max-width: 1024px) {
  .card__title {
    flex-wrap: wrap;
  }
}
@media (max-width: 768px) {
  .card__title {
    flex-wrap: nowrap;
  }
}
.card__title h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%; /* 28.8px */
  letter-spacing: 0.4px;
  margin-bottom: 8px;
}
.card__title h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-transform: none;
}
.card__title img {
  width: 46px;
  height: 46px;
}
.card__content {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 160%;
  color: #212322;
  flex-grow: 1;
}
.card__buttons {
  margin-bottom: 8px;
}
.card__expand {
  position: absolute;
  padding: 24px 20px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #212322;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.16s;
}
.card__expand p {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 160%;
  color: #f2f2f2;
}
.card.card--active .card__expand {
  opacity: 1;
  visibility: visible;
}
.card--large {
  background-color: #fff;
  max-width: 368px;
  padding: 32px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}
.card--large .card__logo {
  margin-bottom: 20px;
  width: auto;
}
.card--large h2 {
  font-size: 36px;
  letter-spacing: 0.72px;
  margin-bottom: 10px;
}
.card--large p {
  font-size: 16px;
  font-weight: 300;
  line-height: 160%;
  color: #333a3c;
  letter-spacing: 0.64px;
}
.card--large .link {
  margin-top: 33px;
}
.card--media {
  padding: 0;
  max-width: unset;
  background-color: transparent;
  gap: 16px;
  width: 100%;
  cursor: pointer;
}
.card--media .card__content {
  padding: 16px 0;
}
.card--media .card__media {
  width: 100%;
  overflow: hidden;
  position: relative;
  aspect-ratio: 16/9;
}
.card--media .card__media img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.card--media .card__title h4 {
  font-size: 24px;
}
.card--media .card__subtitle {
  margin: -6px 0 -10px;
}
.card--media .card__buttons {
  margin-top: auto;
}
.card--media .card__logo {
  height: 30px;
  display: flex;
  align-items: center;
}
.card--media .card__logo img {
  height: auto;
  max-height: 100%;
  width: auto;
  height: 30px;
}
.card--small .card__media {
  height: auto;
}
.card--small .card__logo img {
  height: 30px;
  width: auto;
}
.card--small h3 {
  font-family: Roboto Slab;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%;
  letter-spacing: 0.72px;
}

.cards {
  display: grid;
  max-width: 1769px;
  margin: 0 auto;
  grid-gap: 20px;
  grid-template-columns: repeat(6, 1fr);
}
@media (max-width: 768px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
    max-width: 589px;
    grid-gap: 32px;
  }
}
@media (max-width: 480px) {
  .cards {
    grid-template-columns: repeat(1, 1fr);
    max-width: 100%;
  }
  .cards .card {
    max-width: 100%;
  }
}
.cards .card {
  grid-column: span 2;
  max-width: 100%;
}
.cards--quad .card {
  grid-column: span 3;
  max-width: 100%;
}
.cards--penta .card:nth-child(-n+2) {
  grid-column: span 3;
  max-width: 100%;
}
.cards--penta .card:nth-child(n+3) {
  grid-column: span 2;
  max-width: 100%;
}

.card-expand-section__title {
  max-width: 1769px;
  margin: 0 auto 40px;
}
.card-expand-section__title h2 {
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .card-expand-section {
    padding: 32px 30px;
  }
}

.card-carousel,
.testimonial-card-carousel,
.locations-card-carousel {
  position: relative;
  margin: 32px -16px 0 -16px;
}
.card-carousel-block h2,
.testimonial-card-carousel-block h2,
.locations-card-carousel-block h2 {
  letter-spacing: 0.72px;
  font-size: 36px;
  margin-bottom: 0;
}
.card-carousel .card,
.testimonial-card-carousel .card,
.locations-card-carousel .card {
  padding: 0 16px;
  height: 100%;
}
.card-carousel__header,
.testimonial-card-carousel__header,
.locations-card-carousel__header {
  display: flex;
}
.card-carousel__header div:first-of-type,
.testimonial-card-carousel__header div:first-of-type,
.locations-card-carousel__header div:first-of-type {
  flex-grow: 1;
}
.card-carousel__header .navigation,
.testimonial-card-carousel__header .navigation,
.locations-card-carousel__header .navigation {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  width: 42px;
}
.card-carousel__header .navigation .swiper-button-next,
.card-carousel__header .navigation .swiper-button-prev,
.testimonial-card-carousel__header .navigation .swiper-button-next,
.testimonial-card-carousel__header .navigation .swiper-button-prev,
.locations-card-carousel__header .navigation .swiper-button-next,
.locations-card-carousel__header .navigation .swiper-button-prev {
  position: relative;
  padding: 22px 0px;
  width: 28px;
  margin-left: 0;
  right: 0;
}
.card-carousel__header .navigation .swiper-button-next span,
.card-carousel__header .navigation .swiper-button-prev span,
.testimonial-card-carousel__header .navigation .swiper-button-next span,
.testimonial-card-carousel__header .navigation .swiper-button-prev span,
.locations-card-carousel__header .navigation .swiper-button-next span,
.locations-card-carousel__header .navigation .swiper-button-prev span {
  padding-left: 5px;
}
.card-carousel__header .navigation .swiper-button-next::after,
.card-carousel__header .navigation .swiper-button-prev::after,
.testimonial-card-carousel__header .navigation .swiper-button-next::after,
.testimonial-card-carousel__header .navigation .swiper-button-prev::after,
.locations-card-carousel__header .navigation .swiper-button-next::after,
.locations-card-carousel__header .navigation .swiper-button-prev::after {
  content: none;
}
.card-carousel__header .navigation .swiper-button-prev,
.testimonial-card-carousel__header .navigation .swiper-button-prev,
.locations-card-carousel__header .navigation .swiper-button-prev {
  left: 0;
  right: auto;
}
.card-carousel__header .navigation .swiper-button-prev span,
.testimonial-card-carousel__header .navigation .swiper-button-prev span,
.locations-card-carousel__header .navigation .swiper-button-prev span {
  padding-left: 0px;
  padding-right: 5px;
}
.card-carousel .quote-card,
.testimonial-card-carousel .quote-card,
.locations-card-carousel .quote-card {
  margin: 0;
  width: unset;
  padding-left: 16px;
  padding-right: 16px;
}
.card-carousel .quote-card .quote, .card-carousel .quote-card .description blockquote, .description .card-carousel .quote-card blockquote, .card-carousel .quote-card .post-content__inner .body-text blockquote, .post-content__inner .body-text .card-carousel .quote-card blockquote,
.testimonial-card-carousel .quote-card .quote,
.testimonial-card-carousel .quote-card .description blockquote,
.description .testimonial-card-carousel .quote-card blockquote,
.testimonial-card-carousel .quote-card .post-content__inner .body-text blockquote,
.post-content__inner .body-text .testimonial-card-carousel .quote-card blockquote,
.locations-card-carousel .quote-card .quote,
.locations-card-carousel .quote-card .description blockquote,
.description .locations-card-carousel .quote-card blockquote,
.locations-card-carousel .quote-card .post-content__inner .body-text blockquote,
.post-content__inner .body-text .locations-card-carousel .quote-card blockquote {
  font-size: 20px;
}
.card-carousel .quote-card .quote__logo,
.testimonial-card-carousel .quote-card .quote__logo,
.locations-card-carousel .quote-card .quote__logo {
  margin-bottom: 14px;
}

.card-link {
  text-decoration: none;
}
.card-link:hover {
  color: initial;
}
.card-link:hover .card__title {
  text-decoration: underline;
}

a.card {
  text-decoration: none;
}
a.card:hover {
  cursor: pointer;
  color: #212322;
}
a.card:hover h2, a.card:hover h3 {
  text-decoration: underline;
}
a.card:hover .link {
  color: #0e7a73;
}

.alert-banner {
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 80px;
  gap: 10px;
  top: 0;
  z-index: 11;
  margin-top: -102px;
  height: 40px;
  width: 100%;
  background: #5ae6bd;
  color: #212322;
  font-size: 16px;
  margin-bottom: 80px;
}
.alert-banner a {
  color: inherit;
}
@media (max-width: 768px) {
  .alert-banner {
    padding: 8px;
  }
}
.alert-banner + header {
  top: 33px;
}
.alert-banner.fractional {
  background: #20b59a;
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.32px;
}

body.overflow-hidden .alert-banner {
  position: fixed;
  top: 102px;
}

.location-header {
  height: 40px;
}
.location-header .alert-banner {
  margin-top: 0;
  margin-bottom: 0;
  height: 40px;
}

.modal {
  box-shadow: 4px 4px 24px 3px rgba(0, 0, 0, 0.1);
  max-width: 904px;
  top: -10%;
}
@media (max-width: 768px) {
  .modal {
    top: 0;
  }
}
.modal.fancybox-content {
  padding: 0 !important;
}
.modal__inner {
  display: flex;
  flex-direction: row;
}
@media (max-width: 768px) {
  .modal__inner {
    flex-direction: column;
  }
}
.modal h3 {
  font-size: 24px;
}
.modal__content {
  padding: 60px 30px;
}
.modal__image {
  min-width: 400px;
  max-width: 50%;
  min-height: 100%;
}
@media (max-width: 768px) {
  .modal__image {
    min-width: auto;
    max-width: 100%;
  }
}
.modal__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.modal button.modal__close {
  width: 24px;
  height: 24px;
  font-size: 24px;
  padding: 0;
  display: flex;
  margin: 20px;
}
.modal form {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 20px 0;
}
.modal form label {
  color: #333a3c;
  z-index: 1;
}
.modal form [type=submit] {
  margin-top: 20px;
}

.field {
  position: relative;
  padding-bottom: 21px;
}
.field__input {
  appearance: none;
  border-bottom: 1px solid transparent;
  border-bottom: 1px solid #c1c3c3;
  border-radius: 0;
  color: #333a3c;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  height: 28px;
  margin-top: 4px;
  min-width: 220px;
  padding-bottom: 1px;
  padding-left: 0;
  transition: all 0.2s;
  user-select: none;
  width: 220px;
}
@media (max-width: 768px) {
  .field__input {
    font-size: 16px;
    width: 100%;
  }
}
.field__input::selection {
  background: #0e7a73;
  color: #fff !important;
  text-shadow: none;
}
.field__input:-webkit-autofill, .field__input:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px white inset;
  -webkit-text-fill-color: #333;
}
.field__input--readonly {
  border: none !important;
  cursor: default;
  pointer-events: none;
}
.field__input--readonly::placeholder {
  opacity: 0.7 !important;
}
.field__input--readonly:focus::placeholder {
  opacity: 1 !important;
}
.field__input::placeholder {
  color: #333a3c;
  opacity: 0;
  transition: all 0.2s;
}
.field__input:focus::placeholder {
  opacity: 0;
}
.field__input-buffer {
  left: -1000px;
  position: absolute;
  top: -1000px;
  visibility: hidden;
  white-space: nowrap;
}
.field__label {
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  pointer-events: none;
  position: absolute;
  z-index: 1;
  top: 4px;
  transition: all 0.2s;
}
.field__label--title {
  color: #333a3c;
}
.field__label--error {
  color: #333a3c;
  display: none;
}
.field__label--persist {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.44px;
  opacity: 1;
  text-transform: uppercase;
  top: -12px;
}
.field--is-filled .field__label, .field--is-focused .field__label {
  color: #9da0a1;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  top: -9px;
}
.field--is-focused .field__input {
  border-bottom-color: #0e7a73;
}

.wpcf7-not-valid-tip {
  position: absolute;
  color: #dc3030;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}

.wpcf7-list-item-label {
  font-family: "Roboto Slab", serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #333a3c;
  line-height: 140%;
}

span.wpcf7-list-item,
.checkbox {
  padding-left: 25px;
}
span.wpcf7-list-item label,
.checkbox label {
  position: relative;
  cursor: pointer;
}
span.wpcf7-list-item input[type=checkbox],
.checkbox input[type=checkbox] {
  position: absolute;
  left: -23px;
  top: 0;
  visibility: hidden;
}
span.wpcf7-list-item input[type=checkbox] + span:before,
.checkbox input[type=checkbox] + span:before {
  content: "";
  position: absolute;
  left: -23px;
  top: 1px;
  width: 16px;
  height: 16px;
  background: #fff;
  border: 1px solid #c1c3c3;
  border-radius: 3px;
  display: block;
}
span.wpcf7-list-item input[type=checkbox]:checked + span:before,
.checkbox input[type=checkbox]:checked + span:before {
  background: #212322;
}
span.wpcf7-list-item input[type=checkbox]:checked + span:after,
.checkbox input[type=checkbox]:checked + span:after {
  content: "";
  position: absolute;
  left: -19px;
  top: 5px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nOCcgaGVpZ2h0PSc4JyB2aWV3Qm94PScwIDAgOCA4JyBmaWxsPSdub25lJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGQ9J003LjcgMS4zQzcuMyAwLjkgNi43IDAuOSA2LjMgMS4zTDMgNC42TDEuNyAzLjNDMS4zIDIuOSAwLjcgMi45IDAuMyAzLjNDLTAuMSAzLjcgLTAuMSA0LjMgMC4zIDQuN0wyLjMgNi43QzIuNSA2LjkgMi44IDcgMyA3QzMuMiA3IDMuNSA2LjkgMy43IDYuN0w3LjcgMi43QzguMSAyLjMgOC4xIDEuNyA3LjcgMS4zWicgZmlsbD0nd2hpdGUnLz48L3N2Zz4K");
  width: 8px;
  height: 8px;
  background-repeat: no-repeat;
  background-size: 8px;
}
span.wpcf7-list-item--primary input[type=checkbox]:checked + span:before,
.checkbox--primary input[type=checkbox]:checked + span:before {
  background: #20b59a;
  border-color: #20b59a;
}

label.checkbox {
  display: flex;
  padding-bottom: 20px;
}

.solutions-section {
  padding: 64px 80px 96px;
}
@media (max-width: 768px) {
  .solutions-section {
    padding: 32px;
  }
}
.solutions-section h2 {
  margin-bottom: 40px;
}
.solutions-section .service-card {
  display: flex;
  max-width: 824px;
  margin: 0 auto;
}
@media (max-width: 960px) {
  .solutions-section .service-card__inner {
    height: 320px;
  }
}
@media (max-width: 960px) {
  .solutions-section .service-card__inner {
    height: auto;
    max-height: initial;
    display: flex;
    flex-direction: column-reverse;
  }
  .solutions-section .service-card__inner img {
    max-height: 320px;
  }
}
.solutions-section .service-card img {
  width: 100%;
  min-width: 340px;
}
.solutions-section .service-card + .service-card {
  margin-top: 80px;
}
@media (max-width: 768px) {
  .solutions-section .service-card + .service-card {
    margin-top: 64px;
  }
}

.messaging-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 120px 50px 80px;
  gap: 20px;
}
@media (max-width: 768px) {
  .messaging-section {
    padding: 48px 20px;
    margin-top: 40px;
  }
}
.messaging-section.top-p96 {
  padding-top: 96px;
}
@media (max-width: 768px) {
  .messaging-section.top-p96 {
    padding: 60px 20px;
    margin-top: 0px;
  }
}
.messaging-section .message {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 160%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #212322;
  max-width: 800px;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .messaging-section .message {
    font-size: 24px;
    max-width: 614px;
  }
}
@media (max-width: 768px) {
  .messaging-section.mobile-dark {
    background: #333a3c;
  }
  .messaging-section.mobile-dark .message {
    color: white;
  }
}

.squad {
  display: flex;
  flex-direction: column;
  padding: 64px;
  gap: 10px;
  max-width: 1024px;
  background: #212322;
  color: #fff;
  margin: 0 auto;
  margin-bottom: 80px;
}
@media (max-width: 768px) {
  .squad {
    padding: 32px;
    margin-bottom: 40px;
  }
}
.squad__intro {
  display: flex;
  gap: 20px;
  flex-direction: column;
  text-align: center;
  padding-bottom: 48px;
}
@media (max-width: 768px) {
  .squad__intro {
    padding-bottom: 24px;
  }
}
.squad__content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 78px;
}
@media (max-width: 768px) {
  .squad__content {
    justify-content: center;
    gap: 52px;
  }
}
.squad__members {
  display: flex;
  gap: 48px;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .squad__members {
    justify-content: center;
    text-align: center;
    gap: 64px;
  }
}

.squad-member {
  max-width: 210px;
}
.squad-member__avatar {
  margin-bottom: 38px;
}
@media (max-width: 768px) {
  .squad-member__avatar {
    margin-bottom: 18px;
  }
}
.squad-member__avatar img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  margin: 0 auto;
}
.squad-member__name {
  margin-bottom: 5px;
}
.squad-member__role {
  color: #5ae6bd;
  text-transform: initial;
  margin-bottom: 25px;
}
@media (max-width: 768px) {
  .squad-member__role {
    margin-bottom: 12px;
    font-size: 24px;
  }
}
@media (max-width: 480px) {
  .squad-member__role {
    font-size: 24px;
  }
}

.squad__responsibilities {
  display: flex;
  flex-direction: column;
  width: 343px;
}

.squad-responsibility__header, .squad-responsibility__row {
  display: flex;
  width: 100%;
  height: 33px;
}
.squad-responsibility__header + .squad-responsibility__header, .squad-responsibility__header + .squad-responsibility__row, .squad-responsibility__row + .squad-responsibility__header, .squad-responsibility__row + .squad-responsibility__row {
  border-top: 1px solid #333a3c;
}
.squad-responsibility__header > div, .squad-responsibility__row > div {
  flex-grow: 1;
}
.squad-responsibility__header > div + div, .squad-responsibility__row > div + div {
  display: flex;
  justify-content: center;
  flex-grow: initial;
}
.squad-responsibility__head, .squad-responsibility__cell {
  display: flex;
  align-items: center;
  min-width: 45px;
}
.squad-responsibility__header + .squad-responsibility__row {
  border-top: 0px;
  margin-top: 20px;
}

.clutch {
  --swiper-navigation-size: 30px;
  --swiper-navigation-color: #c1c3c3;
  background-color: #fafafa;
  padding: 90px 80px;
}
@media (max-width: 768px) {
  .clutch {
    padding: 45px 40px;
  }
}
.clutch__inner {
  position: relative;
  gap: 48px;
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  margin: 0 auto;
}
.clutch__intro {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.clutch__intro .clutch-widget {
  width: 195px;
}
@media (max-width: 768px) {
  .clutch__intro {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
}
.clutch .swiper-button-next,
.clutch .swiper-button-prev {
  z-index: 2;
}
.clutch__content {
  position: relative;
  padding: 0 80px;
  margin-left: -80px;
  margin-right: -80px;
}
@media (max-width: 768px) {
  .clutch__content {
    padding: 0 40px;
    margin-left: -40px;
    margin-right: -40px;
  }
}
.clutch__carousel {
  overflow: hidden;
}
.clutch__carousel .swiper-button-prev {
  left: 20px;
}
@media (max-width: 768px) {
  .clutch__carousel .swiper-button-prev {
    left: 10px;
  }
}
.clutch__carousel .swiper-button-next {
  right: 20px;
}
@media (max-width: 768px) {
  .clutch__carousel .swiper-button-next {
    right: 10px;
  }
}
.clutch-review {
  display: flex;
  flex-direction: column;
  height: auto;
  background: #fff;
  padding: 20px;
}
.clutch-review__rating {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 13px;
}
.clutch-review__rating-number {
  font-family: "Roboto Slab", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.015em;
}
.clutch-review__author {
  font-size: 14px;
  color: #7b7f80;
}
.clutch-review__description {
  font-family: "Lato", serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #575c5e;
  margin-bottom: 13px;
  flex-grow: 1;
}
.clutch-review__verified {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 28px;
  font-family: "Lato", serif;
  color: #9da0a1;
  font-weight: 600;
  font-size: 12px;
  line-height: 136.5%;
}

.star__rating {
  display: flex;
}
.star__rating .star {
  width: 16px;
  height: 16px;
}
.star__rating .star:before, .star__rating .star:after {
  content: "";
  display: block;
  height: 100%;
  min-height: 16px;
  width: 100%;
}
.star__rating .star.unchecked:before {
  background-color: #e62415;
  mask-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><g data-name="Layer 2"><path class="cls-1" d="M5.94 1.78 6.89 4l.24.55.6.06 2.43.2-1.85 1.63-.45.39.14.59.55 2.37-2.09-1.27-.52-.31-.52.31-2.09 1.27.55-2.37.12-.59-.46-.39-1.82-1.61 2.43-.2.6-.06L5 4l1-2.24M5.94 0a.48.48 0 0 0-.46.3L4.06 3.63l-3.6.31a.5.5 0 0 0-.29.87l2.74 2.38-.83 3.53a.5.5 0 0 0 .49.62.52.52 0 0 0 .26-.08l3.11-1.88 3.11 1.88a.49.49 0 0 0 .74-.54L9 7.19l2.7-2.38a.49.49 0 0 0-.28-.87l-3.61-.31L6.4.3a.5.5 0 0 0-.46-.3Z" data-name="Layer 1"/></g></svg>');
}
.star__rating .star.checked:before {
  background-color: #e62415;
  mask-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><g data-name="Layer 2"><path class="cls-1" d="m5.94 9.38-3.11 1.88a.5.5 0 0 1-.75-.54l.83-3.53L.17 4.81a.5.5 0 0 1 .29-.87l3.6-.31L5.48.3a.5.5 0 0 1 .92 0l1.41 3.33 3.61.31a.49.49 0 0 1 .28.87L9 7.19l.82 3.53a.5.5 0 0 1-.74.54Z" data-name="Layer 1"/></g></svg>');
}

.case-study {
  max-width: 1024px;
  margin: 0 auto;
}
.case-study__header {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 40px 80px;
  gap: 10px;
}
@media (max-width: 768px) {
  .case-study__header {
    padding: 32px;
  }
}
.case-study__header .heading {
  color: #0e7a73;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
}
.case-study__details {
  padding: 32px 140px 64px;
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: space-between;
}
@media (max-width: 768px) {
  .case-study__details {
    padding: 32px;
    grid-template-columns: repeat(2, auto);
    grid-gap: 30px;
  }
}
@media (max-width: 480px) {
  .case-study__details {
    grid-template-columns: auto;
    grid-gap: 32px;
  }
}
.case-study__detail {
  display: flex;
  flex-direction: column;
}
.case-study__detail-key {
  font-weight: 500;
}
.case-study__detail-value {
  color: #333a3c;
  font-family: "Roboto Slab", serif;
  font-size: 14px;
  font-weight: 300;
}
.case-study__detail.services-provided {
  grid-row: 1/span 2;
  grid-column: 2;
}
@media (max-width: 768px) {
  .case-study__detail.services-provided {
    grid-row: auto;
    grid-column: auto;
  }
}
.case-study__detail.technologies-used {
  flex-direction: column;
  grid-row: 1;
  grid-column: 3;
}
@media (max-width: 768px) {
  .case-study__detail.technologies-used {
    grid-row: auto;
    grid-column: auto;
  }
}
.case-study__detail.technologies-used .case-study__detail-value li {
  display: inline-block;
}
.case-study__detail.technologies-used img {
  width: 24px;
  height: 24px;
}
.case-study__comparison {
  display: flex;
  justify-content: center;
  position: relative;
}
.case-study__comparison .beer-reveal[data-beer-label]:after,
.case-study__comparison .beer-slider[data-beer-label]:after {
  background-color: #f2f2f2;
  font-family: "Roboto Slab", serif;
  padding: 8px;
  font-size: 11px;
  line-height: 15px;
}
.case-study__stats {
  padding: 64px 80px;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}
.case-study__stats--spaced {
  gap: 46px;
}
.case-study__stats--p0 {
  padding: 0;
}
.case-study__stat {
  text-align: center;
  max-width: 222px;
  gap: 8px;
  display: flex;
  flex-direction: column;
}
.case-study__stat-icon img {
  margin: 0 auto;
  width: 58px;
  height: 58px;
}
.case-study__stat-title {
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 120%;
}
.case-study__stat-description {
  font-family: "Roboto Slab", serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.56px;
  color: #212322;
  margin-top: 5px;
}
.case-study__stat-description a {
  font-weight: 400;
}
.case-study__quote {
  padding: 60px 130px;
  display: flex;
  justify-content: center;
  background: #f9f9f9;
}
@media (max-width: 768px) {
  .case-study__quote {
    padding: 60px 50px 60px 90px;
  }
}
.case-study__quote--p0 {
  padding: 0;
}
.case-study__quote-inner {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 100%;
  position: relative;
}
.case-study__quote-inner, .case-study__quote p {
  font-family: "Roboto Slab", serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.03em;
  position: relative;
}
@media (max-width: 768px) {
  .case-study__quote-inner, .case-study__quote p {
    font-size: 16px;
  }
}
.case-study__quote .icon-quote-top-filled,
.case-study__quote .icon-quote-bottom-filled {
  position: absolute;
  color: #20b59a;
  font-size: 25px;
}
.case-study__quote .icon-quote-top-filled {
  left: -45px;
  top: -7px;
}
.case-study__quote .icon-quote-bottom-filled {
  bottom: -10px;
  right: -25px;
}
.case-study__quote .avatar {
  border-radius: 50%;
  overflow: hidden;
  width: 64px;
  height: 64px;
}
.case-study__quote-author {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.case-study__quote-author .titles {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.case-study__quote-author .role,
.case-study__quote-author .name {
  width: 100%;
}
.case-study__quote-author .name {
  font-size: 18px;
  font-weight: 500;
}
.case-study__quote-author .role {
  font-family: "Roboto Slab", serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
}

.section--dark .case-study__quote {
  background: none;
}

.digital-business-card {
  display: flex;
  flex-direction: column;
  max-width: 475px;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
}
.digital-business-card__header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.digital-business-card__header h1 {
  color: #575c5e;
  font-size: 20px;
  text-transform: initial;
}
.digital-business-card__header h3 {
  color: #0e7a73;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
}
.digital-business-card__avatar {
  margin-bottom: 24px;
  border-radius: 50%;
}
.digital-business-card__socials {
  display: flex;
  gap: 32px;
  margin-top: 64px;
  margin-bottom: 80px;
}
.digital-business-card__content {
  padding: 0 32px 58px;
  flex-grow: 1;
}
.digital-business-card__content .p2 {
  font-size: 16px;
}
.digital-business-card__footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 10;
}
.digital-business-card__footer .btn--cta {
  width: 100%;
  height: 70px;
  font-size: 18px;
  font-weight: 500;
  text-transform: initial;
}
.digital-business-card__link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
}
.digital-business-card__section .about-img {
  max-height: 170px;
  overflow: hidden;
  margin: 48px 0 40px;
}
.digital-business-card__section--grey {
  background: #fafafa;
  padding: 80px 32px 58px;
}
.digital-business-card .inline-icon {
  display: flex;
  align-items: center;
  gap: 18px;
  margin-top: 80px;
}

.project__card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  max-width: 290px;
  margin-right: 20px;
}
.project__card-image {
  max-height: 222px;
  overflow: hidden;
}
.project__card-content {
  padding: 20px 14px 24px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
}
.project__card-content h5 {
  margin-bottom: 4px;
}
.project__card-content p {
  flex-grow: 1;
}
.project__card .technologies__icons-list {
  display: flex;
  gap: 13px;
}
.project__card .technologies__icons-list img {
  width: 24px;
  height: 24px;
}

.projects__slider {
  width: 100%;
  height: 100%;
  margin-top: 23px;
  margin: 37px 0 64px;
  height: auto;
}

.project-intro {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1024px) {
  .project-intro {
    flex-direction: column;
  }
}
.project-intro__title {
  flex: 0 0 100%;
  width: 100%;
}
.project-intro__title h2 {
  margin-bottom: 40px;
  font-size: 36px;
}
.project-intro__description {
  flex-basis: calc(100% - 520px);
}
@media (max-width: 768px) {
  .project-intro__description {
    margin-bottom: 20px;
  }
}
.project-intro__description .content-text p, .project-intro__description .title__caption p {
  font-size: 16px;
}
.project-intro__description .content-text p:last-child, .project-intro__description .title__caption p:last-child {
  margin-bottom: 0;
}
.project-intro-title {
  margin-bottom: 30px;
}
.project-intro__details {
  display: flex;
  flex-basis: 520px;
  flex-shrink: 0;
  justify-content: space-between;
  padding-left: 100px;
  max-width: 520px;
}
@media (max-width: 1024px) {
  .project-intro__details {
    flex-basis: 100%;
    justify-content: flex-start;
    padding-left: 0;
    margin-top: 40px;
  }
}
@media (max-width: 768px) {
  .project-intro__details {
    flex-direction: column;
  }
}
.project-intro__details-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.project-intro__details-column {
  flex-basis: 50%;
  padding-left: 20px;
  padding-right: 20px;
}
@media (max-width: 1024px) {
  .project-intro__details-column {
    flex-basis: auto;
  }
}
.project-intro__details-column:first-child {
  flex-grow: 1;
  padding-left: 0;
}
@media (max-width: 1024px) {
  .project-intro__details-column:first-child {
    flex-grow: 0;
  }
}
.project-intro__details-column:last-child {
  flex-basis: 200px;
  padding-right: 0;
}
@media (max-width: 1024px) {
  .project-intro__details-column:last-child {
    flex-basis: auto;
  }
}
@media (max-width: 768px) {
  .project-intro__details-column:last-child {
    padding-left: 0;
  }
}
.project-intro__list-item {
  margin-bottom: 40px;
  width: 200px;
}
@media (max-width: 768px) {
  .project-intro__list-item {
    margin-bottom: 25px;
  }
}
@media (max-width: 1024px) {
  .project-intro__list-item {
    margin-top: 20px;
  }
}
@media (max-width: 480px) {
  .project-intro__list-item {
    width: 100%;
  }
}
.project-intro__list-item .p3, .project-intro__list-item .footer__top-nav li a, .footer__top-nav li .project-intro__list-item a {
  font-size: 14px;
  font-weight: 300;
}
.project-intro__list-title {
  color: #212322;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.1428571429;
  margin-bottom: 10px;
  text-transform: none;
}
.project-intro__list-content {
  color: #333a3c;
  font-size: 16px;
}
.project-intro__list-content ul li a {
  font-size: 14px;
  font-weight: 300;
  color: #212322;
}
.project-intro a {
  font-weight: 300;
}
.project-intro .icons-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-left: -10px;
  margin-right: -10px;
}
.project-intro .icons-list li {
  flex-basis: auto;
  margin-bottom: 10px;
  max-width: 46px;
  padding: 0 10px;
}
.project-intro .icons-list li img {
  width: 100%;
  height: auto;
  max-height: 26px;
}
.project-intro .technologies__list {
  width: auto;
}
@media (max-width: 768px) {
  .project-intro .technologies__list ul {
    margin-bottom: 0;
  }
  .project-intro .technologies__list li:last-child {
    margin-bottom: 0;
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
}
.tags__item {
  position: relative;
}
.tags__item::after {
  color: #7b7f80;
  content: "/";
  display: inline-flex;
  height: 16px;
  margin-left: 7px;
  margin-right: 7px;
}
@media (max-width: 1024px) {
  .tags__item::after {
    height: 13px;
  }
}
.tags__item:last-child::after {
  display: none;
}
.tags--tertiary {
  margin: 24px 0;
  gap: 10px;
}
.tags--tertiary .tags__item {
  background: #e8e8e8;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 11px;
}
.tags--tertiary .tags__item::after {
  content: none;
}
.tags__link {
  color: #0e7a73;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
}
.tags__link:hover {
  color: #212322;
}
.tags--dark .tags__item {
  color: #7b7f80;
}
.tags--dark .tags__item::after {
  color: #5ae6bd;
}
.tags--dark .tags__link {
  font-weight: normal;
  color: #5ae6bd;
}
.tags--dark .tags__link:hover {
  color: #fff;
}
.tags__section img {
  width: 100%;
}
.tags__section p {
  font-family: "Roboto Slab", serif;
  font-weight: 300;
  font-size: 16px;
}

.tag {
  color: #fff;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
}
@media (max-width: 960px) {
  .tag {
    font-size: 16px;
  }
}
.tag a {
  color: #fff;
  text-decoration: none;
}
.tag a:hover {
  text-decoration: none;
}

.blocks {
  display: flex;
  flex-direction: column;
  gap: 80px;
}
.blocks--no-gap {
  gap: 0 80px;
}
.blocks__column {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
}
.blocks__column--shrink {
  flex: 0 0 0;
}
.blocks__column--grow {
  flex: 1 0 0;
}
.blocks__column--align-center {
  align-items: center;
}
.blocks__column--third {
  flex-basis: 60%;
}
.blocks__row {
  display: flex;
  flex-wrap: nowrap;
  gap: 64px;
}
.blocks__row--spaced {
  gap: 64px 94px;
}
.blocks__row--inverse {
  flex-direction: row-reverse;
}
@media (max-width: 960px) {
  .blocks__row {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
}
.blocks img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

h3 + .blocks {
  margin-bottom: 40px;
}

.block {
  flex-grow: 1;
  position: relative;
}
.block__content {
  height: 100%;
  width: 100%;
}
.block__content h3 {
  margin-bottom: 12px;
}
.block__content p,
.block__content p span {
  font-family: "Roboto Slab", serif;
  font-style: normal;
  font-weight: 300 !important;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.04em;
}
.block__content p + p {
  margin-top: 1rem;
}

.gallery {
  display: flex;
  justify-content: center;
  position: relative;
  --swiper-navigation-size: 100px;
}
.gallery--elevated {
  box-shadow: 4px 4px 24px 3px rgba(0, 0, 0, 0.1);
}
.gallery--elevated .swiper-pagination {
  bottom: -40px;
}
.gallery .beer-slider .beer-reveal:before {
  content: "";
  height: 65%;
  width: 2px;
  background-color: #20b59a;
  position: absolute;
  right: 0;
  top: 50%;
  bottom: 50%;
  transform: translateY(-50%);
}
.gallery .beer-slider .beer-handle {
  border: 2px solid #20b59a;
  background-color: #f2f2f2;
  width: 40px;
  height: 40px;
  transform: translate3d(calc(-50% - 1px), -50%, 0);
  transition: none;
}
.gallery .beer-slider .beer-handle:before {
  left: 5px;
}
.gallery .beer-slider .beer-handle:after {
  right: -5px;
}
.gallery .beer-slider:hover .beer-handle {
  background-color: #20b59a;
}
.gallery .beer-slider:hover .beer-handle:before, .gallery .beer-slider:hover .beer-handle:after {
  border-color: white;
}
.gallery .swiper {
  overflow: hidden;
  position: relative;
  user-select: none;
}
.gallery .swiper--contained {
  max-width: 864px;
}
.gallery .swiper-pagination {
  z-index: 5;
  bottom: 0;
  background: #333a3c;
  height: 34px;
}
.gallery .swiper-button-next, .gallery .swiper-button-prev {
  opacity: 0;
  z-index: 2;
  transition: background-color 0.25s, color 0.25s, transform 0.25s, opacity 200ms;
  width: 100px;
  height: 100px;
  background: #333a3c;
  border-radius: 50%;
  color: #5ae6bd;
}
.gallery .swiper-button-next:after, .gallery .swiper-button-prev:after {
  content: "\e924";
  font-family: icomoon;
  font-size: 7px;
  background-size: contain;
  -webkit-font-smoothing: antialiased;
}
.gallery .swiper-button-next {
  margin-right: -70px;
}
.gallery .swiper-button-next:hover {
  transform: translateX(-4px);
}
.gallery .swiper-button-next::after {
  margin-right: 55px;
}
.gallery .swiper-button-prev {
  margin-left: -70px;
}
.gallery .swiper-button-prev:hover {
  transform: translateX(4px);
}
.gallery .swiper-button-prev::after {
  transform: rotate(180deg);
  margin-left: 55px;
}
.gallery .swiper:hover .swiper-button-next,
.gallery .swiper:hover .swiper-button-prev {
  opacity: 1;
}
.gallery .swiper:hover .swiper-button-next.swiper-button-disabled,
.gallery .swiper:hover .swiper-button-prev.swiper-button-disabled {
  opacity: 0.35;
}
@media (pointer: coarse) {
  .gallery .swiper {
    /* Styles for touch-enabled devices */
    /* Add your CSS rules here */
  }
  .gallery .swiper .swiper-button-next,
  .gallery .swiper .swiper-button-prev {
    opacity: 1;
  }
  .gallery .swiper .swiper-button-next.swiper-button-disabled,
  .gallery .swiper .swiper-button-prev.swiper-button-disabled {
    opacity: 0.35;
  }
}
.gallery .swiper-pagination-bullet {
  width: 30px;
  border-radius: 0;
  height: 3px;
  margin: 0 6px;
  opacity: 1;
  background: #fff;
}
.gallery .swiper-pagination-bullet-active {
  background-color: #5ae6bd;
}
.gallery .swiper-slide {
  user-select: none;
}

.gallery-section {
  background: #f2f2f2;
}
.gallery-section__content {
  position: relative;
  display: flex;
}
.gallery-section__left {
  flex-basis: 40%;
  padding: 90px 0 48px 48px;
}
.gallery-section__left h1 {
  color: #0e7a73;
}
.gallery-section__right {
  flex-grow: 1;
  padding: 48px 48px 0;
}

.filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.single-card-block {
  width: 100%;
  height: 496px;
}
.single-card-block__background {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.single-card-block__inner {
  display: flex;
  position: relative;
  padding: 48px;
  height: 100%;
}
.single-card-block--inverse .single-card-block__inner {
  flex-direction: row-reverse;
}

.small-card-block {
  width: calc(50% - 10px);
  padding-bottom: 16px;
}
@media (max-width: 768px) {
  .small-card-block {
    width: 100%;
  }
}
.small-card-block .single-card-block__inner {
  padding: 0px;
}

.dual-card-block__inner {
  display: flex;
  gap: 20px;
}
.dual-card-block__inner .card {
  flex-basis: 50%;
}

.show-sm {
  display: block;
}
@media (max-width: 768px) {
  .show-sm {
    display: none;
  }
}

.hide-sm {
  display: none;
}
@media (max-width: 768px) {
  .hide-sm {
    display: block;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col {
  position: relative;
  width: 100%;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.page-template-home-template, .page-template-front-page, .page-template-capability-template {
  padding-top: 0;
}

.our-story h3 {
  font-size: 36px;
  letter-spacing: 0.72px;
  line-height: 120%;
  margin-bottom: 16px;
}
.our-story img {
  filter: grayscale(1);
}
.our-story a {
  color: #212322;
  font-weight: 400;
}
.our-story a:hover {
  color: #0e7a73;
}

@media (max-width: 768px) {
  .page-template-fractional-people {
    padding-top: 166px;
  }
}
.page-template-fractional-people .alert-banner {
  z-index: 30;
  margin: 0;
  gap: 12px;
}
.page-template-fractional-people .alert-banner a {
  text-align: right;
}
@media (max-width: 768px) {
  .page-template-fractional-people .alert-banner {
    height: 80px;
    padding: 8px 20px;
    gap: 16px;
  }
  .page-template-fractional-people .alert-banner a {
    min-width: 80px;
  }
}
.page-template-fractional-people .fractional-header {
  height: 126px;
  z-index: 20;
}
@media (max-width: 768px) {
  .page-template-fractional-people .fractional-header {
    height: 166px;
  }
}
.page-template-fractional-people .fractional-header .container--header {
  max-width: 1164px;
  padding: 0 23px 0 30px;
  height: 86px;
}
.page-template-fractional-people .fractional-header .header-right {
  display: flex;
  gap: 1px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.page-template-fractional-people .fractional-header .header-right a {
  border-radius: 50%;
}
.page-template-fractional-people .fractional-header .header-right a > div {
  padding: 7px;
  position: relative;
}
.page-template-fractional-people .fractional-header .header-right a > div img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  transition: all 0.1s;
}
.page-template-fractional-people .fractional-header .header-right a > div:before {
  position: absolute;
  z-index: -1;
  top: 7px;
  left: 7px;
  right: 0;
  bottom: 0;
  display: block;
  width: 35px;
  height: 35px;
  content: "";
  background: #f2f2f2;
  border-radius: 50%;
  transition: all 0.1s;
}
.page-template-fractional-people .fractional-header .header-right a:hover > div:before {
  background: #5b6770;
}
.page-template-fractional-people .fractional-header .header-right a:hover > div img {
  filter: brightness(100);
}
.page-template-fractional-people .section--decorate {
  overflow: hidden;
}
.page-template-fractional-people .section--decorate .container--header.bg-header {
  position: relative;
  z-index: 0;
}
.page-template-fractional-people .section--decorate .contact__background.header__background {
  left: 33%;
  top: 75px;
}
.page-template-fractional-people .profile-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
}
@media (max-width: 960px) {
  .page-template-fractional-people .profile-header {
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }
}
.page-template-fractional-people .profile-header .left img {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  min-width: 200px;
  object-fit: cover;
  border: 6px solid white;
}
.page-template-fractional-people .profile-header .right {
  max-width: 500px;
}
.page-template-fractional-people .profile-header .right h1 {
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 58px; /* 120.833% */
  margin-bottom: 10px;
}
.page-template-fractional-people .profile-header .right p {
  color: #333a3c;
  font-family: Roboto Slab;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26.819px; /* 167.619% */
  letter-spacing: 0.48px;
}
.page-template-fractional-people .project-cards {
  padding: 80px 0 0;
}
.page-template-fractional-people .project-cards .container {
  width: 100%;
  max-width: none;
  padding: 0;
}
.page-template-fractional-people .project-cards .container .projects__list .projects__item {
  width: 100%;
  max-width: 410px;
}
@media (max-width: 768px) {
  .page-template-fractional-people .project-cards .container .projects__list .projects__item {
    flex-basis: 100%;
    margin-bottom: 44px;
  }
}
.page-template-fractional-people .btn.loadMore {
  margin: 36px auto 0;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  width: 180px;
  transition: all 0.2s;
}
.page-template-fractional-people .btn.loadMore:hover {
  background: #20b59a;
  color: white;
  border-color: #20b59a;
}
.page-template-fractional-people .contact .row {
  max-width: 800px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .page-template-fractional-people .contact .row .col {
    max-width: 350px;
    margin: 0 auto;
  }
}
@media (max-width: 960px) {
  .page-template-fractional-people .contact .row {
    flex-direction: row;
  }
}
@media (max-width: 768px) {
  .page-template-fractional-people .contact .row {
    flex-direction: column;
  }
}
.page-template-fractional-people .contact .contact__background {
  left: 0%;
}
.page-template-fractional-people .footer {
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%; /* 19.2px */
  letter-spacing: 0.9px;
  color: #333a3c;
  font-family: Roboto Slab;
}
.page-template-fractional-people .footer a {
  font-weight: 400;
  text-decoration: none;
  margin-right: 10px;
  border-bottom: 1px solid #7b7f80;
}
.page-template-fractional-people .footer a:hover {
  text-decoration: none;
  border-bottom: 1px solid #20b59a;
}
.page-template-fractional-people .footer a:last-child {
  border-right: none;
  margin-right: -10px;
}
.page-template-fractional-people .footer .flex {
  justify-content: space-between;
}
.page-template-fractional-people .footer .flex > div {
  width: 240px;
}
@media (max-width: 768px) {
  .page-template-fractional-people .footer .flex {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .page-template-fractional-people .footer .flex span {
    margin-bottom: 10px;
  }
}

.your-needs {
  padding-top: 24px;
  padding-bottom: 100px;
}
@media (max-width: 768px) {
  .your-needs .center-heading {
    margin-bottom: 50px;
  }
}
.your-needs h4 {
  color: #5ae6bd;
}
.your-needs h2 {
  color: white;
}
.your-needs .cols {
  margin: 0px auto;
  padding: 26px 20px 0;
  width: auto;
  max-width: 768px;
  gap: 66px;
}
@media (max-width: 768px) {
  .your-needs .cols {
    flex-direction: column;
    align-items: center;
    max-width: 380px;
    padding: 0px 10px 0;
    gap: 0px;
  }
}
.your-needs .cols .col .item {
  max-width: 360px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 7px 0 1px;
}
@media (max-width: 768px) {
  .your-needs .cols .col .item {
    max-width: 380px;
  }
}
.your-needs .cols .col h5 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 21.6px */
  letter-spacing: 0.36px;
}
.your-needs .cols .col p {
  font-family: Roboto Slab;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%; /* 22.4px */
  letter-spacing: 0.56px;
  margin: 0;
}
@media (max-width: 768px) {
  .your-needs .cols .col:last-child h5 {
    margin-top: 60px;
  }
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.testimonial-card,
.locations-card {
  padding: 30px 30px 40px;
  background: white;
  margin: 0 9px;
  box-shadow: 4.15094px 4.15094px 10.37736px 2.07547px rgba(204, 204, 204, 0.1);
}
.testimonial-card img,
.locations-card img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 13px;
}
.testimonial-card h5, .testimonial-card h6,
.locations-card h5,
.locations-card h6 {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  letter-spacing: 0.7px;
  color: #333a3c;
  margin: 0;
}
.testimonial-card h6,
.locations-card h6 {
  font-weight: 400;
}
.testimonial-card p,
.locations-card p {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 25.2px */
  letter-spacing: 0.72px;
  margin-top: 10px;
  margin-bottom: 30px;
  min-height: 130px;
}

.locations-card {
  position: relative;
  height: 432px;
  min-width: 200px;
  transition: all 0.25s;
  padding: 0;
  color: white;
  transition: all 0.25s;
}
.locations-card h3 {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 8px;
}
.locations-card p {
  opacity: 0;
  transition: all 0.25s delay 0.25s;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 300;
  font-family: "Roboto Slab", serif;
}
.locations-card img {
  border-radius: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.locations-card .overlay {
  transition: all 0.25s;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  padding: 24px;
}
.locations-card:hover {
  color: white;
}
.locations-card:hover p {
  opacity: 1;
}
.locations-card:hover .overlay {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
}

.services-mega-menu {
  background-color: #212322;
  width: 921px;
  position: absolute;
  top: 55px;
  opacity: 0;
  right: -5000px;
  z-index: 100;
  padding: 50px 70px 74px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transition: opacity 0.35s, top 0.35s;
}
@media (max-width: 960px) {
  .services-mega-menu {
    display: none;
  }
}
.services-mega-menu.in {
  right: 0;
  top: 78px;
  opacity: 1 !important;
}
.services-mega-menu h2, .services-mega-menu h2 a {
  color: white;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 24px */
  letter-spacing: 0.4px;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  margin-bottom: 30px;
  text-underline-offset: 2px;
}
.services-mega-menu h2.active, .services-mega-menu h2:hover, .services-mega-menu h2 a.active, .services-mega-menu h2 a:hover {
  color: #5ae6bd;
}
.services-mega-menu .menu-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.services-mega-menu .menu-row .capability {
  border-radius: 8px;
  background: #333a3c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 240px;
  height: 180px;
  padding: 30px 30px 20px;
  margin-bottom: 43px;
}
.services-mega-menu .menu-row .capability img {
  width: 100px;
  height: 100px;
  margin: 0;
}
.services-mega-menu .menu-row .capability h4 {
  color: #5ae6bd;
  text-align: center;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.32px;
  margin: 0;
  padding: 0;
  text-transform: none;
}
.services-mega-menu .menu-row .capability.active, .services-mega-menu .menu-row .capability:hover {
  text-decoration: none;
  background: linear-gradient(180deg, #333a3c 0%, #414a50 100%);
}
.services-mega-menu .menu-row .service {
  width: 240px;
}
.services-mega-menu .menu-row .service h3 {
  margin-bottom: 24px;
}
.services-mega-menu .menu-row .service h3 a {
  color: white;
  font-family: "Ciutadella", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 24px */
  letter-spacing: 0.4px;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}
.services-mega-menu .menu-row .service h3 a.active, .services-mega-menu .menu-row .service h3 a:hover {
  color: #5ae6bd;
}
.services-mega-menu .menu-row .service ul {
  margin: 0;
  padding: 0;
}
.services-mega-menu .menu-row .service ul li {
  margin-bottom: 5px;
  margin-left: 11px;
  padding-inline-start: 4px;
  list-style-image: url(/wp-content/themes/fullclarity/assets/src/img/bullet.svg);
}
.services-mega-menu .menu-row .service ul li a {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  letter-spacing: 0.56px;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  padding: 0;
  font-family: "Roboto Slab", serif;
  text-underline-offset: 2px;
}
.services-mega-menu .menu-row .service ul li a:hover {
  color: #5ae6bd;
}