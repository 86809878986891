.page-template-fractional-people {

    @include screen-sm {
        padding-top: 166px;
    }


    .alert-banner {
        z-index: 30;
        margin: 0;
        gap: 12px;

        a {
            text-align: right;
        }

        @include screen-sm {
            height: 80px;
            padding: 8px 20px;
            gap: 16px;

            a {
                min-width: 80px;
            }
        }
    }

    .fractional-header {
        height: 126px;
        z-index: 20;

        @include screen-sm {
            height: 166px;
          }

        .container--header {
          max-width: 1164px;
          padding: 0 23px 0 30px;
          height: 86px;
        }

        .header-right {
          display: flex;
          gap: 1px;
          align-items: center;
          flex-direction: row;
          justify-content: flex-end;


          a {
            border-radius: 50%;

            > div {
                padding: 7px;
                position: relative;

                img {
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    transition: all 0.1s;
                }

                &:before {
                    position: absolute;
                    z-index: -1;
                    top: 7px;
                    left: 7px;
                    right: 0;
                    bottom: 0;
                    display: block;
                    width: 35px;
                    height: 35px;
                    content: '';
                    background: #f2f2f2;
                    border-radius: 50%;
                    transition: all 0.1s;
                }
            }

            &:hover {
                > div {
                    &:before {
                        background: $stone;
                    }

                    img {
                        filter: brightness(100);
                    }
                }
            }
          }
        }
      }


      .section--decorate {
        overflow: hidden;

        .container--header.bg-header {
            position: relative;
            z-index: 0;
        }

        .contact__background.header__background {
            left: 33%;
            top: 75px;
        }
      }


    .profile-header {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 60px;

        @include screen-md {
            flex-direction: column;
            text-align: center;
            gap: 20px;
        }

        .left {
            img {
                border-radius: 50%;
                width: 200px;
                height: 200px;
                min-width: 200px;
                object-fit: cover;
                border: 6px solid white;
            }
        }

        .right {
            max-width: 500px;

            h1 {
                font-size: 48px;
                font-style: normal;
                font-weight: 500;
                line-height: 58px; /* 120.833% */
                margin-bottom: 10px;
            }

            p {
                color: $graphite;
                font-family: Roboto Slab;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 26.819px; /* 167.619% */
                letter-spacing: 0.48px;
            }
        }
    }

    .project-cards {
        padding: 80px 0 0;

        .container {
            width: 100%;
            max-width: none;
            padding: 0;

            .projects__list {
                .projects__item {
                    width: 100%;
                    max-width: 410px;

                    @include screen-sm {
                        flex-basis: 100%;
                        margin-bottom: 44px;
                    }
                }
            }
        }
    }

    .btn.loadMore {
        margin: 36px auto 0;
        left: 50%;
        position: relative;
        transform: translateX(-50%);
        width: 180px;
        transition: all 0.2s;

        &:hover {
            background: $apple;
            color: white;
            border-color: $apple;
        }
    }

    .contact {
        .row {
            max-width: 800px;
            margin: 0 auto;

            .col {
                @include screen-sm {
                    max-width: 350px;
                    margin: 0 auto;
                }
            }

            @include screen-md {
                flex-direction: row;
            }

            @include screen-sm {
                flex-direction: column;
            }
        }

        .contact__background {
            left: 0%;
        }
    }

    .footer {
        padding-top: 25px;
        padding-bottom: 25px;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 160%; /* 19.2px */
        letter-spacing: 0.9px;
        color: $graphite;
        font-family: Roboto Slab;




        a {
            font-weight: 400;
            text-decoration: none;
            margin-right: 10px;
            border-bottom: 1px solid $grey_2;
            &:hover {
                text-decoration: none;
                border-bottom: 1px solid $apple;
            }

            &:last-child {
                border-right: none;
                margin-right: -10px;
            }
        }

        .flex {
            justify-content: space-between;

            > div {
                width: 240px;
            }

            @include screen-sm {
                flex-direction: column;
                align-items: center;
                text-align: center;

                span {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.your-needs {
    padding-top: 24px;
    padding-bottom: 100px;

    .center-heading {
        @include screen-sm {
            margin-bottom: 50px;
        }
    }

    h4 {
        color: $mint;
    }

    h2 {
        color: white;
    }

    .cols {
        margin: 0px auto;
        padding: 26px 20px 0;
        width: auto;
        max-width: 768px;
        gap: 66px;

        @include screen-sm {
            flex-direction: column;
            align-items: center;
            max-width: 380px;
            padding: 0px 10px 0;
            gap: 0px;
        }

        .col {
            .item {
                max-width: 360px;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                margin: 7px 0 1px;

                @include screen-sm {
                    max-width: 380px;
                }
            }

            h5 {
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%; /* 21.6px */
                letter-spacing: 0.36px;
            }

            p {
                font-family: Roboto Slab;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 160%; /* 22.4px */
                letter-spacing: 0.56px;
                margin: 0;
            }

            &:last-child {
                @include screen-sm {
                    h5 {
                        margin-top: 60px;
                    }

                }
            }
        }
    }
}

.flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.align-center {
    align-items: center;
}

.text-center {
    text-align: center;
}

.testimonial-card,
.locations-card {
    padding: 30px 30px 40px;
    background: white;
    margin: 0 9px;
    box-shadow: 4.15094px 4.15094px 10.37736px 2.07547px rgba(204, 204, 204, 0.10);

    img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 13px;
    }

    h5, h6 {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 21px */
        letter-spacing: 0.7px;
        color: $graphite;
        margin: 0;
    }

    h6 {
        font-weight: 400;
    }

    p {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 25.2px */
        letter-spacing: 0.72px;
        margin-top: 10px;
        margin-bottom: 30px;
        min-height: 130px;
    }
}

.locations-card {
    position: relative;
    height: 432px;
    min-width: 200px;
    transition: all 0.25s;
    padding: 0;
    color: white;
    transition: all 0.25s;

    h3 {
        font-size: 18px;
        line-height: 1.2;
        font-weight: 500;
        margin-bottom: 8px;
    }

    p {
        opacity: 0;
        transition: all 0.25s delay 0.25s;
        font-size: 14px;
        line-height: 1.4;
        font-weight: 300;
        font-family: $font-family-secondary;
    }

    img {
        border-radius: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .overlay {
        transition: all 0.25s;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0));
        padding: 24px;
    }

    &:hover {
        color: white;

        p {
            opacity: 1;
        }

        .overlay {
            background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7));
        }
    }
}
