.sprints-overview {
  padding-bottom: 50px;
  padding-top: 65px;

  @include screen-sm {
    padding-bottom: 30px;
    padding-top: 40px;
  }


  &__columns {
    display: flex;
    flex-wrap: wrap;
    margin-left: -38px;
    margin-right: -38px;
    padding-left: 20px;
    padding-right: 20px;

    @include screen-sm {
      margin-left: -15px;
      margin-right: -15px;
    }

    @include screen-sm-custom {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__column {
    flex-basis: 50%;
    padding-left: 38px;
    padding-right: 38px;

    @include screen-sm {
      padding-left: 15px;
      padding-right: 15px;
    }

    @include screen-sm-custom {
      flex-basis: 100%;
    }

    &:first-child {
      @include screen-sm-custom {
        margin-bottom: 28px;
      }
    }
  }

  &__title {
    margin-bottom: 15px;
  }

  &__description {
    color: $graphite;
    margin-bottom: 40px;

    p {
      margin-bottom: 1.3em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__gallery {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    &-item {
      overflow: hidden;
      position: relative;


      img {
        height: 100%;
        object-fit: cover;
        position: absolute;
        width: 100%;

        @include screen-sm-custom {
          position: static;
        }
      }

      &:first-child {
        flex-basis: 38%;
        margin-bottom: 28px;

        @include screen-sm-custom {
          flex-basis: auto;
        }
      }

      &:last-child {
        flex-grow: 1;
      }
    }
  }




}
