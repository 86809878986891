.articles {
  padding-bottom: 80px;
  padding-top: 0px;

  @include screen-md {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  &--static {
    padding-bottom: 0px;

    @include screen-sm {
      //padding-top: 10px;
    }
  }

  &--team-single {
    padding-bottom: 70px;
    padding-top: 80px;

    @include screen-sm {
      padding-top: 60px;
    }

    @include screen-xs {
      padding-top: 40px;
    }



    .container {
      //@include screen-sm {
      //  padding-left: 0;
      //  padding-right: 0;
      //}
    }
  }

  &--article-single {
    .h2 {
      margin-bottom: 50px;

      @include screen-xs {
        margin-bottom: 30px;
      }
    }
  }

  &__list {
    &--preloader {
      display: none;
      padding: 0;

      &.is-visible {
        display: block;
      }
    }

    &--js:empty {
      display: none;
    }

    &--static,
    &--js {
      padding-bottom: 0;
    }

  }

  &__item {
    margin-bottom: 60px;

    @include screen-xs {
      margin-bottom: 40px;
    }
  }
}

.articles-card {
  align-items: center;
  display: flex;
  max-width: 760px;

  @include screen-sm {
    align-items: flex-start;
  }

  @include screen-xs {
    //flex-direction: column;
  }

  &:hover {
    .articles-card {
      &__image {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  &__image {
    flex-shrink: 0;
    height: 148px;
    overflow: hidden;
    position: relative;
    width: 148px;

    @include screen-xs {
      height: 80px;
      width: 80px;
    }

    &::after {
      background-color: $mint;
      bottom: 0;
      content: '';
      height: 6px;
      left: 0;
      opacity: 0;
      position: absolute;
      transition: opacity 0.2s;
      width: 100%;
    }

    a {
      background-color: lighten($font-color-secondary, 30%);
      display: block;
      height: 100%;
    }

    img {
      height: 100%;
      object-fit: cover;
      transition: all 0.26s;
      width: 100%;
    }
  }

  &__details {
    padding-left: 25px;

    @include screen-xs {
      padding-left: 15px;
    }
  }

  &__heading {
    margin-bottom: 5px;

    a {
      color: $font-color;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

    }
  }

  &__tag {
    margin-bottom: 5px;
  }

  &__description {
    color: $graphite;
  }

  &--preloader {
    .articles-card {
      &__placeholder {
        @extend .loading-animation;
        display: inline-block;
        //border-radius: 4px;
      }

      &__details {
        width: 100%;
      }

      &__image {

        @include screen-xs {
          height: 80px;
          //height: 0;
          //padding-bottom: 80%;
          margin-bottom: 15px;
          padding-bottom: 0;
          position: relative;
        }

        span {
          display: block;
          height: 100%;
          width: 100%;

          @include screen-xs {
            left: 0;
            position: absolute;
            top: 0;
          }
        }

      }

      &__tag {
        span {
          height: 1em;
          width: 60px;
        }
      }

      &__heading {
        span {
          height: 1em;
          max-width: 80%;
          width: 100%;
        }
      }

      &__description {
        span {
          height: 1em;
          max-width: 60%;
          width: 100%;
        }
      }
    }
  }

  &.is-hovered {
    .articles-card__heading a {
      text-decoration: underline;
    }
  }

}

.load-more-holder {
  height: 1px;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
}

.jobs {
  .articles-card__details {
    position: relative;
  }

  .career-hero {
    .career_open_tab {
      top: -41px;
    }
  }

  .career_open_tab {
    position: absolute;
    top: 0;
    right: 0;

    color: $graphite;
    text-align: center;
    font-family: $font-family-secondary;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    padding: 8px;
    border-radius: 2px;
    background: $grey-5;

    &.open {
      background: $mint;
    }

    @include screen-xs {
      position: static;
      width: auto;
      display: inline-block;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
}


.summary-block {
  font-family: $font-family-secondary;

  h2 {
    font-size: 36px;
  }

  p {
    color: $black;
    margin-bottom: 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 160%;
    letter-spacing: .64px;
  }
}
