.project-intro {
  display: flex;
  flex-wrap: wrap;

  @include screen-lg {
    flex-direction: column;
  }

  &__title {
    flex: 0 0 100%;
    width: 100%;

    h2 {
      margin-bottom: 40px;
      font-size: 36px;
    }
  }

  &__description {
    flex-basis: calc(100% - 520px);

    @include screen-sm {
      margin-bottom: 20px;
    }

    .content-text {
      p {
        font-size: 16px;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-title {
    margin-bottom: 30px;
  }

  &__details {
    display: flex;
    flex-basis: 520px;
    flex-shrink: 0;
    justify-content: space-between;
    padding-left: 100px;
    max-width: 520px;

    @include screen-lg {
      flex-basis: 100%;
      justify-content: flex-start;
      padding-left: 0;
      margin-top: 40px;
    }

    @include screen-sm {
      flex-direction: column;
    }

    &-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
    }

    &-column {
      flex-basis: 50%;
      padding-left: 20px;
      padding-right: 20px;

      @include screen-lg {
        flex-basis: auto;
      }

      &:first-child {
        flex-grow: 1;
        padding-left: 0;

        @include screen-lg {
          flex-grow: 0;
        }
      }

      &:last-child {
        flex-basis: 200px;
        padding-right: 0;

        @include screen-lg {
          flex-basis: auto;
        }

        @include screen-sm {
          padding-left: 0;
        }
      }
    }
  }

  &__list-item {
    margin-bottom: 40px;
    width: 200px;

    @include screen-sm {
      margin-bottom: 25px;
    }

    @include screen-lg {
      margin-top: 20px;
    }

    @include screen-xs {
      width: 100%;
    }

    .p3 {
      font-size: 14px;
      font-weight: 300;
    }
  }

  &__list-title {
    color: $black;
    font-family: $heading-font;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: get-line-height(14px, 16px);
    margin-bottom: 10px;
    text-transform: none;
  }

  &__list-content {
    color: $font-color-secondary;
    font-size: 16px;

    ul li a {
      font-size: 14px;
      font-weight: 300;
      color: $black;
    }
  }

  a {
    font-weight: 300;
  }

  .icons-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 10px;
    margin-left: -10px;
    margin-right: -10px;

    li {
      flex-basis: auto;
      margin-bottom: 10px;
      max-width: 46px;
      padding: 0 10px;

      img {
        width: 100%;
        height: auto;
        max-height: 26px;
      }
    }
  }

  .technologies__list {
    width: auto;

    @include screen-sm {
      ul {
        margin-bottom: 0;
      }

      li {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
