.competencies {
  background: $grey_7;
  padding-bottom: 60px;
  padding-top: 80px;

  @include screen-sm {
    padding-bottom: 40px;
    padding-top: 60px;
  }

  @include screen-xs {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  &__title {
    margin-bottom: 48px;
    text-align: center;

    @include screen-md {
      margin-bottom: 40px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item {
    align-items: center;
    display: flex;
    flex-basis: 25%;
    flex-direction: column;
    margin-bottom: 45px;
    padding-bottom: 25px;
    padding-top: 50px;
    text-align: center;

    @include screen-sm {
      flex-basis: 33.3333%;
      margin-bottom: 30px;
    }

    @include screen-xs {
      flex-basis: 50%;
      margin-bottom: 20px;
      padding-bottom: 5px;
      padding-top: 20px;
    }

    &-icon {
      display: inline-block;
      height: 52px;
      margin-bottom: 30px;
      width: 52px;

      @include screen-lg {
        height: 46px;
        width: 46px;
      }

      img {
        height: 100%;
        object-fit: contain;
        opacity: 0.8;
        width: 100%;
      }
    }
  }
}
