.scroll-cards {
  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-right: -16px;
  }

  &__item {
    flex-basis: 33.3333%;
    padding: 16px;

    @include screen-lg {
      flex-basis: 50%;
    }

    @include screen-sm {
      flex-basis: 100%;
    }

    &-holder {
      border-radius: 4px;
      box-shadow: 0 2px 8px 0 rgba(64, 54, 78, 0.2);
      display: block;
      height: 100%;
      padding: 40px 30px;
      text-decoration: none;
      transition: all 0.16s;

      @include screen-md {
        padding: 25px 20px;
      }

      &:hover {
        box-shadow: 0 6px 12px 0 rgba(64, 54, 78, 0.18);

        .scroll-cards {
          &__link {
            span {
              text-decoration: none;
            }
          }
        }
      }
    }

    &:nth-child(2) {
      svg {
        transform: rotate(-58deg);
      }
    }

    &:nth-child(3) {
      svg {
        transform: rotate(210deg);
      }
    }

    &:nth-child(4) {
      svg {
        transform: rotate(160deg);
      }
    }

    &:nth-child(5) {
      svg {
        transform: rotate(100deg);
      }
    }

  }

  &__heading {
    align-items: center;
    display: flex;
    margin-bottom: 30px;

    @include screen-xs {
      margin-bottom: 20px;
    }
  }

  &__content {

  }

  &__title {
    color: $font-color;
    font-family: $heading-font;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.67px;
    line-height: get-line-height(20px, 24px);
    padding-left: 30px;

    @include screen-xs {
      padding-left: 20px;
    }
  }

  &__icon {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    height: 46px;
    justify-content: center;
    position: relative;
    text-align: center;
    width: 46px;

    @include screen-md {
      height: 40px;
      width: 40px;
    }

    svg {
      display: block;
      position: absolute;
      width: 100%;
    }

  }

  &__index {
    color: $brand-color;
    display: inline-flex;
    font-family: $heading-font;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1;
    padding-top: 3px;

    @include screen-md {
      font-size: 22px;
    }
  }

  &__description {
    color: $font-color-secondary;
    line-height: get-line-height(18px, 32px);
    margin-bottom: 10px;

    @include screen-xs {
      font-size: 16px;
    }
  }

  &__link {
    span {
      color: $font-color;
      font-family: $heading-font;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1px;
      text-decoration: underline;
    }
  }

  &--row-2 {
    .scroll-cards {
      &__inner {
        margin-left: -40px;
        margin-right: -40px;

        @include screen-lg {
          margin-left: -20px;
          margin-right: -20px;
        }

        @include screen-md {
          margin-left: -15px;
          margin-right: -15px;
        }
      }

      &__item {
        flex-basis: 50%;
        padding: 15px 40px;

        @include screen-lg {
          padding: 15px 20px;
        }

        @include screen-md {
          padding: 15px;
        }

        @include screen-sm {
          flex-basis: 100%;
        }

        &-holder {
          display: flex;

          @include screen-xs {
            flex-direction: column;
          }
        }
      }

      &__icon {
        flex-shrink: 0;
        height: 55px;
        width: 55px;

        @include screen-md {
          height: 40px;
          width: 40px;
        }

        @include screen-xs {
          margin-bottom: 15px;
        }

        img {
          height: 100%;
          object-fit: contain;
          width: 100%;
        }
      }

      &__title {
        margin-bottom: 10px;
        padding-left: 0;
      }

      &__content {
        flex-grow: 1;
        padding-left: 20px;

        @include screen-xs {
          padding-left: 0;
        }
      }
    }
  }
}
