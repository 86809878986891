.goal {
  margin-bottom: 100px;
  padding-top: 85px;

  @include screen-sm {
    margin-bottom: 50px;
  }


  &__inner {
    background: $grey_6;
    padding-bottom: 90px;
    padding-top: 100px;

    @include screen-md {
      padding-bottom: 50px;
    }
  }

  &__image {
    margin-bottom: 70px;
    margin-top: -200px;

    @include screen-sm {
      margin-bottom: 40px;
    }

    img {
      height: auto;
      max-width: 100%;
    }
  }

  &__header {
    //display: flex;
    //padding-left: 100px;
    //padding-right: 100px;
    margin-bottom: 70px;

    @include screen-md {
      //padding-left: 40px;
      //padding-right: 40px;
    }

    @include screen-sm {
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
    }

    @include screen-xs {
      margin-bottom: 40px;
    }

  }

  &__title {

    @include screen-md {
      font-size: 30px;
    }

    @include screen-sm {
      margin-bottom: 15px;
      padding-right: 0;
    }

    @include screen-xs {
      font-size: 24px;
    }
  }

  &__description {

    @include screen-xs {
    }
    color: $graphite;

    @include screen-sm {
      padding-left: 0;
    }
  }

  &__content {
    display: flex;
    justify-content: center;

    .steps__item {
      border-left: none !important;
    }

    .steps__line {
      left: 47.5% !important;
    }
  }

  &--white {
    .goal {
      &__inner {
        background: #fff;
      }
    }
  }

  &--single-team {
    margin-bottom: 60px;

    @include screen-sm {
      margin-bottom: 20px;
      padding-top: 50px;
    }

    .goal {
      &__header {
        margin-bottom: 78px;

        @include screen-md {
          margin-bottom: 60px;
        }

        @include screen-sm {
          margin-bottom: 40px;
        }

        @include screen-xs {
          margin-bottom: 30px;
        }
      }

      &__inner {
        padding-bottom: 0;
        padding-top: 0;
      }
    }
  }

  &--sprints {
    margin-bottom: 62px;
    padding-bottom: 0;
    padding-top: 0;

    @include screen-sm {
      margin-bottom: 30px;
    }

    .goal {
      &__inner {
        padding-top: 80px;

        @include screen-sm {
          padding-top: 50px;
        }
      }

      &__header {
        display: flex;
      }

      &__title {
        flex-basis: 50%;
        max-width: 460px;
        padding-right: 25px;

        @include screen-max {
          max-width: 380px;
        }

        @include screen-sm {
          max-width: unset;
        }
      }

      &__description {
        flex-basis: 50%;
        max-width: 520px;
        padding-left: 5px;

        @include screen-max {
          max-width: 380px;
        }

        @include screen-sm {
          max-width: unset;
        }

        p {
          margin-bottom: 1.3em;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

}
