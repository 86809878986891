.video {
  position: relative;
  overflow: hidden;
  max-width: 773px;
  // height: 425px;
  margin: 0 auto;
  aspect-ratio: 16 / 9;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    background-color: $graphite;
    z-index: 1;
    cursor: pointer;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background: linear-gradient(0deg, rgba(51, 58, 60, 0.6), rgba(51, 58, 60, 0.6));
    }
  }

  &__play {
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 20px;
    cursor: pointer;
    position: relative;
    z-index: 5;

    .icon-Play {
      font-size: 40px;
      width: 40px;
      height: 40px;
    }
  }

  &:hover {
    .video__overlay::after,
    .video__play {
      opacity: 1;
    }
  }
}
