.logos {
  padding-bottom: 50px;
  padding-top: 80px;

  @include screen-md {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  &__title {
    margin-bottom: 66px;
    text-align: center;

    @include screen-md {
      margin-bottom: 40px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item {
    flex-basis: 20%;
    margin-bottom: 43px;
    padding-bottom: 0;
    padding-top: 0;
    text-align: center;

    @include screen-md {
      flex-basis: 25%;
      margin-bottom: 30px;

    }

    @include screen-sm-custom {
      flex-basis: 33.3333%;
      margin-bottom: 20px;
    }

    img {
      display: inline-flex;
      height: 70px;
      max-width: unset;
      width: auto;

      @include screen-sm-custom {
        height: 50px;
      }
    }
  }
}
