$card-width: 275px;
$card-height: 275px;
$card-columns: (
  lg: 6,
  sm: 2,
  xs: 1,
);
$card-gap: 20px;
$card-icon-size: 46px;
$card-title-font-size: 20px;
$card-content-font-size: 14px;

.project-cards {
  width: 100%;
  max-width: 1184px;
  padding: 0 80px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 80px 20px;

  @include screen-sm {
    padding: 0 20px;
    gap: 40px 20px;
  }

  &.wider {
    max-width: 1180px;
    padding: 0 50px;

    @include screen-sm {
      padding: 0 20px;
    }
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 20px;
  gap: 10px;

  background-color: $grey_6;

  max-width: $card-width;
  min-height: $card-height;

  @include screen-sm {
    min-height: unset;
  }

  .link_on_hover {
    visibility: hidden;
  }

  &:hover {
    .link_on_hover {
      visibility: visible;
    }
  }

  &__logo {
    margin-bottom: 6px;
    height: 42px;
  }

  &__number {
    border: 2px solid $black;
    border-radius: 50%;
    min-width: 48px;
    width: 48px;
    height: 48px;
    text-align: center;
    position: relative;
    display: inline-block;
    font-weight: 500;
    font-size: 24px;
    padding-top: 2px;
    top: -4px;
  }

  &__title {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    color: $black;

    @include screen-lg {
      flex-wrap: wrap;
    }

    @include screen-sm {
      flex-wrap: nowrap;
    }

    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 120%; /* 28.8px */
      letter-spacing: 0.4px;
      margin-bottom: 8px;
    }

    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: $card-title-font-size;
      text-transform: none;
    }

    img {
      width: $card-icon-size;
      height: $card-icon-size;
    }
  }

  &__content {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 300;
    font-size: $card-content-font-size;
    line-height: 160%;
    color: $black;
    flex-grow: 1;
  }

  &__buttons {
    margin-bottom: 8px;
  }

  &__expand {
    position: absolute;
    padding: 24px 20px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $black;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.16s;

    p {
      font-family: "Roboto Slab";
      font-style: normal;
      font-weight: 300;
      font-size: $card-content-font-size;
      line-height: 160%;
      color: $grey_5;
    }
  }

  &.card--active {
    .card__expand {
      opacity: 1;
      visibility: visible;
    }
  }

  &--large {
    background-color: $white;
    max-width: 368px;
    padding: 32px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);

    .card {
      &__logo {
        margin-bottom: 20px;
        width: auto;
      }
    }

    h2 {
      font-size: 36px;
      letter-spacing: 0.72px;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 160%;
      color: $graphite;
      letter-spacing: 0.64px;
    }

    .link {
      margin-top: 33px;
    }
  }

  &--media {
    padding: 0;
    max-width: unset;
    background-color: transparent;
    gap: 16px;
    width: 100%;
    cursor: pointer;

    .card {
      &__content {
        padding: 16px 0;
      }
      &__media {
        width: 100%;
        overflow: hidden;
        position: relative;
        aspect-ratio: 16 / 9;
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          object-fit: cover;
        }
      }

      &__title h4 {
        font-size: 24px;
      }

      &__subtitle {
        margin: -6px 0 -10px;
      }

      &__buttons {
        margin-top: auto;
      }
      &__logo {
        height: 30px;
        display: flex;
        align-items: center;

        img {
          height: auto;
          max-height: 100%;
          width: auto;
          height: 30px;
        }
      }
    }
  }

  &--small {
    .card__media {
      height: auto;
    }

    .card__logo img {
      height: 30px;
      width: auto;
    }

    h3 {
      font-family: Roboto Slab;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 160%;
      letter-spacing: 0.72px;
    }
  }
}

@function get-card-layout-width($screen-size: lg) {
  @return $card-width * map-get($card-columns, $screen-size) +
    ($card-gap * map-get($card-columns, $screen-size) - 1);
}

// Layout
.cards {
  display: grid;
  max-width: get-card-layout-width();
  margin: 0 auto;
  grid-gap: 20px;
  grid-template-columns: repeat(#{map-get($card-columns, lg)}, 1fr);

  @include screen-sm {
    grid-template-columns: repeat(#{map-get($card-columns, sm)}, 1fr);
    max-width: get-card-layout-width(sm);
    grid-gap: 32px;
  }

  @include screen-xs {
    grid-template-columns: repeat(#{map-get($card-columns, xs)}, 1fr);
    max-width: 100%;

    .card {
      max-width: 100%;
    }
  }

  .card {
    grid-column: span 2;
    max-width: 100%;
  }

  // 2/2 layout
  &--quad {
    .card {
      grid-column: span 3;
      max-width: 100%;
    }
  }

  // 2/3
  &--penta {
    .card:nth-child(-n + 2) {
      grid-column: span 3;
      max-width: 100%;
    }

    .card:nth-child(n + 3) {
      grid-column: span 2;
      max-width: 100%;
    }
  }
}

.card-expand-section {
  &__title {
    max-width: get-card-layout-width();
    margin: 0 auto 40px;

    h2 {
      margin-bottom: 20px;
    }
  }

  @include screen-sm {
    padding: 32px 30px;
  }
}

.card-carousel,
.testimonial-card-carousel,
.locations-card-carousel {
  position: relative;
  margin: 32px -16px 0 -16px;

  &-block {
    h2 {
      letter-spacing: .72px;
      font-size: 36px;
      margin-bottom: 0;
    }
  }
  .card {
    padding: 0 16px;
    height: 100%;
  }

  &__header {
    display: flex;

    div:first-of-type {
      flex-grow: 1;
    }

    .navigation {
      position: relative;
      display: flex;
      flex-direction: row-reverse;
      width: 42px;

      .swiper-button-next,
      .swiper-button-prev {
        position: relative;
        padding: 22px 0px;
        width: 28px;
        margin-left: 0;
        right: 0;

        span {
          padding-left: 5px;
        }

        &::after {
          content: none;
        }
      }

      .swiper-button-prev {
        left: 0;
        right: auto;
        span {
          padding-left: 0px;
          padding-right: 5px;
        }
      }
    }
  }

  .quote-card {
    margin: 0;
    width: unset;
    padding-left: 16px;
    padding-right: 16px;


    .quote {
      font-size: 20px;
    }

    .quote__logo {
      margin-bottom: 14px;
    }
  }
}

.card-link {
  text-decoration: none;
  &:hover {
    color: initial;

    .card__title {
      text-decoration: underline;
    }
  }
}

a.card {
  text-decoration: none;

  &:hover {
    cursor: pointer;

    h2, h3 {
      text-decoration: underline;
    }

    color: $black;

    .link {
      color: $forest;
    }
  }
}
