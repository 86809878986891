.outcomes {
  margin-bottom: 40px;
  padding-top: 80px;

  @include screen-sm {
    margin-bottom: 20px;
    padding-top: 40px;
  }

  &__inner {


  }

  &__header {
    display: flex;
    padding-bottom: 44px;

    @include screen-sm {
      flex-direction: column;
      padding-bottom: 30px;
    }
  }

  &__text {
    flex-basis: 50%;
    padding-right: 30px;

    @include screen-sm {
      margin-bottom: 15px;
      padding-right: 0;
    }
  }

  &__title {

    @include screen-md {
    }

    @include screen-xs {
    }
    color: $global-border;
    margin-bottom: 30px;

    @include screen-sm {
      margin-bottom: 15px;
      padding-right: 0;
    }
  }

  &__description {

    @include screen-xs {
    }
    color: $graphite;


    @include screen-sm {
      padding-left: 0;
    }

    p {
      margin-bottom: 1.3em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__image {
    flex-basis: 50%;
    padding-left: 24px;
    padding-right: 17px;

    @include screen-sm {
      padding-left: 0;
    }

    img {
      height: auto;
    }
  }

  &__content {
    padding: 120px 50px;

    @include screen-md {
      padding: 120px 0;
    }

    @include screen-sm {
      padding: 60px 0;
    }

    @include screen-xs {
      padding: 40px 0;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;

    margin-bottom: -50px;
    margin-left: -14px;
    margin-right: -14px;

    @include screen-sm {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  &__item {
    flex-basis: 33.3333%;
    margin-bottom: 50px;
    padding-left: 14px;
    padding-right: 14px;

    @include screen-sm {
      flex-basis: 50%;
      margin-bottom: 40px;
      padding-left: 15px;
      padding-right: 15px;
    }

    @include screen-sm-custom {
      flex-basis: 100%;
      margin-bottom: 25px;
    }

    &-group {
      display: flex;
      flex-direction: column;
    }


    &-link {
      background-color: $grey_7;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      padding: 20px;
      text-decoration: none;
      transition: background-color 0.16s;

      .link {
        color: $stone;
      }

      &:hover {
        background-color: #f1f1f1;

        .link {
          color: $apple;
        }

        .outcomes {
          &__item {
            &-title {
              &-caption {
                text-decoration: underline;
              }

            }
          }
        }
      }

    }

    &-title {
      color: $black;
      display: flex;
      margin-bottom: 33px;
      padding-top: 15px;

      &-caption {
        padding-left: 19px;
      }

      &-index {
        align-items: center;
        display: flex;
        font-family: $font-family-secondary;
        font-size: 15px;
        font-weight: 600;
        height: 42px;
        justify-content: center;
        position: relative;
        text-align: center;
        width: 42px;

        svg {
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
    }

    &-description {

      @include screen-xs {
      }
      color: $graphite;
      margin-bottom: 19px;
      min-height: 93px;

      @include screen-sm-custom {
        min-height: unset;
      }
    }
  }

  &--team-single {
    background-color: $white;
    margin-bottom: 0;
    padding-bottom: 60px;
    padding-top: 100px;

    @include screen-sm {
      padding-bottom: 30px;
      padding-top: 60px;
    }

    .benefits {
      &__header {

        @include screen-sm {
          margin-bottom: 30px;
        }

        @include screen-xs {
          margin-bottom: 10px;
        }
      }

      &__list {
        margin-left: -45px;
        margin-right: -45px;

        @include screen-sm {
          margin-left: -15px;
          margin-right: -15px;
        }
      }

      &__item {
        flex-basis: 50%;
        margin-bottom: 82px;
        padding-left: 45px;
        padding-right: 45px;

        @include screen-sm {
          margin-bottom: 40px;
          padding-left: 15px;
          padding-right: 15px;
        }

        @include screen-sm-custom {
          flex-basis: 100%;
          margin-bottom: 25px;
        }
      }

      &__title {
        margin-bottom: 0;
      }
    }
  }

}
