.digital-business-card {
  display: flex;
  flex-direction: column;
  max-width: 475px;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      color: $grey_1;
      font-size: 20px;
      text-transform: initial;
    }

    h3 {
      color: $forest;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
    }
  }

  &__avatar {
    margin-bottom: 24px;
    border-radius: 50%;
  }

  &__socials {
    display: flex;
    gap: 32px;
    margin-top: 64px;
    margin-bottom: 80px;
  }

  &__content {
    padding: 0 32px 58px;
    flex-grow: 1;

    .p2 {
      font-size: 16px;
    }
  }

  &__footer {
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 10;

    .btn--cta {
      width: 100%;
      height: 70px;

      font-size: 18px;
      font-weight: 500;
      text-transform: initial;
    }
  }

  &__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
  }

  &__section {
    .about-img {
      max-height: 170px;
      overflow: hidden;
      margin: 48px 0 40px;
    }

    &--grey {
      background: $grey_6;
      padding: 80px 32px 58px;
    }
  }

  .inline-icon {
    display: flex;
    align-items: center;
    gap: 18px;
    margin-top: 80px;
  }
}
